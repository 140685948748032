import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Divider } from 'antd'
import {
  multiChoice,
  welcomePage,
  responsiveMultiMap,
  conjointAnalysis,
  imageChoice,
  singleTextbox,
  responsiveMatrix,
  commentBox,
  multiSelect,
  input,
  ranking,
  multiCardChoice,
  inputFieldNumber,
  endPage,
  multiDropdown,
  responsiveMatrixTable,
} from '../question-types/QuestionTypes'
import { smButton, regButton, lgButton, xlButton } from '../ui/Layout/ButtonContainer'
import { ReactComponent as revenue } from '../../assets/images/HCF/revenue.svg'
import leftBackgroundImage from '../../assets/images/FH/dairycow.jpg'
import HCFLogo from '../../assets/images/StepChange/DairyNZ.png'
import conferLogo from '../../assets/images/Confer-logo.png'
import { Textbox } from '../../assets/styles/global'
import { v4 as uuidv4 } from 'uuid'
import { ReactComponent as Happy } from '../../assets/images/Scarlatti/Happy Face.svg'
import { ReactComponent as Ok } from '../../assets/images/Scarlatti/Ok face.svg'
import { ReactComponent as Sad } from '../../assets/images/Scarlatti/Sad Face.svg'
import { ReactComponent as VeryHappy } from '../../assets/images/Scarlatti/Very happy Face.svg'
import { ReactComponent as VerySad } from '../../assets/images/Scarlatti/Very sadFace 1.svg'
import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import { ReactComponent as QuestionMark } from '../../assets/images/PIAS/question-mark-icon.svg'

const IconHappy = styled(Happy)`
  width: 3rem;
  height: 3rem;
`
const IconOk = styled(Ok)`
  width: 3rem;
  height: 3rem;
`
const IconSad = styled(Sad)`
  width: 3rem;
  height: 3rem;
`
const IconVeryHappy = styled(VeryHappy)`
  width: 3rem;
  height: 3rem;
`
const IconVerySad = styled(VerySad)`
  width: 3rem;
  height: 3rem;
`
const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width:24.5rem; */
`

const IconRevenue = styled(revenue)`
  height: 3rem;
`

const Text = styled.p`
  font-weight: 600;
  /* font-size: 1.035rem; */
`

const IconQuestionMark = styled(QuestionMark)`
  width: 3.5rem;
  height: 3rem;
  /* margin-top: -0.2rem; */
  fill: #4d4d7d;
`

const StyledP = styled.p`
  line-height: 2;
  font-size: 1rem;
  /* color: rgba(0, 0, 0, 0.65); */
  margin-bottom: 0rem;
  margin-top: 1rem;
  /* @media (min-width: 0px) and (max-width: 479px) {
    width: 100%;
    font-size:1rem;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    width: 100%;
    font-size:1rem;
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    width: 100%;
    font-size:1rem;
  } */
`
const Disclaimer = styled.p`
  font-style: italic;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  /* width: 800px; */
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: ${props => (props.size ? '49rem' : '35rem')};

  @media (min-width: 0px) and (max-width: 479px) {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    /* margin-left: 1.4rem; */
  }
  @media (min-width: 480px) and (max-width: 768px) {
    display: flex;
    width: 90vw;
    justify-content: flex-start;
    align-items: baseline;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    display: flex;
    /* width: 80vw; */
    justify-content: flex-start;
    align-items: baseline;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  p {
    margin-bottom: 0rem;
  }
  .ant-divider-horizontal {
    margin: 0px 0;
    border-top: 1px solid #c3c3c3;
    width: 49rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 80vw;
    .ant-divider-horizontal {
      width: 90vw;
    }
  }
  /* align-items: center; */
`
// used as Unique Id to send to Conjoint API

const DairyNZWellbeing = {
  questions: [
    //Survey Intro Page
    {
      id: 'intro-page',
      type: welcomePage,
      buttonSize: lgButton.width,
      text: () => {
        return (
          <div>
            <div>
              {/* <h1>Farm manager value proposition</h1> */}
              <p>
                Thanks for taking this wellbeing assessment. We’re keen to get a good understanding
                of the team’s wellbeing and whether there’s anything we can change on-farm to help
                you out. This assessment will ask questions about:
              </p>
              <p>
                <ul style={{ marginLeft: '1rem' }}>
                  <li>Sleep</li>
                  {/* <li>Cognitive functioning</li> */}
                  <li>Social connections</li>
                  <li>Depression and anxiety</li>
                  <li>Financial, spiritual and cultural health</li>
                  <li>Job satisfaction</li>
                </ul>
              </p>
              <p>
                We will use your results to change processes and policies on-farm, where possible,
                and support you as best we can so you are happy and healthy in and outside of work.{' '}
              </p>
              <p>Your results will be anonymous.</p>
              <p>
                If you are feeling frustrated or isolated, do reach out to the Rural Support Trust –
                0800 787 254 (0800 RURAL HELP).
              </p>
            </div>
            {/* <div>
              <h1>Project manager value proposition</h1>
              <p>
                Thanks for taking this wellbeing assessment. We’re keen to get a good understand of
                the team’s wellbeing. We’re keen to use this information to see whether system
                changes as a result of our project have affected your wellbeing, and if there’s
                anything we can change in our projects to ease any pressures.
              </p>
              <p>
                We will use your results to adapt our project’s processes and the way we interact
                with you, where possible.
              </p>
              <p>Your results will be anonymous.</p>
            </div> */}
          </div>
        )
      },
    },
    // 1.1
    {
      id: '1.1',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 1</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text: 'In the past month, how often have you felt energised?',
          value: 'In the past month, how often have you felt energised?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Never',
          value: 'Never',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'Some of the time',
          value: 'Some of the time',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'Half of the time',
          value: 'Half of the time',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Most of the time',
          value: 'Most of the time',
          icon: <IconHappy />,
          submit: true,
        },

        {
          id: 5,
          text: 'All the time',
          value: 'All the time',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
    },
    // 2.1
    {
      id: '2.1',
      type: responsiveMatrix,
      buttonSize: lgButton.width,
      hideQuestionText: true,
      required: true,
      useIcons: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 2</Text>
          <Divider />
        </Wrapper>
      ),
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text: 'In the past month, how often have you felt cheerful and in good spirits?',
          value: 'In the past month, how often have you felt cheerful and in good spirits?',
          html: (
            <p>
              In the past month, how often have you <b>felt</b> cheerful and in good spirits?
            </p>
          ),
        },
      ],
      options: [
        {
          id: 1,
          text: 'Never',
          value: 'Never',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'Some of the time',
          value: 'Some of the time',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'Half of the time',
          value: 'Half of the time',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Most of the time',
          value: 'Most of the time',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 5,
          text: 'All the time',
          value: 'All the time',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
    },
    // 3.1
    {
      id: '3.1',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 3</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      choices: [
        {
          id: 1,
          value:
            'In the past month, how often has your daily life been filled with things that interest you?',

          text:
            'In the past month, how often has your daily life been filled with things that interest you?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Never',
          value: 'Never',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'Some of the time',
          value: 'Some of the time',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'Half of the time',
          value: 'Half of the time',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Most of the time',
          value: 'Most of the time',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 5,
          text: 'All the time',
          value: 'All the time',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
    },
    // 4.1
    {
      id: '4.1',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 4</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text: 'In the past month, how useful have you felt?',
          value: 'In the past month, how useful have you felt?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'A little',
          value: 'A little',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 3,
          text: 'Fairly',
          value: 'Fairly',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 4,
          text: 'Very',
          value: 'Very',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
    },
    // 5.1
    {
      id: '5.1',
      type: responsiveMatrix,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 5</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      hideQuestionText: true,
      useIcons: true,
      choices: [
        {
          id: 1,
          text: 'In the past month, how often did you feel that everything was an effort?',
          value: 'In the past month, how often did you feel that everything was an effort?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'All the time',
          value: 'All the time',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'Most of the time',
          value: 'Most of the time',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'Half of the time',
          value: 'Half of the time',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Some of the time',
          value: 'Some of the time',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 5,
          text: 'Never',
          value: 'Never',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
    },
    // 6.1
    {
      id: '6.1',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 6</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text:
            'In the past month, how often did you feel like you could not get going (throughout the day)?',
          value: 'In the past month, how often did you feel like you could not get going?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'All the time',
          value: 'All the time',
          submit: true,

          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Most of the time',
          value: 'Most of the time',
          submit: true,

          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Half of the time',
          value: 'Half of the time',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Some of the time',
          value: 'Some of the time',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 5,
          text: 'Never',
          value: 'Never',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = [
          'All the time',
          'Most of the time',
          'Half of the time',
          'Some of the time',
        ]
        let userAnswer = answers['6.1'].value[0].value

        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '6.2'
        } else {
          return '7.1'
        }
      },
    },
    // 6.2
    {
      id: '6.2',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 6A</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text: 'How much did work get in the way of your ability to get going?',
          value: 'How much did work get in the way of your ability to get going?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'A lot',
          value: 'A lot',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'A fair amount',
          value: 'A fair amount',
          icon: <IconSad />,
          submit: true,
        },

        {
          id: 4,
          text: 'A little bit',
          value: 'A little bit',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 5,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = ['A lot', 'A fair amount', 'A little bit']
        let userAnswer = answers['6.2'].value[0].value

        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '6.3'
        } else {
          return '7.1'
        }
      },
    },
    // 6.3
    {
      id: '6.3',
      type: multiChoice,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 6B</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      // useIcons: true,
      text: 'What specifically about work affected your ability to get going?',
      disclaimer: 'Pick the one that affected you most.',
      choices: [
        {
          id: 1,
          text: 'Deadlines',
          value: 'Deadlines',
        },
        {
          id: 2,
          text: 'Conflict with someone at work',
          value: 'Conflict with someone at work',
        },
        {
          id: 3,
          text: 'Feeling out of my depth / lack of skills',
          value: 'Feeling out of my depth / lack of skills',
        },
        {
          id: 4,
          type: 'input',
          text: 'Other, please describe',
          value: '',
        },
      ],
    },
    // 7.1
    {
      id: '7.1',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 7</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text: 'In the past month, how often have you felt calm and relaxed?',
          value: 'In the past month, how often have you felt calm and relaxed?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Never',
          value: 'Never',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'Some of the time',
          value: 'Some of the time',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'Half of the time',
          value: 'Half of the time',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Most of the time',
          value: 'Most of the time',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 5,
          text: 'All the time',
          value: 'All the time',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
    },
    // 8.1
    {
      id: '8.1',
      type: responsiveMatrix,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 8</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      hideQuestionText: true,
      useIcons: true,
      choices: [
        {
          id: 1,
          text: 'In the past month, how often did you feel nervous?',
          value: 'In the past month, how often did you feel nervous?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'All the time',
          value: 'All the time',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'Most of the time',
          value: 'Most of the time',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'Half of the time',
          value: 'Half of the time',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Some of the time',
          value: 'Some of the time',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 5,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = [
          'Some of the time',
          'Half of the time',
          'Most of the time',
          'All the time',
        ]
        let userAnswer = answers['8.1'].value[0].value
        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '8.2'
        } else {
          return '9.1'
        }
      },
    },
    // 8.2
    {
      id: '8.2',
      type: responsiveMatrix,
      hideQuestionText: true,
      useIcons: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 8A</Text>
          <Divider />
        </Wrapper>
      ),
      choices: [
        {
          id: 1,
          text: 'How much did work cause you to feel nervous?',
          value: 'How much did work cause you to feel nervous?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'A lot',
          value: 'A lot',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'A fair amount',
          value: 'A fair amount',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'A little',
          value: 'A little',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = ['A little', 'A fair amount', 'A lot']
        let userAnswer = answers['8.2'].value[0].value
        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '8.3'
        } else {
          return '9.1'
        }
      },
    },
    // 8.3
    {
      id: '8.3',
      type: multiChoice,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 8C</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      // useIcons: true,
      text: 'What specifically about work made you nervous?',
      disclaimer: 'Pick the one that affected you most.',
      choices: [
        {
          id: 1,
          text: 'Deadlines',
          value: 'Deadlines',
        },
        {
          id: 2,
          text: 'Conflict with someone at work',
          value: 'Conflict with someone at work',
        },
        {
          id: 3,
          text: 'Feeling out of my depth / lack of skills',
          value: 'Feeling out of my depth / lack of skills',
        },
        {
          id: 4,
          type: 'input',
          text: 'Other, please describe',
          value: '',
        },
      ],
    },
    // 9.1
    {
      id: '9.1',
      type: responsiveMatrix,
      required: true,
      hideQuestionText: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 9</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text: 'In the past month, how often did you wake up feeling refreshed and rested?',
          value: 'In the past month, how often did you wake up feeling refreshed and rested?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Never',
          value: 'Never',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'A little',
          value: 'A little',
          icon: <IconOk />,
          submit: true,
        },

        {
          id: 4,
          text: 'A fair amount',
          value: 'A fair amount',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 5,
          text: 'A lot',
          value: 'A lot',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
    },
    // 10.1
    {
      id: '10.1',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 10</Text>
          <Divider />
        </Wrapper>
      ),
      // confusing use
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text: 'In the past month, how often did you have trouble sleeping?',
          value: 'In the past month, how often did you have trouble sleeping?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'All the time',
          value: 'All the time',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'Most of the time',
          value: 'Most of the time',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'Half of the time',
          value: 'Half of the time',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Some of the time',
          value: 'Some of the time',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 5,
          text: 'Never',
          value: 'Never',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = [
          'Some of the time',
          'Half of the time',
          'Most of the time',
          'All the time',
        ]
        let userAnswer = answers['10.1'].value[0].value

        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '10.2'
        } else {
          return '11.1'
        }
      },
    },
    // 10.2
    {
      id: '10.2',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 10A</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text: 'How much did work contribute to your trouble sleeping?',
          value: 'How much did work contribute to your trouble sleeping?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'A lot',
          value: 'A lot',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'A fair amount',
          value: 'A fair amount',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'A little',
          value: 'A little',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = ['A lot', 'A fair amount', 'A little']
        let userAnswer = answers['10.2'].value[0].value

        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '10.3'
        } else {
          return '11.1'
        }
      },
    },
    // 10.3
    {
      id: '10.3',
      type: multiChoice,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 10B</Text>
          <Divider />
        </Wrapper>
      ),
      text: 'What about work affected your sleep?',
      disclaimer: 'Pick the one that affected you most.',
      choices: [
        {
          id: 1,
          text: 'Shift work / irregular hours messed up my sleep patterns',
          value: 'Shift work / irregular hours messed up my sleep patterns',
        },
        {
          id: 2,
          text: 'Deadlines and stressors made me restless',
          value: 'Deadlines and stressors made me restless',
        },
        {
          id: 3,
          text: 'Excitement and enthusiasm made me want to be working',
          value: 'Excitement and enthusiasm made me want to be working',
        },
        {
          id: 4,
          type: 'input',
          text: 'Other, please describe.',
          value: '',
        },
      ],
    },
    // 11.1
    {
      id: '11.1',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 11</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text: 'In the past month, how well do you think you’ve been dealing with problems?',
          value: 'In the past month, how well do you think you’ve been dealing with problems?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Poorly',
          value: 'Poorly',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'Okay',
          value: 'Okay',
          icon: <IconSad />,
          submit: true,
        },

        {
          id: 4,
          text: 'Well',
          value: 'Well',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 5,
          text: 'Very well',
          value: 'Very well',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
    },
    // 12.1
    {
      id: '12.1',
      type: responsiveMatrix,
      hideQuestionText: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 12</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      required: true,
      choices: [
        {
          id: 1,
          value: 'In the past month, how often did you find you were thinking clearly?',
          text: 'In the past month, how often did you find you were thinking clearly?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Never',
          value: 'Never',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'Some of the time',
          value: 'Some of the time',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'Half of the time',
          value: 'Half of the time',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Most of the time',
          value: 'Most of the time',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 5,
          text: 'All the time',
          value: 'All the time',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
    },
    // 13.1
    {
      id: '13.1',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 13</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text:
            'In the past month, how satisfied were you with your friendships and family relationships?',
          value:
            'In the past month, how satisfied were you with your friendships and family relationships?',
        },
        // {
        //   id: 2,
        //   text: '2) How much did work negatively influence your satisfaction with your relationships?',
        //   value: 'How much did work negatively influence your satisfaction with your relationships?',
        // },
      ],
      options: [
        {
          id: 1,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'A little',
          value: 'A little',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 3,
          text: 'A fair amount',
          value: 'A fair amount',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 4,
          text: 'Very',
          value: 'Very',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = ['Not at all', 'A little', 'A fair amount']
        let userAnswer = answers['13.1'].value[0].value

        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '13.2'
        } else {
          return '14.1'
        }
      },
    },
    // 13.2
    {
      id: '13.2',
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 13A</Text>
          <Divider />
        </Wrapper>
      ),
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text:
            'How much did work negatively influence your satisfaction with your friendships and family relationships?',
          value:
            'How much did work negatively influence your satisfaction with your friendships and family relationships?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'A lot',
          value: 'A lot',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'A fair amount',
          value: 'A fair amount',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'A little',
          value: 'A little',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = ['A lot', 'A fair amount', 'A little']
        let userAnswer = answers['13.2'].value[0].value

        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '13.3'
        } else {
          return '14.1'
        }
      },
    },
    // 13.3
    {
      id: '13.3',
      type: multiChoice,
      mobileHeight: '4rem',
      buttonSize: lgButton.width,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 13B</Text>
          <Divider />
        </Wrapper>
      ),
      text: 'What was it about work that got in the way of your relationships? ',
      disclaimer: 'Pick the one that affected you most.',
      choices: [
        {
          id: 1,
          text: 'Work ate into my time with friends and family',
          value: 'Work ate into my time with friends and family',
        },
        {
          id: 2,
          text:
            'Work physically drained me so I didn’t have the energy to interact with friends and family',
          value:
            'Work physically drained me so I didn’t have the energy to interact with friends and family',
        },
        {
          id: 3,
          text:
            "Work mentally drained me so I didn't have time to help and support friends and family",
          value:
            "Work mentally drained me so I didn't have time to help and support friends and family",
        },
        {
          id: 4,
          text: 'Work required so much socialising that I didn’t really want to be around people',
          value: 'Work required so much socialising that I didn’t really want to be around people',
        },
        {
          id: 5,
          type: 'input',
          text: 'Other, please describe.',
          value: '',
        },
      ],
    },
    // 14.1
    {
      id: '14.1',
      type: responsiveMatrix,
      required: true,
      hideQuestionText: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 14</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      choices: [
        {
          id: 1,
          value: 'In the past month, how financially secure have you felt?',
          text: 'In the past month, how financially secure have you felt?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'A little',
          value: 'A little',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 3,
          text: 'Fairly',
          value: 'Fairly',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 4,
          text: 'Very',
          value: 'Very',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
      // next: ({ answers }) => {
      //   let possValues = ['Not at all', 'A little', 'Fairly']
      //   let userAnswer = answers['14.1'].value[0].value

      //   const found = possValues.find(ua => userAnswer === ua)
      //   if (found) {
      //     return '14.2'
      //   } else {
      //     return '15.1'
      //   }
      // },
    },
    // 14.2
    // {
    //   id: '14.2',
    //   type: multiChoice,
    //   buttonSize: lgButton.width,
    //   required: true,
    //   text: '14.2 Is the reason you’re not feeling financially secure due to your salary or wage? ',
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'Yes',
    //       value: 'Yes',
    //       icon: <Check fill="currentColor" />,
    //       fill: '#50d250',
    //     },
    //     {
    //       id: 2,
    //       text: 'No',
    //       value: 'No',
    //       icon: <Cross fill="currentColor" />,
    //       fill: '#1890ff',
    //     },
    //   ],
    // },
    // 15.1
    {
      id: '15.1',
      type: responsiveMatrix,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 15</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      // useIcons: true,
      hideQuestionText: true,
      useIcons: true,
      choices: [
        {
          id: 1,
          text:
            'How well have your spiritual and / or religious needs been met over the past month?',
          value:
            'How well have your spiritual and / or religious needs been met over the past month?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'Not much',
          value: 'Not much',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'Mostly',
          value: 'Mostly',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 4,
          text: 'Fully',
          value: 'Fully',
          icon: <IconVeryHappy />,
          submit: true,
        },
        {
          id: 5,
          text: 'I don’t think I have spiritual and / or religious needs',
          value: 'I don’t think I have spiritual and / or religious needs',
          paddingLeft: '4rem',
          icon: <Cross fill="currentColor" />,
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = ['Fully', 'I don’t think I have spiritual and / or religious needs']
        let userAnswer = answers['15.1'].value[0].value

        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '16.1'
        } else {
          return '15.2'
        }
      },
    },
    // 15.2
    {
      id: '15.2',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 15A</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text:
            'How much did work affect how well your spiritual and / or religious needs were met?',
          value:
            'How much did work affect how well your spiritual and / or religious needs were met?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'A lot',
          value: 'A lot',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'A fair amount',
          value: 'A fair amount',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'A little',
          value: 'A little',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = ['A little', 'A fair amount', 'A lot']
        let userAnswer = answers['15.2'].value[0].value

        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '15.3'
        } else {
          return '16.1'
        }
      },
    },
    // 15.3
    {
      id: '15.3',
      type: multiChoice,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 15B</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      // useIcons: true,
      text:
        'What specifically about work meant your spiritual and / or religious needs were not met?',
      disclaimer: 'Pick the one that affected you most.',
      choices: [
        {
          id: 1,
          text: 'Work got in the way of my spiritual and / or religious commitments ',
          value: 'Work got in the way of my spiritual and / or religious commitments ',
        },
        {
          id: 2,
          text: 'There is no one else who shares my beliefs at work',
          value: 'There is no one else who shares my beliefs at work',
        },
        {
          id: 3,
          text: 'People make fun of my beliefs',
          value: 'People make fun of my beliefs',
        },

        {
          id: 4,
          type: 'input',
          text: 'Other, please describe',
          value: '',
        },
      ],
    },
    // 16.1
    {
      id: '16.1',
      type: responsiveMatrix,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 16</Text>
          <Divider />
        </Wrapper>
      ),
      hideQuestionText: true,
      required: true,
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text:
            'In the past month, how often did you feel isolated because of your culture or identity? ',
          value:
            'In the past month, how often did you feel isolated because of your culture or identity? ',
        },
      ],
      options: [
        {
          id: 1,
          text: 'All the time',
          value: 'All the time',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'Most of the time',
          value: 'Most of the time',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'Half of the time',
          value: 'Half of the time',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Some of the time',
          value: 'Some of the time',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 5,
          text: 'Never',
          value: 'Never',
          icon: <IconVeryHappy />,
          submit: true,
        },
        {
          paddingLeft: '4rem',
          icon: <Cross />,
          id: 6,
          text: 'I don’t think I have cultural or identity needs',
          value: 'I don’t think I have cultural or identity needs',
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = [
          'All the time',
          'Most of the time',
          'Half of the time',
          'Some of the time',
        ]
        let userAnswer = answers['16.1'].value[0].value

        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '16.2'
        } else {
          return '17.1'
        }
      },
    },
    // 16.2
    {
      id: '16.2',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 16A</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text: 'How much did work contribute to your feelings of isolation?',
          value: 'How much did work contribute to your feelings of isolation?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'A lot',
          value: 'A lot',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'A fair amount',
          value: 'A fair amount',
          icon: <IconSad />,
          submit: true,
        },
        {
          id: 3,
          text: 'A little',
          value: 'A little',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 4,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = ['A little', 'A fair amount', 'A lot']
        let userAnswer = answers['16.2'].value[0].value

        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '16.3'
        } else {
          return '17.1'
        }
      },
    },
    // 16.3
    {
      id: '16.3',
      type: multiChoice,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 16B</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      // useIcons: true,
      text: 'What specifically about work made you feel isolated?',
      disclaimer: 'Pick the one that affected you most.',
      choices: [
        {
          id: 1,
          text: 'Work got in the way of my cultural commitments',
          value: 'Work got in the way of my cultural commitments',
        },
        {
          id: 2,
          text: 'There is no one else like me at work',
          value: 'There is no one else like me at work',
        },
        {
          id: 3,
          text: 'People make fun of me',
          value: 'People make fun of me',
        },

        {
          id: 4,
          type: 'input',
          text: 'Other, please describe',
          value: '',
        },
      ],
    },
    // 17.1
    {
      id: '17.1',
      type: responsiveMatrix,
      hideQuestionText: true,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 17</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text: 'In the past month, how much have you enjoyed your job?',
          value: 'In the past month, how much have you enjoyed your job?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'A little',
          value: 'A little',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 3,
          text: 'A fair amount',
          value: 'A fair amount',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 4,
          text: 'A lot',
          value: 'A lot',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
      next: ({ answers }) => {
        let possValues = ['A lot']
        let userAnswer = answers['17.1'].value[0].value

        const found = possValues.find(ua => userAnswer === ua)
        if (found) {
          return '17.2'
        } else {
          return '17.3'
        }
      },
    },
    // 17.2
    {
      id: '17.2',
      type: multiChoice,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 17A</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      // useIcons: true,
      text: 'What has been the biggest influencer on why you enjoy your job?',
      disclaimer: 'Pick the one that affected you most.',
      choices: [
        {
          id: 1,
          text: 'Purpose of my work',
          value: 'Purpose of my work',
        },
        {
          id: 2,
          text: 'The nature of my day-to-day work',
          value: 'The nature of my day-to-day work',
        },
        {
          id: 3,
          text: 'Who I work for',
          value: 'Who I work for',
        },
        {
          id: 4,
          text: 'Who I work with',
          value: 'Who I work with',
        },
        {
          id: 5,
          type: 'input',
          text: 'Other, please describe',
          value: '',
        },
      ],
      next: ({ answers }) => {
        return '18.1'
      },
    },
    // 17.3
    {
      id: '17.3',
      type: multiChoice,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 17B</Text>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      // useIcons: true,
      text: 'What is the main thing that is keeping you from fully enjoying your job?',
      disclaimer: 'Pick the one that affected you most.',
      choices: [
        {
          id: 1,
          text: 'Workload / intense demands',
          value: 'Workload / intense demands',
        },
        {
          id: 2,
          text: 'Purpose of my work',
          value: 'Purpose of my work',
        },
        {
          id: 3,
          text: 'The nature of my day-to-day work',
          value: 'The nature of my day-to-day work',
        },
        {
          id: 4,
          text: 'Who I work for',
          value: 'Who I work for',
        },
        {
          id: 5,
          text: 'Who I work with',
          value: 'Who I work with',
        },
        {
          id: 6,
          text: 'Rules and regulations',
          value: 'Rules and regulations',
        },
        {
          id: 7,
          type: 'input',
          text: 'Other, please describe',
          value: '',
        },
      ],
      next: ({ answers }) => {
        return '18.1'
      },
    },
    // 18.1
    {
      id: '18.1',
      type: responsiveMatrix,
      required: true,
      hideQuestionText: true,
      // useValues: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 18</Text>
          <Divider />
        </Wrapper>
      ),
      useIcons: true,
      text: 'Please indicate your agreement with the following statement:',
      choices: [
        {
          id: 1,
          text:
            'All things considered, how satisfied have you been with your life as a whole over the past month?',
          value:
            'All things considered, how satisfied have you been with your life as a whole over the past month?',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Not at all',
          value: 'Not at all',
          icon: <IconVerySad />,
          submit: true,
        },
        {
          id: 2,
          text: 'A little',
          value: 'A little',
          icon: <IconOk />,
          submit: true,
        },
        {
          id: 3,
          text: 'Fairly',
          value: 'Fairly',
          icon: <IconHappy />,
          submit: true,
        },
        {
          id: 4,
          text: 'Very',
          value: 'Very',
          icon: <IconVeryHappy />,
          submit: true,
        },
      ],
    },
    // 19.1
    {
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Question 19</Text>
          <Divider />
        </Wrapper>
      ),
      buttonSize: xlButton.width,
      id: '19.1',
      required: true,

      type: commentBox,
      text:
        'If the farm could change one thing to improve your wellbeing, what would you like see changed?',
      placeholder: 'Please type here...',
    },
    {
      id: '20.1',
      required: true,

      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Feedback</Text>
          <p style={{ marginTop: '0.5rem', fontWeight: '400' }}>
            Thanks for trialling the wellbeing assessment tool. The survey has now ended, and we are
            keen to get your feedback on it. Please answer the following four questions. Please
            write as many of your thoughts as possible – the more you put the more useful to us!
          </p>
          <Divider />
        </Wrapper>
      ),
      buttonSize: xlButton.width,
      type: commentBox,
      text: 'What did you like about this survey?',
      placeholder: 'Please type here...',
    },
    {
      id: '20.2',
      required: true,

      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Feedback 1B</Text>

          <Divider />
        </Wrapper>
      ),
      buttonSize: xlButton.width,
      type: commentBox,
      text: 'What didn’t you like about this survey?',
      placeholder: 'Please type here...',
    },
    {
      id: '20.3',
      required: true,

      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Feedback 1C</Text>

          <Divider />
        </Wrapper>
      ),
      buttonSize: xlButton.width,
      type: commentBox,
      text: 'Did anything about the survey make you uncomfortable?',
      placeholder: 'Please type here...',
    },
    {
      id: '20.4',
      required: true,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Feedback 1D</Text>

          <Divider />
        </Wrapper>
      ),
      buttonSize: xlButton.width,
      type: commentBox,
      text: 'Was there anything that confused you about the survey?',
      placeholder: 'Please type here...',
    },
    // Survey End Page
    {
      id: 'survey-end',
      type: endPage,
      text: () => {
        return (
          <Textbox style={{ padding: '0px' }}>
            <p>Thank you for completing the survey!</p>

            <p>
              We’ll merge your results with the others and see if there are any trends that could
              help us identify changes we could make on farm to improve wellbeing.
            </p>

            {/* <p>Like this survey and want to create your own? Let&apos;s Confer!</p> */}
            <a
              href="https://confer.online"
              rel="noreferrer"
              target="_blank"
              style={{ color: '#E82D7C', textDecoration: 'underline' }}
            >
              <p>Like this survey and want to create your own? Let&apos;s Confer!</p>
            </a>
            <div style={{ fontSize: '16px' }}>
              <img width="255px" src={conferLogo} alt="confer-logo.jpg" />
            </div>
          </Textbox>
        )
      },
    },
  ],
  settings: {
    surveyName: 'dairynz-wellbeing',
    primaryColour: '#05B153',
    otherColour: '#72a174',
    hideSubmit: true,
    // turn this off during production
    showQid: false,
    logoFirstPageOnly: true,
    logoStyle: {
      marginTop: '1rem',
    },
    companyLogo: {
      images: [{ image: HCFLogo }],
    },
    logoStyle: {
      marginTop: '1rem',
    },
    logoLocation: 'space-between',
    leftBackgroundImage,
    progressBar: true,
  },
}

export default DairyNZWellbeing
