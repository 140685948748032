import React, { Component } from 'react'
import { } from '../question-types/QuestionTypes'

const params = new URLSearchParams(window.location.search)

const RedirectTest = {
    questions: [
        {
            id: 'welcome',
            type: 'welcomePage',
            text: 'Welcome to the redirect test survey. Click next to be redirected',
        },
        {
            id: 'redirect',
            type: 'endPage',
            text: 'Thanks, you are being redirected to Google',
            redirect: `https://www.google.com/search?q=${params.get('id')}`,
            redirectWaitSeconds: 5,
            hideUi: true,
        }
    ],

    settings: {
        writeToFirestore: true,
        surveyName: 'redirect-test',
        progressBar: true,
        // hideSubmit: true,
    }
}

export default RedirectTest