import React from 'react'
import { ranking, multiSelect, input, welcomePage } from '../question-types/QuestionTypes'
import _ from 'lodash'
import { xlButton } from '../ui/Layout/ButtonContainer'

import { Textbox } from '../../assets/styles/global'

const Hotfixes = {
  questions: [
    {
      id: 'intro-page',
      type: welcomePage,
      text: (
        <Textbox>
          <h1 style={{ color: 'rgba(0, 0, 0, 0.65) ' }}>Survey</h1>
          <p>Page info stuff</p>
          <p>
            If you’re unsure about any of the questions, please just give your best estimate in your
            answer.
          </p>
        </Textbox>
      ),
    },
    {
      id: '1.1',
      required: true,
      type: multiSelect,
      maxChoices: 11,
      text: 'Which of the following have you accessed/used? (Select all that apply)',
      disclaimer: '*CFA below stands for Catchment Farm Advisor',
      choices: [
        { id: 1, text: 'FEPs', value: 'FEPs' },
        { id: 2, text: 'Winter grazing plan', value: 'Winter grazing plan' },
        { id: 3, text: 'GHG number', value: 'GHG number' },
        { id: 4, text: 'Farm map', value: 'Farm map' },
        { id: 5, text: 'ETS expert services', value: 'ETS expert services' },
        { id: 6, text: 'Biodiversity expert services', value: 'Biodiversity expert services' },
        {
          id: 7,
          text: 'Regulatory / compliance advice',
          value: 'Regulatory / compliance advice',
        },
        { id: 8, text: 'Pre-audit support', value: 'Pre-audit support' },
        { id: 9, text: '1BT planting funding', value: '1BT planting funding' },
        {
          id: 10,
          type: input,
          text: 'CFA* connected you to someone/thing (type here)',
          value: '',
        },
      ]
        .sort(function shuffleArray(a, b) {
          return 0.5 - Math.random()
        })
        .concat([
          {
            id: 11,
            text: 'None of the above',
            value: 'None of the above',
            submit: true,
          },
        ]),

      next: ({ answers }) => {
        if (answers['1.1'].value.length == 1) {
          if (answers['1.1'].value[0].value === 'None of the above') {
            return '1.6'
          } else {
            return '1.3'
          }
        } else {
          return '1.2'
        }
      },
    },
    // Part one Q2
    {
      id: '1.2',
      type: ranking,
      required: true,
      mobileHeight: '4rem',
      buttonSize: xlButton.width,
      text: 'Of the services you used, which were the most valuable?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            { id: 1, text: 'FEPs', value: 'FEPs' },
            { id: 2, text: 'Winter grazing plan', value: 'Winter grazing plan' },
            { id: 3, text: 'GHG number', value: 'GHG number' },
            { id: 4, text: 'Farm map', value: 'Farm map' },
            { id: 5, text: 'ETS expert services', value: 'ETS expert services' },
            { id: 6, text: 'Biodiversity expert services', value: 'Biodiversity expert services' },
            {
              id: 7,
              text: 'Regulatory / compliance advice',
              value: 'Regulatory / compliance advice',
            },
            { id: 8, text: 'Pre-audit support', value: 'Pre-audit support' },
            { id: 9, text: '1BT planting funding', value: '1BT planting funding' },
            {
              id: 10,
              text: 'Catchment farm advisor connected you to someone/thing',
              value: 'Catchment farm advisor connected youto someone/thing',
            },
          ]
          return defaultAnswers
        }
        let sortAnswers = answers['1.1'].value
        for (let i = 0; i < sortAnswers.length; i++) {
          if (sortAnswers[i].id == 10) {
            sortAnswers[
              i
            ].value = `Catchment farm advisor connected you to ${sortAnswers[i].rawValue}`
          }
        }
        let pipedAnswers = sortAnswers.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))
        return _.uniq(pipedAnswers)
      },
    },
    // Part one Q3
    {
      id: '1.3',
      type: multiSelect,
      required: true,
      text: 'How did you access these? (Select all that apply)',
      mobileHeight: '4rem',
      choices: [
        {
          id: 1,
          text: 'One-on-one support from Catchment Farm Advisor (i.e Harry, Rebecca, John)',
          value: 'One-on-one support from Catchment Farm Advisor',
        },
        { id: 2, text: 'A workshop/field day', value: 'A workshop/field day' },
        { id: 3, text: 'Advice from an expert visit', value: 'Advice from an expert visit' },
        { id: 4, text: 'An online webinar', value: 'An online webinar' },
      ],
    },
    // Part one Q4
    {
      id: '1.4',
      type: ranking,
      mobileHeight: '4rem',
      required: true,
      text: 'How would you rank these, in order of how valuable they were to you?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'One-on-one support from Catchment Farm Advisor (i.e Harry, Rebecca, John)',
              value: 'One-on-one support from Catchment Farm Advisor (i.e Harry, Rebecca, John)',
            },
            { id: 2, text: 'A workshop/field day', value: 'A workshop/field day' },
            { id: 3, text: 'Advice from an expert visit', value: 'Advice from an expert visit' },
            { id: 4, text: 'An online webinar', value: 'An online webinar' },
          ]
          return defaultAnswers
        }
        let pipedAnswers = answers['1.3'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))
        console.log('pipedANswers from choices', pipedAnswers)
        return _.uniq(pipedAnswers)
      },
    },
  ],

  settings: {
    writeToFirestore: false,
    hideSubmit: true,
    surveyName: 'hotfixes',
    progressBar: true,
  },
}

export default Hotfixes
