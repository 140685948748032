/* eslint-disable no-unused-vars */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'
import React from 'react'
import styled from 'styled-components/macro'
import 'typeface-carter-one'
import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import topBackgroundImage from '../../assets/images/StepChange/cows-bg.svg'
import companyLogo from '../../assets/images/StepChange/DairyNZ-logo-white.png'

// @import url('https://fonts.google.com/specimen/Carter+One?sidebar.open&selection.family=Carter+One')

import { endPage, multiChoice, welcomePage } from '../question-types/QuestionTypes'

import { Textbox } from '../../assets/styles/global'
import { xlButton } from '../ui/Layout/ButtonContainer'

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  font-family: 'Carter One', cursive;
  letter-spacing: 0.1em;
  /* align-items: center; */
`
const GreenText = styled.p`
  font-size: 1rem;
  color: #6fb139;
`

const SectionInfo = styled.p`
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #9b9b9b;
`

const YellowText = styled.p`
  font-size: 1rem;
  color: #eaae19;
`

const DarkBlueText = styled.p`
  font-size: 1rem;
  color: #474f40;
  margin-bottom: 0;
`

const RedText = styled.p`
  font-size: 1rem;
  color: #84367a;
`

const BlueText = styled.p`
  font-size: 1rem;
  color: #00a7b8;
`

const DisclaimerText = styled.p`
  font-size: 1rem;
  font-style: italic;
`

const DarkGreenText = styled.p`
  font-size: 1rem;
  color: #4a6f2f;
`

const Grey = styled.h1`
  color: #535d52;
  font-family: 'Carter One', cursive;
  letter-spacing: 0.1em;
`

const StyledP = styled.p`
  font-size: 20px;
`

const Container = styled.div`
  background-color: red;
  height: 100vh;
  width: 100vw;
`

const StepChange = {
  questions: [
    {
      id: 0,
      buttonSize: xlButton.width,
      type: welcomePage,
      text: (
        <Textbox>
          <Grey>Farmer action survey</Grey>
        </Textbox>
      ),
    },
    {
      id: 1,
      required: true,
      buttonSize: xlButton.width,

      section: (
        <Wrapper>
          <BlueText>Overall</BlueText>
        </Wrapper>
      ),
      type: multiChoice,
      text:
        'Which of the below best describes you in regard to being enviromentally sustainable while being profitable?',
      choices: [
        {
          id: 1,
          text: 'Thinking about the enviroment and profit together is not on my radar',
          value: 'Thinking about the enviroment and profit together is not on my radar',
        },
        {
          id: 2,
          text:
            "I don't think I should have to, or don't want to, take action towards environmental sustainability",
          value:
            "I don't think I should have to, or don't want to, take action towards environmental sustainability",
        },
        {
          id: 3,
          text:
            "I'm willing to take action towards environmental sustainability, but I don't know the best place to start",
          value:
            "I'm willing to take action towards environmental sustainability, but I don't know the best place to start",
        },
        {
          id: 4,
          text:
            "I'm willing to take action towards environmental sustainability, and I know where to start, but its not a priority so I haven't done anything yet",
          value:
            "I'm willing to take action towards environmental sustainability, and I know where to start, but its not a priority so I haven't done anything yet",
        },
        {
          id: 5,
          text:
            "I've taken some action towards environmental sustainability but I'm not sure if its enough to meet long term obligations and if I can stay profitable as well",
          value:
            "I've taken some action towards environmental sustainability but I'm not sure if its enough to meet long term obligations and if I can stay profitable as well",
        },
        {
          id: 6,
          text:
            "I've adapted my farm system to be both environmentally and financially sustainable and I feel confident about the future",
          value:
            "I've adapted my farm system to be both environmentally and financially sustainable and I feel confident about the future",
        },
      ],
    },
    {
      id: 2,
      required: true,
      section: (
        <Wrapper>
          <GreenText>Financial management</GreenText>
        </Wrapper>
      ),
      type: 'imageRadioList',
      text: 'Which of the following is true for you?',
      choices: [
        {
          id: 1,
          text: 'I have a written business plan, which includes KPIs, and I am implementing it',
          value: 'I have a written business plan, which includes KPIs, and I am implementing it',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              icon: <CheckOutlined />,
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              icon: <CloseOutlined />,
            },
            // { id: 1, value: 'Yes' },
            // { id: 2, value: 'No' },
          ],
        },
        {
          id: 2,
          text:
            'I use a budgeting and cashflow tool (template, resource, software etc.) to manage my finances',
          value:
            'I use a budgeting and cashflow tool (template, resource, software etc.) to manage my finances',
          answerChoices: [
            { id: 3, value: 'Yes', icon: <CheckOutlined /> },
            { id: 4, value: 'No', icon: <CloseOutlined /> },
          ],
        },
        {
          id: 3,
          text:
            'I follow a strategic debt management plan (involving current debt level, pay-off plans, and maintenance of debt to asset ratio)',
          value:
            'I follow a strategic debt management plan (involving current debt level, pay-off plans, and maintenance of debt to asset ratio)',
          answerChoices: [
            { id: 5, value: 'Yes', icon: <CheckOutlined /> },
            { id: 6, value: 'No', icon: <CloseOutlined /> },
          ],
        },
        {
          id: 4,
          text: 'I use financial benchmarking to help me achieve my finance goals',
          value: 'I use financial benchmarking to help me achieve my finance goals',
          answerChoices: [
            { id: 7, value: 'Yes', icon: <CheckOutlined /> },
            { id: 8, value: 'No', icon: <CloseOutlined /> },
          ],
        },
      ],
    },
    {
      id: 3,
      required: true,
      buttonSize: xlButton.width,
      section: (
        <Wrapper>
          <DarkBlueText>Freshwater Farm Plan / Farm Environment Plans</DarkBlueText>
          <DisclaimerText>
            Note: currently many Freshwater Farm Plans are known as Farm Environment Plans.
          </DisclaimerText>
        </Wrapper>
      ),
      disclaimer: 'Note: currently many Freshwater Farm Plans are known as Farm Environment Plans.',

      type: multiChoice,
      text: 'Which of the the following best describes you with regards to plan for your farm?',
      choices: [
        {
          id: 1,
          text:
            'Our farm has a Freshwater Farm Plan that was developed with input from a qualified advisor',
          value:
            'Our farm has a Freshwater Farm Plan that was developed with input from a qualified advisor',
        },
        {
          id: 2,
          text:
            'Our farm has a Freshwater Farm Plan but I am not confident that it is up to date and fit-for-purpose',
          value:
            'Our farm has a Freshwater Farm Plan but I am not confident that it is up to date and fit-for-purpose',
        },
        {
          id: 3,
          text: 'I have a plan in place to get a Freshwater Farm Plan',
          value: 'I have a plan in place to get a Freshwater Farm Plan',
        },
        {
          id: 4,
          text: 'I don’t plan to get a Freshwater Farm Plan',
          value: 'I don’t plan to get a Freshwater Farm Plan',
        },
      ],
    },
    {
      id: 4,
      required: true,
      buttonSize: xlButton.width,
      section: (
        <Wrapper>
          <DarkBlueText>Freshwater Farm Plan / Farm Environment Plans</DarkBlueText>
          <DisclaimerText>
            Note: currently many Freshwater Farm Plans are known as Farm Environment Plans.
          </DisclaimerText>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'Is the following true for you?',
      // text:
      //   'We are recording when we complete the actions recommended in the Freshwater Farm Plan',
      statement: () => {
        return (
          <div>
            <p>
              <b>
                We are recording when we complete the actions recommended in the Freshwater Farm
                Plan.
              </b>
            </p>
          </div>
        )
      },
      choices: [
        {
          id: 1,
          text: 'Yes',
          value: 'Yes',
          icon: <IconCheck fill="currentColor" />,
        },
        {
          id: 2,
          text: 'No',
          value: 'No',
          icon: <IconCross fill="currentColor" />,
        },
      ],
    },
    {
      id: 5,
      required: true,
      section: (
        <Wrapper>
          <DarkBlueText>Nutrient management</DarkBlueText>
        </Wrapper>
      ),
      text: 'Which of the following is true for you?',
      type: 'imageRadioList',
      options: [
        { id: 1, value: 'Yes' },
        { id: 2, value: 'No' },
        { id: 3, value: 'Yes' },
        { id: 4, value: 'No' },
        { id: 5, value: 'Yes' },
        { id: 6, value: 'No' },
      ],
      headers: [
        'Which of the following is true for you?',
        'We understand Good Management Practices that apply to nutrient management and fertiliser application and follow these ',
      ],
      choices: [
        {
          id: 1,
          text: 'A nutrient budget is completed every year and reviewed with a qualified advisor',
          value: 'A nutrient budget is completed every year and reviewed with a qualified advisor',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <CheckOutlined /> },
            { id: 2, value: 'No', icon: <CloseOutlined /> },
          ],
        },
        {
          id: 2,
          text:
            'Soil phosphorous levels are monitored through regular soil testing and maintained at or below agronomic optimum levels',
          value:
            'Soil phosphorous levels are monitored through regular soil testing and maintained at or below agronomic optimum levels',
          answerChoices: [
            { id: 3, value: 'Yes', icon: <CheckOutlined /> },
            { id: 4, value: 'No', icon: <CloseOutlined /> },
          ],
        },
      ],
      otherChoices: [
        {
          id: 3,
          text:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          value:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          answerChoices: [
            { id: 5, value: 'Yes', icon: <CheckOutlined /> },
            { id: 6, value: 'No', icon: <CloseOutlined /> },
          ],
        },
        {
          id: 4,
          text:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          value:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          answerChoices: [
            { id: 7, value: 'Yes', icon: <CheckOutlined /> },
            { id: 8, value: 'No', icon: <CloseOutlined /> },
          ],
        },
        {
          id: 5,
          text: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          value: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          answerChoices: [
            { id: 9, value: 'Yes', icon: <CheckOutlined /> },
            { id: 10, value: 'No', icon: <CloseOutlined /> },
          ],
        },
        {
          id: 6,
          text:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          value:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          answerChoices: [
            { id: 11, value: 'Yes', icon: <CheckOutlined /> },
            { id: 12, value: 'No', icon: <CloseOutlined /> },
          ],
        },
      ],
    },
    {
      id: 6,
      required: true,
      section: (
        <Wrapper>
          <DarkBlueText>Nutrient management</DarkBlueText>
        </Wrapper>
      ),
      text: 'Which of the following is true for you?',
      type: 'imageRadioList',
      choices: [
        {
          id: 1,
          text:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          value:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <CheckOutlined /> },
            { id: 2, value: 'No', icon: <CloseOutlined /> },
          ],
        },
        {
          id: 2,
          text:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          value:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          answerChoices: [
            { id: 3, value: 'Yes', icon: <CheckOutlined /> },
            { id: 4, value: 'No', icon: <CloseOutlined /> },
          ],
        },
        {
          id: 3,
          text: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          value: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          answerChoices: [
            { id: 5, value: 'Yes', icon: <CheckOutlined /> },
            { id: 6, value: 'No', icon: <CloseOutlined /> },
          ],
        },
        {
          id: 4,
          text:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          value:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          answerChoices: [
            { id: 7, value: 'Yes', icon: <CheckOutlined /> },
            { id: 8, value: 'No', icon: <CloseOutlined /> },
          ],
        },
      ],
    },
    {
      id: 7,
      required: true,
      section: (
        <Wrapper>
          <BlueText>Water quality risk assesment</BlueText>
        </Wrapper>
      ),
      type: 'imageRadioList',
      text: 'Which of the following is true for you?',
      options: [
        { id: 1, value: 'Yes', icon: <CheckOutlined /> },
        { id: 2, value: 'No', icon: <CloseOutlined /> },
      ],
      choices: [
        {
          id: 1,
          text:
            'I have assessed the risk from all our farm practices to water quality (e.g. from farm dairy effluent, management of critical source areas – where water accumulates during wet periods; water use and irrigation; crop and pasture grazing principles.)',
          value:
            'I have assessed the risk from all our farm practices to water quality (e.g. from farm dairy effluent, management of critical source areas – where water accumulates during wet periods; water use and irrigation; crop and pasture grazing principles.)',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <CheckOutlined /> },
            { id: 2, value: 'No', icon: <CloseOutlined /> },
          ],
        },
        {
          id: 2,
          text:
            'For all high and medium risk areas identified we have developed time-bound actions to mitigate impacts.',
          value:
            'For all high and medium risk areas identified we have developed time-bound actions to mitigate impacts.',
          answerChoices: [
            { id: 3, value: 'Yes', icon: <CheckOutlined /> },
            { id: 4, value: 'No', icon: <CloseOutlined /> },
          ],
        },
      ],
    },
    {
      id: 8,
      required: true,
      buttonSize: xlButton.width,
      section: (
        <Wrapper>
          <GreenText>Greenhouse gases</GreenText>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'Which of the following best describes you with regards to emissions on your farm? ',
      choices: [
        {
          id: 1,
          text:
            'I have not estimated greenhouse gas emissions or set targets / actions for my farm',
          value:
            'I have not estimated greenhouse gas emissions or set targets / actions for my farm',
        },
        {
          id: 2,
          text:
            'I have estimated how much greenhouse gases are emitted from my farm but have not set targets / actions',
          value:
            'I have estimated how much greenhouse gases are emitted from my farm but have not set targets / actions',
        },
        {
          id: 3,
          text: 'I have set targets and / or identified actions but have not taken action yet',
          value: 'I have set targets and / or identified actions but have not taken action yet',
        },
        {
          id: 4,
          text:
            'I have taken specific actions to change the amount of greenhouse gases produced by my farm',
          value:
            'I have taken specific actions to change the amount of greenhouse gases produced by my farm',
        },
      ],
    },
    {
      id: 9,
      text: 'You have reached the end of the survey.',
      type: endPage,
    },
  ],
  settings: {
    writeToFirestore: false,
    surveyName: 'farmer-action-v3',
    companyLogo,
    topBackgroundImage,
    backgroundImage: topBackgroundImage,
  },
}
export default StepChange
