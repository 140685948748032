/* eslint-disable no-unused-vars */
import { Divider } from 'antd'
import queryString from 'query-string'
import React from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import scarlatti from '../../assets/images/PIAS/scarlatti.png'
import { ReactComponent as Happy } from '../../assets/images/Scarlatti/Happy Face.svg'
import { ReactComponent as Ok } from '../../assets/images/Scarlatti/Ok face.svg'
import { ReactComponent as Sad } from '../../assets/images/Scarlatti/Sad Face.svg'
import { ReactComponent as VeryHappy } from '../../assets/images/Scarlatti/Very happy Face.svg'
import { ReactComponent as VerySad } from '../../assets/images/Scarlatti/Very sadFace 1.svg'
import { multiChoice, responsiveSlider } from '../question-types/QuestionTypes'

import leftBackgroundImage from '../../assets/images/Scarlatti/workload.png' // Todo compress image
import { Textbox } from '../../assets/styles/global'

const IconHappy = styled(Happy)`
  width: 3rem;
  height: 3rem;
`
const IconOk = styled(Ok)`
  width: 3rem;
  height: 3rem;
`
const IconSad = styled(Sad)`
  width: 3rem;
  height: 3rem;
`
const IconVeryHappy = styled(VeryHappy)`
  width: 3rem;
  height: 3rem;
`
const IconVerySad = styled(VerySad)`
  width: 3rem;
  height: 3rem;
`

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 800px;
  li {
    font-size: 1rem;
    font-weight: 400;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 100%;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    width: 100%;
  }
`

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const _date = new Date()

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`

const Q = styled.p`
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: #757575;
  margin-bottom: 0.5rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  p {
    margin-bottom: 0rem;
  }
  .ant-divider-horizontal {
    margin: 0px 0;
    border-top: 1px solid #c3c3c3;
    width: 49rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 80vw;
    .ant-divider-horizontal {
      width: 90vw;
    }
  }
  /* align-items: center; */
`

const Capital = styled.span`
  text-transform: capitalize;
`
const Text = styled.p`
  font-size: 1rem;
  color: ${props => props.color};
`

const urlMonth = queryString.parse(window.location.search)
// console.log('companies', distinctCompanies)

const ScarlattiWorkload = {
  questions: [
    // {
    //   id: 'wellbeing-intro',
    //   type: welcomePage,
    //   section: (
    //     <Wrapper style={{ width: '24.5rem', alignSelf: 'center' }}>
    //       <Text color={'#F9744C'}>Wellbeing</Text>
    //       <Divider style={{ width: '24.5rem' }} />
    //     </Wrapper>
    //   ),
    //   text: (
    //     <StyledTextbox>
    //       <p>
    //         All work is stressful, but not all stress is bad. This section is about getting an
    //         understanding of our organisational wellbeing risks. While it is unlikely that any
    //         organisation can remove stressors, it is possible to mitigate them.
    //       </p>
    //       {/* <p>
    //         When answering these questions, it can be difficult to separate work and personal life.
    //         Think carefully on each answer, reflecting on the nature of the job separate to your
    //         personal circumstances or behaviours. For example, things like having overly high
    //         standards, a tendency to procrastination or the fact you live far away from your place
    //         of work are not caused by the job. It can be tricky to separate these things. The
    //         helpful thing to do can be to think if you were in another job – would the situation be
    //         the less or more or the same?
    //       </p> */}
    //     </StyledTextbox>
    //   ),
    // },
    // 3.1
    {
      id: 'workload-slider',
      section: (
        <Wrapper>
          {/* <Q>Questions 1 of 1</Q> */}
          <Text color={'#F9744C'}>Wellbeing</Text>

          <Divider />
        </Wrapper>
      ),
      text: (
        <Wrapper>
          {/* <p>How would you rate your workload for {months[_date.getMonth()]}?</p> */}
          <p>
            How would you rate your workload for <Capital>{urlMonth.id}</Capital>?
          </p>
        </Wrapper>
      ),
      // text:
      //   'If you had to rate your workload for the past quarter (May-July) on a scale of  1- 10, 1 being bored and not knowing what to do, 5 being healthy, rewarding challenges that you can attack within working hours, and 10 being burnout and no longer enjoying your work, where would you put yourself?',
      type: responsiveSlider,
      // showValue: true,
      default: 1,
      min: 1,
      max: 10,
      tooltipVisible: true,
      choices: [
        { id: 1, text: 'Bored', value: '1' },
        { id: 2, text: '', value: '2' },
        { id: 3, text: '', value: '3' },
        { id: 4, text: '', value: '4' },
        { id: 5, text: 'Healthy, rewarding challenges', value: '5' },
        { id: 6, text: '', value: '6' },
        { id: 7, text: '', value: '7' },
        { id: 8, text: '', value: '8' },
        { id: 9, text: '', value: '9' },
        { id: 10, text: 'Burnt out', value: '10' },
      ],
    },
    {
      id: 'role-details',
      type: multiChoice,
      section: (
        <div>
          <p>
            It would be great to know what your role is so we can look for trends within roles.
            However, if you prefer not to indicate, that is okay.
          </p>
        </div>
      ),
      text: 'Which of the following describes your role:',
      choices: [
        { id: 1, text: 'Associate', value: 'Associate' },
        { id: 2, text: 'Research manager', value: 'Research manager' },
        { id: 3, text: 'Senior team', value: 'Senior team' },
        { id: 4, text: 'Prefer not to indicate', value: 'Prefer not to indicate' },
      ],
    },
    // 3.2
    // {
    //   id: 'contribution-slider',
    //   type: responsiveSlider,
    //   section: (
    //     <Wrapper>
    //       <Q>Questions 2 of 6</Q>
    //       <Text color={'#F9744C'}>Wellbeing</Text>
    //       <p style={{ marginTop: '0.5rem' }}>
    //         Please indicate your agreement with the following statements:
    //       </p>
    //       <Divider />
    //     </Wrapper>
    //   ),
    //   text: 'I feel remunerated fairly for the effort I put in and the contribution I make',
    //   default: 2,
    //   max: 10,

    //   // showValue: true,
    //   marks: [
    //     { id: 1, text: 'Poor' },
    //     { id: 2, text: 'Neutral' },
    //     { id: 2, text: 'Excellent' },
    //   ],
    //   choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    // },

    {
      id: 'end-page',
      type: 'endPage',
      text: ({ answers }) => {
        return (
          <div>
            <p style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              Thank you
              <IconVeryHappy style={{ marginLeft: '1rem' }} />
            </p>
          </div>
        )
      },
    },
  ],
  settings: {
    writeToFirestore: true,
    surveyName: 'monthly-workload',
    primaryColour: '#48a54c',
    hideButton: true,
    // showQid: true,
    useSections: false,
    // secondaryColour: '#f38231',
    // contrastColour: '#fff',
    hideSubmit: true,
    hideLogo: false,
    logoFirstPageOnly: true,
    companyLogo: scarlatti,
    logoLocation: 'space-between',
    progressBar: true,
    objectPosition: 'center',
    // primaryColour: '#05B153',
    primaryColour: '#F9744C',
    leftBackgroundImage,
  },
}

export default ScarlattiWorkload
