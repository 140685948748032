import * as firebase from 'firebase/app'
import React from 'react'
import styled, { keyframes } from 'styled-components/macro'
import leftBackgroundImage from '../../assets/images/HCF/backgroundimage.jpg' // Todo compress image
import { endPage, multiChoice, multiDropdown, welcomePage } from '../question-types/QuestionTypes'
import { xlButton } from '../ui/Layout/ButtonContainer'
// import backgroudImage from '../../assets/images/HCF/min-background-1.png'
import { v4 as uuidv4 } from 'uuid'
import conferLogo from '../../assets/images/Confer-logo.png'
import BLNZLogo from '../../assets/images/HCF/beeflamb.jpg'
import { ReactComponent as star } from '../../assets/images/HCF/star.svg'
import HCFLogo from '../../assets/images/HCF/test.svg'
import { ReactComponent as a2 } from '../../assets/images/Scarlatti/a2.svg'
import { ReactComponent as a3 } from '../../assets/images/Scarlatti/a3.svg'
import { ReactComponent as g1 } from '../../assets/images/Scarlatti/g1.svg'
import { ReactComponent as g2 } from '../../assets/images/Scarlatti/g2.svg'
import { ReactComponent as g3 } from '../../assets/images/Scarlatti/g3.svg'
import { ReactComponent as s1 } from '../../assets/images/Scarlatti/s1.svg'
import { ReactComponent as s2 } from '../../assets/images/Scarlatti/s2.svg'
import { ReactComponent as s3 } from '../../assets/images/Scarlatti/s3.svg'
import { Textbox } from '../../assets/styles/global'

const db = firebase.firestore()
const surveyData = db.collection('surveys').doc('hcf-conjoint-v2')

const aColours = {
  income: '#086a46',
  soil: '#4a4a93',
  water: '#4384cd',
  weather: '#003d5b',
  profit: '#adc773',
}

const StarIcon = styled(star)`
  display: flex;
  height: 1.5rem;
  fill: ${props => props.aColor};
`

const ConjointTitle = styled.p`
  font-weight: 600;
  text-align: left;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: baseline;
  padding: 10px;
  @media (min-width: 0px) and (max-width: 479px) {
    width: 100%;
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ImgContainer = styled.div`
  margin: 0;
  height: 100%;
  img {
    padding: 20px;
    height: 12rem;
    svg {
      display: block;
    }
  }
  @media (min-width: 0px) and (max-width: 900px) {
    img {
      height: 7.5rem;
      width: 8rem;
      object-fit: cover;
    }
  }
`
const DBold = styled.span`
  font-weight: 600;
`

const Title = styled.p`
  margin-bottom: 0.5rem;
  text-align: left;
  font-weight: 600;
  color: ${props => props.aColor};
`

const StarContainer = styled.div`
  display: flex;
`

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ul {
    list-style: none;
    list-style-position: inside;
    margin-left: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    margin-top: 0rem;
  }
`

const Q = styled.div`
  margin-top: 1rem;
`

const StyledP = styled.p`
  line-height: 1.7;
  /* font-size: 1rem;
  color: rgba(0, 0, 0, 0.65); */
  margin-bottom: 1rem;
  margin-top: 0rem;
  font-weight: 400;
`

const A2 = styled(a2)`
  height: 4rem;
  width: 4rem;
`
const A3 = styled(a3)`
  height: 4rem;
  width: 4rem;
`
const G1 = styled(g1)`
  height: 4rem;
  width: 4rem;
`
const G2 = styled(g2)`
  height: 4rem;
  width: 4rem;
`
const G3 = styled(g3)`
  height: 4rem;
  width: 4rem;
`
const S1 = styled(s1)`
  height: 4rem;
  width: 4rem;
`
const S2 = styled(s2)`
  height: 4rem;
  width: 4rem;
`

const S3 = styled(s3)`
  height: 4rem;
  width: 4rem;
`

const animateText = keyframes`
0% {
  background-size: 0% 100%;
}
100% {
  background-size: 100% 100%
}
`

const Headline = styled.p`
  font-size: 1rem;
  background: linear-gradient(180deg, transparent 85%, #05b153 0);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  margin-bottom: 0rem;
  animation: 2s ${animateText} ease-in-out;
`

const Header = styled.h2``

const Ol = styled.ol`
  padding-left: 2rem;
`

const tempUserId = uuidv4()

// ******** Survey content ********

const HillCountryFuturesWorkshop = {
  questions: [
    // Survey-intro
    {
      id: 'intro-page',
      type: welcomePage,
      buttonSize: xlButton.width,
      // next: () => 'section6-Q3',
      text: (
        <StyledTextbox style={{ paddingTop: '20px', alignItems: 'baseline' }}>
          <StyledP style={{ fontSize: '1.5rem', fontWeight: '600' }}>
            Hill Country Futures (HCF) impact model input workshop{' '}
          </StyledP>
          <StyledP>
            This workshop contains a series of “thought experiments” relating to{' '}
            <b>on-farm outcomes</b> and <b>farmer networks</b>. We will guide you through this and
            ask that you <b>please do not work ahead</b>. You will be presented with the
            distribution of responses from the group, which we will discuss in attempt to agree upon
            a single input.
          </StyledP>
        </StyledTextbox>
      ),
    },
    // Section 1 intro
    {
      id: 'section1-intro',
      type: welcomePage,
      buttonSize: 'auto',
      text: (
        <StyledP>
          <Header>Thought experiment #1 profitability</Header>
          <StyledP>
            To begin we will consider the particular outcomes that HCF seeks to achieve. We have
            simplified these into six categories:
          </StyledP>
          <Ol>
            <li>Profitability</li>
            <li>Resilience to adverse weather</li>
            <li>Healthy land</li>
            <li>Healthy water</li>
            <li>Sense of community</li>
            <li>Farmer physical and mental health </li>
          </Ol>
          <StyledP>
            This section will go through each of these outcomes, aiming to estimate two parameters:
          </StyledP>
          <Ol>
            <li>The extent to which particular practices will shift the outcome and;</li>
            <li>The time lag between practice change and outcomes </li>
          </Ol>
          <StyledP>
            <b>To begin, let’s consider profitability… </b>
          </StyledP>
        </StyledP>
      ),
    },
    // Section1-Q1 (2.1)
    {
      id: 'section1-Q1',
      type: multiChoice,
      text: (
        <Q>
          <b>To what degree is a farmer’s level of profitability outside of their control</b>{' '}
          (compared to how much they can influence with changing on-farm practice)? (e.g.{' '}
          <b>5% out of their control</b> (95% in their control)).
        </Q>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '5% (95%)',
          text: '5% (95%)',
          // text: (
          //   <span>
          //     <b>5%</b>(95%)
          //   </span>
          // ),
        },
        {
          id: 2,
          value: '10% (90%)',
          text: '10% (90%)',
          // text: (
          //   <span>
          //     <b>10%</b>(90%)
          //   </span>
          // ),
        },
        {
          id: 3,
          value: '30% (70%)',
          text: '30% (70%)',
          // text: (
          //   <span>
          //     <b>30%</b>(70%)
          //   </span>
          // ),
        },
        {
          id: 4,
          value: '50% (50%)',
          text: '50% (50%)',
          // text: (
          //   <span>
          //     <b>50%</b>(95%)
          //   </span>
          // ),
        },
        {
          id: 5,
          value: '70% (30%)',
          text: '70% (30%)',
          // text: (
          //   <span>
          //     <b>70%</b>(30%)
          //   </span>
          // ),
        },
        {
          id: 6,
          value: '90% (10%)',
          text: '90% (10%)',
          // text: (
          //   <span>
          //     <b>90%</b>(10%)
          //   </span>
          // ),
        },
        {
          id: 7,
          value: '95% (5%)',
          text: '95% (5%)',
          // text: (
          //   <span>
          //     <b>95%</b>(5%)
          //   </span>
          // ),
        },
      ],
    },
    // Section1-Q1-results (2.2)
    {
      id: 'section1-Q1-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            <b>To what degree is a farmer’s level of profitability outside of their control</b>{' '}
            (compared to how much they can influence with changing on-farm practice)? (e.g.{' '}
            <b>5% out of their control</b> (95% in their control)).
          </p>
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section1-Q1',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section1-practices
    {
      id: 'section1-Q1-practices',
      type: welcomePage,
      buttonSize: 'auto',
      text: (
        <StyledP>
          Could you please jot down what you believe are <b>the top 5 practices</b> a farmer could
          do to increase their profitability?
        </StyledP>
      ),
    },
    // Section1-Q2(2.3)
    {
      id: 'section1-Q2',
      type: multiChoice,
      text: (
        <Q>
          <p>
            We have considered the following five practices:
            <Ol>
              <li>Using resilient forages</li>
              <li>Planting trees for carbon credits</li>
              <li>Planning pasture use</li>
              <li>Creating an adverse event plan</li>
              <li>Developing a business strategy</li>
            </Ol>
            Of what is in a farmers control, how much would the practices above contribute to
            increasing their profitability?
          </p>
        </Q>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '5%',
          text: '5%',
        },
        {
          id: 2,
          value: '10%',
          text: '10%',
        },
        {
          id: 3,
          value: '30%',
          text: '30%',
        },
        {
          id: 4,
          value: '50%',
          text: '50%',
        },
        {
          id: 5,
          value: '70%',
          text: '70%',
        },
        {
          id: 6,
          value: '90%',
          text: '90%',
        },
        {
          id: 7,
          value: '95%',
          text: '95%',
        },
      ],
    },
    // Section1-Q2-results (2.4)
    {
      id: 'section1-Q2-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            Of what is in a farmers control, how much would:
            <Ol>
              <li>Using resilient forages</li>
              <li>Planting trees for carbon credits</li>
              <li>Planning pasture use</li>
              <li>Creating an adverse event plan</li>
              <li>Developing a business strategy</li>
            </Ol>
            contribute to increasing their profitability?
          </p>
          {/* <p>
            Of what is in a farmers control, how much would the listed practices contribute to
            achieving the highest level of profitability on their farm?
          </p> */}
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section1-Q2',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section1-Q3 (2.5)
    {
      id: 'section1-Q3',
      type: multiChoice,
      text: (
        <StyledTextbox>
          <StyledP>
            If a farmer with average profitability began making changes by:
            <Ol>
              <li>Using resilient forages</li>
              <li>Planting trees for carbon credits</li>
              <li>Planning pasture use</li>
              <li>Creating an adverse event plan</li>
              <li>Developing a business strategy</li>
            </Ol>
            how long would it be before you would expect them to reach their highest potential
            profitability?
          </StyledP>

          {/* <StyledP>
            If a farmer were to begin making changes to improve their profitability, how long would
            it be before you would expect them to reach their potential profitability level?
          </StyledP> */}
        </StyledTextbox>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '0-1 years',
          text: '0-1 years',
        },
        {
          id: 2,
          value: '1-3 years',
          text: '1-3 years',
        },
        {
          id: 3,
          value: '2-5 years',
          text: '2-5 years',
        },
        {
          id: 4,
          value: '3-10 years',
          text: '3-10 years',
        },
        {
          id: 5,
          value: '5-20 years',
          text: '5-20 years',
        },
        {
          id: 6,
          value: '10-30 years',
          text: '10-30 years',
        },
        {
          id: 7,
          value: '20-50 years',
          text: '20-50 years',
        },
      ],
    },
    // Section1-Q3-results (2.6)
    {
      id: 'section1-Q3-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            If a farmer with average profitability began making changes by:
            <Ol>
              <li>Using resilient forages</li>
              <li>Planting trees for carbon credits</li>
              <li>Planning pasture use</li>
              <li>Creating an adverse event plan</li>
              <li>Developing a business strategy</li>
            </Ol>
            how long would it be before you would expect them to reach their highest potential
            profitability?
          </p>
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section1-Q3',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section2-intro (1.1)
    {
      id: 'section2-intro',
      type: welcomePage,
      buttonSize: xlButton.width,
      text: (
        <StyledTextbox style={{ paddingTop: '20px', alignItems: 'baseline' }}>
          <Header>Thought experiment #2 resilience to adverse weather</Header>
          <b>Now let’s consider resilience to adverse weather...</b>
        </StyledTextbox>
      ),
    },
    // Section2-Q1 (1.2)
    {
      id: 'section2-Q1',
      type: multiChoice,
      text: (
        <StyledP>
          <b>To what degree is a farmer’s resilience to adverse weather outside of their control</b>{' '}
          (compared to how much they can influence with changing on-farm practice)? (e.g.{' '}
          <b>5% out of their control</b> (95% in their control)).
        </StyledP>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '5% (95%)',
          text: '5% (95%)',
          // text: (
          //   <span>
          //     <b>5%</b>(95%)
          //   </span>
          // ),
        },
        {
          id: 2,
          value: '10% (90%)',
          text: '10% (90%)',
          // text: (
          //   <span>
          //     <b>10%</b>(90%)
          //   </span>
          // ),
        },
        {
          id: 3,
          value: '30% (70%)',
          text: '30% (70%)',
          // text: (
          //   <span>
          //     <b>30%</b>(70%)
          //   </span>
          // ),
        },
        {
          id: 4,
          value: '50% (50%)',
          text: '50% (50%)',
          // text: (
          //   <span>
          //     <b>50%</b>(95%)
          //   </span>
          // ),
        },
        {
          id: 5,
          value: '70% (30%)',
          text: '70% (30%)',
          // text: (
          //   <span>
          //     <b>70%</b>(30%)
          //   </span>
          // ),
        },
        {
          id: 6,
          value: '90% (10%)',
          text: '90% (10%)',
          // text: (
          //   <span>
          //     <b>90%</b>(10%)
          //   </span>
          // ),
        },
        {
          id: 7,
          value: '95% (5%)',
          text: '95% (5%)',
          // text: (
          //   <span>
          //     <b>95%</b>(5%)
          //   </span>
          // ),
        },
      ],
    },
    // Section2-Q1-results (1.3)
    {
      id: 'section2-Q1-results',
      type: welcomePage,
      text: (
        <div>
          <Header>What do we think of this?</Header>
          <p>
            <b>
              To what degree is a farmer’s resilience to adverse weather is outside of their control{' '}
            </b>
            (compared to how much they can influence with changing on-farm practice)? (e.g.{' '}
            <b>5% out of their control</b> (95% in their control)).
          </p>
        </div>
      ),
      buttonSize: 'auto', // Hack to get QuestionInfo to display full width
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        title:
          "To what extent is the variability in resilience to adverse weather due to differences in farmers' practices?",
        targetQuestionId: 'section2-Q1',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section2-practices
    {
      id: 'section2-Q1-practices',
      type: welcomePage,
      buttonSize: 'auto',
      text: (
        <StyledP>
          Could you please jot down what you believe are <b>the top 5 practices</b> a farmer could
          do to increase their resilience to adverse weather?
        </StyledP>
      ),
    },
    // Section2-Q2(1.4)
    {
      id: 'section2-Q2',
      type: multiChoice,
      text: (
        <div style={{ 'margin-top': '1rem' }}>
          <p>
            We have considered the following five practices:
            <Ol>
              <li>Planting trees for carbon credits</li>
              <li>Creating an adverse event plan</li>
              <li>Using subterranean clover</li>
              <li>Joining a catchment community group</li>
              <li>Planting resilient forages</li>
            </Ol>
            Of what is in a farmer's control, how much would the practices above contribute to
            increasing their resilience to adverse weather?
          </p>
        </div>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '5%',
          text: '5%',
        },
        {
          id: 2,
          value: '10%',
          text: '10%',
        },
        {
          id: 3,
          value: '30%',
          text: '30%',
        },
        {
          id: 4,
          value: '50%',
          text: '50%',
        },
        {
          id: 5,
          value: '70%',
          text: '70%',
        },
        {
          id: 6,
          value: '90%',
          text: '90%',
        },
        {
          id: 7,
          value: '95%',
          text: '95%',
        },
      ],
    },
    // Section2-Q2-results (1.5)
    {
      id: 'section2-Q2-results',
      type: welcomePage,
      text: (
        <div style={{ 'margin-top': '1rem' }}>
          <Header>What do we think of this?</Header>
          <p>
            Of what is in a farmer's control, how much would:
            <Ol>
              <li>Planting trees for carbon credits</li>
              <li>Creating an adverse event plan</li>
              <li>Using subterranean clover</li>
              <li>Joining a catchment community group</li>
              <li>Planting resilient forages</li>
            </Ol>
            contribute to increasing their resilience to adverse weather?
          </p>
          {/* <p>
            Of what is in a farmers control, how much would the listed practices contribute to
            achieving the highest level of resilience to adverse weather on their farm?
          </p> */}
        </div>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section2-Q2',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section2-Q3 (1.6)
    {
      id: 'section2-Q3',
      type: multiChoice,
      text: (
        <StyledTextbox>
          <StyledP>
            If a farmer with average resilience to adverse weather began making changes to their
            resilience to adverse weather by:
            <Ol>
              <li>Planting trees for carbon credits</li>
              <li>Creating an adverse event plan</li>
              <li>Using subterranean clover</li>
              <li>Joining a catchment community group</li>
              <li>Planting resilient forages</li>
            </Ol>
            how long would it be before you would expect them to reach their highest potential
            resilience to adverse weather?
          </StyledP>
        </StyledTextbox>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '0-1 years',
          text: '0-1 years',
        },
        {
          id: 2,
          value: '1-3 years',
          text: '1-3 years',
        },
        {
          id: 3,
          value: '2-5 years',
          text: '2-5 years',
        },
        {
          id: 4,
          value: '3-10 years',
          text: '3-10 years',
        },
        {
          id: 5,
          value: '5-20 years',
          text: '5-20 years',
        },
        {
          id: 6,
          value: '10-30 years',
          text: '10-30 years',
        },
        {
          id: 7,
          value: '20-50 years',
          text: '20-50 years',
        },
      ],
    },
    // Section2-Q3-results (1.7)
    {
      id: 'section2-Q3-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            If a farmer with average resilience to adverse weather began making changes to their
            resilience to adverse weather by:
            <Ol>
              <li>Planting trees for carbon credits</li>
              <li>Creating an adverse event plan</li>
              <li>Using subterranean clover</li>
              <li>Joining a catchment community group</li>
              <li>Planting resilient forages</li>
            </Ol>
            how long would it be before you would expect them to reach their highest potential
            resilience to adverse weather?
          </p>
          {/* <p>
            If a farmer were begin making changes to their resilience to adverse weather, how long
            would it be before you would expect them to reach their potential resilience to adverse
            weather?
          </p> */}
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section2-Q3',
        surveyName: 'hcf-blnz-workshop',
      },
    },

    // Section 3 intro
    {
      id: 'section3-intro',
      type: welcomePage,
      text: (
        <StyledP>
          <Header>Thought experiment #3 healthy land</Header>
          <b>Now let’s consider the health of farmland… </b>
        </StyledP>
      ),
    },
    // Section3-Q1 (3.1)
    {
      id: 'section3-Q1',
      type: multiChoice,
      text: (
        <Q>
          <p>
            <b>To what degree is the health of a farmer’s land outside of their control</b>{' '}
            (compared to how much they can influence with changing on-farm practice)? (i.e. 5% out
            of their control (95% in their control)).
          </p>
        </Q>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '5% (95%)',
          text: '5% (95%)',
          // text: (
          //   <span>
          //     <b>5%</b>(95%)
          //   </span>
          // ),
        },
        {
          id: 2,
          value: '10% (90%)',
          text: '10% (90%)',
          // text: (
          //   <span>
          //     <b>10%</b>(90%)
          //   </span>
          // ),
        },
        {
          id: 3,
          value: '30% (70%)',
          text: '30% (70%)',
          // text: (
          //   <span>
          //     <b>30%</b>(70%)
          //   </span>
          // ),
        },
        {
          id: 4,
          value: '50% (50%)',
          text: '50% (50%)',
          // text: (
          //   <span>
          //     <b>50%</b>(95%)
          //   </span>
          // ),
        },
        {
          id: 5,
          value: '70% (30%)',
          text: '70% (30%)',
          // text: (
          //   <span>
          //     <b>70%</b>(30%)
          //   </span>
          // ),
        },
        {
          id: 6,
          value: '90% (10%)',
          text: '90% (10%)',
          // text: (
          //   <span>
          //     <b>90%</b>(10%)
          //   </span>
          // ),
        },
        {
          id: 7,
          value: '95% (5%)',
          text: '95% (5%)',
          // text: (
          //   <span>
          //     <b>95%</b>(5%)
          //   </span>
          // ),
        },
      ],
    },
    // Section3-Q1-results (3.2)
    {
      id: 'section3-Q1-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            <b>To what degree is the health of a farmer’s land outside of their control</b>{' '}
            (compared to how much they can influence with changing on-farm practice)? (i.e. 5% out
            of their control (95% in their control)).
          </p>
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section3-Q1',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section3-practices
    {
      id: 'section3-Q1-practices',
      type: welcomePage,
      buttonSize: 'auto',
      text: (
        <StyledP>
          Could you please jot down what you believe are <b>the top 5 practices</b> a farmer could
          do to increase their land health?
        </StyledP>
      ),
    },
    // Section3-Q2(3.3)
    {
      id: 'section3-Q2',
      type: multiChoice,
      text: (
        <Q>
          <p>
            We have considered the following five practices:
            <Ol>
              <li>Encouraging biodiversity</li>
              <li>Using subterranean clover</li>
              <li>Undertaking pest control</li>
              <li>Developing and implementing a winter grazing plan</li>
              <li>Using appropriate fertiliser</li>
            </Ol>
            Of what is in a farmer's control, how much would these five practices contribute to
            increasing a farm's land health?
          </p>
        </Q>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '5%',
          text: '5%',
        },
        {
          id: 2,
          value: '10%',
          text: '10%',
        },
        {
          id: 3,
          value: '30%',
          text: '30%',
        },
        {
          id: 4,
          value: '50%',
          text: '50%',
        },
        {
          id: 5,
          value: '70%',
          text: '70%',
        },
        {
          id: 6,
          value: '90%',
          text: '90%',
        },
        {
          id: 7,
          value: '95%',
          text: '95%',
        },
      ],
    },
    // Section3-Q2-results (3.4)
    {
      id: 'section3-Q2-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            Of what is in a farmers control, how much would:
            <Ol>
              <li>Encouraging biodiversity</li>
              <li>Using subterranean clover</li>
              <li>Undertaking pest control</li>
              <li>Developing and implementing a winter grazing plan</li>
              <li>Using appropriate fertiliser</li>
            </Ol>
            contribute to increasing a farm's land health?
          </p>
          {/* <p>
            Of what is in a farmers control, how much would the listed practices contribute to
            achieving the highest level of health land on their farm?
          </p> */}
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section3-Q2',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section3-Q3 (3.5)
    {
      id: 'section3-Q3',
      type: multiChoice,
      text: (
        <StyledTextbox>
          <StyledP>
            If a farmer with average land health began making changes by:
            <Ol>
              <li>Encouraging biodiversity</li>
              <li>Using subterranean clover</li>
              <li>Undertaking pest control</li>
              <li>Developing and implementing a winter grazing plan</li>
              <li>Using appropriate fertiliser</li>
            </Ol>
            how long would it be before you would expect them to reach their highest land health?
          </StyledP>
        </StyledTextbox>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '0-1 years',
          text: '0-1 years',
        },
        {
          id: 2,
          value: '1-3 years',
          text: '1-3 years',
        },
        {
          id: 3,
          value: '2-5 years',
          text: '2-5 years',
        },
        {
          id: 4,
          value: '3-10 years',
          text: '3-10 years',
        },
        {
          id: 5,
          value: '5-20 years',
          text: '5-20 years',
        },
        {
          id: 6,
          value: '10-30 years',
          text: '10-30 years',
        },
        {
          id: 7,
          value: '20-50 years',
          text: '20-50 years',
        },
      ],
    },
    // Section3-Q3-results (3.6)
    {
      id: 'section3-Q3-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <StyledP>
            If a farmer with average land health began making changes by:
            <Ol>
              <li>Encouraging biodiversity</li>
              <li>Using subterranean clover</li>
              <li>Undertaking pest control</li>
              <li>Developing and implementing a winter grazing plan</li>
              <li>Using appropriate fertiliser</li>
            </Ol>
            how long would it be before you would expect them to reach their highest land health?
          </StyledP>
          {/* <p>
            If a farmer were to begin making changes to improve their land health, how long would it
            be before you would expect them to reach their potential healthy land level?
          </p> */}
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section3-Q3',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section 4 intro
    {
      id: 'section4-intro',
      type: welcomePage,
      text: (
        <StyledP>
          <Header>Thought experiment #4 water health</Header>
          <b>Now let’s consider the health of waterways… </b>
        </StyledP>
      ),
    },
    // Section4-Q1 (4.1)
    {
      id: 'section4-Q1',
      type: multiChoice,
      text: (
        <Q>
          <p>
            <b>To what degree is the health of a farmer’s water outside of their control</b>{' '}
            (compared to how much they can influence with changing on-farm practice)? (i.e. 5% out
            of their control (95% in their control)).
          </p>
        </Q>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '5% (95%)',
          text: '5% (95%)',
          // text: (
          //   <span>
          //     <b>5%</b>(95%)
          //   </span>
          // ),
        },
        {
          id: 2,
          value: '10% (90%)',
          text: '10% (90%)',
          // text: (
          //   <span>
          //     <b>10%</b>(90%)
          //   </span>
          // ),
        },
        {
          id: 3,
          value: '30% (70%)',
          text: '30% (70%)',
          // text: (
          //   <span>
          //     <b>30%</b>(70%)
          //   </span>
          // ),
        },
        {
          id: 4,
          value: '50% (50%)',
          text: '50% (50%)',
          // text: (
          //   <span>
          //     <b>50%</b>(95%)
          //   </span>
          // ),
        },
        {
          id: 5,
          value: '70% (30%)',
          text: '70% (30%)',
          // text: (
          //   <span>
          //     <b>70%</b>(30%)
          //   </span>
          // ),
        },
        {
          id: 6,
          value: '90% (10%)',
          text: '90% (10%)',
          // text: (
          //   <span>
          //     <b>90%</b>(10%)
          //   </span>
          // ),
        },
        {
          id: 7,
          value: '95% (5%)',
          text: '95% (5%)',
          // text: (
          //   <span>
          //     <b>95%</b>(5%)
          //   </span>
          // ),
        },
      ],
    },
    // Section4-Q1-results (4.2)
    {
      id: 'section4-Q1-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            <b>To what degree is the health of a farmer’s water outside of their control</b>{' '}
            (compared to how much they can influence with changing on-farm practice)? (i.e. 5% out
            of their control (95% in their control)).
          </p>
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section4-Q1',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section4-practices
    {
      id: 'section4-Q1-practices',
      type: welcomePage,
      buttonSize: 'auto',
      text: (
        <StyledP>
          Could you please jot down what you believe are <b>the top 5 practices</b> a farmer could
          do to increase their water health?
        </StyledP>
      ),
    },
    // Section4-Q2(4.3)
    {
      id: 'section4-Q2',
      type: multiChoice,
      text: (
        <Q>
          <p>
            We have considered the following five practices:
            <Ol>
              <li>Implementing a freshwater farm plan</li>
              <li>Monitoring community-based freshwater</li>
              <li>Managing irrigation</li>
              <li>Protecting waterways from pollutants</li>
              <li>Managing effluent systems</li>
            </Ol>
            Of what is in a farmer's control, how much would these five practices contribute to
            increasing a farm's water health?
          </p>
        </Q>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '5%',
          text: '5%',
        },
        {
          id: 2,
          value: '10%',
          text: '10%',
        },
        {
          id: 3,
          value: '30%',
          text: '30%',
        },
        {
          id: 4,
          value: '50%',
          text: '50%',
        },
        {
          id: 5,
          value: '70%',
          text: '70%',
        },
        {
          id: 6,
          value: '90%',
          text: '90%',
        },
        {
          id: 7,
          value: '95%',
          text: '95%',
        },
      ],
    },
    // Section4-Q2-results (4.4)
    {
      id: 'section4-Q2-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            Of what is in a farmers control, how much would:
            <Ol>
              <li>Implementing a freshwater farm plan</li>
              <li>Monitoring community-based freshwater</li>
              <li>Managing irrigation</li>
              <li>Protecting waterways from pollutants</li>
              <li>Managing effluent systems</li>
            </Ol>
            contribute to increasing a farm's water health?
          </p>
          {/* <p>
            Of what is in a farmers control, how much would the listed practices contribute to
            achieving the highest level of water health on their farm?
          </p> */}
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section4-Q2',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section4-Q3 (4.5)
    {
      id: 'section4-Q3',
      type: multiChoice,
      text: (
        <StyledTextbox>
          <StyledP>
            If a farmer with average water health began making changes by:
            <Ol>
              <li>Implementing a freshwater farm plan</li>
              <li>Monitoring community-based freshwater</li>
              <li>Managing irrigation</li>
              <li>Protecting waterways from pollutants</li>
              <li>Managing effluent systems</li>
            </Ol>
            how long would it be before you would expect them to reach their highest potential water
            health?
          </StyledP>
        </StyledTextbox>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '0-1 years',
          text: '0-1 years',
        },
        {
          id: 2,
          value: '1-3 years',
          text: '1-3 years',
        },
        {
          id: 3,
          value: '2-5 years',
          text: '2-5 years',
        },
        {
          id: 4,
          value: '3-10 years',
          text: '3-10 years',
        },
        {
          id: 5,
          value: '5-20 years',
          text: '5-20 years',
        },
        {
          id: 6,
          value: '10-30 years',
          text: '10-30 years',
        },
        {
          id: 7,
          value: '20-50 years',
          text: '20-50 years',
        },
      ],
    },
    // Section4-Q3-results (4.6)
    {
      id: 'section4-Q3-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <StyledP>
            If a farmer with average water health began making changes by:
            <Ol>
              <li>Implementing a freshwater farm plan</li>
              <li>Monitoring community-based freshwater</li>
              <li>Managing irrigation</li>
              <li>Protecting waterways from pollutants</li>
              <li>Managing effluent systems</li>
            </Ol>
            how long would it be before you would expect them to reach their highest potential water
            health?
          </StyledP>
          {/* <p>
            If a farmer were to begin making changes to improve their water health, how long would
            it be before you would expect them to reach their potential healthy water level?
          </p> */}
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section4-Q3',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section 5 intro
    {
      id: 'section5-intro',
      type: welcomePage,
      text: (
        <StyledP>
          <Header>Thought experiment #5 sense of community</Header>
          <b>Now let’s consider a farmer’s sense of community</b>
        </StyledP>
      ),
    },
    // Section5-Q1 (5.1)
    {
      id: 'section5-Q1',
      type: multiChoice,
      text: (
        <Q>
          <p>
            <b>To what extent is a farmer’s sense of community is outside of their control</b>{' '}
            (compared to how much they can influence with changing their behaviour)? (i.e. 5% out of
            their control (95% in their control)).
          </p>
        </Q>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '5% (95%)',
          text: '5% (95%)',
          // text: (
          //   <span>
          //     <b>5%</b>(95%)
          //   </span>
          // ),
        },
        {
          id: 2,
          value: '10% (90%)',
          text: '10% (90%)',
          // text: (
          //   <span>
          //     <b>10%</b>(90%)
          //   </span>
          // ),
        },
        {
          id: 3,
          value: '30% (70%)',
          text: '30% (70%)',
          // text: (
          //   <span>
          //     <b>30%</b>(70%)
          //   </span>
          // ),
        },
        {
          id: 4,
          value: '50% (50%)',
          text: '50% (50%)',
          // text: (
          //   <span>
          //     <b>50%</b>(95%)
          //   </span>
          // ),
        },
        {
          id: 5,
          value: '70% (30%)',
          text: '70% (30%)',
          // text: (
          //   <span>
          //     <b>70%</b>(30%)
          //   </span>
          // ),
        },
        {
          id: 6,
          value: '90% (10%)',
          text: '90% (10%)',
          // text: (
          //   <span>
          //     <b>90%</b>(10%)
          //   </span>
          // ),
        },
        {
          id: 7,
          value: '95% (5%)',
          text: '95% (5%)',
          // text: (
          //   <span>
          //     <b>95%</b>(5%)
          //   </span>
          // ),
        },
      ],
    },
    // Section5-Q1-results (5.2)
    {
      id: 'section5-Q1-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            <b>To what extent is a farmer’s sense of community is outside of their control</b>{' '}
            (compared to how much they can influence with changing their behaviour)? (i.e. 5% out of
            their control (95% in their control)).
          </p>
          {/* <p>
            To what extent is the variability in sense in community due to differences in farmers'
            practices?
          </p> */}
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section5-Q1',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section5-practices
    {
      id: 'section5-Q1-practices',
      type: welcomePage,
      buttonSize: 'auto',
      text: (
        <StyledP>
          Could you please jot down what you believe are <b>the top 5 practices</b> a farmer could
          do to increase their sense of community?
        </StyledP>
      ),
    },
    // Section5-Q2(5.3)
    {
      id: 'section5-Q2',
      type: multiChoice,
      text: (
        <Q>
          <p>
            We have considered the following five practices:
            <Ol>
              <li>Planning for succession</li>
              <li>Joining a catchment community group</li>
              <li>Talking to friends and colleagues</li>
              <li>Attending informative events</li>
              <li>Taking part in community projects</li>
            </Ol>
            Of what is in a farmers control, how much would these five practices contribute to
            increasing a farmer’s sense of community?
          </p>
        </Q>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '5%',
          text: '5%',
        },
        {
          id: 2,
          value: '10%',
          text: '10%',
        },
        {
          id: 3,
          value: '30%',
          text: '30%',
        },
        {
          id: 4,
          value: '50%',
          text: '50%',
        },
        {
          id: 5,
          value: '70%',
          text: '70%',
        },
        {
          id: 6,
          value: '90%',
          text: '90%',
        },
        {
          id: 7,
          value: '95%',
          text: '95%',
        },
      ],
    },
    // Section5-Q2-results (5.4)
    {
      id: 'section5-Q2-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            Of what is in a farmers control, how much would:
            <Ol>
              <li>Planning for succession</li>
              <li>Joining a catchment community group</li>
              <li>Talking to friends and colleagues</li>
              <li>Attending informative events</li>
              <li>Taking part in community projects</li>
            </Ol>
            contribute to increasing a farmer’s sense of community?
          </p>
          {/* <p>
            Of what is in a farmers control, how much would the listed practices contribute to
            achieving the highest level of sense of community?
          </p> */}
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section5-Q2',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section5-Q3 (5.5)
    {
      id: 'section5-Q3',
      type: multiChoice,
      text: (
        <StyledTextbox>
          <StyledP>
            If a farmer with an average sense of community began making changes by:
            <Ol>
              <li>Planning for succession</li>
              <li>Joining a catchment community group</li>
              <li>Talking to friends and colleagues</li>
              <li>Attending informative events</li>
              <li>Taking part in community projects</li>
            </Ol>
            how long would it be before you would expect them to reach their highest potential sense
            of community?
          </StyledP>
        </StyledTextbox>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '0-1 years',
          text: '0-1 years',
        },
        {
          id: 2,
          value: '1-3 years',
          text: '1-3 years',
        },
        {
          id: 3,
          value: '2-5 years',
          text: '2-5 years',
        },
        {
          id: 4,
          value: '3-10 years',
          text: '3-10 years',
        },
        {
          id: 5,
          value: '5-20 years',
          text: '5-20 years',
        },
        {
          id: 6,
          value: '10-30 years',
          text: '10-30 years',
        },
        {
          id: 7,
          value: '20-50 years',
          text: '20-50 years',
        },
      ],
    },
    // Section5-Q3-results (5.6)
    {
      id: 'section5-Q3-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <StyledP>
            If a farmer with an average sense of community began making changes by:
            <Ol>
              <li>Planning for succession</li>
              <li>Joining a catchment community group</li>
              <li>Talking to friends and colleagues</li>
              <li>Attending informative events</li>
              <li>Taking part in community projects</li>
            </Ol>
            how long would it be before you would expect them to reach their highest potential sense
            of community?
          </StyledP>
          {/* <p>
            If a farmer were to begin making changes to improve their sense of community, how long
            would it be before you would expect them to reach their potential sense of community
            feel?
          </p> */}
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section5-Q3',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section 6 intro
    {
      id: 'section6-intro',
      type: welcomePage,
      text: (
        <StyledP>
          <Header>Thought experiment #6 physical and mental health</Header>
          <b>Now let’s consider a farmer’s physical and mental health </b>
        </StyledP>
      ),
    },
    // Section6-Q1 (6.1)
    {
      id: 'section6-Q1',
      type: multiChoice,
      text: (
        <Q>
          <p>
            <b>To what extent is a farmer’s physical and mental health outside of their control</b>{' '}
            (compared to how much they can influence with changing their behaviours)? (i.e. 5% out
            of their control (95% in their control)).
          </p>
        </Q>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '5% (95%)',
          text: '5% (95%)',
          // text: (
          //   <span>
          //     <b>5%</b>(95%)
          //   </span>
          // ),
        },
        {
          id: 2,
          value: '10% (90%)',
          text: '10% (90%)',
          // text: (
          //   <span>
          //     <b>10%</b>(90%)
          //   </span>
          // ),
        },
        {
          id: 3,
          value: '30% (70%)',
          text: '30% (70%)',
          // text: (
          //   <span>
          //     <b>30%</b>(70%)
          //   </span>
          // ),
        },
        {
          id: 4,
          value: '50% (50%)',
          text: '50% (50%)',
          // text: (
          //   <span>
          //     <b>50%</b>(95%)
          //   </span>
          // ),
        },
        {
          id: 5,
          value: '70% (30%)',
          text: '70% (30%)',
          // text: (
          //   <span>
          //     <b>70%</b>(30%)
          //   </span>
          // ),
        },
        {
          id: 6,
          value: '90% (10%)',
          text: '90% (10%)',
          // text: (
          //   <span>
          //     <b>90%</b>(10%)
          //   </span>
          // ),
        },
        {
          id: 7,
          value: '95% (5%)',
          text: '95% (5%)',
          // text: (
          //   <span>
          //     <b>95%</b>(5%)
          //   </span>
          // ),
        },
      ],
    },
    // Section6-Q1-results (6.2)
    {
      id: 'section6-Q1-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            <b>To what extent is a farmer’s physical and mental health outside of their control</b>{' '}
            (compared to how much they can influence with changing their behaviours)? (i.e. 5% out
            of their control (95% in their control)).
          </p>
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section6-Q1',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section6-practices
    {
      id: 'section6-Q1-practices',
      type: welcomePage,
      buttonSize: 'auto',
      text: (
        <StyledP>
          Could you please jot down what you believe are <b>the top 5 practices</b> a farmer could
          do to increase their physical and mental health?
        </StyledP>
      ),
    },
    // Section6-Q2(6.3)
    {
      id: 'section6-Q2',
      type: multiChoice,
      text: (
        <Q>
          <p>
            We have considered the following five practices:
            <Ol>
              <li>Getting enough sleep</li>
              <li>Eating well</li>
              <li>Exercising regularly</li>
              <li>Talking with friends and colleagues</li>
              <li>Organising work habits</li>
            </Ol>
            Of what is in a farmers control, how much would these five practices contribute to
            increasing a farmer's mental and physical health?
          </p>
        </Q>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '5%',
          text: '5%',
        },
        {
          id: 2,
          value: '10%',
          text: '10%',
        },
        {
          id: 3,
          value: '30%',
          text: '30%',
        },
        {
          id: 4,
          value: '50%',
          text: '50%',
        },
        {
          id: 5,
          value: '70%',
          text: '70%',
        },
        {
          id: 6,
          value: '90%',
          text: '90%',
        },
        {
          id: 7,
          value: '95%',
          text: '95%',
        },
      ],
    },
    // Section6-Q2-results (6.4)
    {
      id: 'section6-Q2-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            Of what is in a farmers control, how much would:
            <Ol>
              <li>Getting enough sleep</li>
              <li>Eating well</li>
              <li>Exercising regularly</li>
              <li>Talking with friends and colleagues</li>
              <li>Organising work habits</li>
            </Ol>
            contribute to them achieving the highest level of physical and mental health?
          </p>
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section6-Q2',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section6-Q3 (6.5)
    {
      id: 'section6-Q3',
      type: multiChoice,
      text: (
        <StyledTextbox>
          <StyledP>
            If a farmer with average physical and mental health began making changes by:
            <Ol>
              <li>Getting enough sleep</li>
              <li>Eating well</li>
              <li>Exercising regularly</li>
              <li>Talking with friends and colleagues</li>
              <li>Organising work habits</li>
            </Ol>
            how long would it be before you would expect them to reach their highest potential
            physical and mental health?
          </StyledP>
        </StyledTextbox>
      ),
      required: false,
      choices: [
        {
          id: 1,
          value: '0-1 years',
          text: '0-1 years',
        },
        {
          id: 2,
          value: '1-3 years',
          text: '1-3 years',
        },
        {
          id: 3,
          value: '2-5 years',
          text: '2-5 years',
        },
        {
          id: 4,
          value: '3-10 years',
          text: '3-10 years',
        },
        {
          id: 5,
          value: '5-20 years',
          text: '5-20 years',
        },
        {
          id: 6,
          value: '10-30 years',
          text: '10-30 years',
        },
        {
          id: 7,
          value: '20-50 years',
          text: '20-50 years',
        },
      ],
    },
    // Section6-Q3-results (6.6)
    {
      id: 'section6-Q3-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <StyledP>
            If a farmer with average physical and mental health began making changes by:
            <Ol>
              <li>Getting enough sleep</li>
              <li>Eating well</li>
              <li>Exercising regularly</li>
              <li>Talking with friends and colleagues</li>
              <li>Organising work habits</li>
            </Ol>
            how long would it be before you would expect them to reach their highest potential
            physical and mental health?
          </StyledP>
          {/* <p>
            If a farmer were to begin making changes to improve their physical and mental health,
            how long would it be before you would expect them to reach their potential physical and
            mental health?
          </p> */}
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Number of responses',
        yLabel: '',
        targetQuestionId: 'section6-Q3',
        surveyName: 'hcf-blnz-workshop',
      },
    },
    // Section6-Q2-results (6.4)
    // {
    //   id: 'section6-Q2-results',
    //   type: welcomePage,
    //   text: (
    //     <Q>
    //       <p>What do we think of this?</p>
    //       <p>
    //         Of what is in a farmers control, how much would the listed practices contribute to
    //         achieving the highest level of physical and mental health?
    //       </p>
    //     </Q>
    //   ),
    //   buttonSize: 'auto',
    //   output: {
    //     type: 'bar-chart',
    //(chartType: 'horizontal'),
    //     xLabel: 'Number of responses',
    //     yLabel: '',
    //     targetQuestionId: 'section6-Q2',
    //     surveyName: 'hcf-blnz-workshop',
    //   },
    // },
    // Section7-Q1 (7.1)
    // {
    //   id: 'section7-Q1',
    //   type: multiChoice,
    //   text: (
    //     <StyledTextbox>
    //       <p>
    //         <b>We will now switch focus to consider the farmer networks</b>
    //       </p>
    //       <p>
    //         How many connections, on average, would a farmer have that they would engage with (in a
    //         meaningful way) each month?
    //       </p>
    //     </StyledTextbox>
    //   ),
    //   required: false,
    //   choices: [
    //     {
    //       id: 1,
    //       value: '1-5',
    //       text: '1-5',
    //     },
    //     {
    //       id: 2,
    //       value: '6-10',
    //       text: '6-10',
    //     },
    //     {
    //       id: 3,
    //       value: '11-15',
    //       text: '11-15',
    //     },
    //     {
    //       id: 4,
    //       value: '16-20',
    //       text: '16-20',
    //     },
    //     {
    //       id: 5,
    //       value: '21-25',
    //       text: '21-25',
    //     },
    //     {
    //       id: 6,
    //       value: '26-30',
    //       text: '26-30',
    //     },
    //     {
    //       id: 7,
    //       value: '31+',
    //       text: '31+',
    //     },
    //   ],
    // },
    {
      id: 'section7-Q1',
      type: multiDropdown,
      text: (
        <StyledTextbox>
          <p>
            What percentage of farmers do you believe have the following number of{' '}
            <b>meaningful connections</b> with other farmers on a monthly basis?
          </p>
          <p>
            <em>Please make sure your responses add to 100% and select a response for each row</em>
          </p>
        </StyledTextbox>
      ),
      constraint: {
        func: answerValue => {
          if (!answerValue) {
            return true
          }
          if (answerValue.some(ans => ans.value === '')) {
            return false
          }
          const total = answerValue.reduce(
            (prev, current) => prev + (current.value ? parseInt(current.value) : 0),
            0,
          )
          console.log('total', total)
          return total === 100
        },
        message: 'Percentages must add to 100% and a response must be selected for each row',
      },

      // defaultValues: () => [
      //   { id: 1, text: '1-2', value: '0%' },
      //   { id: 1, text: '2-5', value: '0%' },
      //   { id: 1, text: '5-15', value: '0%' },
      //   { id: 1, text: '15-30', value: '0%' },
      //   { id: 1, text: '30+', value: '0%' },
      // ],

      required: false,
      choices: [
        {
          id: 1,
          value: '1-2',
          text: '1-2',
        },
        {
          id: 2,
          value: '2-5',
          text: '2-5',
        },
        {
          id: 3,
          value: '5-15',
          text: '5-15',
        },
        {
          id: 4,
          value: '15-30',
          text: '15-30',
        },
        {
          id: 5,
          value: '30+',
          text: '30+',
        },
      ],
      options: [
        {
          id: 1,
          text: '0%',
          value: '0%',
        },
        {
          id: 2,
          text: '10%',
          value: '10%',
        },
        {
          id: 3,
          text: '20%',
          value: '20%',
        },
        {
          id: 4,
          text: '30%',
          value: '30%',
        },
        {
          id: 5,
          text: '40%',
          value: '40%',
        },
        {
          id: 6,
          text: '50%',
          value: '50%',
        },
        {
          id: 7,
          text: '60%',
          value: '60%',
        },
        {
          id: 8,
          text: '70%',
          value: '70%',
        },
        {
          id: 9,
          text: '80%',
          value: '80%',
        },
        {
          id: 10,
          text: '90%',
          value: '90%',
        },
        {
          id: 11,
          text: '100%',
          value: '100%',
        },
      ],
    },
    // Section7-Q1-results (7.1)
    {
      id: 'section7-Q1-results',
      type: welcomePage,
      text: (
        <Q>
          <Header>What do we think of this?</Header>
          <p>
            <p>
              What percentage of farmers do you believe have the following number of{' '}
              <b>meaningful connections</b> with other farmers on a monthly basis?
            </p>
          </p>
        </Q>
      ),
      buttonSize: 'auto',
      output: {
        type: 'bar-chart',
        chartType: 'horizontal',
        xLabel: 'Percent of farmers',
        yLabel: 'Number of connections',
        targetQuestionId: 'section7-Q1',
        surveyName: 'hcf-blnz-workshop',
        transformAggData: data => {
          console.log('transforming data', data)
          if (!data) {
            return data
          }
          let newData = Object.entries(data).map(([key, value]) => {
            let pct_mean = 0
            let count = 0
            for (let [pct_string, n] of Object.entries(value)) {
              pct_mean = pct_mean + n * parseInt(pct_string)
              count = count + n
            }
            pct_mean = Math.round(pct_mean / count)
            return [key, pct_mean]
          })
          newData = Object.fromEntries(newData)
          console.log('transformed data', newData)
          return newData
        },
      },
    },
    // Survey end
    {
      id: 'survey-end',
      type: endPage,
      text: () => {
        return (
          <Textbox style={{ padding: '0px' }}>
            <h1 style={{ color: 'rgba(0, 0, 0, 0.65) ', fontSize: '18px', fontWeight: 600 }}>
              Thank you for completing the survey!
              <br />
              Your response will help inform efforts to protect and enhance our hill country
              environment.
            </h1>

            {/* <p>Like this survey and want to create your own? Let&apos;s Confer!</p> */}
            <a
              href="https://confer.online"
              rel="noreferrer"
              target="_blank"
              style={{ color: '#E82D7C', textDecoration: 'underline' }}
            >
              <p>Like this survey and want to create your own? Let&apos;s Confer!</p>
            </a>
            <div style={{ fontSize: '18px' }}>
              <img width="255px" src={conferLogo} alt="confer-logo.jpg" />
            </div>
          </Textbox>
        )
      },
    },
  ],
  settings: {
    surveyName: 'hcf-blnz-workshop',
    primaryColour: '#05B153',
    uuid: tempUserId,

    otherColour: '#72a174',
    hideSubmit: true,
    showQid: false,
    specialEffects: true,
    logoFirstPageOnly: true,
    logoStyle: {
      marginTop: '1rem',
    },
    companyLogo: {
      images: [{ image: HCFLogo }, { image: BLNZLogo }],
    },
    logoStyle: {
      marginTop: '1rem',
    },
    logoLocation: 'space-between',
    leftBackgroundImage,
    backgroundImage: leftBackgroundImage,
    progressBar: true,
  },
}

export default HillCountryFuturesWorkshop
