/* eslint-disable no-unused-vars */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'
import React from 'react'
import styled from 'styled-components/macro'
import 'typeface-open-sans'
import companyLogo from '../../assets/images/StepChange/DairyNZ.png'
import backgroundVideo from '../../assets/images/StepChange/video.mp4'

import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import {
  endPage,
  input,
  inputFieldNumber,
  multiChoice,
  multiSelect,
  responsiveMatrix,
  welcomePage,
} from '../question-types/QuestionTypes'

import { Textbox } from '../../assets/styles/global'
import { lgButton, xlButton } from '../ui/Layout/ButtonContainer'

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  p {
    margin-bottom: 0;
  }
  /* align-items: center; */
`
const GreenText = styled.p`
  font-size: 18px;
  color: #6fb139;
  font-family: 'Open Sans', sans-serif;
`

const StyledCheckOutlined = styled(CheckOutlined)`
  color: #46e4b5;
`

const StyledCloseOutlined = styled(CloseOutlined)`
  color: #5b3bdf;
`

const Q = styled.span`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`
const SectionInfo = styled.p`
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #9b9b9b;
`

const YellowText = styled.p`
  font-size: 18px;
  color: #eaae19;
`

const DarkBlueText = styled.p`
  font-size: 18px;
  color: #474f40;
  letter-spacing: 0.1em;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  width: 90vw;
`

const RedText = styled.p`
  font-size: 18px;
  color: #84367a;
`

const BlueText = styled.p`
  font-size: 18px;
  color: #00a7b8;
  font-family: 'Open Sans', sans-serif;
`

const DisclaimerText = styled.p`
  font-size: 1rem;
  width: 90vw;
  margin-bottom: 1rem;
  /* font-style: italic; */
`

const DarkGreenText = styled.p`
  font-size: 18px;
  color: #4a6f2f;
  font-family: 'Open Sans', sans-serif;
`

const Grey = styled.h1`
  color: #535d52;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.1em;
`

const StyledP = styled.p`
  font-size: 20px;
`

const StandardText = styled.p`
  margin-bottom: 0.1rem;
  margin-top: 0;
`

const BoldText = styled.p`
  font-weight: 700;
  margin-top: 1rem;
`

const Container = styled.div`
  background-color: red;
  height: 100vh;
  width: 100vw;
`

const StepChangeDel = {
  questions: [
    {
      id: 'intro-page',
      type: welcomePage,
      text: (
        <Textbox>
          {/* <p>
            As a member of the Baseline project you graciously contribute your farm financial
            performance and data to assess environmental impact in your region. This information is
            incredibly useful to understand how proposed policy changes will impact farmers in your
            area with similar farm types. As you know, it’s not just the numbers that count, but
            also how we feel about our businesses and evolving the way we farm. Because of that we
            are keen to understand how you feel about being environmentally sustainable and
            profitable, and what actions you’re taking towards environmental sustainability.
          </p>
          <p style={{ marginBottom: '1rem' }}>
            This information will be used to understand farmer attitudes in your area and, along
            with your data, will inform DairyNZ’s response to supporting farmers in your region.
          </p>
          <p style={{ marginBottom: '0' }}>
            The survey will take 5-10 minutes to complete. The answers you provide will be aligned
            with your farm data results but will remain anonymous.
          </p> */}
          {/* <p>
            DairyNZ are keen to understand what actions you are taking towards environmental
            sustainability. The survey will take 5 – 10 mins to complete. All answers you provide
            are anonymous.
          </p> */}
          <p style={{ marginBottom: '0.5rem' }}>
            DairyNZ are keen to understand what actions you are taking towards environmental
            sustainability. The answers you share will contribute to a wider survey conducted by
            DairyNZ and will be presented at the DEL forum.
          </p>
          <p>The survey will take 5 - 10 minutes to complete</p>
        </Textbox>
      ),
    },
    {
      id: 'recent-activities',
      placeholder: 'What have you been up to over the past year?',
      section: () => {
        return (
          <Textbox>
            <h4 style={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}>
              {/* Thinking about what you’ve been doing over the past year, to what extent has your
              involvement in DEL influenced the things you’ve been doing? */}
              What have you been up to over the past year - on farm, telling the farming story,
              involvement in policy processes?{' '}
            </h4>
            <p>
              Let’s celebrate our achievements – large and small they all contribute to sustainable
              dairying!
            </p>
          </Textbox>
        )
      },
      type: 'commentBox',
      required: true,
    },
    {
      id: 'recent-activities-p2',
      type: responsiveMatrix,
      hideQuestionText: true,
      text: 'Thinking further about the things you’ve been doing over the past year.',
      useColumns: true,
      hide: true,
      useValues: true,
      required: true,
      width: '150px',
      height: '3rem',
      choices: [
        {
          id: 1,
          // text:
          //   'To what extent do you think your involvement in DEL has influenced the things you’ve been doing?',
          text:
            'Thinking about what you’ve been doing over the past year, to what extent has your involvement in DEL influenced the things you’ve been doing?',
          value: 'DEL involvement',
        },
      ],
      columns: [
        {
          hide: true,
          id: 1,
          text: 'Not at all influential',
          value: 'Not at all influential',
        },
        {
          hide: true,
          id: 2,
          text: 'Slightly influential',
          value: 'Slightly influential',
        },
        {
          hide: true,
          id: 3,
          text: 'Moderately influential',
          value: 'Moderately influential',
        },
        {
          hide: true,
          id: 4,
          text: 'Very influential',
          value: 'Very influential',
        },
        {
          hide: true,
          id: 5,
          text: 'Extremely influential',
          value: 'Extremely influential',
        },
      ],
      rows: [
        {
          hide: true,
          id: '1',
          text:
            'To what extent do you think your involvement in DEL has influenced the things you’ve been doing?',
          value: 'DEL involvement',
        },
      ],
      options: [
        {
          hide: true,
          id: 1,
          text: 'Not at all influential',
          value: 'Not at all influential',
        },
        {
          hide: true,
          id: 2,
          text: 'Slightly influential',
          value: 'Slightly influential',
        },
        {
          hide: true,
          id: 3,
          text: 'Moderately influential',
          value: 'Moderately influential',
        },
        {
          hide: true,
          id: 4,
          text: 'Very influential',
          value: 'Very influential',
        },
        {
          hide: true,
          id: 5,
          text: 'Extremely influential',
          value: 'Extremely influential',
        },
      ],
    },
    {
      id: 'region',
      required: true,
      section: (
        <Wrapper>
          <BlueText>Introduction</BlueText>
        </Wrapper>
      ),
      type: multiChoice,
      verticalGrid: true,
      text: 'Which region are you in?',
      choices: [
        {
          id: 1,
          value: 'Northland',
          text: 'Northland',
        },
        {
          id: 2,
          value: 'Waikato',
          text: 'Waikato',
        },
        {
          id: 3,
          value: 'Bay of Plenty',
          text: 'Bay of Plenty',
        },
        {
          id: 4,
          value: 'Taranaki',
          text: 'Taranaki',
        },
        {
          id: 5,
          value: 'Lower North Island',
          text: 'Lower North Island',
        },
        {
          id: 6,
          value: 'Top of South Island',
          text: 'Top of South Island',
        },
        {
          id: 7,
          value: 'West Coast',
          text: 'West Coast',
        },
        {
          id: 8,
          value: 'Canterbury / North Otago',
          text: 'Canterbury / North Otago',
        },
        {
          id: 9,
          value: 'Southland / South Otago',
          text: 'Southland / South Otago',
        },
      ],
    },
    // {
    //   id: 'involved-to-date',
    //   type: ranking,
    //   removable: true,
    //   disclaimer:
    //     'Click the red cross to eliminate any reasons you think aren’t relevant. Then drag and drop the remaining answers to rank them.',
    //   text: () => {
    //     return (
    //       <Textbox>
    //         <p>We appreciate your involvement in the Baseline project so far.</p>
    //         <p>What would you say are the key reason(s) you’ve been involved to-date?</p>
    //       </Textbox>
    //     )
    //   },
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'Accessing the analysis and reporting (physical information)',
    //       value: 'Accessing the analysis and reporting (physical information)',
    //     },
    //     {
    //       id: 2,
    //       text: 'Accessing the analysis and reporting (financial information)',
    //       value: 'Accessing the analysis and reporting (financial information)',
    //     },
    //     {
    //       id: 3,
    //       text: 'Preparing my Overseer file',
    //       value: 'Preparing my Overseer file',
    //     },
    //     {
    //       id: 4,
    //       text: 'Keeping on top of my own monitoring',
    //       value: 'Keeping on top of my own monitoring',
    //     },
    //     {
    //       id: 5,
    //       text: 'Doing my bit for the industry/industry good',
    //       value: 'Doing my bit for the industry/industry good',
    //     },
    //     {
    //       id: 6,
    //       text: 'Contributing to a national dataset',
    //       value: 'Contributing to a national dataset',
    //     },
    //     {
    //       id: 7,
    //       text: 'Another reason',
    //       value: '',
    //       type: input,
    //     },
    //   ],
    // },
    // {
    //   id: 'biggest-barrier-to-participation',
    //   type: ranking,
    //   removable: true,
    //   disclaimer:
    //     'Click the red cross to eliminate any reasons you think aren’t relevant. Then drag and drop the remaining answers to rank them.',
    //   text: () => {
    //     return (
    //       <div>
    //         <p>
    //           Monitoring farm business data over time and identifying trends allows DairyNZ to build
    //           evidence to respond to policy issues, as well as to help other New Zealand dairy
    //           farmers. DairyNZ are looking for more farmers to participate in the project.
    //         </p>
    //         <p>
    //           Thinking about farmers who you know in your area, what do you think the biggest
    //           barriers to participation would be for them?
    //         </p>
    //       </div>
    //     )
    //   },
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'The time commitment to participate',
    //       value: 'The time commitment to participate',
    //     },
    //     {
    //       id: 2,
    //       text: 'The ability to access the data needed',
    //       value: 'The ability to access the data needed',
    //     },
    //     {
    //       id: 3,
    //       text: 'Finding it relevant',
    //       value: 'Finding it relevant',
    //     },
    //     {
    //       id: 4,
    //       text:
    //         'Repetitive requests for the same data that they provide to others (like the dairy company)',
    //       value:
    //         'Repetitive requests for the same data that they provide to others (like the dairy company)',
    //     },
    //     {
    //       id: 5,
    //       text: 'Worrying about the privacy of their data',
    //       value: 'Worrying about the privacy of their data',
    //     },
    //     {
    //       id: 6,
    //       text: 'Another reason',
    //       value: '',
    //       // width: '21.6rem',
    //       type: input,
    //     },
    //     {
    //       id: 7,
    //       text: "I don't think they would have any barriers to participation",
    //       value: "I don't think they would have any barriers to participation",
    //       type: multiChoice,
    //     },
    //   ],
    // },
    // {
    //   id: 'incentivise-involvement',
    //   type: ranking,
    //   disclaimer:
    //     'Click the red cross to eliminate any reasons you think aren’t relevant. Then drag and drop the remaining answers to rank them.',
    //   removable: true,
    //   text: () => {
    //     return (
    //       <Textbox>
    //         <p>Thinking again about the farmers you know in your area.</p>
    //         <p>
    //           What do you think could help to incentivise them to be involved in the Baseline
    //           project?
    //         </p>
    //       </Textbox>
    //     )
    //   },
    //   // text:
    //   //   'Thinking again about the farmers you know in your area. What do you think could help to incentivise them to be involved in the Baseline project? ',
    //   choices: [
    //     {
    //       id: 1,
    //       text: "Paying for the participant's time",
    //       value: "Paying for the participant's time",
    //     },
    //     {
    //       id: 2,
    //       text: 'Providing the participants with a DairyBase report interpretation',
    //       value: 'Providing the participants with a DairyBase report interpretation',
    //     },
    //     {
    //       id: 3,
    //       text: 'Inviting participants to a special DairyNZ event / workshop',
    //       value: 'Inviting participants to a special DairyNZ event / workshop',
    //     },
    //     {
    //       id: 4,
    //       text: 'Paying for a participants Overseer subscription',
    //       value: 'Paying for a participants Overseer subscription',
    //     },
    //     {
    //       id: 5,
    //       text: 'Helping participants to complete a compliance task',
    //       value: 'Helping participants to complete a compliance task',
    //     },
    //     {
    //       id: 6,
    //       text: 'Another reason',
    //       value: '',
    //       type: input,
    //       // width: '21.6rem',
    //     },
    //     {
    //       id: 7,
    //       text: "I don't think they'd need an incentive",
    //       value: "I don't think they'd need an incentive",
    //       type: multiChoice,
    //     },
    //   ],
    // },
    {
      id: 'overall',
      required: true,
      buttonSize: xlButton.width,
      // section: (
      //   <Wrapper>
      //     <BlueText>Overall</BlueText>
      //   </Wrapper>
      // ),
      type: multiChoice,
      verticalGrid: true,
      mobileHeight: '7rem',
      height: '4rem',
      text:
        'Which best describes you in relation to taking action towards environmental sustainability?',
      choices: [
        {
          id: 1,
          text: "It's not on my radar",
          value: "It's not on my radar",
        },
        {
          id: 2,
          text:
            "I don't think I should have to, or don't want to, take action towards environmental sustainability",
          value:
            "I don't think I should have to, or don't want to, take action towards environmental sustainability",
        },
        {
          id: 3,
          text:
            "I'm willing to take action towards environmental sustainability, but I don't know where to start",
          value:
            "I'm willing to take action towards environmental sustainability, but I don't know where to start",
        },
        {
          id: 4,
          text:
            "I'm willing to take action towards environmental sustainability, and I know where to start, but its not a priority so I haven't done anything yet",
          value:
            "I'm willing to take action towards environmental sustainability, and I know where to start, but its not a priority so I haven't done anything yet",
        },
        {
          id: 5,
          text:
            "I've taken some action towards environmental sustainability but I'm not confident its enough to meet long term obligations and whether I can be profitable as well",
          value:
            "I've taken some action towards environmental sustainability but I'm not confident its enough to meet long term obligations and whether I can be profitable as well",
        },
        {
          id: 6,
          text:
            "I've adapted my farm system to be both environmentally and financially sustainable and I feel confident about the future",
          value:
            "I've adapted my farm system to be both environmentally and financially sustainable and I feel confident about the future",
        },
      ],
    },
    {
      id: 'freshwater-farmplan-p1',
      required: true,
      buttonSize: lgButton.width,
      mobileHeight: '5rem',
      section: (
        <Wrapper>
          <BlueText>Freshwater Farm Plan / Farm Environment Plans</BlueText>
          <DisclaimerText>
            Note: currently many Freshwater Farm Plans are known as Farm Environment Plans.
          </DisclaimerText>
        </Wrapper>
      ),
      disclaimer: 'Note: currently many Freshwater Farm Plans are known as Farm Environment Plans.',
      verticalGrid: true,
      type: multiChoice,
      text: 'Which best describes your farm plans?',
      choices: [
        {
          id: 1,
          text: 'I don’t plan to get a Freshwater Farm Plan',
          value: 'I don’t plan to get a Freshwater Farm Plan',
        },
        {
          id: 2,
          text: 'I have a plan in place to get a Freshwater Farm Plan',
          value: 'I have a plan in place to get a Freshwater Farm Plan',
        },
        {
          id: 3,
          text:
            'Our farm has a Freshwater Farm Plan but I am not confident that it is up to date and fit-for-purpose',
          value:
            'Our farm has a Freshwater Farm Plan but I am not confident that it is up to date and fit-for-purpose',
        },
        {
          id: 4,
          text:
            'Our farm has a Freshwater Farm Plan that was developed with input from a qualified advisor',
          value:
            'Our farm has a Freshwater Farm Plan that was developed with input from a qualified advisor',
        },
      ],
      next: ({ answers }) => {
        if (
          answers['freshwater-farmplan-p1'].value ===
            'Our farm has a Freshwater Farm Plan that was developed with input from a qualified advisor' ||
          answers['freshwater-farmplan-p1'].value ===
            'Our farm has a Freshwater Farm Plan but I am not confident that it is up to date and fit-for-purpose'
        ) {
          console.log('answers', answers['freshwater-farmplan-p1'].value)
          return 'freshwater-farm-plan-p2'
        }
        return 'nutrient-management-p1'
      },
    },
    {
      id: 'freshwater-farm-plan-p2',
      required: true,
      verticalGrid: true,
      // section: (
      //   <Wrapper>
      //     <BlueText>Freshwater Farm Plan / Farm Environment Plans</BlueText>
      //     <DisclaimerText>
      //       Note: currently many Freshwater Farm Plans are known as Farm Environment Plans.
      //     </DisclaimerText>
      //   </Wrapper>
      // ),
      type: multiChoice,
      text: 'Is the following true for you?',
      // text:
      //   'We are recording when we complete the actions recommended in the Freshwater Farm Plan',
      statement: () => {
        return (
          <div>
            <BoldText>
              We are recording when we complete the actions recommended in the Freshwater Farm Plan.
            </BoldText>
          </div>
        )
      },
      choices: [
        {
          id: 1,
          text: 'Yes',
          value: 'Yes',
          icon: <IconCheck fill="currentColor" />,
          fill: '#50d250',
        },
        {
          id: 2,
          text: 'No',
          value: 'No',
          icon: <IconCross fill="currentColor" />,
          fill: '#1890ff',
        },
      ],
    },
    {
      id: 'nutrient-management-p1',
      required: true,
      section: (
        <Wrapper>
          <BlueText>Nutrient management</BlueText>
          <Q>Which of the following is true for you?</Q>
        </Wrapper>
      ),
      // text:
      // 'We understand Good Management Practices that apply to nutrient management and fertiliser application and follow these',
      type: 'imageRadioList',
      choices: [
        {
          id: 1,
          text: 'A nutrient budget is completed every year and reviewed with a qualified advisor',
          value: 'A nutrient budget is completed every year and reviewed with a qualified advisor',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 2, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 2,
          text:
            'Soil phosphorous levels are monitored through regular soil testing and maintained at or below agronomic optimum levels',
          value:
            'Soil phosphorous levels are monitored through regular soil testing and maintained at or below agronomic optimum levels',
          answerChoices: [
            { id: 3, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 4, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
      ],
    },
    {
      id: 'nutrient-management-p2',
      required: true,
      buttonSize: xlButton.width,
      section: (
        <Wrapper>
          <Q>Is the following true for you?</Q>
        </Wrapper>
      ),
      text:
        'We understand Good Management Practices that apply to nutrient management and fertiliser application and follow these',
      type: 'imageRadioList',
      choices: [
        {
          id: 1,
          text:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          value:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 2, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 2,
          text:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          value:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          answerChoices: [
            { id: 3, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 4, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 3,
          text: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          value: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          answerChoices: [
            { id: 5, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 6, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 4,
          text:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          value:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          answerChoices: [
            { id: 7, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 8, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
      ],
    },
    {
      id: 'water-quality-assessment',
      required: true,
      section: (
        <Wrapper>
          <BlueText>Water quality risk assessment</BlueText>
        </Wrapper>
      ),
      type: 'imageRadioList',
      text: 'Which of the following is true for you?',
      options: [
        { id: 1, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
        { id: 2, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
      ],
      choices: [
        {
          id: 1,
          text:
            'I have assessed the risk from all our farm practices to water quality (e.g. from farm dairy effluent, management of critical source areas – where water accumulates during wet periods; water use and irrigation; crop and pasture grazing principles.)',
          value:
            'I have assessed the risk from all our farm practices to water quality (e.g. from farm dairy effluent, management of critical source areas – where water accumulates during wet periods; water use and irrigation; crop and pasture grazing principles.)',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 2, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 2,
          text:
            'For all high and medium risk areas identified we have developed time-bound actions to mitigate impacts.',
          value:
            'For all high and medium risk areas identified we have developed time-bound actions to mitigate impacts.',
          answerChoices: [
            { id: 3, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 4, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
      ],
    },
    {
      id: 'greenhouse-gases',
      required: true,
      buttonSize: lgButton.width,
      section: (
        <Wrapper>
          <BlueText>Greenhouse gases</BlueText>
        </Wrapper>
      ),
      height: '4rem',
      mobileHeight: '5rem',
      verticalGrid: true,
      type: multiChoice,
      text: 'Which of the following best describes you with regards to emissions on your farm? ',
      choices: [
        {
          id: 1,
          text:
            'I have not estimated greenhouse gas emissions or set targets / actions for my farm',
          value:
            'I have not estimated greenhouse gas emissions or set targets / actions for my farm',
        },
        {
          id: 2,
          text:
            'I have estimated how much greenhouse gases are emitted from my farm but have not set targets / actions',
          value:
            'I have estimated how much greenhouse gases are emitted from my farm but have not set targets / actions',
        },
        {
          id: 3,
          text: 'I have set targets and / or identified actions but have not taken action yet',
          value: 'I have set targets and / or identified actions but have not taken action yet',
        },
        {
          id: 4,
          text:
            'I have taken specific actions to change the amount of greenhouse gases produced by my farm',
          value:
            'I have taken specific actions to change the amount of greenhouse gases produced by my farm',
        },
      ],
    },
    {
      id: 'financial-management',
      required: true,
      section: (
        <Wrapper>
          <BlueText>Financial management</BlueText>
        </Wrapper>
      ),
      type: 'imageRadioList',
      text: 'Which of the following is true for you?',
      choices: [
        {
          id: 1,
          text: 'I have a written business plan and am implementing it',
          value: 'I have a written business plan and am implementing it',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              icon: <IconCheck fill="currentColor" />,
              fill: '#50d250',
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              icon: <IconCross fill="currentColor" />,
              fill: '#1890ff',
            },
            // { id: 1, value: 'Yes' },
            // { id: 2, value: 'No' },
          ],
        },
        {
          id: 2,
          text:
            'I use a budgeting and cashflow tool (template, resource, software etc.) to manage my finances',
          value:
            'I use a budgeting and cashflow tool (template, resource, software etc.) to manage my finances',
          answerChoices: [
            { id: 3, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 4, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 3,
          text:
            'I use financial benchmarking and key performance indicators (e.g. operating profit/ha, farm working expenses, return on assets etc.) to help me achieve my finance goals',
          value:
            'I use financial benchmarking and key performance indicators (e.g. operating profit/ha, farm working expenses, return on assets etc.) to help me achieve my finance goals',
          answerChoices: [
            { id: 5, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 6, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 4,
          text:
            'We have a clear plan to manage our debt over time (pay off plan, maintaining a debt to asset ratio)',
          value:
            'We have a clear plan to manage our debt over time (pay off plan, maintaining a debt to asset ratio)',
          answerChoices: [
            { id: 7, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 8, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
      ],
    },

    {
      id: 'money-spent-environmental-protection',
      type: inputFieldNumber,
      symbol: '$',
      inputLabel: ' ',
      // section: (
      //   <Wrapper>
      //     <BlueText>Financial management</BlueText>
      //   </Wrapper>
      // ),
      required: true,
      label: 'Please type in a dollar amount',
      text:
        'How much money have you spent on environmental protection on your farm in the last year? e.g. Recontouring races, effluent system upgrade, riparian planting, green wash for the dairy, etc.',
    },
    {
      id: 'actions-taken',
      required: true,
      width: '24rem',
      section: (
        <Wrapper>
          <BlueText>Financial management</BlueText>
        </Wrapper>
      ),
      verticalGrid: true,
      type: multiSelect,
      text:
        'What farm management practices are you applying or have you applied for environmental protection?',
      choices: [
        {
          id: 1,
          value: 'Used coated urea products',
          text: 'Used coated urea products',
        },
        {
          id: 2,
          value: 'Upgraded the effluent system',
          text: 'Upgraded the effluent system',
        },
        {
          id: 3,
          value: 'Put in soil moisture meters',
          text: 'Put in soil moisture meters',
        },
        {
          id: 4,
          value: 'Put in water meters',
          text: 'Put in water meters',
        },
        {
          id: 5,
          value: 'Did riparian planting',
          text: 'Did riparian planting',
        },
        {
          id: 6,
          value: 'Fenced off waterways',
          text: 'Fenced off waterways',
        },
        {
          id: 7,
          value: 'Retired land',
          text: 'Retired land',
        },
        {
          id: 8,
          value: 'Established or preserved wetlands',
          text: 'Established or preserved wetlands',
        },
        {
          id: 9,
          value: 'Provided proof of placement mapping for fertiliser',
          text: 'Provided proof of placement mapping for fertiliser',
        },
        {
          id: 10,
          value: '',
          type: input,
          width: '24rem',
          text: 'Other action: ',
        },
        {
          id: 11,
          value: 'None of the above',
          text: 'None of the above',
          submit: true,
        },
      ],
    },
    {
      id: 'Pride',
      required: true,
      section: (
        <Wrapper>
          <BlueText>Pride</BlueText>
        </Wrapper>
      ),
      verticalGrid: true,
      type: multiChoice,
      text: 'How proud do you feel to be working in the dairy industry at the moment? ',
      choices: [
        {
          id: 1,
          value: 'I am not proud at all',
          text: 'I am not proud at all',
        },
        {
          id: 2,
          value: 'I am somewhat proud',
          text: 'I am somewhat proud',
        },
        {
          id: 3,
          value: 'I am moderately proud',
          text: 'I am moderately proud',
        },
        {
          id: 4,
          value: 'I am very proud',
          text: 'I am very proud',
        },
        {
          id: 5,
          value: 'I am extremely proud',
          text: 'I am extremely proud',
        },
      ],
    },
    {
      id: 'rise-and-shine-campaign',
      type: multiChoice,
      required: true,
      verticalGrid: true,
      section: (
        <Wrapper>
          <BlueText>Rise + Shine campaign</BlueText>
        </Wrapper>
      ),
      text:
        'Before today, had you seen, read or heard anything to do with the campaign ‘Rise & Shine’?',
      choices: [
        {
          id: 1,
          text: 'Yes',
          value: 'Yes',
          icon: <IconCheck fill="currentColor" />,
          fill: '#50d250',
        },
        {
          id: 2,
          text: 'No',
          value: 'No',
          icon: <IconCross fill="currentColor" />,
          fill: '#1890ff',
        },
      ],
    },
    {
      id: 'name',
      text: 'What is your full name?',
      type: multiChoice,
      required: true,
      choices: [
        {
          id: 1,
          text: 'Full name',
          type: input,
          placeholder: 'John Smith',
        },
      ],
    },
    {
      id: 'end-survey',
      text: 'Thank you for your time.',
      type: endPage,
    },
  ],
  settings: {
    writeToFirestore: true,
    surveyName: 'farmer-action-del',
    companyLogo,
    hideSubmit: true,
    objectPosition: 'right',
    primaryColour: '#05B153',
    showQid: true,
    useVideo: true,
    backgroundVideo,
  },
}

export default StepChangeDel
