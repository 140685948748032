import React from 'react'
import styled from 'styled-components/macro'
import { Textbox } from '../../assets/styles/global'
import {
  commentBox,
  endPage,
  multiChoice,
  responsiveMatrix,
  responsiveSlider,
  welcomePage,
} from '../question-types/QuestionTypes'

import RuralCo_Logo from '../../assets/images/RuralCo/Ruralco LOGO_OBSESSED.jpg'
import backgroundVideo from '../../assets/images/Scarlatti/confer-scarlatti.mp4'
import { ReactComponent as Happy } from '../../assets/images/Scarlatti/Happy Face.svg'
import { ReactComponent as Ok } from '../../assets/images/Scarlatti/Ok face.svg'
import { ReactComponent as Sad } from '../../assets/images/Scarlatti/Sad Face.svg'
import { ReactComponent as VeryHappy } from '../../assets/images/Scarlatti/Very happy Face.svg'
import { ReactComponent as VerySad } from '../../assets/images/Scarlatti/Very sadFace 1.svg'
import { xlButton } from '../ui/Layout/ButtonContainer'

// Formatting
const IconHappy = styled(Happy)`
  width: 3rem;
  height: 3rem;
`
const IconOk = styled(Ok)`
  width: 3rem;
  height: 3rem;
`
const IconSad = styled(Sad)`
  width: 3rem;
  height: 3rem;
`
const IconVeryHappy = styled(VeryHappy)`
  width: 3rem;
  height: 3rem;
`
const IconVerySad = styled(VerySad)`
  width: 3rem;
  height: 3rem;
`

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  li {
    font-size: 1rem;
    font-weight: 400;
  }
`
const StyledP = styled.p`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0rem;
  margin-top: 1rem;
`

// Questions
const RuralCo_wellbeing = {
  questions: [
    // Intro Page
    {
      id: 'intro-page',
      type: welcomePage,
      buttonSize: xlButton.width,
      text: (
        <StyledTextbox style={{ alignItems: 'baseline' }}>
          <StyledP>
            Thank you for participating in this survey. The survey is completely anonymous – we will
            not be able to link answers to individuals.
          </StyledP>
          <StyledP>
            The purpose of the survey is to get an understanding of our collective engagement and
            wellbeing.
          </StyledP>
          <StyledP>
            We will use the results to guide us toward improving how we do things, and we will
            involve you in the process by sharing the results and asking your opinions on what we
            could do better.
          </StyledP>
        </StyledTextbox>
      ),
    },
    // Section one: Engagement
    // Intro Page
    // {
    //     id: 'Engagement-intro',
    //     type: welcomePage,
    //     section: (
    //         <StyledTextbox>
    //             <StyledP>Section one: Engagement</StyledP>
    //             {/* <p style={{ marginTop: '1rem', fontSize: '1rem', lineHeight: '2' }}>The following questions are called the “Gallup Q12”. It is the widest used organisational engagement survey in the world. The questions (some are slightly bizarre) are based on research around what creates engagement. By answering them, we will be able to compare ourselves to other organisations, and national averages.</p> */}
    //             <StyledP>
    //                 The following questions are called the “Gallup Q12”. It is the widest used organisational engagement survey in the world. The questions (some are slightly bizarre) are based on research around what creates engagement. By answering them, we will be able to compare ourselves to other organisations, and national averages.
    //             </StyledP>
    //         </StyledTextbox>
    //     ),
    // },
    // Question 1.1 page 1
    {
      id: 'Engagement-Q1-P1',
      type: responsiveMatrix,
      section: (
        // <h3 style={{ color: "#e11b22" }}>Section one: Engagement</h3>
        <h3>Section one: Engagement</h3>
      ),
      // text: "The below questions are called the “Gallup Q12”. It is the widest used organisational engagement survey in the world. The questions (some are slightly bizarre) are based on research around what creates engagement. By answering them, we will be able to compare ourselves to other organisations, and national averages.",
      useIcons: true,
      // disclaimer: "Please rate the following statements from 1-5, where 1=strongly disagree and 5=strongly agree.",
      disclaimer: 'Please rate the following statements.',
      required: true,

      choices: [
        {
          id: 1,
          text: '1) I know what is expected of me at work:',
          value: 'I know what is expected of me at work',
        },
        {
          id: 2,
          text: '2) I have the materials and equipment I need to do my work right:',
          value: 'I have the materials and equipment I need to do my work right',
        },
        {
          id: 3,
          text: '3) At work, I have the opportunity to do what I do best every day:',
          value: 'At work, I have the opportunity to do what I do best every day',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    // Question 1.1 page 2
    {
      id: 'Engagement-Q1-P2',
      type: responsiveMatrix,
      section: (
        // <h3 style={{ color: "#e11b22" }}>Section one: Engagement</h3>
        <h3>Section one: Engagement</h3>
      ),
      useIcons: true,
      disclaimer: 'Please rate the following statements.',
      required: true,

      choices: [
        {
          id: 4,
          text:
            '4) In the last seven days, I have received recognition or praise for doing good work:',
          value:
            'In the last seven days, I have received recognition or praise for doing good work',
        },
        {
          id: 5,
          text: '5) My supervisor, or someone at work, seems to care about me as a person:',
          value: 'My supervisor, or someone at work, seems to care about me as a person',
        },
        {
          id: 6,
          text: '6) There is someone at work who encourages my development:',
          value: 'There is someone at work who encourages my development',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    // Question 1.1 page 3
    {
      id: 'Engagement-Q1-P3',
      type: responsiveMatrix,
      section: <h3>Section one: Engagement</h3>,
      useIcons: true,
      required: true,
      disclaimer: 'Please rate the following statements.',

      choices: [
        {
          id: 7,
          text: '7) At work, my opinions seem to count:',
          value: 'At work, my opinions seem to count',
        },
        {
          id: 8,
          text: '8) The mission or purpose of my company makes me feel my job is important:',
          value: 'The mission or purpose of my company makes me feel my job is important',
        },
        {
          id: 9,
          text: '9) My fellow employees are committed to doing quality work:',
          value: 'My fellow employees are committed to doing quality work',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    // Question 1.1 page 4
    {
      id: 'Engagement-Q1-P4',
      type: responsiveMatrix,
      section: <h3>Section one: Engagement</h3>,
      useIcons: true,
      required: true,
      disclaimer: 'Please rate the following statements.',

      choices: [
        {
          id: 10,
          text: '10) I have a best friend at work:',
          value: 'I have a best friend at work',
        },
        {
          id: 11,
          text: '11) In the last six months, someone at work has talked to me about my progress:',
          value: 'In the last six months, someone at work has talked to me about my progress',
        },
        {
          id: 12,
          text: '12) This last year, I have had opportunities to learn and grow:',
          value: 'This last year, I have had opportunities to learn and grow',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    // Question 1.2 (Q2.1 move here to become Q2)
    {
      // id: "Wellbeing-orgainsation-Q1",
      id: 'Engagement-Q2',
      type: responsiveSlider,
      required: true,
      section: <h3>Section one: Engagement</h3>,
      disclaimer: 'Please rate:',
      text: 'I feel remunerated fairly for the effort I put in and the contribution I make',
      default: 2,
      max: 10,
      marks: [
        { id: 1, text: 'Poor' },
        { id: 2, text: 'Neutral' },
        { id: 3, text: 'Excellent' },
      ],
      choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    // Question 1.3
    {
      id: 'Engagement-Q3',
      type: commentBox,
      text: 'What is the part of your job you like the most?',
      placeholder: 'Please type here...',
      required: true,
      section: <h3>Section one: Engagement</h3>,
    },
    // Question 1.4
    {
      id: 'Engagement-Q4',
      type: commentBox,
      text: 'If there was one thing you could change about your job, what would it be?',
      placeholder: 'Please type here...',
      required: true,
      section: <h3>Section one: Engagement</h3>,
    },
    // Section two: Welllbeing - organisation
    // Intro Page
    {
      id: 'Wellbeing-organisation-intro',
      type: welcomePage,
      section: (
        <StyledTextbox>
          {/* <h3>Section two: Wellbeing-organisation</h3>
                    <p style={{ marginTop: '1rem', fontSize: '1rem', lineHeight: '2' }}>All work involves stress, but not all stress is bad. In fact, without stress, we can become bored and depressed. But too much stress can cause anxiety and poor health. While it is unlikely that any organisation can remove all stressors, it is possible to minimise them.  At Ruralco we want to do what we can to get this balance right. This section is about getting an understanding of our organisational wellbeing risks.</p>
                    <p style={{ marginTop: '1rem', fontSize: '1rem', lineHeight: '2' }}>When answering these questions, it can be difficult to separate work and personal life. Think carefully on each answer, reflecting on the nature of the job separate to your personal circumstances or behaviours. For example, things like having overly high standards, a tendency to procrastinate or the fact you live far away from your place of work are not caused by the job. It can be tricky to separate these things. The helpful thing to do can be to think if you were in another job – would the situation be less or more or the same?</p> */}
          <StyledP>Section two: Wellbeing- organisation</StyledP>
          <StyledP>
            All work involves stress, but not all stress is bad. Without stress, we can become bored
            and depressed. However, too much stress can cause anxiety and poor health.
          </StyledP>
          <StyledP>
            While it is impossible for an organisation to remove all stressors, it is possible to
            minimise them. At Ruralco, we want to do what we can to get this balance right. This
            section is about getting an understanding of our organisational wellbeing risks.
          </StyledP>
          <StyledP>
            When answering these questions, it can be difficult to separate work and personal life.
            Think carefully on each answer, reflecting on the nature of the job separate to your
            personal circumstances or behaviours. For example, things like having overly high
            standards, a tendency to procrastinate, or the fact you live far away from your place of
            work are not caused by the job.
          </StyledP>
          <StyledP>
            It can be tricky to separate these things. The helpful thing to do can be to think if
            you were in another job – would the situation be less or more or the same?
          </StyledP>
        </StyledTextbox>
      ),
    },
    // Question 2.1
    {
      id: 'Wellbeing-organisation-Q1',
      type: responsiveSlider,
      required: true,
      section: <h3>Section two: Wellbeing-organisation</h3>,
      disclaimer: 'Please rate:',
      text: 'How would you rate your current workload?',
      default: 2,
      max: 10,
      marks: [
        { id: 1, text: 'Bored' },
        { id: 2, text: 'Healthy, rewarding challenges' },
        { id: 3, text: 'Burnt out' },
      ],
      choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    // Question 2.2 part1 (smiley faces)
    {
      id: 'Wellbeing-organisation-Q2-P1',
      type: responsiveMatrix,
      section: <h3>Section two: Wellbeing-organisation</h3>,
      useIcons: true,
      required: true,
      disclaimer: 'Please rate the following statements.',

      choices: [
        {
          id: 1,
          text:
            '1) My job does NOT hinder me doing the things I love and seeing the people that are important to me',
          value:
            '1) My job does NOT hinder me doing the things I love and seeing the people that are important to me',
        },
        {
          id: 2,
          text: '2) My job does NOT negatively affect the quality of sleep I get',
          value: '2) My job does NOT negatively affect the quality of sleep I get',
        },
        {
          id: 3,
          text: '3) My job does NOT hinder me from getting the exercise that is right for me',
          value: '3) My job does NOT hinder me from getting the exercise that is right for me',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    // Question 2.2 part2 (smiley faces)
    {
      id: 'Wellbeing-organisation-Q2-P2',
      type: responsiveMatrix,
      section: <h3>Section two: Wellbeing-organisation</h3>,
      useIcons: true,
      required: true,
      disclaimer: 'Please rate the following statements.',

      choices: [
        {
          id: 4,
          text: '4) My job does NOT hinder me from being able to make healthy food choices',
          value: '4) My job does NOT hinder me from being able to make healthy food choices',
        },
        {
          id: 5,
          text: '5) My job does not make me feel isolated due to my ethnicity or gender identity ',
          value: '5) My job does not make me feel isolated due to my ethnicity or gender identity ',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    // Question 2.2 (multiDropDown)
    // {
    //     id: 'wellbeing-organization-Q3-P1',
    //     required: true,
    //     buttonSize: xlButton.width,
    //     type: multiDropdown,
    //     text: 'On a scale of 1-10 (1 being my job greatly hinders, 5 being my job neither helps or hurts me in this area, and 10 being my job greatly helps this aspect of my life) please rate the following',
    //     choices: [
    //         {
    //             id: 1,
    //             text: 'My job does NOT hinder me doing the things I love and seeing the people that are important to me',
    //             value: 'My job does NOT hinder me doing the things I love and seeing the people that are important to me:',
    //         },
    //         {
    //             id: 2,
    //             text: 'My job does NOT negatively affect the quality of sleep I get',
    //             value: 'My job does NOT negatively affect the quality of sleep I get:',
    //         },
    //         {
    //             id: 3,
    //             text: 'My job does NOT hinder me from getting the exercise that is right for me',
    //             value: 'My job does NOT hinder me from getting the exercise that is right for me:',
    //         },
    //         {
    //             id: 4,
    //             text: 'My job does NOT hinder me from being able to make healthy food choices',
    //             value: 'My job does NOT hinder me from being able to make healthy food choices:',
    //         },
    //         {
    //             id: 5,
    //             text: 'My job does not make me feel isolated due to my ethnicity or gender identity ',
    //             value: 'My job does not make me feel isolated due to my ethnicity or gender identity :',
    //         },
    //     ],
    //     options: [
    //         {
    //             id: 1,
    //             text: '1 (greatly hinders)',
    //             value: '1 (greatly hinders)',
    //         },
    //         {
    //             id: 2,
    //             text: '2',
    //             value: '2',
    //         },
    //         {
    //             id: 3,
    //             text: '3',
    //             value: '3',
    //         },
    //         {
    //             id: 4,
    //             text: '4',
    //             value: '4',
    //         },
    //         {
    //             id: 5,
    //             text: '5 (neither helps or hurts)',
    //             value: '5 (neither helps or hurts)',
    //         },
    //         {
    //             id: 6,
    //             text: '6',
    //             value: '6',
    //         },
    //         {
    //             id: 7,
    //             text: '7',
    //             value: '7',
    //         },
    //         {
    //             id: 8,
    //             text: '8',
    //             value: '8',
    //         },
    //         {
    //             id: 9,
    //             text: '9',
    //             value: '9',
    //         },
    //         {
    //             id: 10,
    //             text: '10 (greatly helps)',
    //             value: '10 (greatly helps)',
    //         },
    //     ],
    // },
    // Question 2.2 Page 1 (MultiSlider)
    // {
    //     id: 'Wellbeing-organization-Q3-P1',
    //     useIcons: true,
    //     type: 'multiSlider',
    //     section: (
    //         <h3>Section two: Wellbeing</h3>
    //     ),
    //     // section: (
    //     //   <p>On a </p>
    //     // ),
    //     text: 'On a scale of 1-10 (1 being my job greatly hinders, 5 being my job neither helps or hurts me in this area, and 10 being my job greatly helps this aspect of my life) please rate the following',
    //     choices: [
    //         {
    //             id: 1,
    //             text:
    //                 'My job does NOT hinder me doing the things I love and seeing the people that are important to me',
    //             value: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    //             marks: [
    //                 { id: 1, text: "greatly hinders" },
    //                 { id: 2, text: "neither helps or hinders" },
    //                 { id: 3, text: "greatly helps" },
    //             ],
    //         },
    //         {
    //             id: 2,
    //             text: 'My job does NOT negatively affect the quality of sleep I get',
    //             value: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    //             marks: [
    //                 { id: 1, text: "greatly hinders" },
    //                 { id: 2, text: "neither helps or hinders" },
    //                 { id: 3, text: "greatly helps" },
    //             ]
    //         },
    //         {
    //             id: 3,
    //             text: 'My job does NOT hinder me from getting the exercise that is right for me',
    //             value: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    //             marks: [
    //                 { id: 1, text: "greatly hinders" },
    //                 { id: 2, text: "neither helps or hinders" },
    //                 { id: 3, text: "greatly helps" },
    //             ]
    //         },
    //     ],
    //     // options: [
    //     //     {
    //     //         id: 1,
    //     //         text: 'Strongly disagree',
    //     //         value: 'Strongly disagree',
    //     //         icon: <IconVerySad />,
    //     //     },
    //     //     {
    //     //         id: 2,
    //     //         text: 'Disagree',
    //     //         value: 'Disagree',
    //     //         icon: <IconSad />,
    //     //     },
    //     //     {
    //     //         id: 3,
    //     //         text: 'Neutral',
    //     //         value: 'Neutral',
    //     //         icon: <IconOk />,
    //     //     },
    //     //     {
    //     //         id: 4,
    //     //         text: 'Agree',
    //     //         value: 'Agree',
    //     //         icon: <IconHappy />,
    //     //     },
    //     //     {
    //     //         id: 5,
    //     //         text: 'Strongly agree',
    //     //         value: 'Strongly agree',
    //     //         icon: <IconVeryHappy />,
    //     //     },
    //     // ],
    // },
    // // Question 2.3 Page 2 (multiSlider)
    // {
    //     id: 'Wellbeing-organization-Q3-P2',
    //     useIcons: true,
    //     type: 'responsiveMatrix',
    //     section: (
    //         <h3>Section two: Wellbeing</h3>
    //     ),
    //     // section: (
    //     //     <Wrapper>
    //     //         <Q>Questions 4 of 6</Q>
    //     //         <Text color={'#F9744C'}>Wellbeing</Text>
    //     //         <p style={{ marginTop: '0.5rem' }}>
    //     //             Please indicate your agreement with the following statements:
    //     //         </p>
    //     //         <Divider />
    //     //     </Wrapper>
    //     // ),
    //     // text: '',
    //     choices: [
    //         {
    //             id: 1,
    //             text: 'My job does NOT hinder me from being able to make healthy food choices',
    //             value: 'My job does NOT hinder me from being able to make healthy food choices',
    //         },
    //         {
    //             id: 2,
    //             text: 'My job does NOT make me feel isolated due to my ethnicity or gender identity ',
    //             value: 'My job does NOT make me feel isolated due to my ethnicity or gender identity ',
    //         },
    //     ],
    //     options: [
    //         {
    //             id: 1,
    //             text: 'Strongly disagree',
    //             value: 'Strongly disagree',
    //             icon: <IconVerySad />,
    //         },
    //         {
    //             id: 2,
    //             text: 'Disagree',
    //             value: 'Disagree',
    //             icon: <IconSad />,
    //         },
    //         {
    //             id: 3,
    //             text: 'Neutral',
    //             value: 'Neutral',
    //             icon: <IconOk />,
    //         },
    //         {
    //             id: 4,
    //             text: 'Agree',
    //             value: 'Agree',
    //             icon: <IconHappy />,
    //         },
    //         {
    //             id: 5,
    //             text: 'Strongly agree',
    //             value: 'Strongly agree',
    //             icon: <IconVeryHappy />,
    //         },
    //     ],
    // },
    // Question 2.4
    // {
    //     id: "Wellbeing-organization-Q4",
    //     type: multiChoice,
    //     required: true,
    //     section: (
    //         <h3>Section two: Wellbeing</h3>
    //     ),
    //     // section: (
    //     //     <Wrapper>
    //     //       <Q>Questions 5 of 6</Q>
    //     //       <Text color={'#F9744C'}>Wellbeing</Text>
    //     //       <Divider />
    //     //     </Wrapper>
    //     //   ),
    //     text: 'My job creates distress',
    //     choices: [
    //         {
    //             id: 1,
    //             text: 'Yes',
    //             value: 'Yes',
    //             icon: <IconCheck fill="currentColor" />,
    //             fill: '#50d250',
    //         },
    //         {
    //             id: 2,
    //             text: 'No',
    //             value: 'No',
    //             icon: <IconCross fill="currentColor" />,
    //             fill: '#1890ff',
    //         },
    //     ],
    //     next: ({ answers }) => {
    //         if (answers["Wellbeing-organization-Q4"].value == "Yes") {
    //             return "Wellbeing-organization-Q5"
    //         }
    //         return "Wellbeing-personal-intro"
    //     },
    // },
    // // Question 2.5
    // {
    //     id: "Wellbeing-organization-Q5",
    //     type: multiSelect,
    //     required: true,
    //     text: "Which of the following are contributing to your work-caused distress?",
    //     disclaimer: 'Select all that apply',
    //     section: (
    //         <h3>Section two: Wellbeing</h3>
    //     ),
    //     required: true,
    //     limit: 6,
    //     choices: [
    //         {
    //             id: 1,
    //             text: "Lack of resources (human, equipment or budget)",
    //             value: "Lack of resources (human, equipment or budget)",
    //         },
    //         {
    //             id: 2,
    //             text: "Lack of support",
    //             value: "Lack of support",
    //         },
    //         {
    //             id: 3,
    //             text: "High pressure, frequent urgent deadlines",
    //             value: "High pressure, frequent urgent deadlines",
    //         },
    //         {
    //             id: 4,
    //             text: "Unrealistic responsibilities",
    //             value: "Unrealistic responsibilities",
    //         },
    //         {
    //             id: 5,
    //             text: "Human conflict / bullying / injustice",
    //             value: "Human conflict / bullying / injustice",
    //         },
    //         {
    //             id: 6,
    //             text: "Other",
    //             value: "",
    //             type: input,
    //         },
    //     ],
    // },
    // Section three: Welllbeing - personal
    // Intro Page
    {
      id: 'Wellbeing-personal-intro',
      type: welcomePage,
      section: (
        <StyledTextbox>
          <h3>Section three: Wellbeing-personal</h3>
        </StyledTextbox>
      ),
    },
    // Question 3.1 Page 1
    {
      id: 'Wellbeing-personal-Q1-P1',
      type: responsiveMatrix,
      useIcons: true,
      required: true,
      section: <h3>Section three: Wellbeing-personal</h3>,
      disclaimer: 'Please rate the following statements.',

      choices: [
        {
          id: 1,
          text: '1) Most days, I have enough energy to get through what I need',
          value: '1) Most days, I have enough energy to get through what I need',
        },
        {
          id: 2,
          text: '2) I have the time to do the things I enjoy',
          value: '2) I have the time to do the things I enjoy',
        },
        {
          id: 3,
          text: '3) I am happy with the amount and quality of sleep I get ',
          value: '3) I am happy with the amount and quality of sleep I get ',
        },
        {
          id: 4,
          text: '4) I am happy with how much exercise I get',
          value: '4) I am happy with how much exercise I get',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    // Question 3.1 Page 2
    {
      id: 'Wellbeing-personal-Q1-P2',
      type: responsiveMatrix,
      useIcons: true,
      required: true,
      section: <h3>Section three: Wellbeing-personal</h3>,
      disclaimer: 'Please rate the following statements.',

      choices: [
        {
          id: 5,
          text: '5) I am happy with the food choices I make',
          value: '5) I am happy with the food choices I make',
        },
        {
          id: 6,
          text: '6) I get adequate social connection',
          value: '6) I get adequate social connection',
        },
        {
          id: 7,
          text: '7) I feel financially secure',
          value: '7) I feel financially secure',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    // Section four: The basics
    // Intro Page
    {
      id: 'Basics-intro',
      type: welcomePage,
      section: <StyledTextbox>Section four: The basics</StyledTextbox>,
    },
    // Question 4.1
    {
      id: 'Basics-Q1',
      type: multiChoice,
      required: true,
      text: 'How long have you worked at Ruralco?',
      section: <h3>Section four: The basics</h3>,
      choices: [
        { id: 1, text: 'More than 10 years', value: 'More than 10 years' },
        { id: 2, text: 'Between 6 and 10 years', value: 'Between 6 and 10 years' },
        { id: 3, text: 'Between 3 and 5 years ', value: 'Between 3 and 5 years' },
        { id: 4, text: 'Between 1 and 3 years', value: 'Between 1 and 3 years' },
        { id: 5, text: 'Less than a year', value: 'Less than a year' },
        { id: 6, text: 'Prefer not to indicate', value: 'Prefer not to indicate' },
      ],
    },
    // Question 4.2
    {
      id: 'Basics-Q2',
      type: multiChoice,
      required: true,
      section: <h3>Section four: The basics</h3>,
      text: 'Which of the following teams do you work in?',
      choices: [
        {
          id: 1,
          text: 'Executive, HR & Compliance',
          value: 'Executive, HR & Compliance',
        },
        {
          id: 2,
          text: 'Farm Supplies',
          value: 'Farm Supplies',
        },
        {
          id: 3,
          text: 'Customer Service',
          value: 'Customer Service',
        },
        { id: 4, text: 'Head Office', value: 'Head Office' },
        { id: 5, text: 'On Farm Team & Seed', value: 'On Farm Team & Seed' },
      ],
    },
    // End page
    {
      id: 'End-page',
      type: endPage,
      text: 'Thank you for participating – we will share the results with you soon😊',
    },
  ],
  settings: {
    surveyName: 'ruralco-wellbeing',
    primaryColour: '#e11b22',
    progressBar: true,
    useVideo: true,
    objectPosition: 'center',
    backgroundVideo,
    showQid: false,
    hideSubmit: true,

    // logo
    companyLogo: {
      images: [{ image: RuralCo_Logo }],
    },
    logoFirstPageOnly: true,
    logoLocation: 'space-between',
    logoStyle: {
      marginTop: '1rem',
    },
  },
}

export default RuralCo_wellbeing
