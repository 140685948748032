import * as firebase from 'firebase/app'
import _, { sum } from 'lodash'
import React from 'react'
import styled, { keyframes } from 'styled-components/macro'
import leftBackgroundImage from '../../assets/images/HCF/backgroundimage.jpg' // Todo compress image
import { ReactComponent as revenue } from '../../assets/images/HCF/revenue.svg'
import {
  conjointAnalysis,
  endPage,
  imageChoice,
  inputFieldNumber,
  multiChoice,
  welcomePage,
} from '../question-types/QuestionTypes'
import { xlButton } from '../ui/Layout/ButtonContainer'
// import backgroudImage from '../../assets/images/HCF/min-background-1.png'
import { v4 as uuidv4 } from 'uuid'
import conferLogo from '../../assets/images/Confer-logo.png'
import c1 from '../../assets/images/conjoint_images/community_1.svg'
import c2 from '../../assets/images/conjoint_images/community_2.svg'
import c3 from '../../assets/images/conjoint_images/community_3.svg'
import e1 from '../../assets/images/conjoint_images/e_1.svg'
import e2 from '../../assets/images/conjoint_images/e_2.svg'
import e3 from '../../assets/images/conjoint_images/e_3.svg'
import h1 from '../../assets/images/conjoint_images/health_1.svg'
import h2 from '../../assets/images/conjoint_images/health_2.svg'
import h3 from '../../assets/images/conjoint_images/health_3.svg'
import m1 from '../../assets/images/conjoint_images/m_1.svg'
import m2 from '../../assets/images/conjoint_images/m_2.svg'
import m3 from '../../assets/images/conjoint_images/m_3.svg'
import w1 from '../../assets/images/conjoint_images/weather_1.svg'
import w2 from '../../assets/images/conjoint_images/weather_2.svg'
import w3 from '../../assets/images/conjoint_images/weather_3.svg'
import wq1 from '../../assets/images/conjoint_images/wq_1.svg'
import wq2 from '../../assets/images/conjoint_images/wq_2.svg'
import wq3 from '../../assets/images/conjoint_images/wq_3.svg'
import { ReactComponent as star } from '../../assets/images/HCF/star.svg'
import HCFLogo from '../../assets/images/HCF/test.svg'
import { ReactComponent as a2 } from '../../assets/images/Scarlatti/a2.svg'
import { ReactComponent as a3 } from '../../assets/images/Scarlatti/a3.svg'
import { ReactComponent as g1 } from '../../assets/images/Scarlatti/g1.svg'
import { ReactComponent as g2 } from '../../assets/images/Scarlatti/g2.svg'
import { ReactComponent as g3 } from '../../assets/images/Scarlatti/g3.svg'
import { ReactComponent as s1 } from '../../assets/images/Scarlatti/s1.svg'
import { ReactComponent as s2 } from '../../assets/images/Scarlatti/s2.svg'
import { ReactComponent as s3 } from '../../assets/images/Scarlatti/s3.svg'
import { Textbox } from '../../assets/styles/global'

const db = firebase.firestore()
const surveyData = db.collection('surveys').doc('hcf-conjoint-v2')

const aColours = {
  income: '#086a46',
  soil: '#4a4a93',
  water: '#4384cd',
  weather: '#003d5b',
  profit: '#adc773',
}

const StarIcon = styled(star)`
  display: flex;
  height: 1.5rem;
  fill: ${props => props.aColor};
`

const ConjointTitle = styled.p`
  font-weight: 600;
  text-align: left;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: baseline;
  padding: 10px;
  @media (min-width: 0px) and (max-width: 479px) {
    width: 100%;
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ImgContainer = styled.div`
  margin: 0;
  // svg behaves weirdly with width defined
  /* width: 100%; */
  /* margin-right: 0.5rem; */
  height: 100%;
  // used for debug
  /* background-color: red; */
  img {
    padding: 20px;
    height: 12rem;
    // svg behaves weirdly with width defined

    /* width: 100%; */
    svg {
      display: block;
    }
  }
  @media (min-width: 0px) and (max-width: 900px) {
    img {
      /* border-bottom-left-radius: 10px;
      border-top-left-radius: 10px; */
      height: 7.5rem;
      width: 8rem;
      object-fit: cover;
    }
  }
`
const DBold = styled.span`
  font-weight: 600;
`

const Title = styled.p`
  margin-bottom: 0.5rem;
  text-align: left;
  font-weight: 600;
  color: ${props => props.aColor};
`

const StarContainer = styled.div`
  display: flex;
`

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin-top: 8rem; */
  ul {
    list-style: none;
    list-style-position: inside;
    margin-left: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    margin-top: 0rem;
  }
  /* width: 800px; */
`

const IconRevenue = styled(revenue)`
  height: 3rem;
`

const HText = styled.p`
  /* margin:  */
  text-align: left;
  margin-bottom: 0rem;
`
const Italic = styled.p`
  /* font-style: italic; */
  margin-bottom: 0rem;
`
const Text = styled.p`
  font-weight: 600;
  margin-bottom: 0rem;
  /* font-size: 1.035rem; */
`

const StyledP = styled.p`
  line-height: 1.7;
  /* font-size: 1rem;
  color: rgba(0, 0, 0, 0.65); */
  margin-bottom: 1rem;
  margin-top: 0rem;
  font-weight: 400;
`

const A2 = styled(a2)`
  height: 4rem;
  width: 4rem;
`
const A3 = styled(a3)`
  height: 4rem;
  width: 4rem;
`
const G1 = styled(g1)`
  height: 4rem;
  width: 4rem;
`
const G2 = styled(g2)`
  height: 4rem;
  width: 4rem;
`
const G3 = styled(g3)`
  height: 4rem;
  width: 4rem;
`
const S1 = styled(s1)`
  height: 4rem;
  width: 4rem;
`
const S2 = styled(s2)`
  height: 4rem;
  width: 4rem;
`

const S3 = styled(s3)`
  height: 4rem;
  width: 4rem;
`

const animateText = keyframes`
0% {
  background-size: 0% 100%;
}
100% {
  background-size: 100% 100%
}
`

const Headline = styled.p`
  font-size: 1rem;
  background: linear-gradient(180deg, transparent 85%, #05b153 0);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  margin-bottom: 0rem;
  animation: 2s ${animateText} ease-in-out;
`

let sortedAnswers

const tempUserId = uuidv4()

const HillCountryFuturesConjointV2 = {
  questions: [
    {
      id: 'intro-page',
      type: welcomePage,
      buttonSize: xlButton.width,
      text: (
        <StyledTextbox style={{ paddingTop: '20px', alignItems: 'baseline' }}>
          <StyledP style={{ fontSize: '1.5rem', fontWeight: '600' }}>
            How you value on-farm outcomes{' '}
          </StyledP>
          <StyledP>
            Beef + Lamb New Zealand would love <b>you</b> to tell us the value you place on on-farm
            outcomes, so we can maximise the benefits that <b>you</b> receive from the{' '}
            <a
              href="https://www.hillcountryfutures.co.nz/"
              rel="noreferrer"
              target="_blank"
              style={{ color: '#05B153', textDecoration: 'underline' }}
            >
              Hill Country Futures (HCF) programme.
            </a>
          </StyledP>
          <StyledP>This survey comes in two parts:</StyledP>
          <ul style={{ fontWeight: 400 }}>
            <StyledP>
              1. Part one: You’ll be asked to estimate your position against the following outcomes:
            </StyledP>
            <li style={{ paddingLeft: '1rem' }}>a. Resilience to adverse weather events</li>
            <li style={{ paddingLeft: '1rem' }}>b. Land health</li>
            <li style={{ paddingLeft: '1rem' }}>c. Water quality</li>
            <li style={{ paddingLeft: '1rem' }}>d. Sense of community</li>
            <li style={{ paddingLeft: '1rem' }}>e. Physical and mental health</li>
            <StyledP style={{ marginTop: '1rem' }}>
              2. Part two: Through a series of choices, tell us how you value improving the above
              outcomes relative to each other and profitability.
            </StyledP>
          </ul>
          <StyledP>The results from this survey will be completely anonymous.</StyledP>
          <StyledP> If you have any questions regarding this survey, please contact</StyledP>{' '}
          <a
            href="mailto:penelope.ainsworth@scarlatti.co.nz"
            rel="noreferrer"
            target="_blank"
            style={{ color: '#E82D7C', textDecoration: 'underline' }}
          >
            penelope.ainsworth@scarlatti.co.nz
          </a>
        </StyledTextbox>
      ),
    },
    {
      id: 'p1-intro',
      type: welcomePage,
      buttonSize: xlButton.width,
      text: (
        <StyledTextbox style={{ paddingTop: '20px', alignItems: 'baseline' }}>
          <StyledP style={{ fontSize: '1.5rem', fontWeight: '600' }}>Part one</StyledP>
          <StyledP>
            You will be asked some details around your farm and to estimate your position against a
            series of outcomes{' '}
            <i>
              (resillence to adverse weather, land health, water health, sense of community and
              phyisical and mental health).
            </i>
          </StyledP>
        </StyledTextbox>
      ),
    },
    {
      id: 'farm-land-size',
      type: inputFieldNumber,
      required: true,
      symbol: '',
      inputLabel: 'ha',
      required: true,
      label: 'Please estimate farm size in hectares',
      text: 'How large is the farm you work on?',
    },
    {
      id: 'farm-type',
      type: multiChoice,
      verticalGrid: false,
      text: (
        <Textbox>
          <Text>
            Which of the below <b style={{ color: '#05B153' }}>best</b> describes your farm type?
          </Text>
        </Textbox>
      ),

      // text: 'Which of the below best describes your farm type?',
      required: true,
      choices: [
        {
          id: 1,
          text: 'North Island - Hard hill country',
          value: 'North Island - Hard hill country',
        },
        {
          id: 2,
          text: 'North Island - Hill country',
          value: 'North Island - Hill country',
        },
        {
          id: 3,
          text: 'North Island - Finishing farms',
          value: 'North Island - Finishing farms',
        },
        {
          id: 4,
          text: 'South Island - High country',
          value: 'South Island - High country',
        },
        {
          id: 5,
          text: 'South Island - Hill country',
          value: 'South Island - Hill country',
        },
        {
          id: 6,
          text: 'South Island - Finishing - breeding farms',
          value: 'South Island - Finishing - breeding farms',
        },
        {
          id: 7,
          text: 'South Island - Mixed finishing farms',
          value: 'South Island - Mixed finishing farms',
        },
        // {
        //   id: 7,
        //   text: 'Other',
        //   type: input,
        //   width: '551px',
        //   placeholder: 'Please specify',
        //   value: '',
        //   mobileProps: { top: null },
        // },
      ],
    },
    {
      id: 'defining-levels-1b',
      type: imageChoice,
      buttonSize: xlButton.width,
      required: true,
      text: 'How would you rate your resilience to adverse weather?',
      choices: [
        {
          id: 1,
          text: 'Not resilient',
          value: 'Not resilient',
          category: 'Weather',

          icon: (
            <Row>
              <ImgContainer>
                <img src={w1} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Least resilient to adverse weather</ConjointTitle>
                <HText>
                  My farm would lose more than 30% of annual revenue if it suffered a
                  one-in-ten-year weather event
                </HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                More than 30% <DBold>(Not resilient)</DBold>
              </HText> */}
            </Row>
            // <HText>
            //   More than 30% <DBold>(Not resilient)</DBold>
            // </HText>
          ),
        },
        {
          id: 2,
          text: 'Somewhat resilient',
          value: 'Somewhat resilient',
          category: 'Weather',

          icon: (
            <Row>
              <ImgContainer>
                <img src={w2} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Somewhat resilient to adverse weather</ConjointTitle>
                <HText>
                  My farm would lose 10 to 30% of annual revenue if it suffered a one-in-ten-year
                  weather event
                </HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                Between 10 and 30% <DBold>(Somewhat resilient)</DBold>
              </HText> */}
            </Row>
            // <HText>
            //   Between 10 and 30% <DBold>(Somewhat resilient)</DBold>
            // </HText>
          ),
        },
        {
          id: 3,
          text: 'Very resilient',
          value: 'Very resilient',
          category: 'Weather',

          icon: (
            <Row>
              <ImgContainer>
                <img src={w3} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Most resilient to adverse weather</ConjointTitle>
                <HText>
                  My farm would lose less than 10% of annual revenue if it suffered a
                  one-in-ten-year weather event
                </HText>
              </Column>
            </Row>
            // <HText>
            //   Less than 10% <DBold>(Very resilient)</DBold>
            // </HText>
          ),
        },
      ],
    },
    {
      id: 'defining-levels-1c',
      type: imageChoice,
      buttonSize: xlButton.width,
      required: true,
      text:
        'How would you rate the ‘health of the land’ (soil / erosion / biodiversity) on your farm compared to other similar farms?',
      choices: [
        {
          id: 1,
          text: 'Unhealthy land',
          value: 'Unhealthy land',
          category: 'Land',
          icon: (
            <Row>
              <ImgContainer>
                <img src={e3} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Least healthy land</ConjointTitle>
                <HText>
                  The ‘health of my land’ (soil / erosion / biodiversity) is among the bottom 50% of
                  farms like mine{' '}
                </HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                I estimate the health of my land is among the bottom 50% of farms
                <DBold>(Unhealthy land)</DBold>
              </HText> */}
            </Row>
            // <HText>
            //   More than 30% <DBold>(High soil erosion)</DBold>
            // </HText>
          ),

          altText: 'My production declines by more than 15%',
        },

        {
          id: 2,
          text: 'Reasonably healthy land',
          value: 'Reasonably healthy land',
          category: 'Land',
          icon: (
            <Row>
              <ImgContainer>
                <img src={e2} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Somewhat healthy land</ConjointTitle>
                <HText>
                  The ‘health of my land’ (soil / erosion / biodiversity) is among the top 50% of
                  farms like mine{' '}
                </HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                I think that the health of my land is among the top 50% of farms
                <DBold>(Reasonably healthy land)</DBold>
              </HText> */}
            </Row>
            // <HText>
            //   Between 10 and 30% <DBold>(Medium soil erosion)</DBold>
            // </HText>
          ),

          altText: 'My production declines between 5 and 15%',
        },
        {
          id: 3,
          text: 'Very healthy land',
          value: 'Very healthy land',
          category: 'Land',
          icon: (
            <Row>
              <ImgContainer>
                <img src={e1} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Most healthy land</ConjointTitle>
                <HText>
                  The ‘health of my land’ (soil / erosion / biodiversity) is among the top 10% of
                  farms like mine{' '}
                </HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                I believe the health of my land is among the top 10% of farms{' '}
                <DBold>(Very healthy land)</DBold>
              </HText> */}
            </Row>
          ),
          altText: 'My production declines by less than 5%',
        },
      ],
    },
    {
      id: 'defining-levels-1d',
      type: imageChoice,
      buttonSize: xlButton.width,
      required: true,
      mobileHeight: '5rem',
      text: 'How would you rate the waterway health of your farm’s catchment?',
      // text:
      //   'What is your confidence level in meeting future regulatory requirements regarding healthy waterways?',
      choices: [
        {
          id: 1,
          text: 'Unhealthy waterways',
          value: 'Unhealthy waterways',
          category: 'Waterways',
          icon: (
            <Row>
              <ImgContainer>
                <img src={wq3} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Least healthy waterways</ConjointTitle>
                <HText>
                  The waterway health (E.coli / fish population / sediment) of my farm’s catchment
                  is among the bottom 50%
                </HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                I estimate the health of my farm’s surrounding waterways the bottom 50%
                <DBold>(Unhealthy waterways)</DBold>
              </HText> */}
            </Row>
            // <HText>
            //   <DBold>Follower</DBold> (i.e. my farm is among the bottom 50%)
            // </HText>
          ),
        },

        {
          id: 2,
          text: 'Reasonably healthy waterways',
          value: 'Reasonably healthy waterways',
          category: 'Waterways',

          icon: (
            <Row>
              <ImgContainer>
                <img src={wq2} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Somewhat healthy waterways</ConjointTitle>
                <HText>
                  The waterway health (E.coli / fish population / sediment) of my farm’s catchment
                  is among the top 50%
                </HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                I think that the health of my farm’s surrounding waterways is among the top 50%
                <DBold>(Reasonably healthy waterways)</DBold>
              </HText> */}
            </Row>
            // <HText>
            //   <DBold>Contributor</DBold> (i.e. my farm is among the best 50%)
            // </HText>
          ),
        },
        {
          id: 3,
          text: 'Very healthy waterways',
          value: 'Very healthy waterways',
          category: 'Waterways',
          icon: (
            <Row>
              <ImgContainer>
                <img src={wq1} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Most healthy waterways</ConjointTitle>
                <HText>
                  The waterway health (E.coli / fish population / sediment) of my farm’s catchment
                  is among the top 10%
                </HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                I believe the health my farm’s surrounding waterways is among the top 10%
                <DBold>(Very healthy waterways)</DBold>
              </HText> */}
            </Row>
          ),
        },
      ],
    },
    {
      id: 'defining-levels-1e',
      type: imageChoice,
      buttonSize: xlButton.width,
      required: true,

      text: 'How would you rate the strength of the community around your farm?',
      // text:
      //   'Roughly, what percent of your farm income comes from sources other than sheep and beef?',
      choices: [
        {
          id: 1,
          text: 'No sense of community',
          value: 'No sense of community',
          category: 'Community',
          icon: (
            <Row>
              <ImgContainer>
                <img src={c3} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Struggling community</ConjointTitle>
                <HText>My farm is a part of a struggling rural community</HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                I do not feel a sense of belonging in a community{' '}
                <DBold>(No sense of community)</DBold>
              </HText> */}
            </Row>
            // <HText>
            //   Less than 10% <DBold>(Not diversified)</DBold>
            // </HText>
          ),
        },

        {
          id: 2,
          text: 'Reasonable sense of community',
          value: 'Reasonable sense of community',
          category: 'Community',
          icon: (
            <Row>
              <ImgContainer>
                <img src={c2} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Coping community</ConjointTitle>
                <HText>
                  My farm is a part of a community that is under pressure but still doing well
                </HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                I feel some sense of belonging in a community
                <DBold>(A reasonable sense of community)</DBold>
              </HText> */}
            </Row>
            // <HText>
            //   Between 10 and 50% <DBold>(Reasonably diversified)</DBold>
            // </HText>
          ),
        },
        {
          id: 3,
          text: 'A great sense of community',
          value: 'A great sense of community',
          category: 'Community',
          html: (
            <Row>
              <ImgContainer>
                <img src={c1} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Thriving community</ConjointTitle>
                <HText>My farm is a part of a thriving rural community</HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                I feel a great sense of belonging in a community
                <DBold>(A great sense of community)</DBold>
              </HText> */}
            </Row>
            // <HText>
            //   More than 50% <DBold>(Well diversified)</DBold>
            // </HText>
          ),
        },
      ],
    },
    {
      id: 'defining-levels-1f',
      type: imageChoice,
      buttonSize: xlButton.width,
      required: true,

      text: 'How would you rate your physical and mental health?',
      // text:
      //   'Roughly, what percent of your farm income comes from sources other than sheep and beef?',
      choices: [
        {
          id: 1,
          text: 'Poor physical and mental health',
          value: 'Poor physical and mental health',
          category: 'Health',
          icon: (
            <Row>
              <ImgContainer>
                <img src={h1} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Not great health</ConjointTitle>
                <HText>I sometime struggle to do my work or get excited about it </HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                I often struggle to get up and complete farm tasks{' '}
                <DBold>(Poor physical and mental health)</DBold>
              </HText> */}
            </Row>
            // <HText>
            //   Less than 10% <DBold>(Not diversified)</DBold>
            // </HText>
          ),
        },
        {
          id: 2,
          text: 'Reasonable physical and mental health',
          value: 'Reasonable physical and mental health',
          category: 'Health',
          icon: (
            <Row>
              <ImgContainer>
                <img src={h2} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Fair health</ConjointTitle>
                <HText>I mostly feel in good physical shape and excited to do my work</HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                I sometimes struggle to get up and complete farm tasks
                <DBold>(Reasonable physical and mental health)</DBold>
              </HText> */}
            </Row>
            // <HText>
            //   Between 10 and 50% <DBold>(Reasonably diversified)</DBold>
            // </HText>
          ),
        },

        {
          id: 3,
          text: 'Good physical and mental health',
          value: 'Good physical and mental health',
          category: 'Health',
          html: (
            <Row>
              <ImgContainer>
                <img src={h3} />
              </ImgContainer>
              <Column>
                <ConjointTitle>Best health</ConjointTitle>
                <HText>
                  I almost always feel in good physical shape and excited to do my work{' '}
                </HText>
              </Column>
              {/* <HText style={{ textAlign: 'left' }}>
                I rarely struggle to get up and complete farm tasks
                <DBold>(Good physical and mental health)</DBold>
              </HText> */}
            </Row>
            // <HText>
            //   More than 50% <DBold>(Well diversified)</DBold>
            // </HText>
          ),
        },
      ],
    },
    // {
    //   id: 'defining-levels-1a',
    //   type: imageChoice,
    //   buttonSize: xlButton.width,
    //   text: (
    //     <Headline>
    //       Roughly, what percent of your farm income comes from sources other than sheep and beef?
    //     </Headline>
    //   ),
    //   // text:
    //   //   'Roughly, what percent of your farm income comes from sources other than sheep and beef?',
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'Not diversified',
    //       value: 'Not diversified',
    //       icon: (
    //         <Row>
    //           <ImgContainer>
    //             <img src={d3} />
    //           </ImgContainer>
    //           <HText style={{ textAlign: 'left' }}>
    //             Less than 10% <DBold>(Not diversified)</DBold>
    //           </HText>
    //         </Row>
    //         // <HText>
    //         //   Less than 10% <DBold>(Not diversified)</DBold>
    //         // </HText>
    //       ),
    //     },
    //     {
    //       id: 2,
    //       text: 'Reasonably diversifed',
    //       value: 'Reasonably diversifed',
    //       icon: (
    //         <Row>
    //           <ImgContainer>
    //             <img src={d2} />
    //           </ImgContainer>
    //           <HText style={{ textAlign: 'left' }}>
    //             Between 10 and 50% <DBold>(Reasonably diversified)</DBold>
    //           </HText>
    //         </Row>
    //         // <HText>
    //         //   Between 10 and 50% <DBold>(Reasonably diversified)</DBold>
    //         // </HText>
    //       ),
    //     },
    //     {
    //       id: 3,
    //       text: 'Well diversified',
    //       value: 'Well diversified',
    //       html: (
    //         <Row>
    //           <ImgContainer>
    //             <img src={d1} />
    //           </ImgContainer>
    //           <HText style={{ textAlign: 'left' }}>
    //             More than 50% <DBold>(Well diversified)</DBold>
    //           </HText>
    //         </Row>
    //         // <HText>
    //         //   More than 50% <DBold>(Well diversified)</DBold>
    //         // </HText>
    //       ),
    //     },
    //   ],
    // },

    // {
    //   id: 'test-og',
    //   type: conjointAnalysis,
    //   hideUi: true,
    //   choices: 2,
    //   maxChoices: 6,
    //   attributes: [
    //     {
    //       id: 1,
    //       value: 'Productivity',
    //       levels: [
    //         { id: 1, value: 'level 4', text: 'Productivity level 4' },
    //         { id: 2, value: 'level 3', text: 'Productivity level 3' },
    //         { id: 3, value: 'level 2', text: 'Productivity level 2' },
    //         { id: 4, value: 'level 1', text: 'Productivity level 1' },
    //       ],
    //     },
    //     {
    //       id: 2,
    //       value: 'Environmental',
    //       levels: [
    //         { id: 1, value: 'level 4', text: 'Environmental level 4' },
    //         { id: 2, value: 'level 3', text: 'Environmental level 3' },
    //         { id: 3, value: 'level 2', text: 'Environmental level 2' },
    //         { id: 4, value: 'level 1', text: 'Environmental level 1' },
    //       ],
    //     },
    //     {
    //       id: 3,
    //       value: 'Wellbeing',
    //       levels: [
    //         { id: 1, value: 'level 4', text: 'Wellbeing level 4' },
    //         { id: 2, value: 'level 3', text: 'Wellbeing level 3' },
    //         { id: 3, value: 'level 2', text: 'Wellbeing level 2' },
    //         { id: 4, value: 'level 1', text: 'Wellbeing level 1' },
    //       ],
    //     },
    //   ],
    //   levels: [],
    //   text: (
    //     <Textbox>
    //       <Text>Which of these 2 do you prefer?</Text>
    //     </Textbox>
    //   ),
    // },
    {
      id: 'p2-intro',
      type: welcomePage,
      buttonSize: xlButton.width,
      text: (
        <StyledTextbox style={{ paddingTop: '20px', alignItems: 'baseline' }}>
          <StyledP style={{ fontSize: '1.5rem', fontWeight: '600' }}>Part two</StyledP>
          <StyledP>
            You will now be presented with a series of <b>eight choices</b> to gauge how you value
            improving a selection of outcomes relative to each other and profitability
          </StyledP>
        </StyledTextbox>
      ),
    },
    {
      id: 'valuing-outcomes',
      type: conjointAnalysis,
      hideUi: true,
      useIcon: false,
      useImage: true,
      showProgress: true,
      width: '100%',
      label: false,
      levelsTextAlign: 'left',
      // neutralText: 'No support and no cost', // check with Sam
      removeInfo: true, // removes text on right
      // choices: 2,
      maxChoices: 8,
      buttonSize: xlButton.width,
      justify: 'space-between',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              value: 'Productivity',
              levels: [
                { id: 1, value: 'level 4', text: 'Productivity level 4' },
                { id: 2, value: 'level 3', text: 'Productivity level 3' },
                { id: 3, value: 'level 2', text: 'Productivity level 2' },
                { id: 4, value: 'level 1', text: 'Productivity level 1' },
              ],
            },
            {
              id: 2,
              value: 'Environmental',
              levels: [
                { id: 1, value: 'level 4', text: 'Environmental level 4' },
                { id: 2, value: 'level 3', text: 'Environmental level 3' },
                { id: 3, value: 'level 2', text: 'Environmental level 2' },
                { id: 4, value: 'level 1', text: 'Environmental level 1' },
              ],
            },
            {
              id: 3,
              value: 'Wellbeing',
              levels: [
                { id: 1, value: 'level 4', text: 'Wellbeing level 4' },
                { id: 2, value: 'level 3', text: 'Wellbeing level 3' },
                { id: 3, value: 'level 2', text: 'Wellbeing level 2' },
                { id: 4, value: 'level 1', text: 'Wellbeing level 1' },
              ],
            },
          ]
          return defaultAnswers
        }

        const calculateLevelMeasure = targetQuestionId => {
          let choices = targetQuestionId.choices
          let userAnswer = targetQuestionId.value
          let indexOfUserAnswer = choices.findIndex(choice => choice.value === userAnswer)
          let next = (indexOfUserAnswer - 1) % choices.length

          if (indexOfUserAnswer === 0) {
            next = indexOfUserAnswer
          }

          let text = choices[next].value
          // console.log('altText', choices[next])
          return text
        }

        const calculateNextBestLevel = targetQuestionId => {
          let choices = targetQuestionId.choices
          let userAnswer = targetQuestionId.value
          let indexOfUserAnswer = choices.findIndex(choice => choice.value === userAnswer)
          console.log('targetQuestionId B', targetQuestionId)
          let next = indexOfUserAnswer

          if (indexOfUserAnswer === 0) {
            next = (indexOfUserAnswer + 1) % choices.length
          }

          let text = choices[next].value
          // console.log('altText', choices[next])
          return text
        }

        let northIsland = [
          'Northland',
          'Mid Northern North Island',
          'Eastern North Island',
          'Western North Island',
        ]

        // let checkIfNorthIsland = northIsland.filter(region =>
        //   answers['map'].value.map(dbValue => dbValue.value).includes(region),
        // )

        let standardDeviation = {
          'High country': 40,
          'Hill country': 115,
          'Hard hill country': 121,
          'Intensive finishing': 256,
          'Finishing breeding': 241,
          'Mixed finishing': 365,
        }

        // if (checkIfNorthIsland.length > 1) {
        //   standardDeviation = {
        //     'High country': 40,
        //     'Hill country': 196,
        //     'Hard hill country': 121,
        //     'Intensive finishing': 254,
        //     'Finishing breeding': 241,
        //     'Mixed finishing': 365,
        //   }
        // }

        let farmType = answers['farm-type'].value
        let farmSize = answers['farm-land-size'].value

        let pipedAnswersA = [
          // {
          //   id: 1,
          //   value: 'Income diversification',
          //   text: 'Percent of farm income that comes from sources other than sheep and beef',
          //   levels: [
          //     {
          //       id: 1,
          //       value: 'Help to achieve a small increase in your income diversification',
          //       icon: (
          //         <ImgContainer>
          //           <img src={d1} />
          //           {/* <D1></D1> */}
          //           {/* <Title aColor={aColours['income']}>Income diversification</Title>
          //           <StarContainer>
          //             <StarIcon aColor={aColours['income']} />
          //           </StarContainer> */}
          //         </ImgContainer>
          //       ),
          //       text: (
          //         <div>
          //           {/* <p>
          //             Help to achieve a small increase in your <b>income diversification</b>
          //           </p> */}
          //           <ConjointTitle>Not diversified</ConjointTitle>
          //           <p>Less than 10% of my income comes from sources other than sheep and beef</p>
          //           {/* <Italic>
          //             A 5% increase (e.g. [midpoint in current level]% to [midpoint + 5%]%)
          //           </Italic> */}
          //         </div>
          //       ),
          //       // value: `${calculateLevelMeasure(answers['defining-levels-1a'])}`,
          //       // text: `${calculateLevelMeasure(answers['defining-levels-1a'])}`,
          //       // icon: <IconRevenue />,
          //     },
          //     {
          //       id: 2,
          //       value: 'Help to achieve a large increase in your income diversification',
          //       icon: (
          //         <ImgContainer>
          //           <img src={d2} />

          //           {/* <D2></D2> */}
          //           {/* <Title aColor={aColours['income']}>Income diversification</Title>
          //           <StarContainer>
          //             <StarIcon aColor={aColours['income']} />
          //             <StarIcon aColor={aColours['income']} />
          //           </StarContainer> */}
          //         </ImgContainer>
          //       ),
          //       text: (
          //         <div>
          //           {/* <p>
          //             Help to achieve a large increase in your <b>income diversification</b>
          //           </p> */}
          //           <ConjointTitle>Reasonably diversified</ConjointTitle>
          //           <p>
          //             Between 10 and 50% of my income comes from sources other than sheep and beef
          //           </p>
          //           {/* <Italic>
          //             A 20% increase (e.g. [midpoint in current level]% to [midpoint + 20%]%)
          //           </Italic> */}
          //         </div>
          //       ),
          //     },
          //     {
          //       id: 3,
          //       value: 'Help to achieve a large increase in your income diversification',
          //       icon: (
          //         <ImgContainer>
          //           <img src={d3} />

          //           {/* <D3 /> */}
          //           {/* <Title aColor={aColours['income']}>Income diversification</Title>
          //           <StarContainer>
          //             <StarIcon aColor={aColours['income']} />
          //             <StarIcon aColor={aColours['income']} />
          //             <StarIcon aColor={aColours['income']} />
          //           </StarContainer> */}
          //         </ImgContainer>
          //       ),
          //       text: (
          //         <div>
          //           {/* <p>
          //             Help to achieve a large increase in your <b>income diversification</b>
          //           </p> */}
          //           <ConjointTitle>Well diversified</ConjointTitle>
          //           <p>More than 50% of my income comes from sources other than sheep and beef</p>
          //           {/* <Italic>
          //             A 20% increase (e.g. [midpoint in current level]% to [midpoint + 20%]%)
          //           </Italic> */}
          //         </div>
          //       ),
          //     },
          //   ],
          // },
          {
            id: 1,
            value: 'Weather',
            text: 'Percentage revenue drop due to a one in ten-year weather event',
            levels: [
              {
                id: 1,
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={w1} />
                  </ImgContainer>
                ),
                value: 'Least resilient to adverse weather',
                text: (
                  <div>
                    <ConjointTitle>Least resilient to adverse weather</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      More than 30% of annual revenue is lost in a one-in- ten-year weather event
                    </p>
                  </div>
                ),
              },
              {
                id: 2,
                value: 'Somewhat resilient to adverse weather',
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={w2} />
                  </ImgContainer>
                ),
                text: (
                  <div>
                    <ConjointTitle>Somewhat resilient to adverse weather</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      Between 10 and 30% of annual revenue is lost in a one-in- ten-year weather
                      event
                    </p>
                  </div>
                ),
              },
              {
                id: 3,
                value: 'Most resilient to adverse weather',
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={w3} />
                  </ImgContainer>
                ),
                text: (
                  <div>
                    <ConjointTitle>Most resilient to adverse weather</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      Less than 10% of annual revenue is lost in a one-in- ten-year weather event
                    </p>
                  </div>
                ),
              },
            ],
          },
          {
            id: 2,
            value: 'Land',
            text: 'Percentage production decline due to erosion',
            levels: [
              {
                id: 1,
                value: 'Least healthy land',
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={e3} />
                  </ImgContainer>
                ),
                text: (
                  <div>
                    <ConjointTitle>Least healthy land</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      The health of my land (soil / erosion / biodiversity) is among the bottom 50%
                      of farms
                    </p>
                  </div>
                ),
              },
              {
                id: 2,
                value: 'Somewhat healthy land',
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={e2} />
                  </ImgContainer>
                ),
                text: (
                  <div>
                    <ConjointTitle>Somewhat healthy land</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      The health of my land (soil / erosion / biodiversity) is among the top 50% of
                      farms
                    </p>
                  </div>
                ),
              },
              {
                id: 3,
                value: 'Most healthy land',
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={e1} />
                  </ImgContainer>
                ),
                text: (
                  <div>
                    <ConjointTitle>Most healthy land</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      The health of my land (soil / erosion / biodiversity) is among the top 10% of
                      farms
                    </p>
                  </div>
                ),
              },
            ],
          },
          {
            id: 3,
            value: 'Waterways',
            text: 'Confidence in meeting regulatory requirements relating to fresh water',
            levels: [
              {
                id: 1,
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                value: 'Help to achieve a small increase in the health of your waterways',
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={wq3} />
                  </ImgContainer>
                ),
                value: 'Least healthy waterways',
                text: (
                  <div>
                    <ConjointTitle>Least healthy waterways</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      The health of my catchment’s waterways (E.coli / fish population / sediment)
                      is among the bottom 50%
                    </p>
                  </div>
                ),
              },
              {
                id: 2,
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={wq2} />
                  </ImgContainer>
                ),
                value: 'Somewhat healthy waterways',
                text: (
                  <div>
                    <ConjointTitle>Somewhat healthy waterways</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      The health of my catchment’s waterways (E.coli / fish population / sediment)
                      is among the top 50%
                    </p>
                  </div>
                ),
              },
              {
                id: 3,
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={wq1} />
                  </ImgContainer>
                ),
                value: 'Most healthy waterways',
                text: (
                  <div>
                    <ConjointTitle>Most healthy waterways</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      The health of my catchment’s waterways (E.coli / fish population / sediment)
                      is among the top 10%
                    </p>
                  </div>
                ),
              },
            ],
          },

          {
            id: 4,
            value: 'Community',
            text: 'Additional income earned on farm per year',
            levels: [
              {
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                id: 1,
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={c3} />
                  </ImgContainer>
                ),
                value: 'Struggling community',
                text: (
                  <div>
                    <ConjointTitle>Struggling community</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      My farm is a part of a struggling rural community
                    </p>
                  </div>
                ),
              },
              {
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                id: 2,
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={c2} />
                  </ImgContainer>
                ),
                value: 'Coping community',
                text: (
                  <div>
                    <ConjointTitle>Coping community</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      My farm is a part of a community that is under pressure but still doing well
                    </p>
                  </div>
                ),
              },
              {
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={c1} />
                  </ImgContainer>
                ),
                id: 3,
                value: 'Thriving community',
                text: (
                  <div>
                    <ConjointTitle>Thriving community</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      My farm is a part of a thriving rural community
                    </p>
                  </div>
                ),
              },
            ],
          },
          {
            id: 5,
            value: 'Health',
            text: 'Additional income earned on farm per year',
            levels: [
              {
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                id: 1,
                value: 1,
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={h1} />
                  </ImgContainer>
                ),
                value: 'Not great health',
                text: (
                  <div>
                    <ConjointTitle>Not great health</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      I sometime struggle to do my work or get excited about it
                    </p>
                  </div>
                ),
              },
              {
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                id: 2,
                value: 'Fair health',
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={h2} />
                  </ImgContainer>
                ),
                text: (
                  <div>
                    <ConjointTitle>Fair health</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      I mostly feel in good physical shape and excited to do my work
                    </p>
                  </div>
                ),
              },

              {
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={h3} />
                  </ImgContainer>
                ),
                id: 3,
                value: 'Best health',
                text: (
                  <div>
                    <ConjointTitle>Best health</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      I almost always feel in good physical shape and excited to do my work
                    </p>
                  </div>
                ),
              },
            ],
          },
          {
            id: 6,
            value: 'Profitability',
            text: 'Additional income earned on farm per year',
            levels: [
              {
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={m1} />
                  </ImgContainer>
                ),
                id: 1,
                value: 'Current profitability levels',
                text: (
                  <div>
                    <ConjointTitle>Current profitability levels</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>I maintain my current profitability levels</p>
                  </div>
                ),
              },
              {
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                id: 2,
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={m2} />
                  </ImgContainer>
                ),
                value: 'Slightly more profitable farm',
                text: (
                  <div>
                    <ConjointTitle>Slightly more profitable farm</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      I permanently increase my farm profitability by 10%
                    </p>
                  </div>
                ),
              },
              {
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                id: 3,
                icon: (
                  <ImgContainer>
                    <img style={{ height: 'inherit' }} src={m3} />
                  </ImgContainer>
                ),
                value: 'Considerably more profitable farm',
                text: (
                  <div>
                    <ConjointTitle>Considerably more profitable farm</ConjointTitle>
                    <p style={{ marginBottom: 0 }}>
                      I permanently increase my farm profitability by 30%
                    </p>
                  </div>
                ),
              },
            ],
          },
        ]
        let latestUserAnswers = [
          answers['defining-levels-1b'],
          answers['defining-levels-1c'],
          answers['defining-levels-1d'],
          answers['defining-levels-1e'],
          answers['defining-levels-1f'],
        ]

        console.log('latestUserAnswers', latestUserAnswers)

        let sortedArray
        if (sortedAnswers) {
          sortedArray = sortedAnswers
        } else {
          sortedArray = [...latestUserAnswers]
            .map(value => ({ value, rsort: Math.random() }))
            .sort((a, b) => {
              console.log('sort function fired')
              if (a.value.id !== b.value.id) {
                return a.value.id - b.value.id
              } else {
                return a.rsort - b.rsort
              }
            })
            .map(({ value }) => value)
          sortedAnswers = sortedArray
        }

        sortedArray = sortedArray.slice(0, -2) // remove last 2 elements

        let findMatchingOutcome = obj => {
          let item = _.find(pipedAnswersA, { value: obj.category })
          console.log('found item', item)
          return item
        }

        let buildArray = arr => {
          console.log('start of build', arr)
          let freshArray = []
          arr.map((item, index) => {
            freshArray.push(findMatchingOutcome(item))
          })
          freshArray = [...freshArray, pipedAnswersA[5]]
          console.log('freshArray', freshArray)
          return freshArray
        }
        return buildArray(sortedArray)
      },
      icons: [
        { id: 1, 1: <IconRevenue />, 2: 'id 1 - icon-2' },
        { id: 2, 1: <IconRevenue />, 2: 'id 2 - icon-2' },
        { id: 3, 1: <IconRevenue />, 2: 'id 3 icon-2' },
        { id: 4, 1: <IconRevenue />, 2: 'id 4 icon-2' },
      ],
      levels: [],
      text: (
        <Textbox>
          <Text>
            Which of the combined outcomes would you{' '}
            <span style={{ color: '#05B153' }}>prefer</span>?
          </Text>
        </Textbox>
      ),
    },
    {
      id: 'results',
      type: 'welcomePage',
      buttonSize: xlButton.width,

      // hideUi: true,
      // text: 'placeholder text',
      text: () => {
        return (
          <StyledTextbox style={{ alignItems: 'baseline' }}>
            <StyledP style={{ fontWeight: '600' }}>Thank you for completing the survey.</StyledP>
            <StyledP>
              Based on your responses, the chart below illustrates the relative value you (compared
              to others) place on being a high-achiever in each of the four outcomes.
            </StyledP>
            <StyledP style={{ fontStyle: 'italic' }}>
              For more information about what this is, click{' '}
              <a
                href="https://conjointly.com/guides/how-to-interpret-partworth-utilities/"
                rel="noreferrer"
                target="_blank"
                style={{ color: '#05B153', textDecoration: 'underline' }}
              >
                here
              </a>
            </StyledP>
          </StyledTextbox>
        )
      },
      output: {
        type: 'conjoint',
        chartType: 'horizontal',
        xLabel: 'Relative value',
        userColour: '#07A94B',
        // othersColour: '#EE2B39',
        xStepSize: 5,
        smart: false,
        userAnswers: false,
        usePercentage: true,
        displayLegend: true,
        targetQuestionType: conjointAnalysis,
        targetQuestionId: 'valuing-outcomes',
        surveyName: 'hcf-conjoint-v2',
        transformData: data => {
          let mydata = data['valuing-outcomes']['value'].partworths
          let updatedLabels = {
            Weather: 'Resilience to adverse weather',
            Land: 'Health of land',
            Waterways: 'Health of waterways',
            Community: 'Strength of community',
            Health: 'Physical and mental health',
            Profitability: 'Profitability',
          }

          console.log('rawdata', mydata)

          let attributes = data['valuing-outcomes']['value'].attributes.map(item => ({
            label: updatedLabels[item.value],
            attribute_id: item.id,
            partworth: mydata
              .find(attrId => item.id === attrId.attribute_id)
              ['partworth_value'].find(level => level['level_id'] === 3).partworth,
          }))

          console.log('attr', attributes)
          let partworthsArray = mydata.map(
            arr => arr['partworth_value'].find(level => level['level_id'] === 3).partworth,
          )

          console.log('partworths', partworthsArray)
          let output = {}
          attributes.forEach((item, index) => {
            output[item.label] = Math.round((item['partworth'] / sum(partworthsArray)) * 100)
          })
          console.log('output', output)
          return output
          // object == keys from attributes
        },
        transformAggData: (dbData, userData) => {
          const transformUserdata = udata => {
            let mydata = udata['valuing-outcomes']['value'].partworths
            console.log('rawdata (per user)', mydata)

            let updatedLabels = {
              Weather: 'Resilience to adverse weather',
              Land: 'Health of land',
              Waterways: 'Health of waterways',
              Community: 'Strength of community',
              Health: 'Physical and mental health',
              Profitability: 'Profitability',
            }

            let attributes = udata['valuing-outcomes']['value'].attributes.map(item => ({
              label: updatedLabels[item.value],
              attribute_id: item.id,
              partworth: mydata
                .find(attrId => item.id === attrId.attribute_id)
                ['partworth_value'].find(level => level['level_id'] === 3).partworth,
            }))
            console.log('attr', attributes)

            let partworthsArray = mydata.map(
              arr => arr['partworth_value'].find(level => level['level_id'] === 3).partworth,
            )
            console.log('partworths', partworthsArray)

            let output = {}
            attributes.forEach((item, index) => {
              output[item.label] = Math.round((item['partworth'] / sum(partworthsArray)) * 100)
            })
            console.log('output', output)
            return output
          }

          const transformDbdata = (ddata, udata) => {
            // const fetchDbData = () => {
            //   return surveyData.get().then(doc => {
            //     if (doc.exists){
            //       const results = doc.data().aggResults['valuing-outcomes']
            //       return results
            //     }
            //   }).catch(err => console.log('err', err))
            // }
            // const dbData = await fetchDbData()
            console.log('dbData: ', ddata)
            let updatedDBLabels = {
              'attr_id 1': 'Resilience to adverse weather',
              'attr_id 2': 'Health of land',
              'attr_id 3': 'Health of waterways',
              'attr_id 4': 'Strength of community',
              'attr_id 5': 'Physical and mental health',
              'attr_id 6': 'Profitability',
            }
            let newArray = Object.entries(ddata).map(item => ({
              label: updatedDBLabels[item[0]],
              attribute_id: item[0],
              partworth: item[1]['level_id 3'].PW,
            }))

            let attr_user = udata['valuing-outcomes']['value'].attributes.map(item => item.id)

            let filteredArray = []
            for (let i = 0; i < newArray.length; i++) {
              const t = parseInt(newArray[i].attribute_id.split(' ').pop())
              if (attr_user.includes(t)) {
                filteredArray.push(newArray[i])
              }
            }
            console.log('newArr', newArray)
            console.log('filteredArray', filteredArray)

            const pwArray = filteredArray.map(a => a.partworth)
            let dboutput = {}
            filteredArray.forEach((item, index) => {
              dboutput[item.label] = Math.round((item.partworth / sum(pwArray)) * 100)
            })
            console.log('dboutput', dboutput)
            return dboutput
          }
          const output1 = transformUserdata(userData)
          const output2 = transformDbdata(dbData, userData)
          console.log('output1: ', output1)
          console.log('output2: ', output2)
          return [output1, output2]
        },
      },
    },
    // {
    //   id: 'feedback',
    //   required: true,
    //   type: 'responsiveMatrixTable',
    //   text: () => {
    //     return (
    //       <StyledTextbox style={{ alignItems: 'baseline' }}>
    //         <StyledP style={{ fontWeight: '600' }}>Thank you for completing the survey.</StyledP>
    //         <StyledP>We would love some feedback on this survey.</StyledP>
    //         <StyledP>Please indicate how much you agree with the following statements:</StyledP>
    //       </StyledTextbox>
    //     )
    //   },
    //   // text: 'How much do you agree or disagree with the following statements?',
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'I found the survey intuitive',
    //       value: 'I found the survey intuitive',
    //     },
    //     {
    //       id: 2,
    //       text: 'The questions were clear in what they were asking me',
    //       value: 'The questions were clear in what they were asking me',
    //     },
    //     {
    //       id: 3,
    //       text: 'The wording of the options made sense to me',
    //       value: 'The wording of the options made sense to me',
    //     },
    //     {
    //       id: 4,
    //       text: 'I enjoyed the overall experience',
    //       value: 'I enjoyed the overall experience',
    //     },
    //     {
    //       id: 5,
    //       text: 'I believe that my response will be valuable to the industry',
    //       value: 'I believe that my response will be valuable to the industry',
    //     },
    //   ],
    //   options: [
    //     {
    //       id: 1,
    //       text: 'Strongly disagree',
    //       value: 'Strongly disagree',
    //     },
    //     {
    //       id: 2,
    //       text: 'Disagree',
    //       value: 'Disagree',
    //     },
    //     {
    //       id: 3,
    //       text: 'Neither disagree nor agree',
    //       value: 'Neither disagree nor agree',
    //     },
    //     {
    //       id: 4,
    //       text: 'Agree',
    //       value: 'Agree',
    //     },
    //     {
    //       id: 5,
    //       text: 'Strongly agree',
    //       value: 'Strongly agree',
    //     },
    //   ],
    // },
    // {
    //   id: 'comments',
    //   type: commentBox,
    //   buttonSize: xlButton.width,
    //   text: () => {
    //     return (
    //       <StyledTextbox style={{ alignItems: 'baseline' }}>
    //         <StyledP style={{ fontWeight: '600' }}>Thank you for completing the survey.</StyledP>
    //         <StyledP>We would love some feedback on this survey.</StyledP>
    //         <StyledP>Do you have any suggestions to help us improve the overall survey? </StyledP>
    //       </StyledTextbox>
    //     )
    //   },
    //   placeholder: 'Please type here...',
    //   // text: 'Do you have any suggestions to help us improve the overall survey? ',
    //   // text: 'Can you please describe your role as a rural professional?',
    // },
    {
      id: 'survey-end',
      type: endPage,
      text: () => {
        return (
          <Textbox style={{ padding: '0px' }}>
            <h1 style={{ color: 'rgba(0, 0, 0, 0.65) ', fontSize: '18px', fontWeight: 600 }}>
              Thank you for completing the survey!
              <br />
              Your response will help inform efforts to protect and enhance our hill country
              environment.
            </h1>

            {/* <p>Like this survey and want to create your own? Let&apos;s Confer!</p> */}
            <a
              href="https://confer.online"
              rel="noreferrer"
              target="_blank"
              style={{ color: '#E82D7C', textDecoration: 'underline' }}
            >
              <p>Like this survey and want to create your own? Let&apos;s Confer!</p>
            </a>
            <div style={{ fontSize: '18px' }}>
              <img width="255px" src={conferLogo} alt="confer-logo.jpg" />
            </div>
          </Textbox>
        )
      },
    },
  ],
  settings: {
    surveyName: 'hcf-conjoint-v2',
    // primaryColour: '#afd135',
    primaryColour: '#05B153',
    uuid: tempUserId,

    // hideButton: true,
    otherColour: '#72a174',
    hideSubmit: true,
    showQid: false,
    specialEffects: true,
    logoFirstPageOnly: true,
    logoStyle: {
      marginTop: '1rem',
    },
    companyLogo: {
      images: [{ image: HCFLogo }],
    },
    logoStyle: {
      marginTop: '1rem',
    },
    logoLocation: 'space-between',
    // fullscreen: true,
    // leftBackgroundImage,
    // backgroundImage: backgroudImage,
    leftBackgroundImage,
    backgroundImage: leftBackgroundImage,
    progressBar: true,
  },
}

export default HillCountryFuturesConjointV2
