import 'antd/dist/antd.css'
import React from 'react'
import styled from 'styled-components/macro'
import 'typeface-open-sans'
import companyLogo from '../../assets/images/GreenbayHigh/logo1.png'
import otherLogo from '../../assets/images/GreenbayHigh/logo3.png'
import leftBackgroundImage from '../../assets/images/GreenbayHigh/Slide1.jpg'

import { input, multiSelect, singleTextbox, welcomePage } from '../question-types/QuestionTypes'

import { Textbox } from '../../assets/styles/global'

const StyledImage = styled.img`
  vertical-align: middle;
  border-style: none;
  height: 255px;
  padding-bottom: 2rem;
`

const Blue = styled.p`
  display: grid;
  color: #1c4587;
  b {
    color: #00a651;
  }
`

// eslint-disable-next-line import/prefer-default-export
const TestSurvey = {
  questions: [
    {
      id: '0',
      type: welcomePage,
      hideLogo: true,
      text: (
        <Textbox>
          <h1>THIS IS TEST SURVEY</h1>
          <StyledImage src={otherLogo} alt="greenbayhigh" />
          <Blue>Thank you for taking part in this survey</Blue>
          <Blue>
            Green Bay High School&apos;s current vision is: <b>Innovative</b> <b>Individualised</b>
            <b>Connected</b>
          </Blue>

          <Blue>The survey will take 2 minutes to complete.</Blue>
        </Textbox>
      ),
    },
    {
      id: '1',
      type: multiSelect,
      text: 'Which ethnic group(s) do you belong to?',
      choices: [
        { id: 1, text: 'New Zealand European', value: 'New Zealand European' },
        { id: 2, text: 'Maori', value: 'Maori' },
        { id: 3, text: 'Pacific peoples', value: 'Pacific peoples' },
        {
          id: 4,
          text: 'Middle Eastern / Latin American / African',
          value: 'Middle Eastern / Latin American / African',
        },
        {
          id: 5,
          text: 'Asian',
          value: 'Asian',
        },
        { id: 6, text: 'Other, please specify', value: 'other', type: input },
        { id: 7, text: 'Prefer not to answer', value: 'Prefer not to answer' },
      ],
    },
    {
      id: '2',
      type: 'multiRadioList',
      text: <Blue>Our current vision captures what&apos;s important in our school</Blue>,
      choices: [
        {
          id: 1,
          leftText: 'Effectively',
          rightText: 'Ineffectively',
          answerChoices: [
            { id: 1, value: 1 },
            { id: 2, value: 2 },
            { id: 3, value: 3 },
            { id: 4, value: 4 },
            { id: 5, value: 5 },
          ],
        },
        {
          id: 2,
          leftText: 'Clearly',
          rightText: 'Unclearly',
          answerChoices: [
            { id: 1, value: 1 },
            { id: 2, value: 2 },
            { id: 3, value: 3 },
            { id: 4, value: 4 },
            { id: 5, value: 5 },
          ],
        },
        { id: 3, text: 'Comment box', value: 'Other', type: input },
      ],
    },
    {
      id: 3,
      type: 'multiRadioList',
      text: (
        <Blue>Our current vision inspires everyone in our school to be the best they can be</Blue>
      ),
      choices: [
        {
          id: 1,
          leftText: 'Agree',
          rightText: 'Disagree',
          answerChoices: [
            { id: 1, value: 1 },
            { id: 2, value: 2 },
            { id: 3, value: 3 },
            { id: 4, value: 4 },
            { id: 5, value: 5 },
          ],
        },
        { id: 2, text: 'Comment box', value: 'Other', type: input },
      ],
    },
    {
      id: 4,
      type: 'multiRadioList',
      text: <Blue>Our current vision is relevant to everyone in our school and our community</Blue>,
      choices: [
        {
          id: 1,
          leftText: 'Agree',
          rightText: 'Disagree',
          answerChoices: [
            { id: 1, value: 1 },
            { id: 2, value: 2 },
            { id: 3, value: 3 },
            { id: 4, value: 4 },
            { id: 5, value: 5 },
          ],
        },
        { id: 2, text: 'Comment box', value: 'Other', type: input },
      ],
    },
    {
      id: 5,
      text: (
        <Blue>In a few words, what do you think is important? Eg excellence, respect, courage</Blue>
      ),
      type: singleTextbox,
      label: 'Comment box',
    },
    {
      id: 6,
      type: 'powerBi',
      url:
        'https://app.powerbi.com/view?r=eyJrIjoiZjUwMDY0OTgtMTJkYS00Yzk4LWI5NzAtY2YxODBjYWI3N2E2IiwidCI6IjE5MzU4YzgxLTViMWItNGZjYS1iZmM2LTcwOTEzZDU1ZDA3ZCJ9',
    },
  ],
  settings: {
    writeToFirestore: true,
    surveyName: 'testSurvey',
    companyLogo,
    leftBackgroundImage,
    backgroundImage: leftBackgroundImage,
    logoLocation: 'flex-start',
  },
}

export default TestSurvey
