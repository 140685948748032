/* eslint-disable no-unused-vars */
import React from 'react'
import styled from 'styled-components/macro'
import {
  endPage,
  imageChoice,
  input,
  multiChoice,
  multiSelect,
  responsiveMatrix,
  responsiveSlider,
  section,
  singleTextbox,
  welcomePage,
} from '../question-types/QuestionTypes'

import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import { Textbox } from '../../assets/styles/global'
import { lgButton } from '../ui/Layout/ButtonContainer'

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`
const StyledSpan = styled.span`
  display: flex;
  flex-direction: column;
  width: 500px;
  align-items: center;
  justify-content: center;
  align-self: center;
`
const Tinyh5 = styled.h5`
  margin-bottom: 0.01rem;
  margin-top: 0.35rem;
`

const PositionLeft = styled.div`
  width: 25%;
  position: absolute;
  left: 70px;
  top: 45px;
`

const Card = styled.div`
  display: flex;
  width: 700px;
  flex-direction: column;
  background-color: white;
  padding: 5px 25px;
  padding-bottom: 25px;
  border-bottom: 2px solid #53bf8b;
  margin-top: 1rem;
  p {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 0;
    color: #53bf8b;
    span {
      color: black;
    }
  }
  @media (min-width: 0px) and (max-width: 468px) {
    width: 80vw;
  }
`

const farmArea = [
  'Enviroment management',
  'Financial management',
  'People management',
  'Reproduction management',
  'Feed management',
].sort(function shuffleArray(a, b) {
  return 0.5 - Math.random()
})

const CNA = {
  questions: [
    {
      id: 'Q1',
      text: 'Which of the following is closest describes your role on the farm?',
      type: multiChoice,
      verticalGrid: true,
      choices: [
        {
          id: 1,
          text: 'Farm owner',
          value: 'Farm owner',
        },
        {
          id: 2,
          text: 'Farm manager',
          value: 'Farm manager',
        },
        {
          id: 3,
          text: 'Assistant manager',
          value: 'Assistant manager',
        },
        {
          id: 4,
          text: 'Sharemilker',
          value: 'Sharemilker',
        },
        {
          id: 5,
          text: 'Contract milker',
          value: 'Contract milker',
        },
        { id: 6, text: 'Other', value: 'Other', type: input },
      ],
    },
    {
      id: 'Q3',
      text: 'How would you describe your level of influence in management of the farm?',
      type: multiChoice,
      next: ({ answers }) => {
        console.log('answers', answers)
        if (answers['Q3'].id === 3) {
          return 'Q33'
        }
        return 'Q4'
      },
      choices: [
        {
          id: 1,
          text: 'I am the primary decision maker',
          value: 'I am the primary decision maker',
          image: '1.jpg',
        },
        {
          id: 2,
          text: 'I have moderate to high influence over the farm management',
          value: 'I have moderate to high influence over the farm management',
          image: '2.jpg',
        },
        {
          id: 3,
          text: 'I have little or no influence over decisions made on farm',
          value: 'I have little or no influence over decisions made on farm',
          image: '3.jpg',
        },
      ],
    },
    {
      id: 'Q4',
      text: 'What is the size of the herd on your farm?',
      type: multiChoice,
      choices: [
        {
          id: 1,
          text: '0 - 200',
          value: '0 - 200',
        },
        {
          id: 2,
          text: '201-400',
          value: '201-400',
        },
        {
          id: 3,
          text: '401-800',
          value: '401-800',
        },
        {
          id: 4,
          text: '800 +',
          value: '800 +',
        },
      ],
    },
    {
      id: 'Q5',
      text: 'Which region do you currently work in?',
      type: multiChoice,
      choices: [
        {
          id: 1,
          text: 'Northland',
          value: 'Northland',
        },
        {
          id: 2,
          text: 'Waikato',
          value: 'Waikato',
        },
        {
          id: 3,
          text: 'Bay of Plenty',
          value: 'Bay of Plenty',
        },
        {
          id: 4,
          text: 'Top of South Island',
          value: 'Top of South Island',
        },
        {
          id: 5,
          text: 'Taranaki / Manawatu',
          value: 'Taranaki / Manawatu',
        },
        {
          id: 6,
          text: 'Canterbury',
          value: 'Canterbury',
        },
        {
          id: 7,
          text: 'Lower North Island',
          value: 'Lower North Island',
        },
        {
          id: 8,
          text: 'Lower North Island',
          value: 'Lower North Island',
        },
        { id: 9, text: 'Other', value: 'Other', type: input },
      ],
    },

    {
      id: 'Q6',
      section: 'Priorities',
      buttonSize: lgButton.width,
      type: welcomePage,
      statement:
        'Last year, we asked farmers (like yourself) to tell us about their strengths on farm, and where they could do a bit more upskill.',
    },

    {
      id: 'Q7',
      type: responsiveMatrix,
      text:
        'Compared to everyything you would like to upskill in, please indicate how much of a priority each of these things are to you.',
      next: ({ answers }) => {
        console.log('answers', answers)
        if (
          Object.values(answers['Q7'].value).every(v => v.value === 'On a long list of priorities')
        ) {
          return 'Q8'
        }
        return 'Q9'
      },
      options: [
        {
          id: 1,
          value: 'Not a priority for me',
        },
        {
          id: 2,
          value: 'On a long list of priorities',
        },
        {
          id: 3,
          value: 'One of my top priorities',
        },
        { id: 4, value: 'My top priority right now' },
      ],
      choices: [
        {
          id: 1,
          text: 'Enviroment management',
          value: 'Enviroment management',
          labels: [
            { id: 1, text: 'Understand the enviromental impacts of my farm.' },
            { id: 2, text: 'Understand the obligations to reduce our enviromental impacts.' },
            { id: 3, text: 'Know how to reduce our impacts.' },
          ],
        },
        {
          id: 2,
          text: 'Financial management',
          value: 'Financial management',
          labels: [
            { id: 1, text: 'Know how to monitor my farms cash flow and performance.' },
            { id: 2, text: 'Know how to manage business risk.' },
          ],
        },
        {
          id: 3,
          text: 'People management',
          value: 'People management',
          labels: [
            { id: 1, text: 'Know my legal responsibility around people.' },
            { id: 2, text: 'Know how to create a positive team culture.' },
            { id: 3, text: 'Practice good team communication.' },
          ],
        },
        {
          id: 4,
          text: 'Reproduction management',
          value: 'Reproduction management',
          labels: [
            { id: 1, text: 'Understand methods of breeding cows for herd improvement.' },
            {
              id: 2,
              text: 'Know how to monitor the breeding plan to meet business performance targets.',
            },
          ],
        },
        {
          id: 5,
          text: 'Feed management',
          value: 'Feed management',
          labels: [
            { id: 1, text: 'Understand how to calculate the feed budget fpr the farm.' },
            { id: 2, text: 'Know how to adjust the feed budget to maximise performance.' },
            { id: 3, text: 'Keep up to date with feed research.' },
          ],
        },
      ],
    },

    // multichoice q7
    {
      id: 'Q8',
      text: 'What area do you consider to be your top priority right now?',
      type: singleTextbox,
      label: 'testing',
    },
    {
      id: 'Q9',
      startLoop: farmArea,
      header: ({ answers, loopIndex }) => {
        console.log('answers', answers)
        console.log('this.questions', this.questions)
        return this.questions['Q9'].startLoop[loopIndex]
      },
      statement: ({ answers, loopIndex }) => {
        const currentIndex = this.questions['Q9'].startLoop[loopIndex]
        const newArray = [
          [
            'Understand the environmental impacts of my farm',
            'understand the obligations to reduce our environmental impacts',
            'know how to reduce impacts',
          ].join(', '),
          ['Know how to monitor my farms cash', 'Know how to manage business risk'].join(', '),
          [
            'Know my legal responsibility around people',
            'know how to create a positive team culture ',
            'practice good team communication ',
          ].join(', '),
          [
            'Understand methods of breeding cows for herd improvment',
            'know how to monitor the breeding plan to meet business performance targets',
          ].join(', '),
          [
            'Understand how to calculate the feed budget for the farm',
            'Know how to adjust the feed budget to maximise perofrmance',
            'keep up to date with feed research',
          ].join(', '),
        ]
        return (
          <Textbox>
            <p>We are interested in how you prefer to learn about different topics.</p>
            <p>
              This section is specifically about <b>{currentIndex}</b>
            </p>
            <p>
              How would you rate your <b>current knowledge</b> in this area
            </p>
            <p>This includes: {newArray[loopIndex]}</p>
          </Textbox>
        )
      },
      type: multiChoice,
      choices: [
        {
          id: 1,
          text: 'I know very little',
          value: 'I know very little',
        },
        {
          id: 2,
          text: 'I know the basics',
          value: 'I know the basics',
        },
        {
          id: 3,
          text: 'Above average',
          value: 'Above average',
        },
        {
          id: 4,
          text: 'I could do with a little bit of upskilling',
          value: 'I could do with a little bit of upskilling',
        },
        {
          id: 5,
          text: 'I know all there is to know',
          value: 'I know all there is to know',
        },
      ],
    },
    {
      id: 'Q10',
      header: ({ answers, loopIndex }) => {
        return this.questions[9].startLoop[loopIndex]
      },
      type: multiChoice,
      choices: [
        {
          id: 1,
          text: 'No, there should be more options available',
          value: 'No, there should be more options available',
        },
        {
          id: 2,
          text: 'Yes, there are plenty of options available',
          value: 'Yes, there are plenty of options available',
        },
        {
          id: 3,
          text: 'I dont know, I havent looked into options',
          value: 'I dont know, I havent looked into options',
        },
      ],
    },
    {
      id: 'Q11',
      header: ({ answers, loopIndex }) => {
        return this.questions[9].startLoop[loopIndex]
      },
      type: multiChoice,
      choices: [
        {
          id: 1,
          text: 'Talk to friends or other farmers about the subject',
          value: 'Talk to friends or other farmers about the subject',
        },
        {
          id: 2,
          text: 'Attend a discussion group on the subject',
          value: 'Attend a discussion group on the subject',
        },
        {
          id: 3,
          text: 'Talk to a rural professional / consultant on the subject',
          value: 'Talk to a rural professional / consultant on the subject',
        },
        {
          id: 4,
          text: 'Do some online research / find resources on the subject',
          value: 'Do some online research / find resources on the subject',
        },
        {
          id: 5,
          text: 'Look for courses on the subject',
          value: 'Look for courses on the subject',
        },
        { id: 6, text: 'Other', value: 'Other', type: input },
      ],
    },
    {
      id: 'Q12',
      header: ({ answers, loopIndex }) => {
        return this.questions[9].startLoop[loopIndex]
      },
      type: multiChoice,
      choices: ({ answers, loopIndex }) => {
        const loopValue = this.questions[9].startLoop[loopIndex]
        return this.questions[11].choices.filter(choice => choice.id !== answers[11][loopValue].id)
      },
      next: ({ answers, loopIndex }) => {
        const loopValue = this.questions[9].startLoop[loopIndex]
        if (answers[11][loopValue].id !== 5 && answers[12][loopValue].id !== 5) {
          return 'Q14'
        }
        return 'Q16'
      },
    },
    {
      id: 'Q14',
      header: ({ answers }) => {
        return (
          <h5>
            Why would <b>not</b> consider looking for courses on the subject?
          </h5>
        )
      },
      next: ({ answers, loopIndex }) => {
        const loopValue = this.questions[9].startLoop[loopIndex]
        if (answers[14][loopValue][5]) {
          return 'Q15'
        }
        return 'Q16'
      },
      choices: [
        {
          id: 1,
          text: 'I havent seen anything which fits my needs',
          value: 'I havent seen anything which fits my needs',
        },
        {
          id: 2,
          text: 'There are better ways to learn about this topic',
          value: 'There are better ways to learn about this topic',
        },
        { id: 3, text: 'Not enough time', value: 'Not enough time' },
        { id: 4, text: 'Too expensive', value: 'Too expensive' },
        {
          id: 5,
          text: 'I have had a negative experience in the past',
          value: 'I have had a negative experience in the past',
        },
        {
          id: 6,
          text: 'I dont think my staff could manage the farm without me',
          value: 'I dont think my staff could manage the farm without me',
        },
        { id: 7, text: 'Other', value: 'Other', type: 'input' },
      ],
      type: 'multiSelect',
    },
    {
      id: 'Q15',
      text: 'Can you please explain why it was a negative experience for you?',
      type: singleTextbox,
    },
    {
      id: 'Q16',
      header: ({ answers, loopIndex }) => {
        const currentIndex = this.questions[9].startLoop[loopIndex]
        return `Realistically,  when might you consider looking for opportunities to learn about ${currentIndex}`
      },
      choices: [
        {
          id: 1,
          text: 'I am currently looking for opportunities to learn about this',
          value: 'I am currently looking for opportunities to learn about this',
        },
        {
          id: 2,
          text: 'In the next 3-6 months',
          value: 'In the next 3-6 months',
        },
        {
          id: 3,
          text: 'In the next 12 months',
          value: 'In the next 12 months',
        },
        {
          id: 4,
          text: 'Some day in the future hopefully',
          value: 'Some day in the future hopefully',
        },
        {
          id: 5,
          text: 'Realistically, Ill never find enough time for learning',
          value: 'Realistically, Ill never find enough time for learning',
        },
      ],
      type: multiChoice,
    },
    {
      id: 'Q17',
      section: 'About You',
      type: section,
      statement: ({ answers, loopIndex }) => {
        const currentIndex = this.questions[9].startLoop[loopIndex]
        return (
          <h4>
            I want you to think about your preferred learning environment, where you will get the
            most beneficial learning about {currentIndex}
          </h4>
        )
      },
    },
    {
      id: 'Q18',
      text: 'Where do you want to be learning?',
      choices: [
        {
          id: 1,
          text: 'Walking around a farm',
          value: 'Walking around a farm',
        },
        {
          id: 2,
          text: 'Perching on a stool in a milkshed',
          value: 'Perching on a stool in a milkshed',
        },
        { id: 3, text: 'Sitting in a classroom', value: 'Sitting in a classroom' },
        {
          id: 4,
          text: 'Joining a webinar / online discussion',
          value: 'Joining a webinar / online discussion',
        },
        {
          id: 5,
          text: 'Talking on the phone / sitting in a professionals office',
          value: 'Talking on the phone / sitting in a professionals office',
        },
        { id: 6, text: 'Other', value: 'Other', type: input },
      ],
      type: multiSelect,
    },
    {
      id: 'Q17',
      section: 'About You',
      type: section,
      statement: ({ answers, loopIndex }) => {
        const currentIndex = this.questions[9].startLoop[loopIndex]
        return (
          <h4>
            I want you to think about your preferred learning environment, where you will get the
            most beneficial learning about {currentIndex}
          </h4>
        )
      },
    },
    {
      id: 'Q19',
      text: 'Who do you want to learn from?',
      choices: [
        {
          id: 1,
          text: 'A farm consultant / DairyNZ consulting officer',
          value: 'A farm consultant / DairyNZ consulting officer',
        },
        {
          id: 2,
          text: 'A topic specialist',
          value: 'A topic specialist',
        },
        { id: 3, text: 'A course tutor', value: 'A course tutor' },
        {
          id: 4,
          text: 'Other farmers around the area',
          value: 'Other farmers around the area',
        },
        {
          id: 5,
          text: 'Experts on the internet',
          value: 'Experts on the internet',
        },
        { id: 6, text: 'Other', value: 'Other', type: input },
      ],
      type: multiSelect,
    },
    {
      id: 'Q20',
      type: responsiveSlider,
      text:
        'What type of learning would be most beneficial to you? A knowledge dump, practical farm management tips, or some combination of the two?',
      rating: 'ratingAnswer',
      // showValue: true,
      choices: [
        { id: 1, text: 'A knowledge dump', value: 'A knowledge dump' },
        { id: 2, text: 'A bit of both', value: 'A bit of both' },
        { id: 3, text: 'All practical', value: 'All practical' },
      ],
    },
    {
      id: 'Q21',
      type: multiChoice,
      text: 'How many other people might be learning alongside you?',
      choices: [
        { id: 1, text: 'No one else', value: 'No one else' },
        { id: 2, text: '1 - 4 other farmers', value: '1 - 4 other farmers' },
        { id: 3, text: '5-20 other farmers', value: '5-20 other farmers' },
        {
          id: 4,
          text: 'I dont mind, this wont be a deciding factor for me',
          value: 'I dont mind, this wont be a deciding factor for me',
        },
      ],
    },
    {
      id: 'Q22',
      type: multiChoice,
      text: 'How much time would you be willing to invest in it?',
      choices: [
        { id: 1, text: 'A couple hours', value: 'A couple hours' },
        { id: 2, text: '2 days (8-16 hours total)', value: '2 days (8-16 hours total)' },
        {
          id: 3,
          text: '2-3 hours a week, for a few weeks (8-16 hours total)',
          value: '2-3 hours a week, for a few weeks (8-16 hours total)',
        },
        {
          id: 4,
          text: '2-3 hours a week, for a few months (20-30 hours total)',
          value: '2-3 hours a week, for a few months (20-30 hours total)',
        },
      ],
    },
    {
      id: 'Q23',
      type: multiChoice,
      text: 'What time of the day would suit you best? ',
      choices: [
        { id: 1, text: 'In the morning', value: 'In the morning' },
        { id: 2, text: 'Between milkings', value: 'Between milkings' },
        { id: 3, text: 'In the afternoon', value: 'In the afternoon' },
        { id: 4, text: 'In the evening', value: 'In the evening' },
        {
          id: 5,
          text: 'Time of day is not a deciding factor for me',
          value: 'Time of day is not a deciding factor for me',
        },
      ],
    },
    {
      id: 'Q24',
      type: multiChoice,
      text: 'Would a certificate or qualifcation add value to your learning experience?',
      choices: [
        { id: 1, text: 'Yes it would add value', value: 'Yes it would add value' },
        { id: 2, text: 'No, it would not add value', value: 'No, it would not add value' },
        {
          id: 3,
          text: 'Maybe, but this wouldnt be a deciding factor for me',
          value: 'Maybe, but this wouldnt be a deciding factor for me',
        },
      ],
      next: ({ answers, loopIndex }) => {
        const loopValue = this.questions[9].startLoop[loopIndex]
        if (answers[24][loopValue].id === 1) {
          return 'Q25'
        }
        return 'Q26'
      },
    },
    {
      id: 'Q25',
      type: multiSelect,
      text: 'In what ways would it add value to your learning?',
      next: ({ answers }) => {
        return 'Q27'
      },
      statement: 'Tick all that apply',
      choices: [
        {
          id: 1,
          text: 'I like having my learning recognised',
          value: 'I like having my learning recognised',
        },
        {
          id: 2,
          text: 'To demonstrate my knowledge to potential employers or business partners',
          value: 'To demonstrate my knowledge to potential employers or business partners',
        },
        {
          id: 3,
          text: 'To demonstrat my knowledge to regulators, including councils and dairy processors',
          value:
            'To demonstrat my knowledge to regulators, including councils and dairy processors',
        },
        { id: 4, text: 'Other', value: 'Other', type: input },
      ],
    },
    {
      id: 'Q26',
      type: 'multiSelect',
      text: 'What would make a certificate or qualifcation more appealing to you?',
      statement: 'Tick all that apply',
      choices: [
        {
          id: 1,
          text: 'If it meant learning would be free of charge',
          value: 'If it meant learning would be free of charge',
        },
        {
          id: 2,
          text: 'If it meant additional, ongoing support from an expert',
          value: 'If it meant additional, ongoing support from an expert',
        },
        {
          id: 3,
          text: 'If it meant I would be more "employable" in the future',
          value: 'If it meant I would be more "employable" in the future',
        },
        {
          id: 4,
          text: 'Nothing would make it more appealing to me',
          value: 'Nothing would make it more appealing to me',
        },
        { id: 5, text: 'Other', value: 'Other', type: input },
      ],
    },
    {
      id: 'Q27',
      type: multiChoice,
      text: 'Would you like someone to follow-up support to help implent your learning?',
      choices: [
        {
          id: 1,
          text: 'Yes, one or two phone calls would be helpful',
          value: 'Yes, one or two phone calls would be helpful',
        },
        {
          id: 2,
          text: 'Yes, I would like some ongoing support',
          value: 'Yes, I would like some ongoing support',
        },
        { id: 3, text: 'No, I dont need any support', value: 'No, I dont need any support' },
        { id: 4, text: 'Other', value: 'Other', type: input },
      ],
    },
    {
      id: 'Q28',
      type: multiSelect,
      header: () => {
        return (
          <p>
            <b>Apart from content</b>, is there anything else that would sway your decision to sign
            up for this learnning opportunity?
          </p>
        )
      },
      choices: [
        { id: 1, text: 'Travel distance', value: 'Travel distance' },
        { id: 2, text: 'The cost', value: 'The cost' },
        { id: 3, text: 'Other', value: 'Other', type: input },
      ],
    },
    {
      id: 'Q29',
      type: multiSelect,
      header: 'Great!, it looks like your ideal training would look something like this: ',
      statement: ({ answers, loopIndex }) => {
        const currentIndex = this.questions[9].startLoop[loopIndex]

        const settingAnswers = Object.values(answers[18][currentIndex])
          .map(answer => {
            return answer.name
          })
          .join(', ')

        const supportedAnswers = Object.values(answers[19][currentIndex])
          .map(answer => {
            return answer.name
          })
          .join(', ')

        const attendeeAnswers = answers[21][currentIndex].value
        const structureAnswers = answers[22][currentIndex].value
        const timeAnswers = answers[23][currentIndex].value
        const certAnswers = answers[24][currentIndex].value
        const ongoingAnwers = answers[27][currentIndex].value

        return (
          <div>
            <Card>
              <h4>All you need to know about {currentIndex}</h4>
              <p>
                Setting: <span>{settingAnswers}</span>{' '}
              </p>
              <p>
                Supported by: <span>{supportedAnswers}</span>
              </p>
              <p>
                Other Attendees: <span>{attendeeAnswers}</span>
              </p>
              <p>
                Structure: <span>{structureAnswers}</span>
              </p>
              <p>
                Time of day: <span>{timeAnswers}</span>
              </p>
              <p>
                A certificate/qualifcation: <span>{certAnswers}</span>
              </p>
              <p>
                Ongoing Support: <span>{ongoingAnwers}</span>
              </p>
            </Card>
            <div>
              <Tinyh5>If this existed, would you sign up for it?</Tinyh5>
            </div>
          </div>
        )
      },
      text: 'If not, what choices would you make?',
      choices: [
        { id: 1, text: 'Yes, this sounds great', value: 'Yes' },
        { id: 2, text: 'Other', value: 'Other', type: input },
      ],
      next: ({ answers, loopIndex }) => {
        const currentIndex = this.questions[9].startLoop[loopIndex]
        if (1 in answers[28][currentIndex]) {
          return 'Q30'
        }

        if (2 in answers[28][currentIndex]) {
          return 'Q31'
        }

        return 'Q32'
      },
    },
    {
      id: 'Q30',
      type: multiChoice,
      text: 'How far would you be willing to travel for it?',
      next: ({ answers, loopIndex }) => {
        const currentIndex = this.questions[9].startLoop[loopIndex]
        if (2 in answers[28][currentIndex]) {
          return 'Q31'
        }
        return 'Q32'
      },
      choices: [
        { id: 1, text: '1 hour or less', value: '1 hour or less' },
        { id: 2, text: '2 - 3 hours', value: '2 - 3 hours' },
        {
          id: 3,
          text: 'However far is necessary (within New Zealand)',
          value: 'However far is necessary (within New Zealand)',
        },
      ],
    },
    {
      id: 'Q31',
      type: singleTextbox,
      text: 'How much are you willing to pay for this learning opportunity?',
    },
    {
      id: 'Q32',
      endLoop: true,
      breakLoop: ({ answers, loopIndex }) => {
        const currentIndex = this.questions[9].startLoop[loopIndex]
        if (answers[32][currentIndex].id === 2) {
          return 33
        }
        return null
      },
      type: imageChoice,
      text: 'Thats all of our questions',
      statement: () => {
        return (
          <div>
            <p>
              We are keen to see if your preferred training would look different if we asked you
              about another skill area.
            </p>
            <p>
              <b>Would you be happy to repeat the questions for a different area now?</b>
            </p>
          </div>
        )
      },
      choices: [
        {
          id: 1,
          text: 'Sure thing',
          value: 'Sure thing',
          image: 'CNA/agree_green.svg',
        },
        {
          id: 2,
          text: 'No, skip to the end please',
          value: 'No, skip to the end please',
          image: 'CNA/disagree_red.svg',
        },
      ],
    },
    {
      id: 'Q33',
      type: multiChoice,
      required: true,
      emailInput: true,
      nestedInput: {
        trigger: 'Sure thing!',
        text: 'Email Address',
      },
      noSkip: true,
      inputText: 'What email address can we contact you on in a years’ time?',
      width: '489px',
      text: (
        <Textbox style={{ padding: 0 }}>
          <p style={{ marginBottom: '1rem' }}>Thank you for doing the survey!</p>
          <p>Could we follow up with you in a years’ time to participate in a similar survey?</p>
        </Textbox>
      ),
      choices: [
        {
          id: 1,
          text: 'Sure thing!',
          value: 'Sure thing!',
          icon: <IconCheck fill="currentColor" />,
        },
        {
          id: 2,
          text: 'No thanks',
          value: 'No thanks',
          icon: <IconCross fill="currentColor" />,
          skip: true,
        },
      ],
      // subtext:
      //   'To go into the draw to win of ove five $50 Prezzy card, please leave a contact Email Address or Phone Number below.',
      // disclaimer: 'Dont worry, we wont contact you for anything else unless you would like to be.',
    },
    {
      id: 'Q34',
      text: 'The end.',
      type: endPage,
    },
  ],
  settings: {
    writeToFirestore: false,
    showQid: true,
    hideSubmit: true,
    surveyName: 'cna',
    progressBar: true,
  },
}

export default CNA
