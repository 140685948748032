/* eslint-disable no-unused-vars */
import { Divider } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'
import scarlatti from '../../assets/images/PIAS/scarlatti.png'

import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import { ReactComponent as Happy } from '../../assets/images/Scarlatti/Happy Face.svg'
import { ReactComponent as Ok } from '../../assets/images/Scarlatti/Ok face.svg'
import { ReactComponent as Sad } from '../../assets/images/Scarlatti/Sad Face.svg'
import { ReactComponent as VeryHappy } from '../../assets/images/Scarlatti/Very happy Face.svg'
import { ReactComponent as VerySad } from '../../assets/images/Scarlatti/Very sadFace 1.svg'
import { responsiveSlider, welcomePage } from '../question-types/QuestionTypes'

import backgroundVideo from '../../assets/images/Scarlatti/confer-scarlatti.mp4'
import { Textbox } from '../../assets/styles/global'
import { xlButton } from '../ui/Layout/ButtonContainer'

const IconHappy = styled(Happy)`
  width: 3rem;
  height: 3rem;
`
const IconOk = styled(Ok)`
  width: 3rem;
  height: 3rem;
`
const IconSad = styled(Sad)`
  width: 3rem;
  height: 3rem;
`
const IconVeryHappy = styled(VeryHappy)`
  width: 3rem;
  height: 3rem;
`
const IconVerySad = styled(VerySad)`
  width: 3rem;
  height: 3rem;
`

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 800px;
  li {
    font-size: 1rem;
    font-weight: 400;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 100%;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    width: 100%;
  }
`

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`

const Q = styled.p`
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: #757575;
  margin-bottom: 0.5rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  p {
    margin-bottom: 0rem;
  }
  .ant-divider-horizontal {
    margin: 0px 0;
    border-top: 1px solid #c3c3c3;
    width: 49rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 80vw;
    .ant-divider-horizontal {
      width: 90vw;
    }
  }
  /* align-items: center; */
`
const Text = styled.p`
  font-size: 1rem;
  color: ${props => props.color};
`

// console.log('companies', distinctCompanies)

const Scarlatti = {
  questions: [
    {
      id: 'wellbeing-intro',
      type: welcomePage,
      buttonSize: xlButton.width,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Wellbeing</Text>
          <Divider />
        </Wrapper>
      ),
      text: (
        <StyledTextbox>
          <p>
            All work involves some level of stress, but not all stress is bad. This section is about
            getting an understanding of our organisational wellbeing risks. While it is unlikely
            that any organisation can remove stressors, it is possible to mitigate them.
          </p>
          {/* <p>
            When answering these questions, it can be difficult to separate work and personal life.
            Think carefully on each answer, reflecting on the nature of the job separate to your
            personal circumstances or behaviours. For example, things like having overly high
            standards, a tendency to procrastination or the fact you live far away from your place
            of work are not caused by the job. It can be tricky to separate these things. The
            helpful thing to do can be to think if you were in another job – would the situation be
            the less or more or the same?
          </p> */}
        </StyledTextbox>
      ),
    },
    // 3.1
    {
      id: 'workload-slider',
      section: (
        <Wrapper>
          <Q>Questions 1 of 2</Q>
          <Text color={'#F9744C'}>Wellbeing</Text>
          <p style={{ marginTop: '0.5rem' }}>
            Please indicate your agreement with the following statements:
          </p>
          <Divider />
        </Wrapper>
      ),
      text: (
        <Wrapper>
          {/* <p>Please indicate your agreement with the following statements:</p> */}
          <p>How would you rate your workload for the past quarter (December - February)?</p>
        </Wrapper>
      ),
      // text:
      //   'If you had to rate your workload for the past quarter (May-July) on a scale of  1- 10, 1 being bored and not knowing what to do, 5 being healthy, rewarding challenges that you can attack within working hours, and 10 being burnout and no longer enjoying your work, where would you put yourself?',
      type: responsiveSlider,
      // showValue: true,
      default: 2,
      max: 10,
      marks: [
        { id: 1, text: 'Being bored and not knowing what to do' },
        { id: 2, text: 'Healthy, rewarding challenges' },
        { id: 3, text: 'Burnt out' },
      ],
      choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    // 3.2
    {
      id: 'contribution-slider',
      type: responsiveSlider,
      section: (
        <Wrapper>
          <Q>Questions 2 of 2</Q>
          <Text color={'#F9744C'}>Wellbeing</Text>
          <p style={{ marginTop: '0.5rem' }}>
            Please indicate your agreement with the following statements:
          </p>
          <Divider />
        </Wrapper>
      ),
      text: 'I feel remunerated fairly for the effort I put in and the contribution I make',
      default: 2,
      max: 10,

      // showValue: true,
      marks: [
        { id: 1, text: 'Poor' },
        { id: 2, text: 'Neutral' },
        { id: 2, text: 'Excellent' },
      ],
      choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },

    {
      id: 'end-page',
      type: 'endPage',
      text: ({ answers }) => {
        return (
          <div>
            <p style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              Thank you, we will share results at the Retreat{' '}
              <IconVeryHappy style={{ marginLeft: '1rem' }} />
            </p>
          </div>
        )
      },
    },
  ],
  settings: {
    writeToFirestore: true,
    surveyName: 'scarlatti-sliders',
    primaryColour: '#48a54c',
    hideButton: true,
    // showQid: true,
    useSections: false,
    // secondaryColour: '#f38231',
    // contrastColour: '#fff',
    hideSubmit: true,
    hideLogo: false,
    logoFirstPageOnly: true,
    companyLogo: scarlatti,
    logoLocation: 'space-between',
    progressBar: true,
    objectPosition: 'center',
    // primaryColour: '#05B153',
    primaryColour: '#F9744C',
    useVideo: true,
    backgroundVideo,
  },
}

export default Scarlatti
