// Survey Components
// autoComplete for surveyCreation
export const multiRatioList = 'multiRatioList'
export const multiTextBox = 'multiTextBox'
export const welcomePage = 'welcomePage'
export const endPage = 'endPage'
export const section = 'section'
export const endSurvey = 'endSurvey'
export const endSurveyForm = 'endSurveyForm'
export const dragDrop = 'dragDrop'

export const commentBox = 'commentBox'

export const responsiveSlider = 'responsiveSlider'
export const responsiveMatrix = 'responsiveMatrix'
export const responsiveMatrixTable = 'responsiveMatrixTable'

export const autoComplete = 'autoComplete'
export const multiselectDropdown = 'multiselectDropdown'
export const ranking = 'ranking'
export const multiChoice = 'multiChoice'
export const multiSelect = 'multiSelect'
export const matrix = 'matrix'
export const multiDropdown = 'multiDropdown'
export const multiCardChoice = 'multiCardChoice'

export const conjointAnalysis = 'conjointAnalysis'
export const choiceRank = 'choiceRank'
export const compareSlider = 'compareSlider'

export const starRating = 'starRating'
export const imageChoice = 'imageChoice'
export const responsiveMultiMap = 'responsiveMultiMap'
export const inputFieldNumber = 'inputFieldNumber'

export const hidden = 'hidden'
export const slider = 'slider'
export const multiSlider = 'multiSlider'
export const singleTextbox = 'singleTextbox'
export const inputField = 'inputField'
export const socialNetwork = 'socialNetwork'
export const imageRadioList = 'imageRadioList'

export const simpleMap = 'simpleMap'
export const simpleMapMulti = 'simpleMapMulti'
// Other
export const button = 'button'
export const input = 'input'
