import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import conferLogo from '../../assets/images/Confer-logo.png'
import leftBackgroundImage from '../../assets/images/HCF/backgroundimage.jpg' // Todo compress image
import BeefLamb from '../../assets/images/HCF/beeflamb.jpg'
import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import MBIE from '../../assets/images/HCF/Slice 1.svg'
import HCFLogo from '../../assets/images/HCF/test.svg'
import {
  commentBox,
  endPage,
  input,
  multiChoice,
  multiDropdown,
  multiSelect,
  responsiveMatrix,
  responsiveMultiMap,
  welcomePage,
} from '../question-types/QuestionTypes'
import { xlButton } from '../ui/Layout/ButtonContainer'

import { Textbox } from '../../assets/styles/global'

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
`

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`

const StyledP = styled.p`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 0rem;
  margin-top: 1rem;
`

const groupSources = [
  {
    id: 1,
    text: 'Beef + Lamb New Zealand',
    value: 'Beef + Lamb New Zealand',
  },
  {
    id: 2,
    text: 'Regional councils',
    value: 'Regional councils',
  },
  {
    id: 3,
    text: 'Ministry for Primary Industries (MPI)',
    value: 'Ministry for Primary Industries (MPI)',
  },
  {
    id: 4,
    text: 'Ministry for the Environment (MtE)',
    value: 'Ministry for the Environment (MtE)',
  },
  {
    id: 5,
    text: 'In the media',
    value: 'In the media',
  },
  {
    id: 6,
    text: 'At field days / industry events',
    value: 'At field days / industry events',
  },
  {
    id: 7,
    text: 'Advisors / consultants',
    value: 'Advisors / consultants',
  },
  {
    id: 8,
    text: 'Other farmers',
    value: 'Other farmers',
  },
].sort(function shuffleArray(a, b) {
  return 0.5 - Math.random()
})

const HillCountryFutures = {
  questions: [
    {
      id: 'intro-page',
      type: welcomePage,
      text: (
        <StyledTextbox style={{ alignItems: 'baseline' }}>
          {/* <h1>Sorry, this survey is now closed.</h1>
          <SadFace /> */}
          {/* <Logo>
            <img src={BeefLamb} />
          </Logo> */}
          <StyledP>
            The challenges of the past year have proven just how valuable New Zealand’s primary
            sector is and will be in the future.
          </StyledP>
          <StyledP>
            Beef + Lamb New Zealand are undertaking work to future-proof New Zealand’s hill country
            farms and rural communities, and we need your help.
          </StyledP>
          <StyledP>
            If you are a <b>hill country farmer, or work in a related role</b>, please take this
            quick survey to help inform efforts to protect and enhance our hill country.
          </StyledP>
          <StyledP>This survey should only take 5-10 minutes to complete.</StyledP>
          <StyledP>Be assured that all answers you provide will be confidential.</StyledP>
        </StyledTextbox>
      ),
    },

    {
      id: 'farm-roles',
      type: multiChoice,
      text: 'Which of the following best describes your role?',
      required: true,
      // verticalGrid: true,
      choices: [
        {
          id: 1,
          text: 'Farmer',
          value: 'farmer',
          // icon: <IconFarmer fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Rural professional',
          value: 'Rural professional',
          // type: input,
          // width: '551px',
          // icon: <IconProfessional fill="currentColor" />,
        },
        {
          id: 3,
          type: input,
          width: '489px',
          text: 'Other, please specify',
          value: '',
        },
      ],
    },
    {
      id: 'map',
      type: responsiveMultiMap,
      customSource: 'hcf-regions-simple.json', // for custom maps
      disclaimer: 'Click on all regions that apply',
      required: true,
      choices: [
        { id: 1, name: 1, text: 'Northland ', value: 'Northland Region' },
        { id: 2, name: 2, text: 'Auckland ', value: 'Auckland Region' },
        { id: 3, name: 3, text: 'Waikato ', value: 'Waikato Region' },
        { id: 4, name: 4, text: 'Bay of Plenty ', value: 'Bay of Plenty Region' },
        { id: 5, name: 6, text: 'Gisborne ', value: 'Gisborne Region' },
        { id: 6, name: 6, text: "Hawke's Bay ", value: "Hawke's Bay Region" },
        { id: 7, name: 7, text: 'Taranaki ', value: 'Taranaki Region' },
        { id: 8, name: 8, text: 'Manawatu-Wanganui ', value: 'Manawatu-Wanganui Region' },
        { id: 9, name: 9, text: 'Wellington ', value: 'Wellington Region' },
        { id: 10, name: 10, text: 'West Coast ', value: 'West Coast Region' },
        { id: 11, name: 11, text: 'Canterbury ', value: 'Canterbury Region' },
        { id: 12, name: 12, text: 'Otago ', value: 'Otago Region' },
        { id: 13, name: 13, text: 'Southland ', value: 'Southland Region' },
        { id: 14, name: 14, text: 'Marlborough ', value: 'Marlborough Region' },
        { id: 15, name: 15, text: 'Nelson/Tasman ', value: 'Nelson/Tasman Region' },
      ],
      text: 'Which region do you currently work in?',
    },
    {
      id: 'age-group',
      type: multiChoice,
      verticalGrid: true,
      required: true,
      text: 'Which age group do you fit into?',
      choices: [
        { id: 1, text: '18 - 30 years', value: '18 - 30 years' },
        { id: 2, text: '31 - 40 years', value: '31 - 40 years' },
        { id: 3, text: '41 - 50 years', value: '41 - 50 years' },
        { id: 4, text: '51 - 60 years', value: '51 - 60 years' },
        { id: 5, text: '61+ years', value: '61+ years' },
      ],
    },
    {
      id: 'ethnicity',
      type: multiSelect,
      required: true,
      verticalGrid: true,
      text: 'What ethnicity do you identify with?',
      choices: [
        { id: 1, text: 'European / Pākehā', value: 'European / Pākehā' },
        { id: 2, text: 'Māori', value: 'Māori' },
        {
          id: 3,
          text: 'Other, please specify',
          value: 'Other',
          width: '551px',
          placeholder: 'Other, please specify',
          type: input,
        },
      ],
    },
    {
      id: 'gender',
      type: multiChoice,
      verticalGrid: true,
      required: true,
      text: 'What gender do you identify with?',
      choices: [
        // { id: 1, text: 'Male', value: 'Male', icon: <IconMale fill="currentColor" /> },
        // { id: 2, text: 'Female', value: 'Female', icon: <IconFemale fill="currentColor" /> },
        { id: 1, text: 'Male', value: 'Male' },
        { id: 2, text: 'Female', value: 'Female' },
        {
          id: 3,
          text: 'Other',
          value: 'Other',
          // width: '551px',
          // placeholder: 'Other',
          // type: input,
        },
      ],
      next: ({ answers }) => {
        if (answers['farm-roles'].id === 1) {
          return 'role-on-farm'
        }
        return 'rural-demographics'
      },
    },
    {
      id: 'role-on-farm',
      type: multiChoice,
      text: 'What is your role on farm?',
      verticalGrid: true,
      required: true,
      choices: [
        { id: 1, text: 'Farm owner', value: 'Farm owner' },
        { id: 2, text: 'Farm manager', value: 'Farm manager' },
        { id: 3, text: 'Stock/block manager', value: 'Stock/block manager' },
        { id: 4, text: 'Shepherd', value: 'Shepherd' },
        { id: 5, text: 'Junior shepherd', value: 'Junior shepherd' },

        // { id: 1, text: 'Male', value: 'Male', icon: <IconMale fill="currentColor" /> },
        // { id: 2, text: 'Female', value: 'Female', icon: <IconFemale fill="currentColor" /> },
        {
          id: 6,
          text: 'Other, please specify',
          value: 'Other',
          width: '551px',
          placeholder: 'Other, please specify',
          type: input,
        },
      ],
    },
    {
      id: 'farm-land-size',
      type: multiChoice,
      required: true,
      verticalGrid: true,
      text: 'How large is the farm you work on?',
      choices: [
        {
          id: 1,
          text: 'Less than 100 ha',
          value: 'Less than 100 ha',
        },
        {
          id: 2,
          text: '100-500 ha',
          value: '100-500 ha',
        },
        {
          id: 3,
          text: '501-1000 ha',
          value: '501-1000 ha',
        },
        {
          id: 4,
          text: '1001+ ha',
          value: '1001+ ha',
        },
      ],
    },
    {
      id: 'ownership-structure',
      type: multiChoice,
      required: true,
      verticalGrid: true,
      text: 'Which of the below best describes the ownership structure of your farm?',
      choices: [
        { id: 1, text: 'Sole trader', value: 'Sole trader' },
        { id: 2, text: 'General partnership', value: 'General partnership' },
        { id: 3, text: 'Limited partnership', value: 'Limited partnership' },
        { id: 4, text: 'Company', value: 'Company' },
        { id: 5, text: 'Trust', value: 'Trust' },
        { id: 6, text: 'Māori trust or incorporation', value: 'Māori trust or incorporation' },
        {
          id: 7,
          text: 'Other, please specify',
          value: 'Other',
          width: '551px',
          placeholder: 'Other, please specify',
          type: input,
        },
      ],
    },
    {
      id: 'farm-type',
      type: multiChoice,
      verticalGrid: true,
      text: 'Which of the below best describes your farm type?',
      required: true,
      choices: [
        {
          id: 1,
          text: 'High country',
          value: 'High country',
        },
        {
          id: 2,
          text: 'Hill country',
          value: 'Hill country',
        },
        {
          id: 3,
          text: 'Hard hill country',
          value: 'hard hill country',
        },
        {
          id: 4,
          text: 'Intensive finishing',
          value: 'Intensive finishing',
        },
        {
          id: 5,
          text: 'Finishing breeding',
          value: 'Finishing breeding',
        },
        {
          id: 6,
          text: 'Mixed finishing',
          value: 'Mixed finishing',
        },
        {
          id: 7,
          text: 'Other',
          type: input,
          width: '551px',
          placeholder: 'Please specify',
          value: '',
          mobileProps: { top: null },
        },
      ],
      // next: ({ answers }) => {
      //   if (answers['farm-roles'].value === 'farmer') {
      //     return 'hill-country-farming-future'
      //   }
      //   return 'map'
      // },
    },

    // {
    //   id: 'role-on-farm',
    //   type: multiChoice,
    //   text: 'Which of the following best describes your role?',
    //   required: true,
    //   verticalGrid: true,
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'Business manager',
    //       value: 'business manager',
    //       // icon: <IconFarmer fill="currentColor" />,
    //     },
    //     {
    //       id: 2,
    //       text: 'Farm manager',
    //       value: 'Farm manager',
    //       // icon: <IconFarmer fill="currentColor" />,
    //     },
    //     {
    //       id: 3,
    //       text: 'Stock/block manager',
    //       value: 'Stock/block manager',
    //       // icon: <IconFarmer fill="currentColor" />,
    //     },
    //     {
    //       id: 3,
    //       text: 'Shepherd',
    //       value: 'Shepherd',
    //       // icon: <IconFarmer fill="currentColor" />,
    //     },
    //     {
    //       id: 4,
    //       text: 'Junior shepherd',
    //       value: 'Junior shepherd',
    //       // icon: <IconFarmer fill="currentColor" />,
    //     },
    //     {
    //       id: 5,
    //       text: 'Other, please specify',
    //       value: 'Other',
    //       width: '489px',
    //       placeholder: 'Other, please specify',
    //       type: input,
    //     },
    //   ],
    //   // next: ({ answers }) => {
    //   //   if (answers['farm-roles'].value === 'farmer') {
    //   //     return 'farm-type'
    //   //   }
    //   //   return 'map'
    //   // },
    // },
    // {
    //   id: 'farm-land-size',
    //   type: multiChoice,
    //   required: true,
    //   verticalGrid: true,
    //   text: 'How large is the farm you currently own / work on?',
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'Less than 100 ha',
    //       value: 'Less than 100 ha',
    //     },
    //     {
    //       id: 2,
    //       text: '100-500 ha',
    //       value: '100-500 ha',
    //     },
    //     {
    //       id: 3,
    //       text: '501+ ha',
    //       value: '501+ ha',
    //     },
    //   ],
    // },
    // {
    //   id: 'farm-ownership',
    //   type: multiChoice,
    //   text: 'Which of the below best describes the ownership structure of your farm?',
    //   required: true,
    //   verticalGrid: true,
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'Sole trader',
    //       value: 'Sole trader',
    //       // icon: <IconFarmer fill="currentColor" />,
    //     },
    //     {
    //       id: 2,
    //       text: 'General partnership',
    //       value: 'General partnership',
    //       // icon: <IconFarmer fill="currentColor" />,
    //     },
    //     {
    //       id: 3,
    //       text: 'Limited partnership',
    //       value: 'Limited partnership',
    //       // icon: <IconFarmer fill="currentColor" />,
    //     },
    //     {
    //       id: 3,
    //       text: 'Company',
    //       value: 'Company',
    //       // icon: <IconFarmer fill="currentColor" />,
    //     },
    //     {
    //       id: 4,
    //       text: 'Trust',
    //       value: 'Trust',
    //       // icon: <IconFarmer fill="currentColor" />,
    //     },
    //     {
    //       id: 5,
    //       text: 'Māori trust and incorporation',
    //       value: 'Māori trust and incorporation',
    //       // icon: <IconFarmer fill="currentColor" />,
    //     },
    //     {
    //       id: 6,
    //       text: 'Other, please specify',
    //       value: 'Other',
    //       width: '489px',
    //       placeholder: 'Other, please specify',
    //       type: input,
    //     },
    //   ],
    //   // next: ({ answers }) => {
    //   //   if (answers['farm-roles'].value === 'farmer') {
    //   //     return 'farm-type'
    //   //   }
    //   //   return 'map'
    //   // },
    // },
    // {
    //   id: 'farm-type',
    //   type: multiChoice,
    //   text: 'Which of the below best describes your farm type?',
    //   required: true,
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'High country',
    //       value: 'High country',
    //       // icon: <IconAdvisor fill="currentColor" />,
    //     },
    //     {
    //       id: 2,
    //       text: 'Hill country',
    //       value: 'Hill country',
    //       // icon: <IconAdvisor fill="currentColor" />,
    //     },
    //     {
    //       id: 3,
    //       text: 'Hard hill country',
    //       value: 'Hard hill country',
    //       // icon: <IconAdvisor fill="currentColor" />,
    //     },
    //     {
    //       id: 4,
    //       text: 'Intensive finishing',
    //       value: 'Intensive finishing',
    //       // icon: <IconAdvisor fill="currentColor" />,
    //     },
    //     {
    //       id: 5,
    //       text: 'Finishing breeding',
    //       value: 'Finishing breeding',
    //       // icon: <IconAdvisor fill="currentColor" />,
    //     },
    //     {
    //       id: 6,
    //       text: 'Mixed finishing',
    //       value: 'Mixed finishing',
    //       // icon: <IconAdvisor fill="currentColor" />,
    //     },
    //     {
    //       id: 7,
    //       text: 'Other',
    //       type: input,
    //       width: '489px',
    //       placeholder: 'Please specify',
    //       value: '',
    //     },
    //   ],
    // },
    {
      id: 'hill-country-farming-future',
      type: multiChoice,
      buttonSize: xlButton.width,
      verticalGrid: true,
      required: true,
      // padding: '1.6rem',
      mobileHeight: '7.25rem',
      mobilePadding: '2.5rem',
      text:
        'To what extent do you think change is needed on farm to secure the future of hill country farmers and their communities?',
      choices: [
        {
          id: 1,
          text: 'I’ve never thought about it / it’s not on my radar',
          value: 'I’ve never thought about it / it’s not on my radar',
        },
        {
          id: 2,
          text:
            'I don’t think change is needed – we are already doing all we can to protect the hill country',
          value:
            'I don’t think change is needed – we are already doing all we can to protect the hill country',
        },
        {
          id: 3,
          text:
            'I think change is needed - I’ll probably make the required changes when I see other farmers making changes',
          value:
            'I think change is needed - I’ll probably make the required changes when I see other farmers making changes',
        },
        {
          id: 4,
          text:
            'I think change is needed – I’m willing to make it a top priority this year to make some changes',
          value:
            'I think change is needed – I’m willing to make it a top priority this year to make some changes',
        },
        {
          id: 5,
          text: 'I think change is needed – I’m in the process of making changes',
          value: 'I think change is needed – I’m in the process of making changes',
        },
        // {
        //   id: 6,
        //   text: 'I think change is needed, and it is currently one of my top priorities',
        //   value: 'I think change is needed, and it is currently one of my top priorities',
        // },
      ],
    },
    {
      id: 'hill-country-resilience',
      type: multiChoice,
      required: true,
      // padding: '15px',
      verticalGrid: true,
      mobilePadding: '2.25rem',
      mobileHeight: '2rem',
      text: (
        <>
          <StyledP>
            Does the term <b>‘hill country resilience and sustainability’</b> mean anything to you?
          </StyledP>
        </>
      ),
      choices: [
        {
          id: 1,
          text: 'I’ve never heard this term before',
          value: 'I’ve never heard this term before',
        },
        {
          id: 2,
          text: 'It sounds vaguely familiar but doesn’t mean anything to me',
          value: 'It sounds vaguely familiar but doesn’t mean anything to me',
        },
        {
          id: 3,
          text: 'I know generally what it means but don’t know what it involves',
          value: 'I know generally what it means but don’t know what it involves',
        },
        {
          id: 4,
          text: 'Yes, I’m well aware of what this is all about',
          value: 'Yes, I’m well aware of what this is all about',
        },
      ],
      next: ({ answers }) => {
        if (
          answers['hill-country-resilience'].id === 3 ||
          answers['hill-country-resilience'].id === 4
          // Q2.2
        ) {
          return 'knowledge-of-resilience-sustainability'
        }
        return 'sustainability topics'
      },
    },
    // {
    //   id: 'hill-country-resilience-sustainability-opinion',
    //   type: commentBox,
    //   placeholder: 'Hill country resilience and sustainability stands for...',
    //   text: () => {
    //     return (
    //       <p>
    //         In just a few words, what does '<b>Hill country resilience and sustainability</b>' mean
    //         to you?
    //       </p>
    //     )
    //   },
    // },
    {
      id: 'knowledge-of-resilience-sustainability',
      type: multiSelect,
      required: true,
      verticalGrid: true,
      text: 'Where (or from whom) have you heard this term used?',
      choices: ({ answers }) => {
        const randomArray = [groupSources]
        const other = { id: 7, type: input, text: 'Other, please specify', value: 'other' }

        randomArray.push(other)
        return _.flatten(randomArray)
      },
      next: ({ answers }) => {
        if (
          answers['hill-country-resilience'].id === 3 ||
          answers['hill-country-resilience'].id === 4
        ) {
          return 'describe-concepts-of-resilience-and-sustainability'
        }
        return 'sustainability topics'
      },
    },
    {
      id: 'sustainability topics',
      type: multiSelect,
      required: true,
      verticalGrid: true,
      text:
        'Where (or from whom) would you expect to hear about hill country resilience and sustainability topics?',
      choices: ({ answers }) => {
        const randomArray = [groupSources]
        const other = { id: 7, type: input, text: 'Other, please specify', value: 'other' }

        randomArray.push(other)
        return _.flatten(randomArray)
      },
      next: ({ answers }) => {
        return 'engage-again'
      },
    },
    {
      id: 'describe-concepts-of-resilience-and-sustainability',
      type: multiChoice,
      required: true,
      buttonSize: xlButton.width,
      verticalGrid: true,
      // padding: '1.6rem',
      mobileHeight: '6rem',
      mobilePadding: '2.25rem',
      text: () => {
        return (
          <p>
            Would you feel comfortable describing <b>the concept</b> of a resilient and sustainable
            hill country system to another farmer?{' '}
          </p>
        )
      },
      // text:
      //   'Would you feel comfortable describing the concept of a resilient and sustainable hill country system to another farmer? ',
      choices: [
        {
          id: 1,
          text: 'Probably not, I would need to do some research first',
          value: 'Probably not, I would need to do some research first',
        },
        {
          id: 2,
          text: 'I know the basics, but wouldn’t feel confident talking to someone about it yet',
          value: 'I know the basics, but wouldn’t feel confident talking to someone about it yet',
        },
        {
          id: 3,
          text:
            'I could describe some of the concepts, but wouldn’t consider myself an expert in it',
          value:
            'I could describe some of the concepts, but wouldn’t consider myself an expert in it',
        },
        {
          id: 4,
          text:
            'I could confidently describe the concept of a resilient and sustainable hill country system to someone',
          value:
            'I could confidently describe the concept of a resilient and sustainable hill country system to someone',
        },
      ],
      next: ({ answers }) => {
        if (
          answers['describe-concepts-of-resilience-and-sustainability'].id === 3 ||
          answers['describe-concepts-of-resilience-and-sustainability'].id === 4
          // Q3.1
        ) {
          return 'suggest-tools-available'
        }
        return 'decision-making-tools'
      },
    },
    {
      id: 'suggest-tools-available',
      type: multiChoice,
      required: true,
      verticalGrid: true,
      mobileHeight: '2rem',
      mobilePadding: '2.25rem',
      text: () => {
        return (
          <p>
            Could you then suggest to them some <b>resources</b> and/or <b>tools available</b> that
            will raise hill country resilience and sustainability?
          </p>
          // check logic
        )
      },
      // text:
      //   'Could you then suggest to them some tools available to promote hill country resilience and sustainability?',
      choices: [
        {
          id: 1,
          text: 'Probably not, I would need to do some research first',
          value: 'Probably not, I would need to do some research first',
        },
        {
          id: 2,
          text: 'I could give it a go, but would rather refer them on to an expert',
          value: 'I could give it a go, but would rather refer them on to an expert',
        },
        {
          id: 3,
          text: 'I could confidently suggest some tools available that they could try',
          value: 'I could confidently suggest some tools available that they could try',
        },
      ],
    },
    {
      id: 'decision-making-tools',
      hide: true,
      required: true,
      // type: responsiveMatrix,
      type: multiDropdown,
      // tHeader: 'Landscape classification tools',
      text: () => {
        return (
          <div>
            {/* <p style={{ marginBottom: '1rem' }}>
              The following questions relate to resources and decision-making tools which have been
              developed as part of the Hill Country Futures research programme.
            </p> */}
            <p style={{ marginBottom: '0rem' }}>
              Which of these <b>landscape classification resources/tools</b> could you describe to
              another farmer?
            </p>
          </div>
        )
      },
      // text: 'Which of these decision-making tools could you describe to a farmer?',
      width: '200px',
      height: '70px',
      marginTop: '2rem',
      boxWidth: '800px',
      useColumns: true,
      useValues: true,
      choices: [
        {
          id: 1,
          hide: true,
          text: 'A) Legume yield model',
          disclaimers:
            'A model to estimate legume yield and suitability of forage legumes across New Zealand and their impact on production, environment, climate change and nutrient leaching.',
          value: 'Legume yield model:',
        },
        {
          id: 2,
          hide: true,
          disclaimers:
            'The use of micro-scale indicators (soil temperature and moisture) to classify variable landscapes within farms to help inform which forage mixes are likely to do well in specific areas. ',
          text: 'B) Micro-scale indicators',
          value: 'Micro-scale indicators:',
        },
        {
          id: 3,
          hide: true,
          text: 'C) Measures of natural capital',
          disclaimers:
            'A system for landscape classification that recognises the diversity of landscapes in terms of their financial and non-financial (social and environmental) values at the regional and sub-regional level and the farm level.',
          value: 'Measures of natural capital:',
        },
      ],
      options: [
        {
          id: 1,
          text: "I've never heard of it",
          value: "I've never heard of it",
        },
        {
          id: 2,
          text: "I've heard of it but don't know much about it",
          value: "I've heard of it but don't know much about it",
        },
        {
          id: 3,
          text: 'I know the basics and could probably explain it to someone else',
          value: 'I know the basics and could probably explain it to someone else',
        },
        {
          id: 4,
          text: "I'm using it",
          value: "I'm using it",
        },
      ],
      columns: [
        {
          id: 1,
          hide: true,
          text: "I've never heard of it",
          value: "I've never heard of it",
        },
        {
          id: 2,
          hide: true,
          text: "I've heard of it but don't know much about it",
          value: "I've heard of it but don't know much about it",
        },
        {
          id: 3,
          hide: true,
          text: 'I know the basics and could probably explain it to someone else',
          value: 'I know the basics and could probably explain it to someone else',
        },
        {
          id: 4,
          hide: true,
          text: "I'm using it",
          value: "I'm using it",
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'A) Legume yield model',
          disclaimers:
            'A model to estimate legume yield and suitability of forage legumes across New Zealand and their impact on production, environment, climate change and nutrient leaching.',
          value: 'Legume yield model:',
        },
        {
          id: 2,
          hide: true,
          disclaimers:
            'The use of micro-scale indicators (soil temperature and moisture) to classify variable landscapes within farms to help inform which forage mixes are likely to do well in specific areas. ',
          text: 'B) Micro-scale indicators',
          value: 'Micro-scale indicators:',
        },
        {
          id: 3,
          hide: true,
          text: 'C) Measures of natural capital',
          disclaimers:
            'A system for landscape classification that recognises the diversity of landscapes in terms of their financial and non-financial (social and environmental) values at the regional and sub-regional level and the farm level.',
          value: 'Measures of natural capital:',
        },
      ],
    },
    {
      id: 'decision-making-tools-p2',
      hide: true,
      required: true,
      // type: responsiveMatrix,
      type: multiDropdown,
      // tHeader: 'Biodiversity in forage landscapes',
      text: () => {
        return (
          <p style={{ marginBottom: '0rem' }}>
            Which of these <b>forage biodiversity resources/tools</b> could you describe to another
            farmer?
          </p>
        )
      },
      // text: 'Which of these decision-making tools could you describe to a farmer?',
      width: '200px',
      height: '70px',
      marginTop: '2rem',
      boxWidth: '800px',
      useColumns: true,
      useValues: true,
      choices: [
        {
          id: 1,
          hide: true,
          text: 'D) National database of pasture production',
          disclaimers:
            'A forage database that will capture and preserve historic and current New Zealand specific forage data.',
          value: 'National database of pasture production:',
        },
        {
          id: 2,
          hide: true,
          text: 'E) Legume map',
          disclaimers:
            'An on-farm decision making tool that can be used to match a property’s different land management units with the appropriate legume.',
          value: 'Legume map:',
        },
        {
          id: 3,
          hide: true,
          text: 'F) Native plants research',
          disclaimers:
            'Research into the suitability of different native plants for use on farm, for example as sheep fodder.',
          value: 'Native plants:',
        },
        {
          id: 4,
          hide: true,
          text: 'G) Forage selection tool',
          disclaimers:
            'A forage value selection tool for sheep and beef hill country farming systems.',
          value: 'Forage selection tool:',
        },
      ],
      options: [
        {
          id: 1,
          text: "I've never heard of it",
          value: "I've never heard of it",
        },
        {
          id: 2,
          text: "I've heard of it but don't know much about it",
          value: "I've heard of it but don't know much about it",
        },
        {
          id: 3,
          text: 'I know the basics and could probably explain it to someone else',
          value: 'I know the basics and could probably explain it to someone else',
        },
        {
          id: 4,
          text: "I'm using it",
          value: "I'm using it",
        },
      ],
      columns: [
        {
          id: 1,
          hide: true,
          text: "I've never heard of it",
          value: "I've never heard of it",
        },
        {
          id: 2,
          hide: true,
          text: "I've heard of it but don't know much about it",
          value: "I've heard of it but don't know much about it",
        },
        {
          id: 3,
          hide: true,
          text: 'I know the basics and could probably explain it to someone else',
          value: 'I know the basics and could probably explain it to someone else',
        },
        {
          id: 4,
          hide: true,
          text: "I'm using it",
          value: "I'm using it",
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'D) National database of pasture production',
          disclaimers:
            'A forage database that will capture and preserve historic and current New Zealand specific forage data.',
          value: 'National database of pasture production:',
        },
        {
          id: 2,
          hide: true,
          text: 'E) Legume map',
          disclaimers:
            'An on-farm decision making tool that can be used to match a property’s different land management units with the appropriate legume.',
          value: 'Legume map:',
        },
        {
          id: 3,
          hide: true,
          text: 'F) Native plants research',
          disclaimers:
            'Research into the suitability of different native plants for use on farm, for example as sheep fodder.',
          value: 'Native plants:',
        },
        {
          id: 4,
          hide: true,
          text: 'G) Forage selection tool',
          disclaimers:
            'A forage value selection tool for sheep and beef hill country farming systems.',
          value: 'Forage selection tool:',
        },
      ],
      next: ({ answers }) => {
        if (
          answers['hill-country-resilience'].id === 3 ||
          answers['hill-country-resilience'].id === 4
          // Q2.2
        ) {
          return 'connections-related-to-hill-country-resilience'
        } else if (
          answers['describe-concepts-of-resilience-and-sustainability'].id === 3 ||
          answers['describe-concepts-of-resilience-and-sustainability'].id === 4
          // Q3.1
        ) {
          return 'confidence-related-to-hill-country-resilience'
        } else if (
          _.find(answers['decision-making-tools'].value, { value: "I'm using it" }) ||
          _.find(answers['decision-making-tools-p2'].value, { value: "I'm using it" })
        ) {
          return 'behavior-change'
        }
      },
    },
    {
      id: 'connections-related-to-hill-country-resilience',
      type: multiChoice,
      required: true,
      verticalGrid: true,
      text:
        'If your neighbour had asked you who to talk to about hill country resilience and sustainability (before doing this survey), would you have been able to refer them to someone?',
      choices: [
        { id: 1, text: 'No, probably not', value: 'No, probably not' },
        {
          id: 2,
          text: 'I could probably figure out who they could talk to',
          value: 'I could probably figure out who they could talk to',
        },
        {
          id: 3,
          text: 'Yes, I would have known who to refer them to',
          value: 'Yes, I would have known who to refer them to',
        },
      ],
      next: ({ answers }) => {
        if (
          answers['connections-related-to-hill-country-resilience'].value ===
          'Yes, I would have known who to refer them to'
        ) {
          return 'connections-related-to-hill-country-resilience-p2'
        } else if (
          answers['describe-concepts-of-resilience-and-sustainability'].id === 3 ||
          answers['describe-concepts-of-resilience-and-sustainability'].id === 4
        ) {
          return 'confidence-related-to-hill-country-resilience'
        } else if (
          _.find(answers['decision-making-tools'].value, { value: "I'm using it" }) ||
          _.find(answers['decision-making-tools-p2'].value, { value: "I'm using it" })
        ) {
          return 'behavior-change'
        }
        return 'engage-again'
      },
    },
    {
      id: 'connections-related-to-hill-country-resilience-p2',
      type: multiSelect,
      verticalGrid: true,
      required: true,
      text:
        'Who have you talked to in the past six months about hill country resilience and sustainability?',
      choices: [
        { id: 1, text: 'Advisors and consultants', value: 'Advisors and consultants' },
        { id: 2, text: 'Other farmers', value: 'Other farmers' },
        { id: 3, text: 'Beef + Lamb NZ', value: 'Beef + Lamb NZ' },
        { id: 4, text: 'Other, Please Specify', value: 'Other', type: input },
        {
          id: 5,
          text: "I haven't talked to anyone",
          value: "I haven't talked to anyone",
          submit: true,
        },
      ],
      next: ({ answers }) => {
        if (
          answers['describe-concepts-of-resilience-and-sustainability'].id === 3 ||
          answers['describe-concepts-of-resilience-and-sustainability'].id === 4
          // Q3.1
        ) {
          return 'confidence-related-to-hill-country-resilience'
        } else if (
          _.find(answers['decision-making-tools'].value, { value: "I'm using it" }) ||
          _.find(answers['decision-making-tools-p2'].value, { value: "I'm using it" })
        ) {
          return 'behavior-change'
        }
        return 'engage-again'
      },
    },
    {
      id: 'confidence-related-to-hill-country-resilience',
      type: responsiveMatrix,
      required: true,
      text:
        'If your neighbour asked you for advice on how to identify and implement sustainable practices on farm, how confident would you be to:',
      hide: true,
      width: '200px',
      height: '70px',
      marginTop: '2rem',
      boxWidth: '800px',
      useColumns: true,
      useValues: true,
      choices: [
        {
          id: 1,
          hide: true,
          text:
            'Identify some changes they could make to promote hill country resilience and sustainability',
          value:
            'Identify some changes they could make to promote hill country resilience and sustainability:',
        },
        {
          id: 2,
          hide: true,
          text: 'Help them implement those changes in the next 12 months',
          value: 'Help them implement those changes in the next 12 months:',
        },
      ],
      options: [
        {
          id: 1,
          text: "I couldn't do this yet",
          value: "I couldn't do this yet",
        },
        {
          id: 2,
          text: 'I think I could do this now',
          value: 'I think I could do this now',
        },
        {
          id: 3,
          text: "I'm very confident I could do this now",
          value: "I'm very confident I could do this now",
        },
      ],
      columns: [
        {
          id: 1,
          hide: true,
          text: "I couldn't do this yet",
          value: "I couldn't do this yet",
        },
        {
          id: 2,
          hide: true,
          text: 'I think I could do this now',
          value: 'I think I could do this now',
        },
        {
          id: 3,
          hide: true,
          text: "I'm very confident I could do this now",
          value: "I'm very confident I could do this now",
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text:
            'Identify some changes they could make to promote hill country resilience and sustainability',
          value:
            'Identify some changes they could make to promote hill country resilience and sustainability:',
        },
        {
          id: 2,
          hide: true,
          text: 'Help them implement those changes in the next 12 months',
          value: 'Help them implement those changes in the next 12 months:',
        },
      ],
      next: ({ answers }) => {
        if (
          _.find(answers['decision-making-tools'].value, { value: "I'm using it" }) ||
          _.find(answers['decision-making-tools-p2'].value, { value: "I'm using it" })
        ) {
          return 'behavior-change'
        }
        return 'engage-again'
      },
    },
    {
      id: 'behavior-change',
      type: multiChoice,
      required: true,
      // padding: '1.8rem',
      verticalGrid: true,
      mobileHeight: '2rem',
      mobilePadding: '2.25rem',
      text:
        'Could you describe a change you’ve made as a result of using the decision-making resources/tools?',
      choices: [
        {
          id: 1,
          text: 'Please specify',
          value: 'other',
          placeholder: 'Please specify',
          type: commentBox,
          width: '551px',
          height: '100px',
        },
        {
          id: 2,
          text: "I haven't made any changes yet, but I have written it into our plans to",
          value: "I haven't made any changes yet, but I have written it into our plans to",
          width: '590px',
        },
        {
          id: 3,
          text: 'I haven’t made any changes yet, and I have no immediate intention to',
          value: 'I haven’t made any changes yet, and I have no immediate intention to',
          width: '590px',
        },
      ],
      next: ({ answers }) => {
        return 'engage-again'
      },
    },
    {
      id: 'rural-demographics',
      type: commentBox,
      required: true,
      placeholder: 'My role entails...',
      text: () => {
        return (
          <p style={{ marginBottom: '0rem' }}>
            Can you please describe your role as a rural professional?
          </p>
        )
      },
      // text: 'Can you please describe your role as a rural professional?',
    },
    {
      id: 'rural-demographics-p2', // Q2.1
      type: multiChoice,
      verticalGrid: true,
      required: true,
      mobileHeight: '2rem',
      mobilePadding: '2.5rem',
      text: 'What proportion of your work is related to hill country farms?',
      choices: [
        {
          id: 1,
          text: 'Less than 20% of your work',
          value: 'Less than 20% of your work',
        },
        {
          id: 2,
          text: '21% - 50% of your work',
          value: '21% - 50% of your work',
        },
        {
          id: 3,
          text: '51% - 100% of your work',
          value: '51% - 100% of your work',
        },
      ],
    },

    {
      id: 'awareness-of-hill-country-resilience', // Q2.1
      type: multiChoice,
      // padding: '1.6rem',
      required: true,
      verticalGrid: true,
      mobileHeight: '2rem',
      mobilePadding: '2.5rem',
      text:
        'To what extent do you think change is needed on farm to secure the future of hill country farmers and their communities?',
      choices: [
        {
          id: 1,
          text: 'I’ve never thought about it / it’s not on my radar',
          value: 'I’ve never thought about it / it’s not on my radar',
        },
        {
          id: 2,
          text:
            'I don’t think change is needed – we are already doing all we can to protect the hill country',
          value:
            'I don’t think change is needed – we are already doing all we can to protect the hill country',
        },
        {
          id: 3,
          text:
            'I think change is needed – I’ll help farmers to implement change when they want to change',
          value:
            'I think change is needed – I’ll help farmers to implement change when they want to change',
        },
        {
          id: 4,
          text:
            'I think change is needed – I’m willing to make it a top priority this year to help farmers implement change',
          value:
            'I think change is needed – I’m willing to make it a top priority this year to help farmers implement change',
        },
        {
          id: 5,
          text:
            'I think change is needed – I’m in the process of helping farmers to implement change',
          value:
            'I think change is needed – I’m in the process of helping farmers to implement change',
        },

        // {
        //   id: 6,
        //   text: 'I think change is needed, and it is currently one of my top priorities',
        //   value: 'I think change is needed, and it is currently one of my top priorities',
        // },
      ],
    },
    {
      id: 'hill-country-resilience-rural',
      type: multiChoice,
      verticalGrid: true,
      required: true,

      mobileHeight: '2.25rem',
      mobilePadding: '2.5rem',
      text: (
        <>
          <StyledP>
            Does the term <b>‘hill country resilience and sustainability’</b> mean anything to you?
          </StyledP>
        </>
      ),
      choices: [
        {
          id: 1,
          text: 'I’ve never heard this term before',
          value: 'I’ve never heard this term before',
        },
        {
          id: 2,
          text: 'It sounds vaguely familiar but doesn’t mean anything to me',
          value: 'It sounds vaguely familiar but doesn’t mean anything to me',
        },
        {
          id: 3,
          text: 'I know generally what it means but don’t know what it involves',
          value: 'I know generally what it means but don’t know what it involves',
        },
        {
          id: 4,
          text: 'Yes, I’m well aware of what this is all about',
          value: 'Yes, I’m well aware of what this is all about',
        },
      ],
      next: ({ answers }) => {
        if (
          answers['hill-country-resilience-rural'].id === 3 ||
          answers['hill-country-resilience-rural'].id === 4
          // Q2.2
        ) {
          return 'knowledge-of-resilience-sustainability-rural'
        }
        return 'sustainability topics-rural'
      },
    },
    // {
    //   id: 'hill-country-resilience-sustainability-opinion-rural',
    //   type: commentBox,
    //   placeholder: 'Hill country resilience and sustainability stands for...',
    //   text: () => {
    //     return (
    //       <p style={{ marginBottom: '0rem' }}>
    //         In just a few words, what does '<b>Hill country resilience and sustainability</b>' mean
    //         to you?
    //       </p>
    //     )
    //   },
    // },
    {
      id: 'knowledge-of-resilience-sustainability-rural',
      type: multiSelect,
      verticalGrid: true,
      required: true,
      text: 'Where (or from whom) have you heard this term used?',
      choices: ({ answers }) => {
        const randomArray = [groupSources]
        const other = { id: 7, type: input, text: 'Other, please specify', value: 'other' }

        randomArray.push(other)
        return _.flatten(randomArray)
      },
      next: ({ answers }) => {
        if (
          answers['hill-country-resilience-rural'].id === 3 ||
          answers['hill-country-resilience-rural'].id === 4
        ) {
          return 'describe-concepts-of-resilience-and-sustainability-rural'
        }
        return ''
      },
    },
    {
      id: 'sustainability topics-rural',
      type: multiSelect,
      verticalGrid: true,
      text:
        'Where (or from whom) would you expect to hear about hill country resilience and sustainability topics?',
      choices: ({ answers }) => {
        const randomArray = [groupSources]
        const other = { id: 7, type: input, text: 'Other, please specify', value: 'other' }

        randomArray.push(other)
        return _.flatten(randomArray)
      },
      next: ({ answers }) => {
        return 'engage-again'
      },
    },
    {
      id: 'describe-concepts-of-resilience-and-sustainability-rural',
      type: multiChoice,
      verticalGrid: true,
      required: true,
      // padding: '1.6rem',
      mobileHeight: '2rem',
      mobilePadding: '2.25rem',
      text:
        'Would you feel comfortable describing the concept of a resilient and sustainable hill country system to a farmer? ',
      choices: [
        {
          id: 1,
          text: 'Probably not, I would need to do some research first',
          value: 'Probably not, I would need to do some research first',
        },
        {
          id: 2,
          text: 'I know the basics, but wouldn’t feel confident talking to someone about it yet',
          value: 'I know the basics, but wouldn’t feel confident talking to someone about it yet',
        },
        {
          id: 3,
          text:
            'I could describe some of the concepts, but wouldn’t consider myself an expert in it',
          value:
            'I could describe some of the concepts, but wouldn’t consider myself an expert in it',
        },
        {
          id: 4,
          text:
            'I could confidently describe the concept of a resilient and sustainable hill country system to someone',
          value:
            'I could confidently describe the concept of a resilient and sustainable hill country system to someone',
        },
      ],
      next: ({ answers }) => {
        if (
          answers['describe-concepts-of-resilience-and-sustainability-rural'].id === 3 ||
          answers['describe-concepts-of-resilience-and-sustainability-rural'].id === 4
          // Q3.1
        ) {
          return 'suggest-tools-available-rural'
        }
        return 'decision-making-tools-rural'
      },
    },
    {
      id: 'suggest-tools-available-rural',
      type: multiChoice,
      required: true,
      verticalGrid: true,
      text: () => {
        return (
          <p>
            Could you then suggest to them some <b>resources </b>and/or <b>tools available</b> that
            will raise hill country resilience and sustainability?
          </p>
        )
      },
      choices: [
        {
          id: 1,
          text: 'Probably not, I would need to do some research first',
          value: 'Probably not, I would need to do some research first',
        },
        {
          id: 2,
          text: 'I could give it a go, but would rather refer them on to an expert',
          value: 'I could give it a go, but would rather refer them on to an expert',
        },
        {
          id: 3,
          text: 'I could confidently suggest some tools available that they could try',
          value: 'I could confidently suggest some tools available that they could try',
        },
      ],
    },
    {
      id: 'decision-making-tools-rural',
      hide: true,
      required: true,
      // type: responsiveMatrix,
      type: multiDropdown,
      // tHeader: 'Landscape classification tools',
      text: () => {
        return (
          <div>
            {/* <p style={{ marginBottom: '1rem' }}>
              The following questions relate to resources and decision-making tools which have been
              developed as part of the Hill Country Futures research programme.{' '}
            </p> */}
            <p style={{ marginBottom: '0rem' }}>
              Which of these <b>landscape classification resources/tools</b> could you describe to a
              farmer?{' '}
            </p>
          </div>
        )
      },
      // text: 'Which of these biodiversity tools could you describe to a farmer?',
      width: '200px',
      height: '70px',
      marginTop: '2rem',
      boxWidth: '800px',
      useColumns: true,
      useValues: true,
      choices: [
        {
          id: 1,
          hide: true,
          text: 'A) Legume yield model',
          disclaimers:
            'A model to estimate legume yield and suitability of forage legumes across New Zealand and their impact on production, environment, climate change and nutrient leaching.',
          value: 'Legume yield model:',
        },
        {
          id: 2,
          hide: true,
          disclaimers:
            'The use of micro-scale indicators (soil temperature and moisture) to classify variable landscapes within farms to help inform which forage mixes are likely to do well in specific areas. ',
          text: 'B) Micro-scale indicators',
          value: 'Micro-scale indicators:',
        },
        {
          id: 3,
          hide: true,
          text: 'C) Measures of natural capital',
          disclaimers:
            'A system for landscape classification that recognises the diversity of landscapes in terms of their financial and non-financial (social and environmental) values at the regional and sub-regional level and the farm level.',
          value: 'Measures of natural capital:',
        },
      ],
      options: [
        {
          id: 1,
          text: "I've never heard of it",
          value: "I've never heard of it",
        },
        {
          id: 2,
          text: "I've heard of it but don't know much about it",
          value: "I've heard of it but don't know much about it",
        },
        {
          id: 3,
          text: 'I know the basics and could probably explain it to someone else',
          value: 'I know the basics and could probably explain it to someone else',
        },
        {
          id: 4,
          hide: true,
          text: 'I am already telling people about it',
          value: 'I am already telling people about it',
        },
      ],
      columns: [
        {
          id: 1,
          hide: true,
          text: "I've never heard of it",
          value: "I've never heard of it",
        },
        {
          id: 2,
          hide: true,
          text: "I've heard of it but don't know much about it",
          value: "I've heard of it but don't know much about it",
        },
        {
          id: 3,
          hide: true,
          text: 'I know the basics and could probably explain it to someone else',
          value: 'I know the basics and could probably explain it to someone else',
        },
        {
          id: 4,
          hide: true,
          text: 'I am already telling people about it',
          value: 'I am already telling people about it',
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'A) Legume yield model',
          disclaimers:
            'A model to estimate legume yield and suitability of forage legumes across New Zealand and their impact on production, environment, climate change and nutrient leaching.',
          value: 'Legume yield model:',
        },
        {
          id: 2,
          hide: true,
          disclaimers:
            'The use of micro-scale indicators (soil temperature and moisture) to classify variable landscapes within farms to help inform which forage mixes are likely to do well in specific areas. ',
          text: 'B) Micro-scale indicators',
          value: 'Micro-scale indicators:',
        },
        {
          id: 3,
          hide: true,
          text: 'C) Measures of natural capital',
          disclaimers:
            'A system for landscape classification that recognises the diversity of landscapes in terms of their financial and non-financial (social and environmental) values at the regional and sub-regional level and the farm level.',
          value: 'Measures of natural capital:',
        },
      ],
    },
    {
      id: 'decision-making-tools-p2-rural',
      hide: true,
      required: true,
      // type: responsiveMatrix,
      type: multiDropdown,
      // tHeader: 'Biodiversity in forage landscapes',
      text: () => {
        return (
          <p>
            Which of these <b>forage biodiversity resources/tools</b> could you describe to a
            farmer?
          </p>
        )
      },
      width: '200px',
      height: '70px',
      marginTop: '2rem',
      boxWidth: '800px',
      useColumns: true,
      useValues: true,
      choices: [
        {
          id: 1,
          hide: true,
          text: 'D) National database of pasture production',
          disclaimers:
            'A forage database that will capture and preserve historic and current New Zealand specific forage data.',
          value: 'National database of pasture production:',
        },
        {
          id: 2,
          hide: true,
          text: 'E) Legume map',
          disclaimers:
            'An on-farm decision making tool that can be used to match a property’s different land management units with the appropriate legume.',
          value: 'Legume map:',
        },
        {
          id: 3,
          hide: true,
          text: 'F) Native plants research',
          disclaimers:
            'Research into the suitability of different native plants for use on farm, for example as sheep fodder.',
          value: 'Native plants:',
        },
        {
          id: 4,
          hide: true,
          text: 'G) Forage selection tool',
          disclaimers:
            'A forage value selection tool for sheep and beef hill country farming systems.',
          value: 'Forage selection tool:',
        },
      ],
      options: [
        {
          id: 1,
          text: "I've never heard of it",
          value: "I've never heard of it",
        },
        {
          id: 2,
          text: "I've heard of it but don't know much about it",
          value: "I've heard of it but don't know much about it",
        },
        {
          id: 3,
          text: 'I know the basics and could probably explain it to someone else',
          value: 'I know the basics and could probably explain it to someone else',
        },
        {
          id: 4,
          hide: true,
          text: "I'm already telling farmers about it",
          value: "I'm already telling farmers about it",
        },
      ],
      columns: [
        {
          id: 1,
          hide: true,
          text: "I've never heard of it",
          value: "I've never heard of it",
        },
        {
          id: 2,
          hide: true,
          text: "I've heard of it but don't know much about it",
          value: "I've heard of it but don't know much about it",
        },
        {
          id: 3,
          hide: true,
          text: 'I know the basics and could probably explain it to someone else',
          value: 'I know the basics and could probably explain it to someone else',
        },
        {
          id: 4,
          hide: true,
          text: "I'm already telling farmers about it",
          value: "I'm already telling farmers about it",
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text: 'D) National database of pasture production',
          disclaimers:
            'A forage database that will capture and preserve historic and current New Zealand specific forage data.',
          value: 'National database of pasture production:',
        },
        {
          id: 2,
          hide: true,
          text: 'E) Legume map',
          disclaimers:
            'An on-farm decision making tool that can be used to match a property’s different land management units with the appropriate legume.',
          value: 'Legume map:',
        },
        {
          id: 3,
          hide: true,
          text: 'F) Native plants research',
          disclaimers:
            'Research into the suitability of different native plants for use on farm, for example as sheep fodder.',
          value: 'Native plants:',
        },
        {
          id: 4,
          hide: true,
          text: 'G) Forage selection tool',
          disclaimers:
            'A forage value selection tool for sheep and beef hill country farming systems.',
          value: 'Forage selection tool:',
        },
      ],
      next: ({ answers }) => {
        if (
          answers['hill-country-resilience-rural'].id === 3 ||
          answers['hill-country-resilience-rural'].id === 4
          // Q2.2
        ) {
          return 'connections-related-to-hill-country-resilience-rural'
        } else if (
          answers['describe-concepts-of-resilience-and-sustainability-rural'].id === 3 ||
          answers['describe-concepts-of-resilience-and-sustainability-rural'].id === 4
          // Q3.1
        ) {
          return 'confidence-related-to-hill-country-resilience-rural'
        }
      },
    },
    {
      id: 'connections-related-to-hill-country-resilience-rural',
      type: multiChoice,
      required: true,
      verticalGrid: true,
      mobileHeight: '2rem',
      mobilePadding: '2.25rem',
      text:
        'If one of your clients had asked you who to talk to about hill country resilience and sustainability (before doing this survey), would you have been able to refer them to someone? ',
      choices: [
        { id: 1, text: 'No, probably not', value: 'No, probably not' },
        {
          id: 2,
          text: 'I could probably figure out who they could talk to',
          value: 'I could probably figure out who they could talk to',
        },
        {
          id: 3,
          text: 'Yes, I would have known who to refer them to',
          value: 'Yes, I would have known who to refer them to',
        },
      ],
      next: ({ answers }) => {
        if (
          answers['connections-related-to-hill-country-resilience-rural'].value ===
          'Yes, I would have known who to refer them to'
        ) {
          return 'connections-related-to-hill-country-resilience-p2-rural'
        } else if (
          answers['describe-concepts-of-resilience-and-sustainability-rural'].id === 3 ||
          answers['describe-concepts-of-resilience-and-sustainability-rural'].id === 4
          // Q3.1
        ) {
          return 'confidence-related-to-hill-country-resilience-rural'
        }
        return 'engage-again'
      },
    },
    {
      id: 'connections-related-to-hill-country-resilience-p2-rural',
      type: multiSelect,
      required: true,
      verticalGrid: true,
      text:
        'Who have you talked to in the past six months about hill country resilience and sustainability?',
      choices: [
        { id: 1, text: 'Advisors and consultants', value: 'Advisors and consultants' },
        { id: 2, text: 'Other farmers', value: 'Other farmers' },
        { id: 3, text: 'Beef + Lamb NZ', value: 'Beef + Lamb NZ' },
        { id: 4, text: 'Other, Please Specify', value: 'Other', type: input },
        {
          id: 5,
          text: "I haven't talked to anyone",
          value: "I haven't talked to anyone",
          submit: true,
        },
      ],
      next: ({ answers }) => {
        if (
          answers['describe-concepts-of-resilience-and-sustainability-rural'].id === 3 ||
          answers['describe-concepts-of-resilience-and-sustainability-rural'].id === 4
          // Q3.1
        ) {
          return 'confidence-related-to-hill-country-resilience-rural'
        }
        return 'engage-again'
      },
    },
    {
      id: 'confidence-related-to-hill-country-resilience-rural',
      type: responsiveMatrix,
      required: true,
      text:
        'If a client asked you for advice on how to identify and implement sustainable practices on farm, how confident would you be to...',
      hide: true,
      width: '200px',
      height: '70px',
      marginTop: '2rem',
      boxWidth: '800px',
      useColumns: true,
      useValues: true,
      choices: [
        {
          id: 1,
          hide: true,
          text:
            'Identify some changes they could make to promote hill country resilience and sustainability',
          value:
            'Identify some changes they could make to promote hill country resilience and sustainability:',
        },
        {
          id: 2,
          hide: true,
          text: 'Help them implement those changes in the next 12 months',
          value: 'Help them implement those changes in the next 12 months:',
        },
      ],
      options: [
        {
          id: 1,
          text: "I couldn't do this yet",
          value: "I couldn't do this yet",
        },
        {
          id: 2,
          text: 'I think I could do this now',
          value: 'I think I could do this now',
        },
        {
          id: 3,
          text: "I'm very confident I could do this now",
          value: "I'm very confident I could do this now",
        },
      ],
      columns: [
        {
          id: 1,
          hide: true,
          text: "I couldn't do this yet",
          value: "I couldn't do this yet",
        },
        {
          id: 2,
          hide: true,
          text: 'I think I could do this now',
          value: 'I think I could do this now',
        },
        {
          id: 3,
          hide: true,
          text: "I'm very confident I could do this now",
          value: "I'm very confident I could do this now",
        },
      ],
      rows: [
        {
          id: 1,
          hide: true,
          text:
            'Identify some changes they could make to promote hill country resilience and sustainability',
          value:
            'Identify some changes they could make to promote hill country resilience and sustainability:',
        },
        {
          id: 2,
          hide: true,
          text: 'Help them implement those changes in the next 12 months',
          value: 'Help them implement those changes in the next 12 months:',
        },
      ],
      // next: ({ answers }) => {
      //   if (
      //     _.find(answers['decision-making-tools'].value, { value: "I'm using it" }) ||
      //     _.find(answers['decision-making-tools-p2'].value, { value: "I'm using it" })
      //   ) {
      //     return 'behavior-change'
      //   }
      //   return 'survey-end'
      // },
    },
    {
      id: 'engage-again',
      type: multiChoice,
      required: true,
      emailInput: true,
      nestedInput: {
        trigger: 'Sure thing!',
        text: 'Email Address',
      },
      noSkip: true,
      inputText: 'What email address can we contact you on in a years’ time?',
      width: '489px',
      text: (
        <Textbox style={{ padding: 0 }}>
          <p style={{ marginBottom: '1rem' }}>
            We are keen to gauge how your awareness and confidence relating to hill country
            resilience and sustainability concepts changes over time.{' '}
          </p>
          <p>Could we follow up with you in a years’ time to participate in a similar survey?</p>
        </Textbox>
      ),
      choices: [
        {
          id: 1,
          text: 'Sure thing!',
          value: 'Sure thing!',
          icon: <IconCheck fill="currentColor" />,
        },
        {
          id: 2,
          text: 'No thanks',
          value: 'No thanks',
          icon: <IconCross fill="currentColor" />,
          skip: true,
        },
      ],
    },
    {
      id: 'survey-end',
      type: endPage,
      text: () => {
        return (
          <Textbox style={{ padding: '0px' }}>
            <h1 style={{ color: 'rgba(0, 0, 0, 0.65) ', fontSize: '18px', fontWeight: 600 }}>
              Thank you for completing the survey! <br />
              <br />
              Your response will help inform efforts to protect and enhance our hill country
              environment.
            </h1>

            {/* <p>Like this survey and want to create your own? Let&apos;s Confer!</p> */}
            <a
              href="https://confer.online"
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: '#E82D7C', textDecoration: 'underline' }}
            >
              <p>Like this survey and want to create your own? Let&apos;s Confer!</p>
            </a>
            <div style={{ fontSize: '18px' }}>
              <img width="255px" src={conferLogo} alt="confer-logo.jpg" />
            </div>
          </Textbox>
        )
      },
    },
  ],
  settings: {
    surveyName: 'hill-country-futures',
    // primaryColour: '#afd135',
    primaryColour: '#05B153',
    // hideButton: true,
    otherColour: '#72a174',
    hideSubmit: true,
    // showQid: true,
    specialEffects: true,
    logoFirstPageOnly: true,
    logoStyle: {
      marginTop: '1rem',
    },
    companyLogo: {
      images: [{ image: HCFLogo }, { image: MBIE }, { image: BeefLamb }],
    },

    logoLocation: 'space-between',
    leftBackgroundImage,
    backgroundImage: leftBackgroundImage,
    progressBar: true,
  },
}

export default HillCountryFutures
