import _, { now } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import w1 from '../../assets/images/conjoint_images/weather_1.svg'
import DaysOff2 from '../../assets/images/GFiD/DaysOff2.png'
import DaysOff3 from '../../assets/images/GFiD/DaysOff3.png'
import DaysOff4 from '../../assets/images/GFiD/DaysOff4.png'
import DaysWorked5 from '../../assets/images/GFiD/DaysWorked5.png'
import DaysWorked5_5 from '../../assets/images/GFiD/DaysWorked5_5.png'
import DaysWorked6 from '../../assets/images/GFiD/DaysWorked6.png'
import FarmAccom from '../../assets/images/GFiD/FarmAccom.png'
import leftBackgroundImage from '../../assets/images/GFiD/gfidBg.jpg'
import Hours40 from '../../assets/images/GFiD/Hours40.png'
import Hours45 from '../../assets/images/GFiD/Hours45.png'
import Hours50 from '../../assets/images/GFiD/Hours50.png'
import Hours55 from '../../assets/images/GFiD/Hours55.png'
import OwnAccom from '../../assets/images/GFiD/OwnAccom.png'
import Salary45k from '../../assets/images/GFiD/Salary45k.png'
import Salary55k from '../../assets/images/GFiD/Salary55k.png'
import Salary65k from '../../assets/images/GFiD/Salary65k.png'
import TeamCultureGood from '../../assets/images/GFiD/TeamCultureGood.png'
import TeamCulturePoor from '../../assets/images/GFiD/TeamCulturePoor.png'
import Town30 from '../../assets/images/GFiD/Town30.png'
import Town5 from '../../assets/images/GFiD/Town5.png'
import {
  commentBox,
  conjointAnalysis,
  endPage,
  imageChoice,
  multiChoice,
  multiDropdown,
  multiSelect,
  multiTextBox,
  welcomePage,
} from '../question-types/QuestionTypes'
import { xlButton } from '../ui/Layout/ButtonContainer'
import scarlattiLogo from '../../assets/images/Scarlatti/scarlattiLogo.svg'
import Salary50k from '../../assets/images/GFiD/Salary50k.png'
import Salary60k from '../../assets/images/GFiD/Salary60k.png'
import Salary70k from '../../assets/images/GFiD/Salary70k.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  font-size: 1rem;
  //   margin: 1rem;
  h1 {
    color: #f9744c;
    font-size: 1.5rem;
    font-weight: bold;
  }
`
const ConjointTitle = styled.p`
  font-weight: 600;
  text-align: left;
`
const ConjointText = styled.div`
  overflow-wrap: anywhere;
`
const ImgContainer = styled.div`
  display: flex;
  margin: 0;
  height: 15rem;
  img {
    padding: 5px;
    svg {
      display: block;
    }
  }
  @media (min-width: 0px) and (max-width: 900px) {
    // height: 7.5rem;
    height: unset;
    width: 100%;
    img {
      object-fit: cover;
    }
  }
`

const TextSign = styled.div`
  //   margin: 8rem 3.5rem 0.5rem auto;
  font-style: italic;
  //   font-size: 0.8rem;
  color: #e4813f;
  margin-top: 3rem;
`

const JobContainer = styled.table`
  //   display: grid;
  //   flex-direction: column;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0.5rem;
  font-size: 1rem;
  text-align: left;
  //   border-spacing: 0 0.5rem;
  //   border-collapse: separate;
`

const JobAttr = styled.tr`
  //   display: grid;
  //   flex-direction: row;
  //   justify-content: space-between;
  text-align: left;
`

const AttrTitle = styled.th`
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 1rem;
`
const AttrValue = styled.td``

const params = new URLSearchParams(window.location.search)
const params_pid = params.get('pid')
const params_psid = params.get('id')

const jobLoopArray = [
  'choice-1',
  'choice-2',
  'choice-3',
  'choice-4',
  'choice-5',
  'choice-6',
  'choice-7',
]

const jobDetails = [
  ['$60,000', '($23 per hour)', 60000, ''],
  ['$65,000', '($25 per hour)', 65000, '$60,000'],
  ['$70,000', '($27 per hour)', 70000, '$65,000'],
  ['$75,000', '($29 per hour)', 75000, '$70,000'],
  ['$80,000', '($31 per hour)', 80000, '$75,000'],
  ['$90,000', '($35 per hour)', 90000, '$80,000'],
  ['$100,000', '($39 per hour)', 100000, '$90,000'],
]

const jobLoopArray2 = ['choice-1', 'choice-2', 'choice-3', 'choice-4', 'choice-5']

const jobDetails2 = [0, 1000, 2000, 5000, 10000, 20000]

const preparedJobs = jobDetails.map(job => (
  <JobContainer style={{ textAlign: 'center' }}>
    {/* <tbody> */}
    <p style={{ marginBottom: '5px' }}>50 hours per week (on average)</p>
    <p style={{ marginBottom: '5px' }}>
      5<sup>1</sup>&frasl;<sub>2</sub> days per week (on average)
    </p>
    <p style={{ marginBottom: '5px' }}>Milking starts 6am</p>
    <p style={{ marginBottom: '5px' }}>You are living on-farm (market rent deducted from pay)</p>
    <p style={{ marginBottom: '5px' }}>Nearest town is 15 minutes from the farm</p>
    {/* <br></br> */}
    {/* <p>You are paid {job[0]} per year </p> */}
    {/* <JobAttr>
        <AttrTitle>Annual pay: </AttrTitle>
        <AttrValue>{job[0]}</AttrValue>
      </JobAttr>
      <JobAttr>
        <AttrTitle>Hours worked per week (on average): </AttrTitle>
        <AttrValue>{job[3]}</AttrValue>
      </JobAttr>
      <JobAttr>
        <AttrTitle>Days worked per week (on average): </AttrTitle>
        <AttrValue>{job[2]}</AttrValue>
      </JobAttr>
      <JobAttr>
        <AttrTitle>Distance to nearest town: </AttrTitle>
        <AttrValue>{job[1]}</AttrValue>
      </JobAttr>
      <p>Nearest town is 15 minutes from the farm</p>
      <JobAttr>
        <AttrTitle>Team culture: </AttrTitle>
        <AttrValue>{job[4]}</AttrValue>
      </JobAttr>
      <JobAttr>
        <AttrTitle>Accommodation: </AttrTitle>
        <AttrValue>{job[5]}</AttrValue>
      </JobAttr> */}
    <p style={{ marginBottom: 0, textAlign: 'center', width: '100%' }}>
      You are paid <del>{job[3]}</del>
      <span style={{ fontSize: '2rem' }}>
        <b>{job[0]}</b>
      </span>{' '}
      {' per year'}
      <br></br>
      {/* <p style={{ visibility: 'hidden', marginBottom: '5px' }}>
        You are paid <del style={{ visibility: 'visible' }}>{job[3]}</del>
      </p> */}
      {job[1]}
    </p>
    {/* </tbody> */}
  </JobContainer>
))

function getJob(answer, loopIndex) {
  console.log('answer', answer)
  console.log('loopIndex', loopIndex)
  switch (loopIndex) {
    case 0:
      return 3
    case 1:
      if (answer[jobLoopArray[0]].id === 1) {
        // Chose offered job - offer worse job
        return 1
      } else {
        // Chose own job
        return 5
      }
    case 2:
      if (answer[jobLoopArray[0]].id === 1 && answer[jobLoopArray[1]].id === 1) {
        // Chose offered job - offer worse job
        return 0
      } else if (answer[jobLoopArray[0]].id === 1 && answer[jobLoopArray[1]].id === 2) {
        // Chose own job - offer better job
        return 2
      } else if (answer[jobLoopArray[0]].id === 2 && answer[jobLoopArray[1]].id === 1) {
        // Chose offered job - offer worse job
        return 4
      } else if (answer[jobLoopArray[0]].id === 2 && answer[jobLoopArray[1]].id === 2) {
        // Chose own job - offer better job
        return 6
      }
  }
}
// const preparedJobs = [
//   <Wrapper>
//     <li>Sala</li>
//     <li>detail#2</li>
//   </Wrapper>,
//   <Wrapper>
//     <li>detail#1</li>
//     <li>detail#2</li>
//   </Wrapper>,
//   <Wrapper>
//     <li>detail#1</li>
//     <li>detail#2</li>
//   </Wrapper>,
//   <Wrapper>
//     <li>detail#1</li>
//     <li>detail#2</li>
//   </Wrapper>,
//   <Wrapper>
//     <li>detail#1</li>
//     <li>detail#2</li>
//   </Wrapper>,
// ]
const tempUserId = uuidv4()

const GFiD = {
  questions: [
		// Closed
		{
			id: 'closed',
			type: welcomePage,
			section: (
				<Wrapper>
					<h1>Know Your Market survey</h1>
					<p>Thank you for your interest in this survey.</p>
					<p>This survey is now closed.</p>
				</Wrapper>
			),
			hideUi: true,
		},
    // Intro
    {
      id: 'intro-page',
      type: welcomePage,
      section: (
        <Wrapper>
          <h1>Know Your Market survey</h1>
          <p>Thank you for your interest in this survey.</p>
          <p>
            We are interested to learn about peoples' job experiences, and what they think of
            working in the dairy farming sector.
          </p>
          <p>
            Please answer the first seven questions to see if you are eligible. Eligible people will
            be asked to continue to an approximately 10-minute survey.
          </p>
          <p>This is a project funded by DairyNZ, and run by research consultancy, Scarlatti.</p>
          <p>
            Learn more about our ethics processes and your rights here:&nbsp;
            <a
              href="https://www.dropbox.com/s/w6vxb6y29b3y2ze/Round%202%20-%20information%20sheet_v3_1%20%28PG%29.pdf?dl=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              information sheet
            </a>
            .
          </p>
          <p>
            For any questions, please contact:
            <br></br>&emsp;&emsp;Phoebe Gill
            <br></br>&emsp;&emsp;Associate Researcher
            <br></br>&emsp;&emsp;phoebe.gill@scarlatti.co.nz
            <br></br>&emsp;&emsp;021300725
          </p>
          <p>-The team at Scarlatti&nbsp;</p>
          <a href="https://www.scarlatti.co.nz/" target="_blank" rel="noopener noreferrer">
            <img style={{ height: 60 }} src={scarlattiLogo} alt="Scarlatti Logo" />
          </a>

          <em>
            This survey may not be compatible with all cellphones, if you have any problems, please
            use your computer.
          </em>
        </Wrapper>
      ),
      // next: () => '7_1_preferred_voucher',
    },
    // 0. Screening Questions
    // 0.1 Age
    {
      id: '0_1_age',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Screening Questions</h1>
          <p>How old are you?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Under 18', value: 'Under 18' },
        { id: 2, text: '18-24', value: '18-24' },
        { id: 3, text: '25-29', value: '25-29' },
        { id: 4, text: '30-34', value: '30-34' },
        { id: 5, text: '35-39', value: '35-39' },
        { id: 6, text: '40-44', value: '40-44' },
        { id: 7, text: '45-49', value: '45-49' },
        { id: 8, text: '50-54', value: '50-54' },
        { id: 9, text: '55-59', value: '55-59' },
        { id: 10, text: '60-64', value: '60-64' },
        { id: 11, text: '65+', value: '65+' },
        { id: 12, text: 'Prefer not to say', value: 'Prefer not to say' },
      ],
    },
    // 0.2 Address Classification
    {
      id: '0_2_address_classification',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Screening Questions</h1>
          <p>Which best describes where you live?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'I live in the periphery of a big city, less than 15 minutes to countryside',
          value: 'I live in the periphery of a big city, less than 15 minutes to countryside',
        },
        {
          id: 2,
          text: 'I live in a big city, more than 15 minutes to countryside',
          value: 'I live in a big city, more than 15 minutes to countryside',
        },
        {
          id: 3,
          text: 'I live in a large regional centre (like Invercargill, Gisborne, Queenstown)',
          value: 'I live in a large regional centre (like Invercargill, Gisborne, Queenstown)',
        },
        {
          id: 4,
          text: 'I live in a small to medium town (like Bulls, Paeroa, Methven)',
          value: 'I live in a small to medium town (like Bulls, Paeroa, Methven)',
        },
        {
          id: 5,
          text: 'I live in a small town or rural location',
          value: 'I live in a small town or rural location',
        },
        { id: 6, text: 'Prefer not to say', value: 'Prefer not to say' },
      ],
    },
    // 0.3 Occupation
    {
      id: '0_3_occupation',
      type: multiDropdown,
      section: (
        <Wrapper>
          <h1>Screening Questions</h1>
          <p>The following options are quite broad. Which group is closest to your occupation?</p>
        </Wrapper>
      ),
      required: true,
      choices: [{ id: 1, text: '' }],
      options: [
        {
          id: 1,
          text: 'CEO, general manager, or senior executive',
          value: 'CEO, general manager, or senior executive',
        },
        { id: 2, text: 'Farmer manager or owner', value: 'Farmer manager or owner' },
        { id: 3, text: 'Other professional manager', value: 'Other professional manager' },
        {
          id: 4,
          text: 'Professional (education, health, ICT, legal, …)',
          value: 'Professional (education, health, ICT, legal, …)',
        },
        {
          id: 5,
          text: 'Technician (engineering, ICT, science, …)',
          value: 'Technician (engineering, ICT, science, …)',
        },
        {
          id: 6,
          text: 'Automotive and engineering trades worker',
          value: 'Automotive and engineering trades worker',
        },
        {
          id: 7,
          text: 'Construction worker (builder, painter…)',
          value: 'Construction worker (builder, painter…)',
        },
        {
          id: 8,
          text: 'Electrician, electronics, telecommunication tradesperson',
          value: 'Electrician, electronics, telecommunication tradesperson',
        },
        {
          id: 9,
          text: 'Food tradesperson (baker, butcher, chef, …)',
          value: 'Food tradesperson (baker, butcher, chef, …)',
        },
        {
          id: 10,
          text: 'Horticulture worker (grower, landscaper, gardener, …)',
          value: 'Horticulture worker (grower, landscaper, gardener, …)',
        },
        {
          id: 11,
          text: 'Animal worker (trainer, attendant, shearer, …)',
          value: 'Animal worker (trainer, attendant, shearer, …)',
        },
        {
          id: 12,
          text: 'Health and welfare support worker (ambulance, dental, …)',
          value: 'Health and welfare support worker (ambulance, dental, …)',
        },
        { id: 13, text: 'Carer or aide', value: 'Carer or aide' },
        { id: 14, text: 'Hospitality worker', value: 'Hospitality worker' },
        {
          id: 15,
          text: 'Protective or security worker (police, guard, firefighter, …)',
          value: 'Protective or security worker (police, guard, firefighter, …)',
        },
        {
          id: 16,
          text: 'Sports or personal service worker (beautician, personal trainer, guide, …)',
          value: 'Sports or personal service worker (beautician, personal trainer, guide, …)',
        },
        {
          id: 17,
          text: 'Administrative or clerical worker',
          value: 'Administrative or clerical worker',
        },
        { id: 18, text: 'Retail worker or salesperson', value: 'Retail worker or salesperson' },
        { id: 19, text: 'Plant or machine operator', value: 'Plant or machine operator' },
        { id: 20, text: 'Driver', value: 'Driver' },
        { id: 21, text: 'Store person', value: 'Store person' },
        { id: 22, text: 'Cleaner or laundry worker', value: 'Cleaner or laundry worker' },
        {
          id: 23,
          text: 'Construction or mining labourer',
          value: 'Construction or mining labourer',
        },
        { id: 24, text: 'Factory worker', value: 'Factory worker' },
        { id: 25, text: 'Forestry worker', value: 'Forestry worker' },
        { id: 26, text: 'Garden worker or landscaper', value: 'Garden worker or landscaper' },
        { id: 27, text: 'Aquaculture or fishing worker', value: 'Aquaculture or fishing worker' },
        { id: 28, text: 'Farm worker', value: 'Farm worker' },
        {
          id: 29,
          text: 'Food preparation worker (fast food cook, food trades assistants, kitchenhand, …)',
          value: 'Food preparation worker (fast food cook, food trades assistants, kitchenhand, …)',
        },
        { id: 30, text: 'Unemployed', value: 'Unemployed' },
        { id: 31, text: 'Other, please specify', value: 'Other, please specify' },
      ],
      next: ({ answers }) => {
        if (answers['0_3_occupation'].value[0].value === 'Other, please specify') {
          return '0_3a_occupation_other'
        }
        return '0_4_occupation_tenure'
      },
    },
    // 0.3a Occupation - Other
    {
      id: '0_3a_occupation_other',
      type: multiTextBox,
      section: (
        <Wrapper>
          <h1>Screening Questions</h1>
          <p>Please specify your occupation</p>
        </Wrapper>
      ),
      required: true,
      choices: [{ id: 1, text: 'Occupation:', value: '' }],
      buttonSize: xlButton.width,
    },
    // 0.4 Occupation tenure
    {
      id: '0_4_occupation_tenure',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Screening Questions</h1>
          <p>How many years have you been in this occupation?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Less than 6 months', value: 'Less than 6 months' },
        { id: 2, text: '6 months to 1 year', value: '6 months to 1 year' },
        { id: 3, text: '1 to 2 years', value: '1 to 2 years' },
        { id: 4, text: '2 to 5 years', value: '2 to 5 years' },
        { id: 5, text: '6 to 7 years', value: '6 to 7 years' },
        { id: 6, text: 'More than 7 years', value: 'More than 7 years' },
      ],
    },
    // 0.5 Farm
    {
      id: '0_5_farm',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Screening Questions</h1>
          <p>How much do you know about farming (dairy, sheep, beef)?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'I work on a farm', value: 'I work on a farm' },
        {
          id: 2,
          text:
            "I know quite a lot about farming (e.g., I grew up on a farm, I used to work on a farm, but don't now)",
          value:
            "I know quite a lot about farming (e.g., I grew up on a farm, I used to work on a farm, but don't now)",
        },
        {
          id: 3,
          text:
            'I know a little bit about farming (e.g., I have friends that work on a farm, I work with farmers)',
          value:
            'I know a little bit about farming (e.g., I have friends that work on a farm, I work with farmers)',
        },
        {
          id: 4,
          text: 'I know almost nothing about farming',
          value: 'I know almost nothing about farming',
        },
      ],
      buttonSize: xlButton.width,
    },
    // 0.6 Pay frequency
    {
      id: '0_6_pay_frequency',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Screening Questions</h1>
          <p>Are you paid an annual salary or by the hour?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Annual salary', value: 'Annual salary' },
        { id: 2, text: 'By the hour', value: 'By the hour' },
      ],
      next: ({ answers }) => {
        if (answers['0_6_pay_frequency'].value === 'Annual salary') {
          return '0_7b_current_salary'
        }
        return '0_7_current_wage'
      },
    },
    // 0.7 Current wage
    {
      id: '0_7_current_wage',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Screening Questions</h1>
          <p>How much are you currently paid per hour (assuming a 40-hour week)?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Less than $23 per hour', value: 'Less than $23 per hour' },
        { id: 2, text: '$23-25 per hour', value: '$23-25 per hour' },
        { id: 3, text: '$25-27 per hour', value: '$25-27 per hour' },
        { id: 4, text: '$27-30 per hour', value: '$27-30 per hour' },
        { id: 5, text: '$30-35 per hour', value: '$30-35 per hour' },
        { id: 6, text: '$35-40 per hour', value: '$35-40 per hour' },
        { id: 7, text: '$40-50 per hour', value: '$40-50 per hour' },
        { id: 8, text: 'Prefer not to say', value: 'Prefer not to say' },
      ],
      next: ({ answers }) => {
        if (
          (answers['0_1_age'].value === '18-24' ||
            answers['0_1_age'].value === '25-29' ||
            answers['0_1_age'].value === '30-34') &&
          (answers['0_2_address_classification'].value ===
            'I live in the periphery of a big city, less than 15 minutes to countryside' ||
            answers['0_2_address_classification'].value ===
              'I live in a large regional centre (like Invercargill, Gisborne, Queenstown)' ||
            answers['0_2_address_classification'].value ===
              'I live in a small to medium town (like Bulls, Paeroa, Methven)' ||
            answers['0_2_address_classification'].value ===
              'I live in a small town or rural location') &&
          (answers['0_3_occupation'].value[0].value ===
            'Technician (engineering, ICT, science, …)' ||
            answers['0_3_occupation'].value[0].value ===
              'Automotive and engineering trades worker' ||
            answers['0_3_occupation'].value[0].value ===
              'Construction worker (builder, painter…)' ||
            answers['0_3_occupation'].value[0].value ===
              'Electrician, electronics, telecommunication tradesperson' ||
            answers['0_3_occupation'].value[0].value ===
              'Food tradesperson (baker, butcher, chef, …)' ||
            answers['0_3_occupation'].value[0].value ===
              'Horticulture worker (grower, landscaper, gardener, …)' ||
            answers['0_3_occupation'].value[0].value ===
              'Animal worker (trainer, attendant, shearer, …)' ||
            answers['0_3_occupation'].value[0].value ===
              'Health and welfare support worker (ambulance, dental, …)' ||
            answers['0_3_occupation'].value[0].value === 'Carer or aide' ||
            answers['0_3_occupation'].value[0].value === 'Hospitality worker' ||
            answers['0_3_occupation'].value[0].value ===
              'Protective or security worker (police, guard, firefighter, …)' ||
            answers['0_3_occupation'].value[0].value ===
              'Sports or personal service worker (beautician, personal trainer, guide, …)' ||
            answers['0_3_occupation'].value[0].value === 'Administrative or clerical worker' ||
            answers['0_3_occupation'].value[0].value === 'Retail worker or salesperson' ||
            answers['0_3_occupation'].value[0].value === 'Plant or machine operator' ||
            answers['0_3_occupation'].value[0].value === 'Driver' ||
            answers['0_3_occupation'].value[0].value === 'Store person' ||
            answers['0_3_occupation'].value[0].value === 'Cleaner or laundry worker' ||
            answers['0_3_occupation'].value[0].value === 'Construction or mining labourer' ||
            answers['0_3_occupation'].value[0].value === 'Factory worker' ||
            answers['0_3_occupation'].value[0].value === 'Forestry worker' ||
            answers['0_3_occupation'].value[0].value === 'Garden worker or landscaper' ||
            answers['0_3_occupation'].value[0].value === 'Aquaculture or fishing worker' ||
            answers['0_3_occupation'].value[0].value ===
              'Food preparation worker (fast food cook, food trades assistants, kitchenhand, …)' ||
            answers['0_3_occupation'].value[0].value === 'Other, please specify') &&
          (answers['0_4_occupation_tenure'].value === 'Less than 6 months' ||
            answers['0_4_occupation_tenure'].value === '6 months to 1 year' ||
            answers['0_4_occupation_tenure'].value === '1 to 2 years' ||
            answers['0_4_occupation_tenure'].value === '2 to 5 years' ||
            answers['0_4_occupation_tenure'].value === '6 to 7 years') &&
          (answers['0_5_farm'].value ===
            "I know quite a lot about farming (e.g., I grew up on a farm, I used to work on a farm, but don't now)" ||
            answers['0_5_farm'].value ===
              'I know a little bit about farming (e.g., I have friends that work on a farm, I work with farmers)' ||
            answers['0_5_farm'].value === 'I know almost nothing about farming') &&
          (answers['0_7_current_wage'].value === 'Less than $23 per hour' ||
            answers['0_7_current_wage'].value === '$23-25 per hour' ||
            answers['0_7_current_wage'].value === '$25-27 per hour' ||
            answers['0_7_current_wage'].value === '$27-30 per hour' ||
            answers['0_7_current_wage'].value === '$30-35 per hour' ||
            answers['0_7_current_wage'].value === '$35-40 per hour')
        ) {
          return 'intro_1'
        }
        return 'end-page-ineligible'
      },
    },
    // 0.7b Current salary
    {
      id: '0_7b_current_salary',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Screening Questions</h1>
          <p>How much are you currently paid annually?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Less than $48k', value: 'Less than $48k' },
        { id: 2, text: '$48-52k', value: '$48-52k' },
        { id: 3, text: '$52-55k', value: '$52-55k' },
        { id: 4, text: '$55-60k', value: '$55-60k' },
        { id: 5, text: '$60-70k', value: '$60-70k' },
        { id: 6, text: '$70-80k', value: '$70-80k' },
        { id: 7, text: '$80-100k', value: '$80-100k' },
        { id: 8, text: 'More than $100k', value: 'More than $100k' },
        { id: 9, text: 'Prefer not to say', value: 'Prefer not to say' },
      ],
      next: ({ answers }) => {
        if (
          (answers['0_1_age'].value === '18-24' ||
            answers['0_1_age'].value === '25-29' ||
            answers['0_1_age'].value === '30-34') &&
          (answers['0_2_address_classification'].value ===
            'I live in the periphery of a big city, less than 15 minutes to countryside' ||
            answers['0_2_address_classification'].value ===
              'I live in a large regional centre (like Invercargill, Gisborne, Queenstown)' ||
            answers['0_2_address_classification'].value ===
              'I live in a small to medium town (like Bulls, Paeroa, Methven)' ||
            answers['0_2_address_classification'].value ===
              'I live in a small town or rural location') &&
          (answers['0_3_occupation'].value[0].value ===
            'Technician (engineering, ICT, science, …)' ||
            answers['0_3_occupation'].value[0].value ===
              'Automotive and engineering trades worker' ||
            answers['0_3_occupation'].value[0].value ===
              'Construction worker (builder, painter…)' ||
            answers['0_3_occupation'].value[0].value ===
              'Electrician, electronics, telecommunication tradesperson' ||
            answers['0_3_occupation'].value[0].value ===
              'Food tradesperson (baker, butcher, chef, …)' ||
            answers['0_3_occupation'].value[0].value ===
              'Horticulture worker (grower, landscaper, gardener, …)' ||
            answers['0_3_occupation'].value[0].value ===
              'Animal worker (trainer, attendant, shearer, …)' ||
            answers['0_3_occupation'].value[0].value ===
              'Health and welfare support worker (ambulance, dental, …)' ||
            answers['0_3_occupation'].value[0].value === 'Carer or aide' ||
            answers['0_3_occupation'].value[0].value === 'Hospitality worker' ||
            answers['0_3_occupation'].value[0].value ===
              'Protective or security worker (police, guard, firefighter, …)' ||
            answers['0_3_occupation'].value[0].value ===
              'Sports or personal service worker (beautician, personal trainer, guide, …)' ||
            answers['0_3_occupation'].value[0].value === 'Administrative or clerical worker' ||
            answers['0_3_occupation'].value[0].value === 'Retail worker or salesperson' ||
            answers['0_3_occupation'].value[0].value === 'Plant or machine operator' ||
            answers['0_3_occupation'].value[0].value === 'Driver' ||
            answers['0_3_occupation'].value[0].value === 'Store person' ||
            answers['0_3_occupation'].value[0].value === 'Cleaner or laundry worker' ||
            answers['0_3_occupation'].value[0].value === 'Construction or mining labourer' ||
            answers['0_3_occupation'].value[0].value === 'Factory worker' ||
            answers['0_3_occupation'].value[0].value === 'Forestry worker' ||
            answers['0_3_occupation'].value[0].value === 'Garden worker or landscaper' ||
            answers['0_3_occupation'].value[0].value === 'Aquaculture or fishing worker' ||
            answers['0_3_occupation'].value[0].value ===
              'Food preparation worker (fast food cook, food trades assistants, kitchenhand, …)' ||
            answers['0_3_occupation'].value[0].value === 'Other, please specify') &&
          (answers['0_4_occupation_tenure'].value === 'Less than 6 months' ||
            answers['0_4_occupation_tenure'].value === '6 months to 1 year' ||
            answers['0_4_occupation_tenure'].value === '1 to 2 years' ||
            answers['0_4_occupation_tenure'].value === '2 to 5 years' ||
            answers['0_4_occupation_tenure'].value === '6 to 7 years') &&
          (answers['0_5_farm'].value ===
            "I know quite a lot about farming (e.g., I grew up on a farm, I used to work on a farm, but don't now)" ||
            answers['0_5_farm'].value ===
              'I know a little bit about farming (e.g., I have friends that work on a farm, I work with farmers)' ||
            answers['0_5_farm'].value === 'I know almost nothing about farming') &&
          (answers['0_7b_current_salary'].value === 'Less than $48k' ||
            answers['0_7b_current_salary'].value === '$48-52k' ||
            answers['0_7b_current_salary'].value === '$52-55k' ||
            answers['0_7b_current_salary'].value === '$55-60k' ||
            answers['0_7b_current_salary'].value === '$60-70k')
        ) {
          return 'intro_1'
        }
        return 'end-page-ineligible'
      },
    },
    // 1. About you
    // 1.0 Section Intro
    {
      id: 'intro_1',
      type: welcomePage,
      section: (
        <Wrapper>
          <h1>Screening Questions</h1>
          <p>That is great - you are eligible.</p>
          <p>
            Click next to continue with the paid survey - we estimate that this should take 10
            minutes.
          </p>
        </Wrapper>
      ),
      hidePreviousButton: true,
    },
    // 1.1 Gender
    {
      id: '1_1_gender',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>About you</h1>
          <p>What gender do you identify with?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Male', value: 'Male' },
        { id: 2, text: 'Female', value: 'Female' },
        { id: 3, text: 'Another gender', value: 'Another gender' },
        { id: 4, text: 'Prefer not to say', value: 'Prefer not to say' },
      ],
    },
    // 1.2 Ethnicity
    {
      id: '1_2_ethnicity',
      type: multiSelect,
      section: (
        <Wrapper>
          <h1>About you</h1>
          <p>What ethnicity, or ethnicities, do you identify with?</p>
          <em>Tick all that apply</em>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Māori', value: 'Māori' },
        { id: 2, text: 'New Zealand European / Pākehā', value: 'New Zealand European / Pākehā' },
        { id: 3, text: 'Pacific People', value: 'Pacific People' },
        { id: 4, text: 'Asian', value: 'Asian' },
        {
          id: 5,
          text: 'Middle Eastern / Latin American / African',
          value: 'Middle Eastern / Latin American / African',
        },
        {
          id: 6,
          type: 'input',
          text: 'Other ethnicity, please specify',
          value: '',
        },
        { id: 7, text: 'Prefer not to say', value: 'Prefer not to say', submit: true },
      ],
    },
    // 1.3 Region
    {
      id: '1_3_region',
      type: multiDropdown,
      section: (
        <Wrapper>
          <h1>About you</h1>
          <p>Which region do you live in?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: '',
        },
      ],
      options: [
        { id: 1, text: 'Northland / Te Tai Tokerau', value: 'Northland / Te Tai Tokerau' },
        { id: 2, text: 'Auckland / Tāmaki Makaurau', value: 'Auckland / Tāmaki Makaurau' },
        { id: 3, text: 'Waikato', value: 'Waikato' },
        { id: 4, text: 'Bay of Plenty / Te Moana-a-Toi', value: 'Bay of Plenty / Te Moana-a-Toi' },
        { id: 5, text: 'Gisborne / Te Tai Rāwhiti', value: 'Gisborne / Te Tai Rāwhiti' },
        { id: 6, text: "Hawke's Bay / Te Matau-a-Māui", value: "Hawke's Bay / Te Matau-a-Māui" },
        { id: 7, text: 'Taranaki', value: 'Taranaki' },
        { id: 8, text: 'Whanganui - Manawatu', value: 'Whanganui - Manawatu' },
        {
          id: 9,
          text: 'Wellington / Te Whanganui-a-Tara',
          value: 'Wellington / Te Whanganui-a-Tara',
        },
        {
          id: 10,
          text: 'Marlborough / Te Tauihu-o-te-waka',
          value: 'Marlborough / Te Tauihu-o-te-waka',
        },
        { id: 11, text: 'Nelson - Tasman / Whakatū', value: 'Nelson - Tasman / Whakatū' },
        { id: 12, text: 'West Coast / Te Tai Poutini', value: 'West Coast / Te Tai Poutini' },
        { id: 13, text: 'Canterbury / Waitaha', value: 'Canterbury / Waitaha' },
        { id: 14, text: 'Otago / Ōtākou', value: 'Otago / Ōtākou' },
        { id: 15, text: 'Southland / Murihiku', value: 'Southland / Murihiku' },
      ],
    },
    // 1.4 Qualification
    {
      id: '1_4_qualification',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>About you</h1>
          <p>What is your highest level of qualification?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'No qualification', value: 'No qualification' },
        {
          id: 2,
          text: 'High school qualification (e.g., NCEA)',
          value: 'High school qualification (e.g., NCEA)',
        },
        {
          id: 3,
          text: 'Certificate/Diploma or equivalent',
          value: 'Certificate/Diploma or equivalent',
        },
        {
          id: 4,
          text: "Bachelor's degree or equivalent",
          value: "Bachelor's degree or equivalent",
        },
        {
          id: 5,
          text: 'Postgraduate (e.g., Masters, Doctorate)',
          value: 'Postgraduate (e.g., Masters, Doctorate)',
        },
      ],
    },
    // 1.5 Hours of work
    {
      id: '1_5_hours_of_work',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>About you</h1>
          <p>During a typical work week, how many hours do you work?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Less than 40 hours per week', value: 'Less than 40 hours per week' },
        { id: 2, text: '40-45 hours', value: '40-45 hours' },
        { id: 3, text: '45-50 hours', value: '45-50 hours' },
        { id: 4, text: '50-55 hours', value: '50-55 hours' },
        { id: 5, text: 'More than 55 hours', value: 'More than 55 hours' },
        { id: 6, text: 'Prefer not to say', value: 'Prefer not to say' },
      ],
    },
    // 1.6 Days of work
    {
      id: '1_6_days_of_work',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>About you</h1>
          <p>During a typical work week, how many days do you work?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Less than 5 days', value: 'Less than 5 days' },
        {
          id: 2,
          text: '5 days (e.g., work weekdays, have weekends off)',
          value: '5 days (e.g., work weekdays, have weekends off)',
        },
        {
          id: 3,
          text: '5 and a half days (e.g., work Monday to Friday, and every second Saturday)',
          value: '5 and a half days (e.g., work Monday to Friday, and every second Saturday)',
        },
        {
          id: 4,
          text: '6 days (e.g., work Monday to Saturday)',
          value: '6 days (e.g., work Monday to Saturday)',
        },
        { id: 5, text: 'More than 6 days', value: 'More than 6 days' },
      ],
    },
    // 1.7 Farming expertise
    // {
    //   id: '1_7_farming_expertise',
    //   type: multiChoice,
    //   section: (
    //     <Wrapper>
    //       <h1>About you</h1>
    //       <p>How much do you know about farming?</p>
    //     </Wrapper>
    //   ),
    //   required: true,
    //   choices: [
    //     {
    //       id: 1,
    //       text:
    //         'I know quite a lot about farming (e.g. I grew up on a farm, I used to work on a farm, but don\'t now)',
    //       value:
    //         'I know quite a lot about farming (e.g. I grew up on a farm, I used to work on a farm, but don\'t now)',
    //     },
    //     {
    //       id: 2,
    //       text:
    //         'I know a little bit about farming (e.g. I have friends that work on a farm, I work with farmers)',
    //       value:
    //         'I know a little bit about farming (e.g. I have friends that work on a farm, I work with farmers)',
    //     },
    //     {
    //       id: 3,
    //       text: 'I know almost nothing about farming',
    //       value: 'I know almost nothing about farming',
    //     },
    //   ],
    //   buttonSize: xlButton.width,
    // },
    // // 1.6 Remuneration
    // {
    //   id: '1_6_remuneration',
    //   type: multiChoice,
    //   section: (
    //     <Wrapper>
    //       <h1>About you</h1>
    //       <p>How would you prefer to indicate your remuneration?</p>
    //     </Wrapper>
    //   ),
    //   required: true,
    //   choices: [
    //     { id: 1, text: 'Annual salary', value: 'Annual salary' },
    //     { id: 2, text: 'Hourly rate', value: 'Hourly rate' },
    //   ],
    // },
    // // 1.7 Accommodation size
    // {
    //   id: '1_7_accommodation_size',
    //   type: multiChoice,
    //   section: (
    //     <Wrapper>
    //       <h1>About you</h1>
    //       <p>How big would your accommodation have to be if you were to move for a new job?</p>
    //     </Wrapper>
    //   ),
    //   required: true,
    //   choices: [
    //     { id: 1, text: '1 bedroom', value: '1 bedroom' },
    //     { id: 2, text: '2 bedrooms', value: '2 bedrooms' },
    //     { id: 3, text: '3 bedrooms', value: '3 bedrooms' },
    //     { id: 4, text: 'More than 3 bedrooms', value: 'More than 3 bedrooms' },
    //     { id: 5, text: 'Prefer not to say', value: 'Prefer not to say' },
    //   ],
    // },
    // // 1.10 Days off
    // {
    //   id: '1_10_days_off',
    //   type: multiChoice,
    //   section: (
    //     <Wrapper>
    //       <h1>About you</h1>
    //       <p>
    //         How many <b>days off</b> do you get per fortnight?
    //       </p>
    //     </Wrapper>
    //   ),
    //   required: true,
    //   choices: [
    //     { id: 1, text: '1 day', value: '1 day' },
    //     { id: 2, text: '2 days', value: '2 days' },
    //     { id: 3, text: '3 days', value: '3 days' },
    //     { id: 4, text: '4 days', value: '4 days' },
    //     { id: 5, text: 'More than 4 days', value: 'More than 4 days' },
    //     { id: 6, text: 'Prefer not to say', value: 'Prefer not to say' },
    //   ],
    // },
    // // 1.11 Team culture
    // {
    //   id: '1_11_team_culture',
    //   type: multiSelect,
    //   section: (
    //     <Wrapper>
    //       <h1>About you</h1>
    //       <p>According to you, what makes a good team culture?</p>
    //       <p>
    //         <i>Tick up to two</i>
    //       </p>
    //     </Wrapper>
    //   ),
    //   required: true,
    //   max: 2,
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'Management tries to mitigate stress and pressure',
    //       value: 'Management tries to mitigate stress and pressure',
    //     },
    //     { id: 2, text: 'Worked hours are flexible', value: 'Worked hours are flexible' },
    //     { id: 3, text: 'Tasks are varied', value: 'Tasks are varied' },
    //     {
    //       id: 4,
    //       text: 'Training opportunities are provided to you',
    //       value: 'Training opportunities are provided to you',
    //     },
    //     {
    //       id: 5,
    //       text: 'Management is looking out for your career development',
    //       value: 'Management is looking out for your career development',
    //     },
    //     {
    //       id: 6,
    //       text: 'Social events are promoted and funded',
    //       value: 'Social events are promoted and funded',
    //     },
    //     {
    //       id: 7,
    //       text: 'Prefer not to say',
    //       value: 'Prefer not to say',
    //       submit: true,
    //     },
    //   ],
    // },
    // 2.0 Section Intro
    // {
    //   id: 'intro_2',
    //   type: welcomePage,
    //   section: (
    //     <Wrapper>
    //       <h1>Section 2 Introduction</h1>
    //       <p>This is section 2 introduction. </p>
    //     </Wrapper>
    //   ),
    // },
    // 2 Work preferences
    // 2.1 Farming expertise (again)
    // {
    //   id: '2_1_farming_expertise',
    //   type: multiChoice,
    //   section: (
    //     <Wrapper>
    //       <h1>Thoughts on a dairy farm job</h1>
    //       <p>Which best describes how much do you know about farming?</p>
    //     </Wrapper>
    //   ),
    //   required: true,
    //   choices: [
    //     {
    //       id: 1,
    //       text:
    //         'I know quite a lot about farming (e.g. I grew up on a farm, I used to work on a farm, but don\'t now)',
    //       value:
    //         'I know quite a lot about farming (e.g. I grew up on a farm, I used to work on a farm, but don\'t now)',
    //     },
    //     {
    //       id: 2,
    //       text:
    //         'I know a little bit about farming (e.g. I have friends that work on a farm, I work with farmers)',
    //       value:
    //         'I know a little bit about farming (e.g. I have friends that work on a farm, I work with farmers)',
    //     },
    //     {
    //       id: 3,
    //       text: 'I know almost nothing about farming',
    //       value: 'I know almost nothing about farming',
    //     },
    //   ],
    //   buttonSize: xlButton.width,
    // },
    // 2.2 Ideal payment frequency
    {
      id: '2_2_ideal_payment_frequency',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Thoughts on a dairy farm job</h1>
          <p>If you took a dairy farming job, how would you prefer to be paid?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'An hourly wage', value: 'An hourly wage' },
        { id: 2, text: 'An annual salary', value: 'An annual salary' },
        // {
        //   id: 3,
        //   type: 'input',
        //   text: 'Other, please specify',
        //   value: '',
        // },
      ],
    },
    // 2.3 Good workplace
    {
      id: '2_3_good_workplace',
      type: multiSelect,
      section: (
        <Wrapper>
          <h1>Thoughts on a dairy farm job</h1>
          <p>According to you, what is important in a good workplace? Pick your top two</p>
        </Wrapper>
      ),
      required: true,
      max: 2,
      choices: [
        { id: 1, text: 'Flexible work hours', value: 'Flexible work hours' },
        { id: 2, text: 'A manager who cares about you', value: 'A manager who cares about you' },
        {
          id: 3,
          text: 'Career development (e.g., training opportunities)',
          value: 'Career development (e.g., training opportunities)',
        },
        {
          id: 4,
          text: 'Opportunity for social connection',
          value: 'Opportunity for social connection',
        },
      ],
    },
    // 3 What is most important to you?
    // 3.0 Section Intro
    {
      id: '3_conjoint_intro',
      type: welcomePage,
      section: (
        <Wrapper>
          <h1>Which combination would you prefer?</h1>
          <p>
            {' '}
            The next series of questions is aimed at understanding what is most important to you in
            a dairy farm job.{' '}
          </p>
          <p>
            If you don't know much about dairy, each job is different, but here is what a typical
            job might be like:
          </p>
          <ul style={{ marginLeft: 17 }}>
            <li>Work outdoors</li>
            <li>Work with your hands, animals, vehicles and machinery</li>
            <li>Be part of a team and a rural community</li>
            <li>
              Live on-farm, in a house rented to you by your employer - or find accommodation nearby
            </li>
            <li>Begin work early - normally before 6am</li>
            <li>Milking cows is a big part of your job</li>
            <li>Work on a roster (including some weekends) - as cows need to be milked daily</li>
            <li>Work long hours</li>
            <li>Live close to town or remotely - depending on the farm</li>
          </ul>
          <p>
            The next questions ask you to make a choice between two combinations of the following
            job attributes:
          </p>
          <ul style={{ marginLeft: 17 }}>
            <li>Salary</li>
            <li>Hours of work</li>
            <li>Number of days off</li>
            <li>Distance from town</li>
            <li>Workplace culture</li>
          </ul>
          <p>You will be asked to make about 10 choices.</p>
        </Wrapper>
      ),
    },
    // 3.1 Job attribute trade-off
    {
      id: '3_conjoint_jobs',
      type: conjointAnalysis,
      section: (
        <Wrapper>
          <h1>Which combination would you prefer?</h1>
        </Wrapper>
      ),
      hideUi: true,
      showProgress: true,
      choices: 4,
      maxChoices: 10,
      required: true,
      buttonSize: xlButton.width,
      useIcon: false,
      useImage: true,
      // width: '100%',
      levelsTextAlign: 'left',
      removeInfo: true, // removes text on right
      choices: ({ answers }) => {
        let defaultChoices = [
          {
            id: 1,
            value: 'Annual salary',
            icon: (
              <ImgContainer>
                <img style={{ height: 'inherit', width: '100%' }} src={w1} />
              </ImgContainer>
            ),
            levels: [
              { id: 1, value: 'level 3', text: 'Annual salary level 3' },
              { id: 2, value: 'level 2', text: 'Annual salary level 2' },
              { id: 3, value: 'level 1', text: 'Annual salary level 1' },
            ],
          },
          {
            id: 2,
            value: 'Distance to town from your house',
            icon: (
              <ImgContainer>
                <img style={{ height: 'inherit', width: '100%' }} src={w1} />
              </ImgContainer>
            ),
            levels: [
              { id: 1, value: 'level 3', text: 'Distance to town level 3' },
              { id: 2, value: 'level 2', text: 'Distance to town level 2' },
            ],
          },
          {
            id: 3,
            value: 'Worked days per week',
            icon: (
              <ImgContainer>
                <img style={{ height: 'inherit', width: '100%' }} src={w1} />
              </ImgContainer>
            ),
            levels: [
              { id: 1, value: 'level 3', text: 'Worked days level 3' },
              { id: 2, value: 'level 2', text: 'Worked days level 2' },
              { id: 3, value: 'level 1', text: 'Worked days level 1' },
            ],
          },
          {
            id: 4,
            value: 'Worked hours (h/week) – Full-time',
            icon: (
              <ImgContainer>
                <img style={{ height: 'inherit', width: '100%' }} src={w1} />
              </ImgContainer>
            ),
            levels: [
              { id: 1, value: 'level 3', text: 'Worked hours level 3' },
              { id: 2, value: 'level 2', text: 'Worked hours level 2' },
              { id: 3, value: 'level 1', text: 'Worked hours level 1' },
            ],
          },
          // {
          //   id: 5,
          //   value: 'Accommodation',
          //   icon: (
          //     <ImgContainer>
          //       <img style={{ height: 'inherit' }} src={w1} />
          //     </ImgContainer>
          //   ),
          //   levels: [
          //     { id: 1, value: 'level 3', text: 'Accommodation level 3' },
          //     { id: 2, value: 'level 2', text: 'Accommodation level 2' },
          //   ],
          // },
          {
            id: 6,
            value: 'Team culture',
            icon: (
              <ImgContainer>
                <img style={{ height: 'inherit', width: '100%' }} src={w1} />
              </ImgContainer>
            ),
            levels: [
              { id: 1, value: 'level 3', text: 'Team level 3' },
              { id: 2, value: 'level 2', text: 'Team level 2' },
            ],
          },
        ]
        if (_.isUndefined(answers)) {
          return defaultChoices
        }
        let curChoices = [
          {
            id: 1,
            value: 'Annual salary',
            levels: [
              {
                id: 1,
                value: '50000',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={Salary50k}
                      alt="Salary $50k"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Annual salary</ConjointTitle>
                //   </ConjointText>
                // ),
              },
              {
                id: 3,
                value: '60000',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={Salary60k}
                      alt="Salary $60k"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Annual salary</ConjointTitle>
                //   </ConjointText>
                // ),
              },
              {
                id: 4,
                value: '70000',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={Salary70k}
                      alt="Salary $70k"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Annual salary</ConjointTitle>
                //   </ConjointText>
                // ),
              },
            ],
          },
          {
            id: 2,
            value: 'Distance to town from your house',
            levels: [
              {
                id: 1,
                value: '30min',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={Town30}
                      alt="Nearest town is 30 minutes away"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Distance to town from your house</ConjointTitle>
                //   </ConjointText>
                // ),
              },
              // {
              //   id: 2,
              //   value: '5min',
              //   icon: (
              //     <ImgContainer>
              //       <img style={{ height: 'inherit' }} src={Town5} />
              //     </ImgContainer>
              //   ),
              //   text: (
              //     <ConjointText>
              //       <ConjointTitle>Distance to town from your house</ConjointTitle>
              //       <p>5 minutes</p>
              //     </ConjointText>
              //   ),
              // },
              {
                id: 4,
                value: '5min',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={Town5}
                      alt="Nearest town is 5 minutes away"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Distance to town from your house</ConjointTitle>
                //   </ConjointText>
                // ),
              },
            ],
          },
          {
            id: 3,
            value: 'Worked days per week',
            levels: [
              {
                id: 1,
                value: '6 days',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={DaysWorked6}
                      alt="6 days worked per week on average"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Average days worked per week</ConjointTitle>
                //     <p>On average, 6 days per week</p>
                //   </ConjointText>
                // ),
              },
              {
                id: 3,
                value: '5.5 days',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={DaysWorked5_5}
                      alt="5.5 days worked per week on average"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Average days worked per week</ConjointTitle>
                //     <p>On average, 5 and a half days per week</p>
                //   </ConjointText>
                // ),
              },
              {
                id: 4,
                value: '5 days',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={DaysWorked5}
                      alt="5 days worked per week on average"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Average days worked per week</ConjointTitle>
                //     <p>On average, 5 days per week</p>
                //   </ConjointText>
                // ),
              },
            ],
          },
          {
            id: 4,
            value: 'Worked hours (h/week) - Full-time',
            levels: [
              {
                id: 1,
                value: '55',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={Hours55}
                      alt="55 hours per week on average"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Average hours of work per week</ConjointTitle>
                //   </ConjointText>
                // ),
              },
              {
                id: 2,
                value: '50',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={Hours50}
                      alt="50 hours per week on average"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Average hours of work per week</ConjointTitle>
                //   </ConjointText>
                // ),
              },
              {
                id: 3,
                value: '45',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={Hours45}
                      alt="45 hours per week on average"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Average hours of work per week</ConjointTitle>
                //   </ConjointText>
                // ),
              },
              {
                id: 4,
                value: '40',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={Hours40}
                      alt="40 hours per week on average"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Average hours of work per week</ConjointTitle>
                //   </ConjointText>
                // ),
              },
            ],
          },
          // {
          //   id: 5,
          //   value: 'Accommodation',
          //   levels: [
          //     {
          //       id: 1,
          //       value: 'You need to find your own',
          //       icon: (
          //         <ImgContainer>
          //           <img style={{ height: 'inherit' }} src={OwnAccom} />
          //         </ImgContainer>
          //       ),
          //       text: (
          //         <ConjointText>
          //           <ConjointTitle>Accommodation</ConjointTitle>
          //           <p>You need to find your own</p>
          //         </ConjointText>
          //       ),
          //     },
          //     {
          //       id: 2,
          //       value: 'The farm owns a house you can rent',
          //       icon: (
          //         <ImgContainer>
          //           <img style={{ height: 'inherit' }} src={FarmAccom} />
          //         </ImgContainer>
          //       ),
          //       text: (
          //         <ConjointText>
          //           <ConjointTitle>Accommodation</ConjointTitle>
          //           <p>The farm owns a house you can rent</p>
          //         </ConjointText>
          //       ),
          //     },
          //     //   {
          //     //     id: 3,
          //     //     value: '3+ bedrooms',
          //     //     icon: (
          //     //       <ImgContainer>
          //     //         <img style={{ height: 'inherit' }} src={Acc3Bed} />
          //     //       </ImgContainer>
          //     //     ),
          //     //     text: (
          //     //       <ConjointText>
          //     //         <ConjointTitle>Accommodation</ConjointTitle>
          //     //         <p>3+ bedrooms</p>
          //     //       </ConjointText>
          //     //     ),
          //     //   },
          //   ],
          // },
          {
            id: 6,
            value: 'Team culture',
            levels: [
              {
                id: 1,
                value: 'Poor team culture',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={TeamCulturePoor}
                      alt="Poor team culture: Uncaring boss, little opportunity for career development and social connection"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Team culture</ConjointTitle>
                //     <p>Poor team culture e.g.</p>
                //     <ul>
                //       <li>Inflexible work hours</li>
                //       <li>Uncaring manager</li>
                //       <li>No career development opportunities</li>
                //       <li>Few opportunities for social connection</li>
                //     </ul>
                //   </ConjointText>
                // ),
              },
              {
                id: 4,
                value: 'Good team culture',
                icon: (
                  <ImgContainer>
                    <img
                      style={{ height: 'inherit', width: '100%' }}
                      src={TeamCultureGood}
                      alt="Good team culture: Caring boss, good opportunities for career development and social connection"
                    />
                  </ImgContainer>
                ),
                // text: (
                //   <ConjointText>
                //     <ConjointTitle>Team culture</ConjointTitle>
                //     <p>Good team culture e.g.</p>
                //     <ul>
                //       <li>Flexible work hours</li>
                //       <li>Caring manager</li>
                //       <li>Good career development opportunities</li>
                //       <li>Many opportunities for social connection</li>
                //     </ul>
                //   </ConjointText>
                // ),
              },
            ],
          },
          //   {
          //     id: 6,
          //     value: 'Accommodation quality',
          //     levels: [
          //       {
          //         id: 1,
          //         value: 'Okay for a young bugger',
          //         icon: (
          //           <ImgContainer>
          //             <img style={{ height: 'inherit' }} src={Acc1p} />
          //           </ImgContainer>
          //         ),
          //         text: (
          //           <ConjointText>
          //             <ConjointTitle>Accommodation quality</ConjointTitle>
          //             <p>Okay for a young bugger</p>
          //           </ConjointText>
          //         ),
          //       },
          //       {
          //         id: 2,
          //         value: 'Okay for a family',
          //         icon: (
          //           <ImgContainer>
          //             <img style={{ height: 'inherit' }} src={Acc4p} />
          //           </ImgContainer>
          //         ),
          //         text: (
          //           <ConjointText>
          //             <ConjointTitle>Accommodation quality</ConjointTitle>
          //             <p>Okay for a family</p>
          //           </ConjointText>
          //         ),
          //       },
          //       {
          //         id: 3,
          //         value: 'Flash, nicer than my place',
          //         icon: (
          //           <ImgContainer>
          //             <img style={{ height: 'inherit' }} src={Accnp} />
          //           </ImgContainer>
          //         ),
          //         text: (
          //           <ConjointText>
          //             <ConjointTitle>Accommodation quality</ConjointTitle>
          //             <p>Flash, nicer than my place</p>
          //           </ConjointText>
          //         ),
          //       },
          //     ],
          //   },
        ]
        // if (answers['1_6_remuneration'].value === 'Hourly rate') {
        //   let indToChange = curChoices.findIndex(element => {
        //     return element.value === 'Annual salary'
        //   })
        //   curChoices[indToChange].value = 'Hourly rate'
        //   curChoices[indToChange].levels = [
        //     {
        //       id: 1,
        //       value: '$21',
        //       icon: (
        //         <ImgContainer>
        //           <img style={{ height: 'inherit' }} src={HourRate21} />
        //         </ImgContainer>
        //       ),
        //       text: (
        //         <ConjointText>
        //           <ConjointTitle>Hourly rate</ConjointTitle>
        //           <p>$21</p>
        //         </ConjointText>
        //       ),
        //     },
        //     {
        //       id: 2,
        //       value: '$22-24',
        //       icon: (
        //         <ImgContainer>
        //           <img style={{ height: 'inherit' }} src={HourRate24} />
        //         </ImgContainer>
        //       ),
        //       text: (
        //         <ConjointText>
        //           <ConjointTitle>Hourly rate</ConjointTitle>
        //           <p>$22-24</p>
        //         </ConjointText>
        //       ),
        //     },
        //     {
        //       id: 3,
        //       value: '$25 and more',
        //       icon: (
        //         <ImgContainer>
        //           <img style={{ height: 'inherit' }} src={HourRate25} />
        //         </ImgContainer>
        //       ),
        //       text: (
        //         <ConjointText>
        //           <ConjointTitle>Hourly rate</ConjointTitle>
        //           <p>$25 and more</p>
        //         </ConjointText>
        //       ),
        //     },
        //   ]
        // }
        return curChoices
      },
      levels: [],
    },
    // 4 Accommodation
    // 4.1 Bedrooms
    {
      id: '4_1_bedrooms',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Accommodation</h1>
          <p>
            If you moved house for a dairy farm job, how big would your accommodation need to be?
          </p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: '1 bedroom', value: '1 bedroom' },
        { id: 2, text: '2 bedrooms', value: '2 bedrooms' },
        { id: 3, text: '3 bedrooms', value: '3 bedrooms' },
        { id: 4, text: '4 or more bedrooms', value: '4 or more bedrooms' },
      ],
    },
    // 4.2 Farm vs Accommodation
    {
      id: '4_2_farm_vs_accommodation',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Accommodation</h1>
          <p>Which would you rather have?</p>
          <p>In both cases you will pay a market rent for the accommodation.</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'A house on-farm (market rent is deducted from your pay)',
          value: 'A house on-farm (market rent is deducted from your pay)',
        },
        {
          id: 2,
          text: 'A house off-farm (you rent or buy 15-minutes away from the farm, in town)',
          value: 'A house off-farm (you rent or buy 15-minutes away from the farm, in town)',
        },
        {
          id: 3,
          text: 'Both options are equally attractive to me',
          value: 'Both options are equally attractive to me',
        },
      ],
      next: ({ answers }) => {
        if (
          answers['4_2_farm_vs_accommodation'].value === 'Both options are equally attractive to me'
        ) {
          return '5_0_section_intro'
        }
        return '4_3_annual_pay_sacrifice'
      },
      buttonSize: xlButton.width,
    },
    // 4.3 Annual pay sacrifice
    {
      id: '4_3_annual_pay_sacrifice',
      type: multiChoice,
      section: ({ answers }) => {
        const choice = answers['4_2_farm_vs_accommodation'].value
        let oppositeChoice
        if (choice === 'A house on-farm (market rent is deducted from your pay)') {
          oppositeChoice =
            'A house off-farm (you rent or buy 15-minutes away from the farm, in town)'
        } else {
          oppositeChoice = 'A house on-farm (market rent is deducted from your pay)'
        }
        return (
          <Wrapper>
            <h1>Accommodation</h1>
            <p>
              How much more money would you need to be paid to choose <i>{oppositeChoice}</i>{' '}
              instead?
            </p>
            <p>
              Reminder - you chose <i>{choice}</i>
            </p>
          </Wrapper>
        )
      },
      required: true,
      choices: [
        { id: 1, text: 'Less than $1,000 per year', value: 'Less than $1,000 per year' },
        { id: 2, text: '$1,000 to $3,000 per year', value: '$1,000 to $3,000 per year' },
        { id: 3, text: '$3,000 to $10,000 per year', value: '$3,000 to $10,000 per year' },
        { id: 4, text: 'More than $10,000 per year', value: 'More than $10,000 per year' },
      ],
      // next: ({ answers }) => {
      //   if (
      //     answers['4_2_farm_vs_accommodation'].value ===
      //     'You are free to find suitable accommodation for yourself, a 5-minute drive away in the nearest town'
      //   ) {
      //     return '4_4_farm_vs_accommodation_2'
      //   }
      //   return '5_battle_of_the_jobs'
      // },
    },
    // 4.4 Farm vs Accommodation 2
    // {
    //   id: '4_4_farm_vs_accommodation_2',
    //   type: multiChoice,
    //   section: (
    //     <Wrapper>
    //       <h1>Accommodation</h1>
    //       <p>
    //         If the nearest town was 30 minutes away, rather than 5 minutes, which would you rather
    //         have?
    //       </p>
    //       <p>In both cases you will pay a market rent for the accommodation.</p>
    //     </Wrapper>
    //   ),
    //   required: true,
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'A house on-farm (market rent is deducted from your pay)',
    //       value: 'A house on-farm (market rent is deducted from your pay)',
    //     },
    //     {
    //       id: 2,
    //       text:
    //         'You are free to find suitable accommodation for yourself, a 30-minute drive away in the nearest town',
    //       value:
    //         'You are free to find suitable accommodation for yourself, a 30-minute drive away in the nearest town',
    //     },
    //     {
    //       id: 3,
    //       text: 'Both options are equally attractive to me',
    //       value: 'Both options are equally attractive to me',
    //     },
    //   ],
    //   next: ({ answers }) => {
    //     if (
    //       answers['4_4_farm_vs_accommodation_2'].value ===
    //       'Both options are equally attractive to me'
    //     ) {
    //       return '5_battle_of_the_jobs'
    //     }
    //     return '4_5_annual_pay_sacrifice_2'
    //   },
    //   buttonSize: xlButton.width,
    // },
    // 4.5 Annual pay sacrifice 2
    // {
    //   id: '4_5_annual_pay_sacrifice_2',
    //   type: multiChoice,
    //   section: ({ answers }) => (
    //     <Wrapper>
    //       <h1>Accommodation</h1>
    //       <p>And how much annual pay would you be willing to sacrifice to have this option?</p>
    //       <p>
    //         Reminder - you chose <i>{answers['4_4_farm_vs_accommodation_2'].value}</i>
    //       </p>
    //     </Wrapper>
    //   ),
    //   required: true,
    //   choices: [
    //     { id: 1, text: 'Less than $300 per year', value: 'Less than $300 per year' },
    //     { id: 2, text: '$300 to $1,000 per year', value: '$300 to $1,000 per year' },
    //     { id: 3, text: '$1,000 to $3,000 per year', value: '$1,000 to $3,000 per year' },
    //     { id: 4, text: '$3,000 to $10,000 per year', value: '$3,000 to $10,000 per year' },
    //     { id: 5, text: 'More than $10,000 per year', value: 'More than $10,000 per year' },
    //   ],
    // },
    // 5.0 section intro
    {
      id: '5_0_section_intro',
      type: welcomePage,
      section: (
        <Wrapper>
          <h1>Which job would you choose?</h1>
          <p>This section asks you to make a choice between a dairy farm job and your job.</p>
          <p>Which would you choose?</p>
          <br></br>
          <p>The only job characteristic that changes is the salary.</p>
        </Wrapper>
      ),
    },
    // 5 Battle of the jobs
    {
      id: '5_battle_of_the_jobs',
      type: imageChoice,
      buttonSize: xlButton.width,
      startLoop: jobLoopArray,
      max: 1,
      endLoop: true,
      required: true,
      nowrap: true,
      section: (
        <Wrapper>
          <h1>Which job would you choose?</h1>
          <p>This section asks you to make a choice between a dairy farm job and your job.</p>
          <p>Which would you choose?</p>
        </Wrapper>
      ),
      choices: ({ answers, loopIndex }) => {
        const jobNum = loopIndex ?? 0
        return [
          {
            id: 1,
            text: 'A job on a dairy farm',
            value: jobDetails[jobNum][0],
            html: (
              <div>
                <h2>A job on a dairy farm</h2>
                <p>{preparedJobs[jobNum]}</p>
              </div>
            ),
          },
          {
            id: 2,
            text: 'Your current job',
            value: 'Your current job',
            html: (
              <div
                style={{
                  padding: '0.5rem',
                  minHeight: '11rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <h2>Your current job</h2>
              </div>
            ),
          },
        ]
      },
      breakLoop: ({ answers, loopIndex }) => {
        if (answers['5_battle_of_the_jobs'][jobLoopArray[loopIndex]].id === 1) {
          if (jobDetails[loopIndex][0] === '$90,000' || jobDetails[loopIndex][0] === '$100,000') {
            return '6_turn_offs'
          }
          return '5b_0_section_intro'
        }
      },
      next: ({ answers, loopIndex }) => {
        return '6_turn_offs'
      },
    },
    // 5b section intro
    {
      id: '5b_0_section_intro',
      type: welcomePage,
      section: ({ answers }) => {
        const acceptedJobIndex = jobLoopArray.findIndex(
          job => answers['5_battle_of_the_jobs'][job]?.id === 1,
        )
        return (
          <Wrapper>
            <h1>Would you take the new offer?</h1>
            <p>You accepted the job.</p>
            <p>
              What if you accepted the job, but your new employer then asked to change your start
              time to <b>5am</b>?
            </p>
            <p>
              This section asks you to make a choice between the existing offer, and the new offer.
            </p>
            <br></br>
            <p>The only job characteristic that changes in each screen is the salary.</p>
            <br></br>
            <p>Reminder - you chose:</p>
            <JobContainer
              style={{ color: '#a0411e', border: '1px dashed black', borderRadius: '10px' }}
            >
              <p style={{ marginBottom: '5px' }}>50 hours per week (on average)</p>
              <p style={{ marginBottom: '5px' }}>
                5<sup>1</sup>&frasl;<sub>2</sub> days per week (on average)
              </p>
              <p style={{ marginBottom: '5px' }}>Milking starts 6am</p>
              <p style={{ marginBottom: '5px' }}>
                You are living on-farm (market rent deducted from pay)
              </p>
              <p style={{ marginBottom: '5px' }}>Nearest town is 15 minutes from the farm</p>
              <p style={{ marginBottom: 0, textAlign: 'center', width: '100%' }}>
                You are paid{' '}
                <span style={{ fontSize: '2rem' }}>
                  <b>{jobDetails[acceptedJobIndex][0]}</b>
                </span>{' '}
                {' per year'}
                <br></br>
                {jobDetails[acceptedJobIndex][1]}
              </p>
            </JobContainer>
          </Wrapper>
        )
      },
    },
    // 5b Battle of the jobs 2
    {
      id: '5b_battle_of_the_jobs_2',
      type: imageChoice,
      buttonSize: xlButton.width,
      startLoop: jobLoopArray2,
      max: 1,
      endLoop: true,
      required: true,
      nowrap: true,
      // section: (
      //   <Wrapper>
      //   </Wrapper>
      // ),
      choices: ({ answers, loopIndex }) => {
        const acceptedJobIndex = jobLoopArray.findIndex(
          job => answers['5_battle_of_the_jobs'][job]?.id === 1,
        )
        const [acceptedSalaryText, acceptedWageText, acceptedSalary] = jobDetails[acceptedJobIndex]
        const offeredSalary = acceptedSalary + jobDetails2[loopIndex + 1]
        const previouslyOfferedSalary = acceptedSalary + jobDetails2[loopIndex]
        const previouslyOfferedSalaryText = `$${previouslyOfferedSalary.toLocaleString('en-US')}`
        const offeredSalaryText = `$${offeredSalary.toLocaleString('en-US')}`
        const offeredWageText = `($${(offeredSalary / 52 / 50).toFixed(1)} per hour)`
        const jobNum = loopIndex
        return [
          {
            id: 1,
            text: 'Your new job on a dairy farm',
            value: 'Your new job on a dairy farm',
            html: (
              <div
                style={{
                  // padding: '0.5rem',
                  minHeight: '11rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <h2>Job accepted on a dairy farm</h2>
                <JobContainer>
                  <p style={{ marginBottom: '5px' }}>50 hours per week (on average)</p>
                  <p style={{ marginBottom: '5px' }}>
                    5<sup>1</sup>&frasl;<sub>2</sub> days per week (on average)
                  </p>
                  <p style={{ marginBottom: '5px' }}>Milking starts 6am</p>
                  <p style={{ marginBottom: '5px' }}>
                    You are living on-farm (market rent deducted from pay)
                  </p>
                  <p style={{ marginBottom: '5px' }}>Nearest town is 15 minutes from the farm</p>
                  {/* <br></br> */}
                  <p style={{ marginBottom: '5px', textAlign: 'center', width: '100%' }}>
                    You are paid{' '}
                    <span style={{ fontSize: '2rem' }}>
                      <b>{acceptedSalaryText}</b>
                    </span>{' '}
                    {'per year'}
                    <br></br>
                    {acceptedWageText}
                  </p>
                </JobContainer>
              </div>
            ),
          },
          {
            id: 2,
            text: 'The new offer',
            value: offeredSalaryText,
            html: (
              <div
                style={
                  {
                    // padding: '0.5rem',
                  }
                }
              >
                <h2>The new offer</h2>
                {/* <p style={{ padding: '0.5rem' }}> */}
                <JobContainer>
                  <p style={{ marginBottom: '5px', textAlign: 'center' }}>Same as above, but...</p>
                  <p style={{ marginBottom: '5px' }}>Milking starts 5am</p>
                  <p style={{ marginBottom: 0, textAlign: 'center', width: '100%' }}>
                    You are paid <del>{previouslyOfferedSalaryText}</del>
                    <span style={{ fontSize: '2rem' }}>
                      <b>{offeredSalaryText}</b>
                    </span>{' '}
                    {'per year'}
                    <br></br>
                    {offeredWageText}
                  </p>
                </JobContainer>
                {/* </p> */}
              </div>
            ),
          },
        ]
      },
      breakLoop: ({ answers, loopIndex }) => {
        if (answers['5b_battle_of_the_jobs_2'][jobLoopArray[loopIndex]].id === 2) {
          return '6_turn_offs'
        }
      },
    },
    // 6 Turn offs and turn ons
    // 6.1 turn offs
    {
      id: '6_turn_offs',
      type: multiSelect,
      section: (
        <Wrapper>
          <h1>Turn offs and turn ons</h1>
          <p>This section asks you to choose what would be most vital in a dairy farm job.</p>
          <p>What puts you off a dairy farm job?</p>
          <p>Pick all that apply</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Pay', value: 'Pay' },
        { id: 2, text: 'Number of hours', value: 'Number of hours' },
        { id: 3, text: 'Not many days off', value: 'Not many days off' },
        { id: 4, text: 'Living remotely', value: 'Living remotely' },
        { id: 5, text: 'Workplace culture', value: 'Workplace culture' },
        { id: 6, text: 'Early starts in the morning', value: 'Early starts in the morning' },
        { id: 7, text: 'Working outside', value: 'Working outside' },
        { id: 8, text: 'Working with animals', value: 'Working with animals' },
        {
          id: 9,
          text: 'Ability to keep in touch with friends and family',
          value: 'Ability to keep in touch with friends and family',
        },
        { id: 10, text: 'Other, please specify', value: '', type: 'input' },
      ],
    },
    // 6.2 turn ons
    {
      id: '6_turn_ons',
      type: multiSelect,
      section: (
        <Wrapper>
          <h1>Turn offs and turn ons</h1>
          <p>What would make a dairy farm more job more attractive?</p>
          <p>Pick your top 3</p>
        </Wrapper>
      ),
      required: true,
      max: 3,
      choices: [
        {
          id: 1,
          text: 'Opportunities to use automation and technology',
          value: 'Opportunities to use automation and technology',
        },
        { id: 2, text: 'Shifts based on school hours', value: 'Shifts based on school hours' },
        { id: 3, text: '40 to 45 hour work week', value: '40 to 45 hour work week' },
        { id: 4, text: 'Monday to Friday work', value: 'Monday to Friday work' },
        { id: 5, text: 'No early mornings', value: 'No early mornings' },
        { id: 6, text: 'Good variety of tasks', value: 'Good variety of tasks' },
        { id: 7, text: 'Good team culture', value: 'Good team culture' },
        { id: 8, text: 'Job security', value: 'Job security' },
        {
          id: 9,
          text: 'Time to connect with community',
          value: 'Time to connect with community',
        },
        { id: 10, text: 'Good hourly rates', value: 'Good hourly rates' },
        { id: 11, text: 'Other, please specify', value: '', type: 'input' },
      ],
    },
    // 3.0 Section Intro
    // {
    //   id: 'intro_3',
    //   type: welcomePage,
    //   section: (
    //     <Wrapper>
    //       <h1>Section 3 Introduction</h1>
    //       <p>This is section 3 introduction. </p>
    //     </Wrapper>
    //   ),
    // },
    // 4. Putting you off
    // {
    //   id: '4_putting_you_off',
    //   type: commentBox,
    //   section: (
    //     <Wrapper>
    //       <h1> Comments </h1>
    //       <p>What are the main things putting you off a dairy farming job?</p>
    //     </Wrapper>
    //   ),
    // },
    // 5. Optional contact - interview
    // {
    //   id: '5_contact_email_interview',
    //   type: multiTextBox,
    //   section: (
    //     <Wrapper>
    //       <h1>Optional contact</h1>
    //       <p>
    //         If you are interested in participating in an interview about your <b>motivations</b> to
    //         work in a dairy farming job and the <b>disincentives</b> or <b>barriers</b> you face
    //         when considering it, please enter you email in the text box below. Thank you!
    //       </p>
    //     </Wrapper>
    //   ),
    //   choices: [{ id: 1, text: 'Email:', value: '' }],
    //   textBelow: (
    //     <Wrapper>
    //       <p>It will be kept separately from your responses to ensure confidentiality.</p>
    //       <em>
    //         This is entirely optional. Your email address will solely be used to contact you for
    //         further research and will not be added to any database.
    //       </em>
    //     </Wrapper>
    //   ),
    // },
    // 5. Optional contact - results
    // {
    //   id: '5_contact_email_results',
    //   type: multiTextBox,
    //   section: (
    //     <Wrapper>
    //       <h1>Optional contact</h1>
    //       <p>
    //         If you are interested in receiving a summary of the findings from the research once it
    //         is completed (mid-2023), please enter your email address.
    //       </p>
    //     </Wrapper>
    //   ),
    //   choices: [{ id: 1, text: 'Email:', value: '' }],
    //   textBelow: (
    //     <Wrapper>
    //       <p>It will be kept separately from your responses to ensure confidentiality.</p>
    //       <em>
    //         This is entirely optional. Your email address will solely be used for sending a summary
    //         of the findings and will not be added to any database.
    //       </em>
    //     </Wrapper>
    //   ),
    // },
    // 7. End Page (Eligible)
    // // 7.1 Preferred voucher
    // {
    //   id: '7_1_preferred_voucher',
    //   type: multiChoice,
    //   section: (
    //     <Wrapper>
    //       <h1>Thanks!</h1>
    //       <p>You have reached the end of the survey - thank you for your responses!</p>
    //       <p>Please fill in the following details to receive your gift card.</p>
    //       <p>Please choose your preferred voucher</p>
    //     </Wrapper>
    //   ),
    //   required: true,
    //   choices: [
    //     { id: 1, text: 'Bunnings', value: 'Bunnings' },
    //     { id: 2, text: 'JB Hi-Fi', value: 'JB Hi-Fi' },
    //     { id: 3, text: 'Event cinemas', value: 'Event cinemas' },
    //     { id: 4, text: 'PaperPlus', value: 'PaperPlus' },
    //     { id: 5, text: 'Torpedo7', value: 'Torpedo7' },
    //     { id: 6, text: 'EB games (Playstation or Xbox)', value: 'EB games (Playstation or Xbox)' },
    //   ],
    //   // textBelow: (
    //   //   <Wrapper>
    //   //     <em>
    //   //       Please note that your voucher will be emailed to you before 31/03/23. Your address and
    //   //       number will only be used to validate unique respondents. Receiving a summary of findings
    //   //       is entirely optional. Your email address will be used solely for the activities
    //   //       described and agreed to here. It will not be used for marketing purposes or given to any
    //   //       third parties.
    //   //     </em>
    //   //   </Wrapper>
    //   // ),
    // },
    // // 7.4 Club
    // {
    //   id: '7_4_club',
    //   type: multiChoice,
    //   section: (
    //     <Wrapper>
    //       <h1>Thanks!</h1>
    //       <p>If you heard about this survey from your club, please tell us which one</p>
    //     </Wrapper>
    //   ),
    //   required: true,
    //   choices: [
    //     { id: 1, text: 'Club name: ', value: '', type: 'input' },
    //     {
    //       id: 2,
    //       text: 'I did not hear about this from a club',
    //       value: 'I did not hear about this from a club',
    //     },
    //   ],
    // },
    // // 7.5 Additional Interview
    // {
    //   id: '7_5_additional_interview',
    //   type: multiChoice,
    //   section: (
    //     <Wrapper>
    //       <h1>Thanks!</h1>
    //       <p>
    //         We will also be conducting a small number of 20 minute online interviews (teams or
    //         zoom). Would you be interested in participating in an interview, for an additional $50?
    //       </p>
    //     </Wrapper>
    //   ),
    //   required: true,
    //   choices: [
    //     { id: 1, text: 'Yes', value: 'Yes' },
    //     { id: 2, text: 'No', value: 'No' },
    //   ],
    // },
    // // 7.2 Contact details
    // {
    //   id: '7_2_contact_details',
    //   type: multiTextBox,
    //   section: (
    //     <Wrapper>
    //       <h1>Thanks!</h1>
    //       <p>Please enter your contact details</p>
    //     </Wrapper>
    //   ),
    //   required: true,
    //   choices: [
    //     { id: 1, text: 'Email address:    ', value: '' },
    //     { id: 2, text: 'Physical address: ', value: '' },
    //     { id: 3, text: 'Phone number:     ', value: '' },
    //   ],
    //   textBelow: (
    //     <Wrapper>
    //       <em>
    //         Please note that your voucher will be emailed to you before 31/03/23. Your address and
    //         number will only be used to validate unique respondents, or to contact you if you are
    //         interested in being interviewed.
    //       </em>
    //     </Wrapper>
    //   ),
    //   buttonSize: xlButton.width,
    // },
    // // 7.3 Receive summary
    // {
    //   id: '7_3_receive_summary',
    //   type: multiChoice,
    //   section: (
    //     <Wrapper>
    //       <h1>Thanks!</h1>
    //       <p>
    //         Finally, would you like to receive a summary of the findings from the research
    //         (completion expected mid-2023)?
    //       </p>
    //       {/* <em>
    //         Receiving a summary of findings is entirely optional. Your email address will be used
    //         solely for the activities described and agreed to here. It will not be used for
    //         marketing purposes or given to any third parties.
    //       </em> */}
    //     </Wrapper>
    //   ),
    //   required: true,
    //   choices: [
    //     { id: 1, text: 'Yes', value: 'Yes' },
    //     { id: 2, text: 'No', value: 'No' },
    //   ],
    //   textBelow: (
    //     <Wrapper>
    //       <em>
    //         Receiving a summary of findings is entirely optional. Your email address will be used
    //         solely for the activities described and agreed to here. It will not be used for
    //         marketing purposes or given to any third parties.
    //       </em>
    //     </Wrapper>
    //   ),
    // },
    // End Page
    {
      id: 'end-page',
      type: endPage,
      section: (
        <Wrapper>
          <h1>Thanks!</h1>
          <p>You have reached the end of the survey - thank you for your responses!</p>
          <p>Please wait while you are redirected.</p>
          <TextSign>
            <p>This survey was powered by</p>
            <span>
              <a href="https://www.scarlatti.co.nz/" target="_blank" rel="noopener noreferrer">
                <img src={scarlattiLogo} alt="Scarlatti logo" height="60" />
              </a>
            </span>
          </TextSign>
        </Wrapper>
      ),
      redirect: `https://dkr1.ssisurveys.com/projects/end?rst=1&pid=${params_pid}&psid=${params_psid}&basic=67368`,
      redirectWaitSeconds: 5,
      hideUi: true,
    },
    // End Page (Ineligible)
    {
      id: 'end-page-ineligible',
      type: endPage,
      section: (
        <Wrapper>
          <p>
            Thank you for completing our eligibility questions. It appears that in this instance,
            you are not eligible.
          </p>
          <p>We appreciate your interest nonetheless.</p>
          <p>Please wait while you are redirected.</p>
        </Wrapper>
      ),
      redirect: `https://dkr1.ssisurveys.com/projects/end?rst=2&pid=${params_pid}&psid=${params_psid}`,
      redirectWaitSeconds: 5,
      hideUi: true,
    },
  ],

  settings: {
    writeToFirestore: true,
    surveyName: 'gfid-dynata',
    progressBar: true,
    uuid: tempUserId,
    leftBackgroundImage,
    primaryColour: '#F9744C',
  },
}

export default GFiD
