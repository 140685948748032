import React, { Component } from 'react'
import {
  choiceRank,
  compareSlider,
  endPage,
  ranking,
  welcomePage,
} from '../question-types/QuestionTypes'
import _ from 'lodash'
import styled from 'styled-components'
import { Divider } from 'antd'
import L0 from '../../assets/images/GridSkills/levelicons/level-zero.svg'
import L1 from '../../assets/images/GridSkills/levelicons/level-one.svg'
import L2 from '../../assets/images/GridSkills/levelicons/level-two.svg'
import L3 from '../../assets/images/GridSkills/levelicons/level-three.svg'
import L4 from '../../assets/images/GridSkills/levelicons/level-four.svg'
import L5 from '../../assets/images/GridSkills/levelicons/level-five.svg'
import L6 from '../../assets/images/GridSkills/levelicons/level-six.svg'
import L7 from '../../assets/images/GridSkills/levelicons/level-seven.svg'
import L8 from '../../assets/images/GridSkills/levelicons/level-eight.svg'
import L9 from '../../assets/images/GridSkills/levelicons/level-nine.svg'
import L10 from '../../assets/images/GridSkills/levelicons/level-ten.svg'
import tick from '../../assets/images/GridSkills/diploma.svg'
import cross from '../../assets/images/GridSkills/blank.svg'
import f1 from '../../assets/images/GridSkills/portraits/f1.png'
import f2 from '../../assets/images/GridSkills/portraits/f2.png'
import f3 from '../../assets/images/GridSkills/portraits/f3.png'
import f4 from '../../assets/images/GridSkills/portraits/f4.png'
import f5 from '../../assets/images/GridSkills/portraits/f5.png'
import f6 from '../../assets/images/GridSkills/portraits/f6.png'
import f7 from '../../assets/images/GridSkills/portraits/f7.png'
import f8 from '../../assets/images/GridSkills/portraits/f8.png'
import f9 from '../../assets/images/GridSkills/portraits/f9.png'
import f10 from '../../assets/images/GridSkills/portraits/f10.png'
import m1 from '../../assets/images/GridSkills/portraits/m1.png'
import m2 from '../../assets/images/GridSkills/portraits/m2.png'
import m3 from '../../assets/images/GridSkills/portraits/m3.png'
import m4 from '../../assets/images/GridSkills/portraits/m4.png'
import m5 from '../../assets/images/GridSkills/portraits/m5.png'
import m6 from '../../assets/images/GridSkills/portraits/m6.png'
import m7 from '../../assets/images/GridSkills/portraits/m7.png'
import m8 from '../../assets/images/GridSkills/portraits/m8.png'
import m9 from '../../assets/images/GridSkills/portraits/m9.png'
import m10 from '../../assets/images/GridSkills/portraits/m10.png'
import leftBackgroundImage from '../../assets/images/GridSkills/GSbg.jpg'
import { xlButton } from '../ui/Layout/ButtonContainer'
import logo from '../../assets/images/GridSkills/GSlogo.JPG'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  font-size: 1rem;
  width: 100%;
  p {
    margin: 0.3rem 0;
  }
  .ant-divider-horizontal {
    margin: 0px 0;
    border-top: 1px solid #c3c3c3;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 80vw;
    .ant-divider-horizontal {
      width: 90vw;
    }
  }
`
const Text = styled.p`
  font-size: 1.2rem;
  color: ${props => props.color};
  font-weight: bold;
  margin-bottom: 0rem;
`
const Icon = styled.div`
  display: flex;
  width: 50px;
  align-items: center;
  justify-content: center;
`
const characters = {
  females: [
    { name: 'Sofia', portrait: f1 },
    { name: 'Hannah', portrait: f2 },
    { name: 'Phoebe', portrait: f3 },
    { name: 'Deirdre', portrait: f10 },
    { name: 'Dana', portrait: f1 },
    { name: 'Denise', portrait: f9 },
    { name: 'Julie', portrait: f7 },
    { name: 'Penelope', portrait: f8 },
    { name: 'Rachel', portrait: f9 },
    { name: 'Janet', portrait: f10 },
  ],
  males: [
    { name: 'Fred', portrait: m1 },
    { name: 'Martin', portrait: m2 },
    { name: 'Sam', portrait: m3 },
    { name: 'Alex', portrait: m4 },
    { name: 'Adam', portrait: m5 },
    { name: 'Giotto', portrait: m6 },
    { name: 'Vaughan', portrait: m7 },
    { name: 'Kenny', portrait: m8 },
    { name: 'John', portrait: m9 },
    { name: 'Howick', portrait: m10 },
  ],
}
const ExpIcons = [
  <>
    <img class="exp" src={L0} alt="0/10" height="30" /> <b>No</b> experience
  </>,
  <>
    <img class="exp" src={L1} alt="1/10" height="30" /> <b>1 Year</b> experience
  </>,
  <>
    <img class="exp" src={L2} alt="2/10" height="30" /> <b>2 Years</b> experience
  </>,
  <>
    <img class="exp" src={L5} alt="5/10" height="30" /> <b>5 Years</b> experience
  </>,
  <>
    <img class="exp" src={L10} alt="10/10" height="30" /> <b>10 Years</b> experience
  </>,
]
const QualIcons = [
  <img src={tick} alt="Tick" height="30" />,
  <img src={cross} alt="Cross" height="30" />,
]
const experienceLevels = [
  {
    value: 0,
    text: (
      <>
        <Icon>
          <img class="exp" src={L0} alt="0/10" height="40" />
        </Icon>
        <div>
          <b>No</b> experience
        </div>
      </>
    ),
  },
  {
    value: 1,
    text: (
      <>
        <Icon>
          <img class="exp" src={L1} alt="1/10" height="40" />
        </Icon>
        <div>
          <b>1 year</b> experience
        </div>
      </>
    ),
  },
  {
    value: 2,
    text: (
      <>
        <Icon>
          <img src={L2} alt="2/10" height="40" />
        </Icon>
        <p>
          <b>2 years</b> experience
        </p>
      </>
    ),
  },
  {
    value: 5,
    text: (
      <>
        <Icon>
          <img src={L5} alt="5/10" height="40" />
        </Icon>
        <p>
          <b>5 years</b> experience
        </p>
      </>
    ),
  },
  {
    value: 10,
    text: (
      <>
        <Icon>
          <img src={L10} alt="10/10" height="40" />
        </Icon>
        <p>
          <b>10 years</b> experience
        </p>
      </>
    ),
  },
]
const qualLevels = [
  {
    value: false,
    text: (
      <>
        <Icon>
          <img src={cross} alt="Cross" height="30" />
        </Icon>
        <p>No Grid Skills TLM qualification</p>
      </>
    ),
    textString: 'No Grid Skills TLM qualification',
  },
  {
    value: true,
    text: (
      <>
        <Icon>
          <img src={tick} alt="Tick" height="30" />
        </Icon>
        <p>Has Grid Skills TLM qualification</p>
      </>
    ),
    textString: 'Has Grid Skills TLM qualification',
  },
]

const GridSkillsTLM = {
  questions: [
    {
      id: 'intro-page',
      type: welcomePage,
      text: (
        <Wrapper style={{ cursor: 'default' }}>
          <Text color={'#4cb8d2'}>Section 1: Rank the profiles</Text>
          <p>
            In the following section you will be presented with a series of choices between pairs of
            employees with differing levels of experience.
          </p>
          <p>
            Some of these have completed the Grid Skills Transmission Line Mechanic pathway, while
            others have not undertaken any Grid Skills Transmission Line Mechanic training
          </p>
          <p>In each case, you will choose the employee you would prefer to have working for you</p>
          <p>
            – note that in this hypothetical scenario there is no regulatory requirement for
            employees to be trained; you should make your choice holistically based on the perceived
            quality of the employee only.
          </p>
        </Wrapper>
      ),
      buttonSize: xlButton.width,
    },
    {
      id: 'rank-profiles',
      type: choiceRank,
      hideUi: true,
      section: (
        <Wrapper>
          <Text color={'#4cb8d2'}>Rank the profiles</Text>
          <Divider />
          <p>Which of these 2 employees would you rather hire?</p>
          <em>Please click on the profile you prefer</em>
        </Wrapper>
      ),
      characters: characters,
      attr_1_obj: {
        name: 'Experience (years)',
        levels: experienceLevels,
      },
      attr_2_obj: {
        name: 'Qualification',
        levels: qualLevels,
      },
    },
    {
      id: 'rank_results',
      type: ranking,
      section: (
        <Wrapper>
          <Text color={'#4cb8d2'}>Section 1: Rank the profiles</Text>
          <Divider />
          <p>
            This is the ranking (best to worst) of all the potential employees based on your
            answers.
          </p>
          <p>
            Take a second to review and check that you agree with the ranking; if not, you can go
            back and redo your choices.
          </p>
        </Wrapper>
      ),
      buttonSize: 'auto',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          return []
        }
        const rawRanks = answers['rank-profiles'].value
        const rankChoices = rawRanks.map(item => {
          const curExpText = ExpIcons[item.attr_1_index]
          const curQualText = item.attr_2 ? (
            <>{QualIcons[0]} Has Grid Skills TLM qualification</>
          ) : (
            <>{QualIcons[1]} No Grid Skills TLM qualification</>
          )
          return {
            id: item.id,
            displaytext: (
              <>
                <p>{curExpText}</p>
                <p>{curQualText}</p>
              </>
            ),
            text: 'placeholder',
            value: 'displayrank',
          }
        })
        return rankChoices.reverse()
      },
      disabled: true,
    },
    {
      id: 'part-2-intro',
      type: welcomePage,
      text: (
        <Wrapper>
          <Text color={'#4cb8d2'}>Section 2: Compare the profiles</Text>
          <p>
            In this section you will be presented with a series of pairs of potential employees.
          </p>
          <p>
            The second employee will always be better than the first employee, based on your choices
            from the previous section.
          </p>
          <p>
            You will be asked to choose how much more money per hour you’d be willing to pay for the
            second employee over the first employee, based solely on their differences in experience
            and/or qualification.
          </p>
          <p>
            Don’t worry about precise numbers, a rough estimate is all we’re after. If you don’t
            feel there’s any significant difference between the two, an answer of $0 is valid.
          </p>
          <p>
            To avoid logical inconsistencies (e.g., paying more for an employee with the same
            qualification but less experience), later questions will have a cap on the allowed pay
            difference based on your earlier answers.
          </p>
        </Wrapper>
      ),
      buttonSize: xlButton.width,
    },
    {
      id: 'compare-slider',
      type: compareSlider,
      section: (
        <Wrapper>
          <Text color={'#4cb8d2'}>Compare the Profiles</Text>
          <Divider />
          <p>
            How much more would you be willing to pay for the second worker over the first worker?
          </p>
        </Wrapper>
      ),
      max: 10,
      default: 2,
      hideUi: true,
      characters: characters,
      attr_1_obj: {
        name: 'Experience',
        levels: experienceLevels,
      },
      attr_2_obj: {
        name: 'Qualification',
        levels: qualLevels,
      },
      convertValue: (step, maxStep, maxAbs) => {
        const value = (Math.pow(10, (Math.log10(maxAbs + 1) / maxStep) * step) - 1).toFixed(2)
        return value
      },
      convertStep: (value, maxStep, maxAbs) => {
        const step = ((Math.log10(value + 1) * maxStep) / Math.log10(maxAbs + 1)).toFixed(0)
        return step
      },
      choices: ({ answers }) => {
        if (!answers) {
          return []
        }
        return answers['rank-profiles'].value
      },
      intermediateSkippable: false,
      pairSet: numProfiles => {
        if (numProfiles === 8) {
          return [
            { pair: [0, 2], intermediate: false },
            { pair: [2, 4], intermediate: false },
            { pair: [4, 6], intermediate: false },
            { pair: [6, 7], intermediate: false },
            { pair: [0, 1], intermediate: true },
            { pair: [2, 3], intermediate: true },
            { pair: [4, 5], intermediate: true },
          ]
        } else if (numProfiles === 9) {
          return [
            { pair: [0, 2], intermediate: false },
            { pair: [2, 4], intermediate: false },
            { pair: [4, 6], intermediate: false },
            { pair: [6, 8], intermediate: false },
            { pair: [0, 1], intermediate: true },
            { pair: [2, 3], intermediate: true },
            { pair: [4, 5], intermediate: true },
            { pair: [6, 7], intermediate: true },
          ]
        } else if (numProfiles === 10) {
          return [
            { pair: [0, 2], intermediate: false },
            { pair: [2, 4], intermediate: false },
            { pair: [4, 6], intermediate: false },
            { pair: [6, 9], intermediate: false },
            { pair: [0, 1], intermediate: true },
            { pair: [2, 3], intermediate: true },
            { pair: [4, 5], intermediate: true },
            { pair: [6, 7], intermediate: true },
            { pair: [7, 8], intermediate: true },
          ]
        }
      },
    },
    {
      id: 'results_user_only',
      type: welcomePage,
      hidePreviousButton: true,
      buttonSize: 'auto',
      text: (
        <Wrapper>
          <p>
            The chart below shows the value generation profile of an employee over time with and
            without a Grid Skills qualification, based on the choices you’ve made in this survey.
          </p>
          <p>
            The figure given on the left represents the dollar value added to Transpower by the
            employee's Grid Skills qualification over the course of a 10-year employment lifespan,
            calculated from your value generation profile.
          </p>
        </Wrapper>
      ),
      resultType: 'user-only',
      output: {
        type: 'roi-critter',
        targetQuestionType: compareSlider,
        targetQuestionId: 'compare-slider',
        surveyName: 'gridskillstlm',
        height: '90%',
      },
    },
    {
      id: 'results_agg_only',
      type: welcomePage,
      buttonSize: 'auto',
      resultType: 'agg-only',
      text: (
        <Wrapper>
          <p>
            This page shows the value generation profile calculated from the responses of everyone
            else who has completed the survey.
          </p>
          <p>
            The figure given on the left is the average dollar value added to Transpower over a 10
            year tenure, based on the average of these responses.
          </p>
          <em>Go to the next page to see how your choices stack up against everyone else's.</em>
        </Wrapper>
      ),
      output: {
        type: 'roi-critter',
        targetQuestionType: compareSlider,
        targetQuestionId: 'compare-slider',
        surveyName: 'gridskillstlm',
        height: '90%',
      },
    },
    {
      id: 'results',
      type: endPage,
      buttonSize: 'auto',
      text: (
        <Wrapper>
          <p>
            Here you can see the comparison between your choices (in blue) and everyone else's (in
            grey).{' '}
          </p>
          <p>How does your valuation compare to the average?</p>
        </Wrapper>
      ),
      output: {
        type: 'roi-critter',
        targetQuestionType: compareSlider,
        targetQuestionId: 'compare-slider',
        surveyName: 'gridskillstlm',
        height: '90%',
      },
    },
  ],

  settings: {
    writeToFirestore: true,
    surveyName: 'gridskillstlm',
    progressBar: true,
    leftBackgroundImage,
    companyLogo: { images: [{ image: logo, height: '100px' }] },
    logoLocation: 'end',
    logoStyle: { marginTop: '2rem' },
    primaryColour: '#4cb8d2',
  },
}
export default GridSkillsTLM
