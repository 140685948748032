import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as revenue } from '../../assets/images/HCF/revenue.svg'
import {
  commentBox,
  conjointAnalysis,
  endPage,
  input,
  multiChoice,
  multiSelect,
  ranking,
  welcomePage,
} from '../question-types/QuestionTypes'
import { lgButton, xlButton } from '../ui/Layout/ButtonContainer'
// import backgroudImage from '../../assets/images/HCF/min-background-1.png'
// import backgroudImage from '../../assets/images/HCF/hcf-white.png'
import { v4 as uuidv4 } from 'uuid'
import conferLogo from '../../assets/images/Confer-logo.png'
import HCFLogo from '../../assets/images/FH/HDLG-logo.jpg'
import leftBackgroundImage from '../../assets/images/FH/landscape.JPG'
import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { Textbox } from '../../assets/styles/global'
// import leftBackgroundImage from '../../assets/images/HCF/backgroundimage.jpg' // Todo compress image

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width:24.5rem; */
`

const IconRevenue = styled(revenue)`
  height: 3rem;
`

const Text = styled.p`
  font-weight: 600;
  /* font-size: 1.035rem; */
`

const StyledP = styled.p`
  line-height: 2;
  font-size: 1rem;
  /* color: rgba(0, 0, 0, 0.65); */
  margin-bottom: 0rem;
  margin-top: 1rem;
  /* @media (min-width: 0px) and (max-width: 479px) {
    width: 100%;
    font-size:1rem;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    width: 100%;
    font-size:1rem;
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    width: 100%;
    font-size:1rem;
  } */
`
const Disclaimer = styled.p`
  font-style: italic;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  /* width: 800px; */
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: ${props => (props.size ? '49rem' : '35rem')};

  @media (min-width: 0px) and (max-width: 479px) {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    /* margin-left: 1.4rem; */
  }
  @media (min-width: 480px) and (max-width: 768px) {
    display: flex;
    width: 90vw;
    justify-content: flex-start;
    align-items: baseline;
  }
  @media (min-width: 768px) and (max-width: 1025px) {
    display: flex;
    /* width: 80vw; */
    justify-content: flex-start;
    align-items: baseline;
  }
`

// used as Unique Id to send to Conjoint API
const tempUserId = uuidv4()

const FutureHurunuiConjoint = {
  questions: [
    //Survey Intro Page
    {
      id: 'intro-page',
      type: welcomePage,
      buttonSize: lgButton.width,
      text: () => {
        return (
          <div>
            <p>Hurunui District Landcare Group annual survey</p>
            <p>
              Thank you for participating in our annual survey – your responses are highly valuable
              to us as they allow us to adapt our programme to better support you.
            </p>
            <p>This survey is estimated to take 15 minutes</p>
          </div>
        )
      },
      // text: (
      //   <StyledTextbox style={{ alignItems: 'baseline' }}>
      //     <StyledP>
      //       Hurunui District Landcare Group annual survey
      //     </StyledP>
      //     <StyledP>
      //       Thank you for participating in our annual survey – your responses are highly valuable to us as they allow us to adapt our programme to better support you.
      //     </StyledP>
      //     <StyledP>This survey is estimated to take 15 minutes</StyledP>
      //   </StyledTextbox>
      // ),
    },
    // Part One Intro Page
    {
      id: 'part-one-intro',
      type: welcomePage,
      buttonSize: lgButton.width,
      text: () => {
        return (
          <div>
            <p>Part one: Evaluation of the past year</p>
            <p>
              This part of the survey focuses on understanding your experiences with Hurunui
              District Landcare Group in the last year.
            </p>
          </div>
        )
      },
      // text: (
      //   <StyledTextbox style={{ alignItems: 'baseline' }}>
      //     <StyledP>
      //       Part one: Evaluation of the past year
      //     </StyledP>
      //     <StyledP>
      //       This part of the survey focuses on understanding your experiences with Hurunui District Landcare Group in the last year.
      //     </StyledP>
      //   </StyledTextbox>
      // ),
    },
    // Part one Q1
    {
      id: '1.1',
      required: true,
      type: multiSelect,
      maxChoices: 11,
      text: 'Which of the following have you accessed/used? (Select all that apply)',
      disclaimer: '*CFA below stands for Catchment Farm Advisor',
      choices: [
        { id: 1, text: 'FEPs', value: 'FEPs' },
        { id: 2, text: 'Winter grazing plan', value: 'Winter grazing plan' },
        { id: 3, text: 'GHG number', value: 'GHG number' },
        { id: 4, text: 'Farm map', value: 'Farm map' },
        { id: 5, text: 'ETS expert services', value: 'ETS expert services' },
        { id: 6, text: 'Biodiversity expert services', value: 'Biodiversity expert services' },
        {
          id: 7,
          text: 'Regulatory / compliance advice',
          value: 'Regulatory / compliance advice',
        },
        { id: 8, text: 'Pre-audit support', value: 'Pre-audit support' },
        { id: 9, text: '1BT planting funding', value: '1BT planting funding' },
        {
          id: 10,
          type: input,
          text: 'CFA* connected you to someone/thing (type here)',
          value: '',
        },
      ]
        .sort(function shuffleArray(a, b) {
          return 0.5 - Math.random()
        })
        .concat([
          {
            id: 11,
            text: 'None of the above',
            value: 'None of the above',
            submit: true,
          },
        ]),

      next: ({ answers }) => {
        if (answers['1.1'].value.length == 1) {
          if (answers['1.1'].value[0].value === 'None of the above') {
            return '1.6'
          } else {
            return '1.3'
          }
        } else {
          return '1.2'
        }
      },
    },
    // Part one Q2
    {
      id: '1.2',
      type: ranking,
      required: true,
      mobileHeight: '4rem',
      buttonSize: xlButton.width,
      text: 'Of the services you used, which were the most valuable?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            { id: 1, text: 'FEPs', value: 'FEPs' },
            { id: 2, text: 'Winter grazing plan', value: 'Winter grazing plan' },
            { id: 3, text: 'GHG number', value: 'GHG number' },
            { id: 4, text: 'Farm map', value: 'Farm map' },
            { id: 5, text: 'ETS expert services', value: 'ETS expert services' },
            { id: 6, text: 'Biodiversity expert services', value: 'Biodiversity expert services' },
            {
              id: 7,
              text: 'Regulatory / compliance advice',
              value: 'Regulatory / compliance advice',
            },
            { id: 8, text: 'Pre-audit support', value: 'Pre-audit support' },
            { id: 9, text: '1BT planting funding', value: '1BT planting funding' },
            {
              id: 10,
              text: 'Catchment farm advisor connected you to someone/thing',
              value: 'Catchment farm advisor connected youto someone/thing',
            },
          ]
          return defaultAnswers
        }
        let sortAnswers = answers['1.1'].value
        for (let i = 0; i < sortAnswers.length; i++) {
          if (sortAnswers[i].id == 10) {
            sortAnswers[
              i
            ].value = `Catchment farm advisor connected you to ${sortAnswers[i].rawValue}`
          }
        }
        let pipedAnswers = sortAnswers.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))
        return _.uniq(pipedAnswers)
      },
    },
    // Part one Q3
    {
      id: '1.3',
      type: multiSelect,
      required: true,
      text: 'How did you access these? (Select all that apply)',
      mobileHeight: '4rem',
      choices: [
        {
          id: 1,
          text: 'One-on-one support from Catchment Farm Advisor (i.e Harry, Rebecca, John)',
          value: 'One-on-one support from Catchment Farm Advisor',
        },
        { id: 2, text: 'A workshop/field day', value: 'A workshop/field day' },
        { id: 3, text: 'Advice from an expert visit', value: 'Advice from an expert visit' },
        { id: 4, text: 'An online webinar', value: 'An online webinar' },
      ],

      next: ({ answers }) => {
        if (answers['1.3'].value.length == 1) {
          return '1.6'
        } else {
          return '1.4'
        }
      },
    },
    // Part one Q4
    {
      id: '1.4',
      type: ranking,
      mobileHeight: '4rem',
      required: true,
      text: 'How would you rank these, in order of how valuable they were to you?',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              text: 'One-on-one support from Catchment Farm Advisor (i.e Harry, Rebecca, John)',
              value: 'One-on-one support from Catchment Farm Advisor (i.e Harry, Rebecca, John)',
            },
            { id: 2, text: 'A workshop/field day', value: 'A workshop/field day' },
            { id: 3, text: 'Advice from an expert visit', value: 'Advice from an expert visit' },
            { id: 4, text: 'An online webinar', value: 'An online webinar' },
          ]
          return defaultAnswers
        }
        let pipedAnswers = answers['1.3'].value.map(item => ({
          id: item.id,
          text: item.value,
          value: item.value,
        }))
        return _.uniq(pipedAnswers)
      },
    },
    // Part one Q5
    {
      id: '1.5',
      type: commentBox,
      required: true,
      // text: 'Why do you rank this first?',
      text: ({ answers }) => {
        let textAnswer = answers['1.4'].value[0]['text']
        let result = `Why did you rank '${textAnswer}' as the most valuable to you in the last question?`
        return result
      },
      placeholder: 'Please type here...',
    },
    // Part one Q6
    {
      id: '1.6',
      required: true,
      type: 'responsiveMatrixTable',
      text: 'How much do you agree or disagree with the following statements?',
      choices: [
        {
          id: 1,
          text: 'I am aware of what has to be done on-farm to manage environmental impact',
          value: 'I am aware of what has to be done on-farm to manage environmental impact',
          html: (
            <p>
              I am <b>aware</b> of what has to be done on-farm to manage environmental impact
            </p>
          ),
        },
        {
          id: 2,
          text:
            'I know what has to be done on-farm to manage environmental impact – for example, I could explain the basics to another farmer',
          value:
            'I know what has to be done on-farm to manage environmental impact – for example, I could explain the basics to another farmer',
          html: (
            <p>
              I <b>know</b> what has to be done on-farm to manage environmental impact – for
              example, I could explain the basics to another farmer
            </p>
          ),
        },
        {
          id: 3,
          text:
            'I could help another farmer manage environmental impact on-farm – if all other barriers were removed (time, money, etc)',
          value:
            'I could help another farmer manage environmental impact on-farm – if all other barriers were removed (time, money, etc)',
          html: (
            <p>
              I could <b>help</b> another farmer manage environmental impact on-farm – if all other
              barriers were removed (time, money, etc)
            </p>
          ),
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
        },
        {
          id: 3,
          text: 'Neither disagree nor agree',
          value: 'Neither disagree nor agree',
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
        },
      ],
      // next: ({ answers }) => {
      //   if (answers['1.6'].value != "OR I haven’t made any changes") {
      //     return '1.9_new_p1'
      //   }
      //   return '1.9'
      // },
    },
    // Part one Q7
    {
      id: '1.7',
      type: multiChoice,
      required: true,
      text:
        'If you made changes on farm this year, to what extent were these changes influenced by your involvement in Hurunui District Landcare Group?',
      choices: [
        { id: 1, text: 'Extremely', value: 'Extremely' },
        { id: 2, text: 'Very much so', value: 'Very much so' },
        { id: 3, text: 'Moderately', value: 'Moderately' },
        { id: 4, text: 'Slightly', value: 'Slightly' },
        { id: 5, text: 'Not at all', value: 'Not at all' },
        { id: 6, text: 'OR I haven’t made any changes', value: 'OR I haven’t made any changes' },
      ],
      next: ({ answers }) => {
        if (
          answers['1.7'].value == 'Not at all' ||
          answers['1.7'].value == 'OR I haven’t made any changes'
        ) {
          return '1.11'
        }
        return '1.8'
      },
    },
    // Part one Q8
    {
      id: '1.8',
      type: multiChoice,
      required: true,
      text:
        'Have you talked with other farmers about the changes you made on farm as a result of Hurunui District Landcare Group?',
      choices: [
        {
          id: 1,
          text: 'Yes',
          value: 'Yes',
        },
        {
          id: 2,
          text: 'No',
          value: 'No',
        },
      ],
      next: ({ answers }) => {
        if (answers['1.8'].id == 1) {
          return '1.9'
        }
        return '1.11'
      },
    },
    // Part one Q9
    {
      id: '1.9',
      type: multiChoice,
      required: true,
      text: 'What change / topic did you talk to other farmers about? (e.g. FEP, fencing)',
      choices: [
        {
          id: 1,
          type: input,
          text: 'Please type here...',
          value: '',
        },
      ],
    },
    // Part one Q10
    {
      id: '1.10',
      type: multiSelect,
      text: 'Where did you talk about this topic? (Select all that apply)',
      required: true,
      choices: [
        {
          id: 1,
          text: 'In an informal discussion',
          value: 'In an informal discussion',
        },
        {
          id: 2,
          text: 'At an on-farm event',
          value: 'At an on-farm event',
        },
        {
          id: 3,
          text: 'In the media',
          value: 'In the media',
        },
        {
          id: 4,
          type: input,
          text: 'Other:',
          placeholder: 'Please type here...',
          value: '',
        },
      ],
    },
    // Part one Q11
    {
      id: '1.11',
      type: multiChoice,
      required: true,
      text: () => {
        return (
          <div>
            <p>To what extent do you agree with the following:</p>
            <p style={{ marginBottom: '0rem' }}>
              As a result of being a part of Hurunui District Landcare Group, I/we are more
              confident about the future of my/our farming business.
            </p>
          </div>
        )
      },
      // text: (
      //   <StyledTextbox style={{ alignItems: 'baseline' }}>
      //     <StyledP>
      //       Please complete the following sentence:
      //     </StyledP>
      //     <StyledP>
      //       As a result of being a part of Hurunui District Landcare Group, my/our farm’s financial sustainability has…
      //     </StyledP>
      //   </StyledTextbox>
      // ),
      choices: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
        },
        {
          id: 3,
          text: 'Neither agree nor disagree',
          value: 'Neither agree nor disagree',
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
        },
      ],
      // choices: [
      //   { id: 1, text: 'Improved substantially', value: 'Improved substantially' },
      //   { id: 2, text: 'Improved somewhat', value: 'Improved somewhat' },
      //   {
      //     id: 3,
      //     text: 'Not changed (but I expect it will improve)',
      //     value: 'Not changed (but I expect it will improve)',
      //   },
      //   {
      //     id: 4,
      //     text: 'Not changed (and I don’t expect it will improve)',
      //     value: 'Not changed (and I don’t expect it will improve)',
      //   },
      //   { id: 5, text: 'Declined somewhat', value: 'Declined somewhat' },
      //   { id: 6, text: 'Declined substantially', value: 'Declined substantially' },
      //   { id: 7, type: input, text: 'Other, please specify', value: 'other' },
      // ],
    },
    // Part one Q12
    {
      id: '1.12',
      required: true,
      type: 'responsiveMatrixTable',
      text: () => {
        return (
          <div>
            {/* <p>
              Please complete the following sentence:
            </p> */}
            <p style={{ marginBottom: '-2rem' }}>
              As a result of being a part of Hurunui District Landcare Group, I feel…
            </p>
          </div>
        )
      },
      choices: [
        {
          id: 1,
          text: 'Confused about what to do next',
          value: 'Confused about what to do next',
        },
        {
          id: 2,
          text: 'Frustrated about the changes that need to be made',
          value: 'Frustrated about the changes that need to be made',
        },
        {
          id: 3,
          text: 'Connected to my community',
          value: 'Connected to my community',
        },
        {
          id: 4,
          text: 'Confident I can implement the necessary changes',
          value: 'Confident I can implement the necessary changes',
        },
        {
          id: 5,
          text: 'Hopeful about possibilities',
          value: 'Hopeful about possibilities',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Less',
          value: 'Less',
        },
        {
          id: 3,
          text: 'Equally',
          value: 'Equally',
        },
        {
          id: 5,
          text: 'More',
          value: 'More',
        },
        {
          id: 5,
          text: 'OR I never felt this way',
          value: 'OR I never felt this way',
        },
      ],
    },
    // Part Two Intro Page
    {
      id: 'part-two-intro',
      type: welcomePage,
      buttonSize: lgButton.width,
      text: () => {
        return (
          <div>
            <p>Part two: Planning our future direction</p>
            <p>
              The Hurunui District Landcare Groups (HDLG) current funding has reached its halfway
              mark and we are now looking at what the HDLG could or should offer to its members
              after June 2023 when our MPI funding ends.
            </p>
            <p>
              This part of the survey aims to establish the value you place on different services
              HDLG offers. From the survey we will develop a plan for HDLG's future – this will be
              presented at our AGM in the middle of the year.
            </p>
          </div>
        )
      },
      // text: (
      //   <StyledTextbox style={{ alignItems: 'baseline' }} >
      //     <StyledP>
      //       Part two: Planning our future direction
      //     </StyledP>
      //     <StyledP>
      //       The Hurunui District Landcare groups Future Hurunui project has reached its halfway mark - at this halfway point, it is timely that we look ahead to the future of HDLG post MPI funding.
      //     </StyledP>
      //     <StyledP>
      //       To ensure we can continue to add value to our members’ businesses in the future, we are considering a number of options and this part of the survey aims to determine what you value as a member. We will collate your answers and have a proposal for members at our AGM in the middle of the year.
      //     </StyledP>
      //   </StyledTextbox >
      // ),
    },
    // Part two Q1
    {
      id: '2.1',
      type: multiSelect,
      buttonSize: lgButton.width,
      required: true,
      mobileHeight: '4rem',
      text: 'Which of the following would you be willing to pay for? (Select all that apply)',
      choices: [
        {
          id: 1,
          text: 'Attend workshops/field days',
          value: 'Attend workshops/field days',
        },
        {
          id: 2,
          text: 'Receive advice from an expert visit (e.g. ETS consultant, ecologist)',
          value: 'Receive advice from an expert visit (e.g. ETS consultant, ecologist)',
        },
        {
          id: 3,
          text:
            'Receive one on one support from Catchment Farm Advisor (i.e. Harry, Rebecca or John)',
          value:
            'Receive one on one support from Catchment Farm Advisor (i.e. Harry, Rebecca or John)',
        },
        {
          id: 4,
          text: 'Attend an online webinar',
          value: 'Attend an online webinar',
        },
        {
          id: 5,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
    },
    // Part two Q2 conjoint part intro page
    {
      id: '2.2_conjoint_intro',
      type: welcomePage,
      text: () => {
        return (
          <div>
            <p>Making choices</p>
            <p>
              The following questions are a bit challenging – they will ask you to make some
              trade-offs! We ask that you persevere through this.
            </p>
            <p>
              Please note: the dollar values are random and do not describe what it would cost the
              HDLG to deliver a specific service. By picking one of the two options you will help us
              refine what services you value the most.
            </p>
          </div>
        )
      },
      // text:
      //   'The following questions are a bit challenging – they will ask you to make some trade-offs! We ask that you persevere through this. Please note the values assigned are arbitrary but will help us understand where value lies for you.',
    },
    // Part two Q2 conjoint analysis
    {
      id: 'services-conjoint',
      type: conjointAnalysis,
      hideUi: true,
      maxChoices: 10,
      buttonSize: xlButton.width,
      label: false,
      useIcon: false,
      levelsTextAlign: 'center',
      neutralText: 'No support and no cost',
      removeInfo: true,
      choices: [
        {
          id: 1,
          value: 'Someone local looking out for your interests',
          cardPriority: 1,
          levels: [
            {
              id: 2,
              neutral: true,
              value: 'HDLG doesn’t provide a strong local voice representing your interests',
              text: '',
            },
            {
              id: 1,
              value: 'HDLG provides a strong local voice representing your interests',
              text: (
                <span>
                  A <strong>strong local voice</strong> representing your interests
                </span>
              ),
            },
          ],
        },
        {
          id: 2,
          value: 'One-on-one support',
          cardPriority: 1,
          levels: [
            {
              id: 3,
              neutral: true,
              value:
                'Catchment farm advisors (e.g. Harry, John, Rebecca) don’t provide one-on-one support',
              text: '',
            },
            {
              id: 2,
              value: 'Telephone advice from catchment farm advisors (e.g. Harry, John, Rebecca)',
              text: (
                <span>
                  <strong>Telephone advice</strong> from catchment farm advisors (e.g. Harry, John,
                  Rebecca)
                </span>
              ),
            },
            {
              id: 1,
              value:
                'One-on-one on-farm advice from catchment farm advisors (e.g. Harry, John, Rebecca)',
              text: (
                <span>
                  One-on-one <strong>on-farm</strong> advice from catchment farm advisors (e.g.
                  Harry, John, Rebecca)
                </span>
              ),
            },
          ],
        },
        {
          id: 3,
          value: 'Expert/technical advice (e.g. ETS)',
          cardPriority: 1,
          levels: [
            {
              id: 3,
              neutral: true,
              value: 'HDLG doesn’t provide support for accessing technical experts (e.g. ETS)',
              text: '',
            },
            {
              id: 2,
              value: 'Access to technical experts (e.g ETS)',
              text: (
                <span>
                  <strong>Access to</strong> technical experts (e.g ETS)
                </span>
              ),
            },
            {
              id: 1,
              value: 'Access to and funding for technical experts (e.g ETS)',
              text: (
                <span>
                  <strong>Access to and funding for</strong> technical experts (e.g ETS)
                </span>
              ),
            },
          ],
        },
        {
          id: 4,
          value: 'Farm plans (e.g. freshwater, winter grazing, biodiversity)',
          cardPriority: 1,
          levels: [
            {
              id: 4,
              neutral: true,
              value: 'HDLG doesn’t provide farm planning support',
              text: '',
            },
            {
              id: 3,
              value: 'Support to understand farm planning ',
              text: (
                <span>
                  Support to <strong>understand</strong> farm planning
                </span>
              ),
            },
            {
              id: 2,
              value: 'Support to understand and create a farm plan',
              text: (
                <span>
                  Support to <strong>understand and create</strong> a farm plan
                </span>
              ),
            },
            {
              id: 1,
              value: 'Support to understand, create and maintain a farm plan',
              text: (
                <span>
                  Support to <strong>understand, create and maintain</strong> a farm plan
                </span>
              ),
            },
          ],
        },
        {
          id: 5,
          value: 'cost',
          cardPriority: 2,
          hideAndBefore: true,
          levels: [
            { id: 5, value: '$3000', text: 'Costing you $3000' },
            { id: 4, value: '$2000', text: 'Costing you $2000' },
            { id: 3, value: '$1000', text: 'Costing you $1000' },
            { id: 2, value: '$500', text: 'Costing you $500' },
            { id: 1, neutral: true, value: '$0', text: 'Costing you $0' },
          ],
        },
      ],
      levels: [],
      // text: (
      //   <Textbox>
      //     <Text>Which of these 2 do you prefer?</Text>
      //   </Textbox>
      // ),
      text: 'Which of these would you rather Hurunui District Landcare Group  provides?',
      // disclaimer:
      //   'Note: the dollar values are random and do not describe what it would cost the HDLG to deliver a specific service. By picking one of the two options you will help us refine what services you value the most.',
    },
    // Part two Q3
    {
      id: '2.3',
      text: 'What would you be willing to pay for the current service, per year?',
      required: true,
      type: multiChoice,
      choices: [
        {
          id: 1,
          text: 'Nothing',
          value: 'Nothing',
        },
        {
          id: 2,
          text: '$1-$500',
          value: '$1-$500',
        },
        {
          id: 3,
          text: '$501-$1,000',
          value: '$501-$1000',
        },
        {
          id: 4,
          text: '$1,001-$1,500',
          value: '$1001-$1500',
        },
        {
          id: 5,
          text: '$1,501-$2,000',
          value: '$1501-$2000',
        },
        {
          id: 6,
          text: '$2,001-$2,500',
          value: '$2001-$2500',
        },
        {
          id: 7,
          text: '$2,501-$3,000',
          value: '$2501-$3000',
        },
        {
          id: 8,
          text: '$3,001 or above',
          value: '$3001 or above',
        },
      ],
    },
    // Part two Q4
    {
      id: '2.4',
      text: 'Any other comments?',
      type: commentBox,
      placeholder: 'Please type here...',
    },
    // Part two Q5
    {
      id: '2.5',
      text: () => {
        return (
          <div>
            <p>What is your name?</p>
            <p
              style={{
                fontStyle: 'italic',
                fontSize: '14px',
                color: 'rgba(0,0,0,0.65',
                fontWeight: '400',
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
              }}
            >
              It would be useful to know your full name so that we (HDLG) can link your responses
              with our administration records to understand who (e.g. farm type and location) needs
              which services. Please be rest assured that your personal information will not be
              shared further and no identifiable information will be shared with MPI.
            </p>
          </div>
        )
      },
      required: true,
      type: multiChoice,
      choices: [
        {
          id: 1,
          type: input,
          text: 'Full name',
          value: '',
        },
        {
          id: 2,
          text: 'I don’t want to provide this',
          value: 'I don’t want to provide this',
        },
      ],
    },
    // // // Part Three Logic Page (user choose to continue or submit?)
    // // {
    // //   id: 'part-three-logic',
    // //   type: multiChoice,
    // //   required: true,
    // //   text: () => {
    // //     return (
    // //       <div>
    // //         <p>
    // //           Thank you for completing the survey so far!
    // //         </p>
    // //         <p>
    // //           If you have time, the following part of the survey has been developed to help evaluate  the return on investment of Ministry of Primary Industries Extension Services (the funding programme that funds Hurunui District Landcare Group at present).
    // //         </p>
    // //         <p>
    // //           This will help prove the value of investing in groups like Hurunui District Landcare Group , and guide the government as to what to fund in future.
    // //         </p>
    // //         <p>
    // //           For the purposes of this section, questions will focus on water, support and community outlook – although we know that other challenges may be more significant for your farm and the Hurunui district.
    // //         </p>
    // //         <p>
    // //           This is estimated to take 3 minutes.
    // //         </p>
    // //         <p>
    // //           Would you like to have a go?
    // //         </p>
    // //       </div>
    // //     )
    // //   },
    // //   // text: (
    // //   //   <StyledTextbox style={{ alignItems: 'baseline' }}>
    // //   //     <StyledP style={{ lineHeight: '1.5', fontSize: '0.9rem' }}>
    // //   //       Thank you for completing the survey so far!
    // //   //     </StyledP>
    // //   //     <StyledP style={{ lineHeight: '1.5', fontSize: '0.9rem' }}>
    // //   //       If you have time, the following part of the survey has been developed to help evaluate  the return on investment of Ministry of Primary Industries Extension Services (the funding programme that funds Hurunui District Landcare Group at present).
    // //   //     </StyledP>
    // //   //     <StyledP style={{ lineHeight: '1.5', fontSize: '0.9rem' }}>
    // //   //       This will help prove the value of investing in groups like Hurunui District Landcare Group , and guide the government as to what to fund in future.
    // //   //     </StyledP>
    // //   //     <StyledP style={{ lineHeight: '1.5', fontSize: '0.9rem' }}>
    // //   //       For the purposes of this section, questions will focus on water, support and community outlook – although we know that other challenges may be more significant for your farm and the Hurunui district.
    // //   //     </StyledP>
    // //   //     <StyledP style={{ lineHeight: '1.5', fontSize: '0.9rem' }}>
    // //   //       This is estimated to take 3 minutes.
    // //   //     </StyledP>
    // //   //     <StyledP style={{ lineHeight: '1.5', fontSize: '0.9rem' }}>
    // //   //       Would you like to have a go?
    // //   //     </StyledP>
    // //   //   </StyledTextbox>
    // //   // ),
    // //   choices: [
    // //     {
    // //       id: 1,
    // //       text: "Yes",
    // //       value: "Yes",
    // //     },
    // //     {
    // //       id: 2,
    // //       text: "No",
    // //       value: "No",
    // //     },
    // //   ],

    // //   next: ({ answers }) => {
    // //     if (answers['part-three-logic'].value == "Yes") {
    // //       return 'part-three-intro'
    // //     }
    // //     return 'survey-end'
    // //   },
    // // },
    // // // Part Three Intro Page
    // // {
    // //   id: 'part-three-intro',
    // //   type: welcomePage,
    // //   buttonSize: lgButton.width,
    // //   text: () => {
    // //     return (
    // //       <div>
    // //         <p>
    // //           Part three: Proving the value of our environmental and wellbeing actions
    // //         </p>
    // //         <p>
    // //           This part of the survey goes beyond Hurunui District Landcare Group, to focus on understanding the long-term value of programmes like ours that provide support to farmers. This will be used to help design better programmes for farmers nation-wide.
    // //         </p>
    // //       </div>
    // //     )
    // //   },
    // //   // text: (
    // //   //   <StyledTextbox style={{ alignItems: 'baseline' }}>
    // //   //     <StyledP>
    // //   //       Part three: Proving the value of our environmental and wellbeing actions
    // //   //     </StyledP>
    // //   //     <StyledP>
    // //   //       This part of the survey goes beyond Hurunui District Landcare Group, to focus on understanding the long-term value of programmes like ours that provide support to farmers. This will be used to help design better programmes for farmers nation-wide.
    // //   //     </StyledP>
    // //   //   </StyledTextbox>
    // //   // ),
    // // },
    // // // Part three Q1
    // // {
    // //   id: 'defining-levels-1b',
    // //   type: multiChoice,
    // //   required: true,
    // //   text: () => {
    // //     return (
    // //       <div>
    // //         <p>
    // //           Before we get started, we’d like to get an idea of what you think of the following areas:
    // //         </p>
    // //         <p>
    // //           The Hurunui farming community outlook is…
    // //         </p>
    // //       </div>
    // //     )
    // //   },
    // //   // text: (
    // //   //   <StyledTextbox style={{ alignItems: 'baseline' }}>
    // //   //     <StyledP>
    // //   //       Before we get started, we’d like to get an idea of what you think of the following areas:
    // //   //     </StyledP>
    // //   //     <StyledP>
    // //   //       The Hurunui farming community outlook is…
    // //   //     </StyledP>
    // //   //   </StyledTextbox>
    // //   // ),
    // //   choices: [
    // //     {
    // //       id: 1,
    // //       text: 'Highly certain, positive',
    // //       value: 'highly certain, positive',
    // //     },
    // //     {
    // //       id: 2,
    // //       text: 'Somewhat uncertain, neutral',
    // //       value: 'Somewhat uncertain, neutral',
    // //     },
    // //     {
    // //       id: 3,
    // //       text: 'Highly uncertain, insecure',
    // //       value: 'highly uncertain, insecure',
    // //     },
    // //   ],
    // // },
    // // // Part three Q2
    // // {
    // //   id: 'defining-levels-1c',
    // //   type: multiChoice,
    // //   required: true,
    // //   text: ' As a farmer, I feel…',
    // //   choices: [
    // //     {
    // //       id: 1,
    // //       text: 'Well supported, positively perceived',
    // //       value: 'well supported, and positively perceived',
    // //     },
    // //     {
    // //       id: 2,
    // //       text: 'Somewhat supported, neutrally perceived',
    // //       value: 'somewhat supported, and neutrally perceived',
    // //     },
    // //     {
    // //       id: 3,
    // //       text: 'Unsupported, negatively perceived',
    // //       value: 'unsupported, and negatively perceived',
    // //     },
    // //   ],
    // // },
    // // // Part three Q3
    // // {
    // //   id: 'defining-levels-1a',
    // //   type: multiChoice,
    // //   required: true,
    // //   text: 'The water quality in my catchment is…',
    // //   choices: [
    // //     {
    // //       id: 1,
    // //       text: 'Not degraded',
    // //       value: 'not degraded',
    // //     },
    // //     {
    // //       id: 2,
    // //       text: 'Slightly degraded',
    // //       value: 'slightly degraded',
    // //     },
    // //     {
    // //       id: 3,
    // //       text: 'Highly degraded',
    // //       value: 'highly degraded',
    // //     },
    // //     {
    // //       id: 4,
    // //       text: "I don't know",
    // //       value: "I don't know",
    // //     },
    // //   ],
    // // },
    // // // Part three Q4 conjoint part intro page
    // // {
    // //   id: '3.4_conjoint_intro',
    // //   type: welcomePage,
    // //   text: 'Like before, the following questions may seem odd – they will ask you to make some trade-offs! This is designed to be more engaging and to help us understand what you value.',
    // // },
    // // // Part three Q4 conjoint analysis
    // // {
    // //   id: 'valuing-outcomes',
    // //   type: conjointAnalysis,
    // //   hideUi: true,
    // //   // choices: 2,
    // //   label: false,
    // //   maxChoices: 10,
    // //   buttonSize: xlButton.width,
    // //   justify: 'space-between',
    // //   choices: ({ answers }) => {
    // //     if (_.isUndefined(answers)) {
    // //       let defaultAnswers = [
    // //         // placeholder
    // //         {
    // //           id: 1,
    // //           value: 'Productivity',
    // //           levels: [
    // //             { id: 1, value: 'level 4', text: 'Productivity level 4' },
    // //             { id: 2, value: 'level 3', text: 'Productivity level 3' },
    // //             { id: 3, value: 'level 2', text: 'Productivity level 2' },
    // //             { id: 4, value: 'level 1', text: 'Productivity level 1' },
    // //           ],
    // //         },
    // //         {
    // //           id: 2,
    // //           value: 'Environmental',
    // //           levels: [
    // //             { id: 1, value: 'level 4', text: 'Environmental level 4' },
    // //             { id: 2, value: 'level 3', text: 'Environmental level 3' },
    // //             { id: 3, value: 'level 2', text: 'Environmental level 2' },
    // //             { id: 4, value: 'level 1', text: 'Environmental level 1' },
    // //           ],
    // //         },
    // //         {
    // //           id: 3,
    // //           value: 'Wellbeing',
    // //           levels: [
    // //             { id: 1, value: 'level 4', text: 'Wellbeing level 4' },
    // //             { id: 2, value: 'level 3', text: 'Wellbeing level 3' },
    // //             { id: 3, value: 'level 2', text: 'Wellbeing level 2' },
    // //             { id: 4, value: 'level 1', text: 'Wellbeing level 1' },
    // //           ],
    // //         },
    // //       ]
    // //       return defaultAnswers
    // //     }

    // //     const calculateLevelMeasure = targetQuestionId => {
    // //       let choices = targetQuestionId.choices
    // //       let userAnswer = targetQuestionId.value
    // //       let indexOfUserAnswer = choices.findIndex(choice => choice.value === userAnswer)
    // //       // let next = (indexOfUserAnswer + 1) % choices.length
    // //       let next = (indexOfUserAnswer - 1) % choices.length

    // //       if (indexOfUserAnswer === 0) {
    // //         // console.log('next undefined')
    // //         // console.log('next now', next)
    // //         next = indexOfUserAnswer
    // //         // console.log('next later', next)
    // //       }

    // //       // console.log('userAnswer', userAnswer)
    // //       // console.log('next', choices[next])
    // //       // console.log('indexedUserAnswer', indexOfUserAnswer)
    // //       let text = choices[next].value
    // //       return text
    // //     }

    // //     const calculateNextBestLevel = targetQuestionId => {
    // //       let choices = targetQuestionId.choices
    // //       let userAnswer = targetQuestionId.value
    // //       let indexOfUserAnswer = choices.findIndex(choice => choice.value === userAnswer)
    // //       // let next = (indexOfUserAnswer + 1) % choices.length
    // //       let next = indexOfUserAnswer

    // //       if (indexOfUserAnswer === 0) {
    // //         // console.log('next undefined')
    // //         // console.log('next now', next)
    // //         next = (indexOfUserAnswer + 1) % choices.length
    // //         console.log('nextbest', choices[next])
    // //         // console.log('next later', next)
    // //       }

    // //       // console.log('userAnswer', userAnswer)
    // //       // console.log('next', choices[next])
    // //       // console.log('indexedUserAnswer', indexOfUserAnswer)
    // //       let text = choices[next].value
    // //       return text
    // //     }

    // //     const standardDeviation = {
    // //       'High country': 50,
    // //       'Hill country': 75,
    // //       'Hard hill country': 54,
    // //       'Intensive farming': 30,
    // //       'Finishing breeding': 68,
    // //       'Mixed finishing': 42,
    // //     }

    // //     // let farmType = answers['farm-type'].value
    // //     // let farmSize = answers['farm-land-size'].value

    // //     const calculateLevelsWaterQuality = targetQuestionId => {
    // //       let userAnswer = targetQuestionId.value

    // //       let levels = [
    // //         {
    // //           id: 1,
    // //           value: `The water quality in my catchment is ${calculateLevelMeasure(
    // //             answers['defining-levels-1a'],
    // //           )}`,
    // //           text: `The water quality in my catchment is ${calculateLevelMeasure(
    // //             answers['defining-levels-1a'],
    // //           )}`,
    // //         },
    // //         {
    // //           id: 2,
    // //           value: `The water quality in my catchment is ${calculateNextBestLevel(
    // //             answers['defining-levels-1a'],
    // //           )}`,
    // //           text: `The water quality in my catchment is ${calculateNextBestLevel(
    // //             answers['defining-levels-1a'],
    // //           )}`,
    // //         },
    // //       ]
    // //       if (userAnswer === "I don't know") {
    // //         levels = [
    // //           {
    // //             id: 1,
    // //             value: `The water quality in my catchment is not degraded`,
    // //             text: `The water quality in my catchment is not degraded`,
    // //           },
    // //           {
    // //             id: 2,
    // //             value: `The water quality in my catchment is slightly degraded`,
    // //             text: `The water quality in my catchment is slightly degraded`,
    // //           },
    // //           {
    // //             id: 3,
    // //             value: `The water quality in my catchment is highly degraded`,
    // //             text: `The water quality in my catchment is highly degraded`,
    // //           },
    // //         ]
    // //       }
    // //       return levels
    // //     }

    // //     let pipedAnswersA = [
    // //       {
    // //         id: 1,
    // //         value: '',
    // //         text: '',
    // //         levels: calculateLevelsWaterQuality(answers['defining-levels-1a']),
    // //       },
    // //       {
    // //         id: 2,
    // //         value: '',
    // //         text: '',
    // //         levels: [
    // //           {
    // //             id: 1,
    // //             value: `The Hurunui farming community outlook is ${calculateLevelMeasure(
    // //               answers['defining-levels-1b'],
    // //             )}`,
    // //             text: `The Hurunui farming community outlook is ${calculateLevelMeasure(
    // //               answers['defining-levels-1b'],
    // //             )}`,
    // //             // icon: <IconRevenue />,
    // //           },
    // //           {
    // //             id: 2,
    // //             value: `The Hurunui farming community outlook is ${calculateNextBestLevel(
    // //               answers['defining-levels-1b'],
    // //             )}`,
    // //             text: `The Hurunui farming community outlook is ${calculateNextBestLevel(
    // //               answers['defining-levels-1b'],
    // //             )}`,
    // //             // icon: <IconRevenue />,
    // //           },
    // //         ],
    // //       },
    // //       {
    // //         id: 3,
    // //         value: '',
    // //         text: '',
    // //         levels: [
    // //           {
    // //             id: 1,
    // //             value: `New Zealand farmers are ${calculateLevelMeasure(
    // //               answers['defining-levels-1c'],
    // //             )} by the public`,
    // //             text: `New Zealand farmers are ${calculateLevelMeasure(
    // //               answers['defining-levels-1c'],
    // //             )} by the public`,
    // //             // icon: <IconRevenue />,
    // //           },
    // //           {
    // //             id: 2,
    // //             value: `New Zealand farmers are ${calculateNextBestLevel(
    // //               answers['defining-levels-1c'],
    // //             )} by the public`,
    // //             text: `New Zealand farmers are ${calculateNextBestLevel(
    // //               answers['defining-levels-1c'],
    // //             )} by the public`,
    // //             // icon: <IconRevenue />,
    // //           },
    // //         ],
    // //       },
    // //       // {
    // //       //   id: 4,
    // //       //   value: '',
    // //       //   text: '',
    // //       //   levels: [
    // //       //     {
    // //       //       id: 1,
    // //       //       value: `Local farm supply stores, assistance & services are ${calculateLevelMeasure(
    // //       //         answers['defining-levels-1d'],
    // //       //       )}`,
    // //       //       text: `Local farm supply stores, assistance & services are ${calculateLevelMeasure(
    // //       //         answers['defining-levels-1d'],
    // //       //       )}`,
    // //       //       // icon: <IconRevenue />,
    // //       //     },
    // //       //     {
    // //       //       id: 2,
    // //       //       value: `Local farm supply stores, assistance & services are ${calculateNextBestLevel(
    // //       //         answers['defining-levels-1d'],
    // //       //       )}`,
    // //       //       text: `Local farm supply stores, assistance & services are ${calculateNextBestLevel(
    // //       //         answers['defining-levels-1d'],
    // //       //       )}`,
    // //       //       // icon: <IconRevenue />,
    // //       //     },
    // //       //   ],
    // //       // },
    // //       {
    // //         id: 4,
    // //         value: '',
    // //         text: '',
    // //         levels: [
    // //           {
    // //             id: 5,
    // //             value: `Cost $100,000`,
    // //             text: `Cost $100,000`,
    // //           },
    // //           {
    // //             id: 4,
    // //             value: `Cost $50,000`,
    // //             text: `Cost $50,000`,
    // //           },
    // //           {
    // //             id: 3,
    // //             value: `Cost $25,000`,
    // //             text: `Cost $25,000`,
    // //           },
    // //           {
    // //             id: 2,
    // //             value: `Cost $10,000`,
    // //             text: `Cost $10,000`,
    // //           },
    // //           {
    // //             id: 1,
    // //             value: `Cost $0`,
    // //             text: `Cost $0`,
    // //           },
    // //         ],
    // //       },
    // //     ]
    // //     return pipedAnswersA
    // //   },
    // //   // icons: [
    // //   //   { id: 1, 1: <IconRevenue />, 2: 'id 1 - icon-2' },
    // //   //   { id: 2, 1: <IconRevenue />, 2: 'id 2 - icon-2' },
    // //   //   { id: 3, 1: <IconRevenue />, 2: 'id 3 icon-2' },
    // //   //   { id: 4, 1: <IconRevenue />, 2: 'id 4 icon-2' },
    // //   // ],
    // //   text: 'Which of these 2 do you prefer?',
    // //   // text: (
    // //   //   <StyledTextbox style={{ alignItems: 'baseline' }}>
    // //   //     <StyledP>
    // //   //       Which of these 2 do you prefer?
    // //   //     </StyledP>
    // //   //   </StyledTextbox>
    // //   // ),
    // // },
    // Survey End Page
    {
      id: 'survey-end',
      type: endPage,
      text: () => {
        return (
          <Textbox style={{ padding: '0px' }}>
            <p>Thank you for completing the survey!</p>

            <p>
              We recognise how busy our farmers are, so we truly appreciate the time you have taken.{' '}
            </p>
            <p>
              Your responses will be invaluable to Hurunui District Landcare Group, as they will
              help us decide what services to provide our farmers in upcoming years.
            </p>
            <p>
              Any further thoughts, please contact your Catchment Farm Advisor (Harry, Rebecca or
              John).
            </p>

            {/* <p>Like this survey and want to create your own? Let&apos;s Confer!</p> */}
            <a
              href="https://confer.online"
              rel="noreferrer"
              target="_blank"
              style={{ color: '#E82D7C', textDecoration: 'underline' }}
            >
              <p>Like this survey and want to create your own? Let&apos;s Confer!</p>
            </a>
            <div style={{ fontSize: '16px' }}>
              <img width="255px" src={conferLogo} alt="confer-logo.jpg" />
            </div>
          </Textbox>
        )
      },
    },
  ],
  settings: {
    surveyName: 'fh-conjoint',
    // primaryColour: '#afd135',
    primaryColour: '#05B153',
    uuid: tempUserId,

    // hideButton: true,
    otherColour: '#72a174',
    hideSubmit: true,
    // turn this off during production
    showQid: false,
    specialEffects: true,
    logoFirstPageOnly: true,
    logoStyle: {
      marginTop: '1rem',
    },
    companyLogo: {
      images: [{ image: HCFLogo }],
    },
    logoStyle: {
      marginTop: '1rem',
    },
    logoLocation: 'space-between',
    // fullscreen: true,
    leftBackgroundImage,
    // backgroundImage: leftBackgroundImage,
    // backgroundImage: backgroudImage,
    progressBar: true,
  },
}

export default FutureHurunuiConjoint
