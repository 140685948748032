import React from 'react'

import {
  responsiveMatrix,
  multiChoice,
  imageChoice,
  welcomePage,
  multiSelect,
  input,
  singleTextbox,
} from '../question-types/QuestionTypes'
import styled from 'styled-components/macro'
import leftBackgroundImage from '../../assets/images/Bcito/left-banner.jpg' // Todo compress image
import bcito from '../../assets/images/Bcito/bcito-cropped.png'
import scarlatti from '../../assets/images/Bcito/scarlatti-logo.png'
import conferLogo from '../../assets/images/Confer-logo.png'
import { SmallText, Textbox } from '../../assets/styles/global'
import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import { ReactComponent as Email } from '../../assets/images/Bcito/email.svg'
import { ReactComponent as Phone } from '../../assets/images/phone.svg'
import { lgButton } from '../ui/Layout/ButtonContainer'
// import { ReactComponent as Phone } from '../../assets/images/Bcito/phone.svg'

// const IconPhone = styled(Phone)`
//   width: 45px;
//   height: 80px;
// `

const IconPhone = styled(Phone)`
  width: 45px;
  height: 80px;
`
const IconEmail = styled(Email)`
  width: 45px;
  height: 80px;
`
const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`

const BCITO = {
  questions: [
    {
      id: 'intro-page',
      type: welcomePage,
      text: (
        <Textbox>
          <h1 style={{ color: 'rgba(0, 0, 0, 0.65) ' }}> BCITO Survey</h1>
          <p>Page info stuff</p>
          <p>
            If you’re unsure about any of the questions, please just give your best estimate in your
            answer.
          </p>
        </Textbox>
      ),
    },
    {
      id: 'current-records-show',
      type: multiChoice,
      buttonSize: lgButton.width,
      text: (
        <Textbox>
          <h3 style={{ color: 'rgba(0, 0, 0, 0.65) ', fontWeight: 600, fontSize: 16 }}>
            What is your current situation
          </h3>
          <SmallText>
            Please select the statement below that best describes your situation
          </SmallText>
        </Textbox>
      ),
      choices: [
        {
          id: 1,
          text: "I've left my employer and BCITO apprenticeship / training",
          value: 'Left training',
        },
        {
          id: 2,
          text:
            "I've started working for a new employer and have restarted my BCITO training, or plan to soon",
          value: 'Restarted training',
        },
        {
          id: 3,
          text: "I'm looking for a new apprenticeship so I can finish my BCITO training",
          value: 'Looking for new apprenticeship',
        },
        {
          id: 4,
          text: "I'm still with the same employer and still doing my apprenticeship",
          value: 'Same apprenticeship / employer',
        },
        {
          id: 5,
          text: 'I have left my trade and moved to another in the construction industry',
          value: 'Left trade, but staying within construction',
        },
        {
          id: 6,
          text: 'I am taking a break from my BCITO training',
          value: 'Taking a break from training',
        },
        {
          id: 7,
          text: 'I have left the construction industry',
          value: 'Left the industry',
        },
        {
          id: 8,
          text: 'I think I completed my apprenticeship',
          value: 'I think I completed my apprenticeship',
        },
      ],
    },
    {
      id: 'reasons-for-stopping',
      type: multiChoice,
      text: 'What were the main reason for stopping your apprenticeship?',
      next: ({ answers }) => {
        if (answers['reasons-for-stopping'].id === 1) {
          return 'personal-issues'
        }
        if (answers['reasons-for-stopping'].id === 2) {
          return 'employment-issues'
        }
        if (answers['reasons-for-stopping'].id === 3) {
          return 'learning-issues'
        }
        return 'personal-issues'
      },
      choices: [
        {
          id: 1,
          value: 'Personal issues',
          text: 'Personal issues',
        },
        {
          id: 2,
          value: 'Employment issues',
          text: 'Employment issues',
        },
        {
          id: 3,
          value: 'Lack of learning support',
          text: 'Lack of learning support',
        },
      ],
    },
    {
      id: 'personal-issues',
      text: 'Can you specify which personal reasons caused you to stop your apprenticeship?',
      type: multiSelect,
      next: () => {
        return 'could-we-help'
      },
      disclaimer: 'Select all that apply',
      choices: [
        {
          id: 1,
          value: 'Lost interest / enthusiasm',
          text: 'Lost interest / enthusiasm',
        },
        {
          id: 2,
          value: 'Family commitments',
          text: 'Family commitments',
        },
        {
          id: 3,
          value: 'Personal injury',
          text: 'Personal injury',
        },
        {
          id: 4,
          value: 'Workplace bullying / harassment',
          text: 'Workplace bullying / harassment',
        },
        {
          id: 5,
          value: "Didn't get along with my employer",
          text: "Didn't get along with my employer",
        },
        {
          id: 6,
          value: 'Financial hardship',
          text: 'Financial hardship',
        },
        {
          id: 7,
          value: "Apprenticeship wasn't what I expected",
          text: "Apprenticeship wasn't what I expected",
        },
        {
          id: 8,
          value: 'Moving away / overseas',
          text: 'Moving away / overseas',
        },
        { id: 9, text: 'Other, please specify', value: 'other', type: input },
      ],
    },
    {
      id: 'employment-issues',
      text: 'Can you specify which employment reasons caused you to stop your apprenticeship?',
      type: multiSelect,
      next: () => {
        return 'could-we-help'
      },
      disclaimer: 'Select all that apply',
      choices: [
        {
          id: 1,
          value: 'Made redundant / asked to leave',
          text: 'Made redundant / asked to leave',
        },
        {
          id: 2,
          value: "Employer's business failed",
          text: "Employer's business failed",
        },
        {
          id: 3,
          value: 'Employer retired / closed the business',
          text: 'Employer retired / closed the business',
        },
        {
          id: 4,
          value: 'Got a better offer from another employer',
          text: 'Got a better offer from another employer',
        },
        {
          id: 5,
          value: 'Decided to start my own business',
          text: 'Decided to start my own business',
        },
        {
          id: 6,
          value: 'Workplace injury',
          text: 'Workplace injury',
        },
        {
          id: 7,
          value: 'Visa / immigration issues',
          text: 'Visa / immigration issues',
        },
        {
          id: 8,
          value: 'Workplace bullying / harassment',
          text: 'Workplace bullying / harassment',
        },
        {
          id: 9,
          value: "Didn't get along with my employer",
          text: "Didn't get along with my employer",
        },
        { id: 10, text: 'Other, please specify', value: 'other', type: input },
      ],
    },
    {
      id: 'learning-issues',
      text:
        'Can you specify which learning support reasons caused you to stop your apprenticeship?',
      type: multiSelect,
      next: () => {
        return 'could-we-help'
      },
      disclaimer: 'Select all that apply',
      choices: [
        {
          id: 1,
          value: 'Lack of support from my employer',
          text: 'Lack of support from my employer',
        },
        {
          id: 2,
          value: 'Lack of support from BCITO',
          text: 'Lack of support from BCITO',
        },
        {
          id: 3,
          value: 'Lack of support from my TA',
          text: 'Lack of support from my TA',
        },
        {
          id: 4,
          value: 'Book work / theory difficulties',
          text: 'Book work / theory difficulties',
        },
        { id: 5, text: 'Other, please specify', value: 'other', type: input },
      ],
    },
    {
      id: 'could-we-help',
      text: 'Was there anything BCITO could have done to help you during this time?',
      type: multiChoice,
      next: ({ answers }) => {
        if (answers['could-we-help'].id === 1) {
          return 'how-could-we-help'
        }
        return 'contact-apprentice'
      },
      choices: [
        {
          id: 1,
          text: 'Yes',
          value: 'Yes',
          fill: '#50d250',
          icon: <IconCheck fill="currentColor" />,
        },
        {
          id: 2,
          text: 'No',
          value: 'No',
          fill: '#1890ff',
          icon: <IconCross fill="currentColor" />,
        },
      ],
    },
    {
      id: 'how-could-we-help',
      text: 'How could BCITO have helped?',
      type: multiSelect,
      disclaimer: 'Select all that apply',
      choices: [
        {
          id: 1,
          value: 'Help with book work / theory',
          text: 'Help with book work / theory',
        },
        {
          id: 2,
          value: 'More TA visits',
          text: 'More TA visits',
        },
        {
          id: 3,
          value: 'Understand my needs better',
          text: 'Understand my needs better',
        },
        {
          id: 4,
          value: 'Help me set goals / training plan',
          text: 'Help me set goals / training plan',
        },
        {
          id: 5,
          value: 'More contact and communication',
          text: 'More contact and communication',
        },
        {
          id: 6,
          value: 'Help with employer issues',
          text: 'Help with employer issues',
        },
        { id: 7, text: 'Other, please specify', value: 'other', type: input },
      ],
    },
    {
      id: 'contact-apprentice',
      type: multiChoice,
      next: ({ answers }) => {
        if (answers['contact-apprentice'].id === 1) {
          return 'contact-me'
        }
        return 'matrix-BCITO'
      },
      text:
        'Would you like BCITO to put in touch with employers so that you might finish our training?',
      choices: [
        {
          id: 1,
          text: 'Yes',
          value: 'Yes',
          fill: '#50d250',
          icon: <IconCheck fill="currentColor" />,
        },
        {
          id: 2,
          text: 'No',
          value: 'No',
          fill: '#1890ff',
          icon: <IconCross fill="currentColor" />,
        },
      ],
    },
    {
      id: 'contact-me',
      text: 'What is the best way to contact you?',
      type: multiChoice,
      choices: [
        {
          id: 1,
          value: 'Phone',
          icon: <IconPhone fill="currentColor" />,

          fill: '#50d250',

          // image: 'Bcito/phone.svg',
          text: 'Phone',
        },
        {
          id: 2,
          value: 'Email',
          icon: <IconEmail fill="currentColor" />,
          // fill: '#50d250',
          image: 'Bcito/email.svg',
          text: 'Email',
        },
      ],
    },
    {
      id: 'matrix-BCITO',
      type: responsiveMatrix,
      text: 'How satisfied are you that the BCITO programme provided:',
      options: [
        {
          id: 1,
          value: 'Very dissatisfied',
        },
        {
          id: 2,
          value: 'Dissatisfied',
        },
        {
          id: 3,
          value: 'Neutral',
        },
        { id: 4, value: 'Satisfied' },
        { id: 5, value: 'Very satisfied' },
      ],
      choices: [
        {
          id: 1,
          text: 'The right support for you to be successful in your training programme',
          value: 'The right support for you to be successful in your training programme',
        },
        {
          id: 2,
          text: 'The right skills for you to be successful in your career',
          value: 'The right skills for you to be successful in your career',
        },
        {
          id: 3,
          text: 'Useful resources and workbooks',
          value: 'Useful resources and workbooks',
        },
        {
          id: 4,
          text: 'A fair assessment process',
          value: 'A fair assessment process',
        },
        {
          id: 5,
          text: 'Good value for money',
          value: 'Good value for money',
        },
        {
          id: 6,
          text: 'Overall satisfaction',
          value: 'Overall satisfaction',
        },
      ],
    },
    {
      // develop mobile friendly component
      id: 'recommend-bcito',
      type: 'multiRadioList',
      submit: true,
      numbersOnly: true,
      text: 'How likely are you to recommend the BCITO apprentice programme?',
      choices: [
        {
          id: 1,
          leftText: 'Very unlikely',
          rightText: 'Very likely',
          centerText: 'Neutral',
          answerChoices: [
            { id: 0, value: 0 },
            { id: 1, value: 1 },
            { id: 2, value: 2 },
            { id: 3, value: 3 },
            { id: 4, value: 4 },
            { id: 5, value: 5 },
            { id: 6, value: 6 },
            { id: 7, value: 7 },
            { id: 8, value: 8 },
            { id: 9, value: 9 },
            { id: 10, value: 10 },
          ],
        },
      ],
    },
    {
      id: 'reasons-for recommending',
      type: 'commentBox',
      text: 'What is the main reason for your recommendation or not recommending?',
      label: 'My main reason is..',
    },
    {
      id: 'matrix-ta-bcito',
      type: responsiveMatrix,
      text: 'How satisfied are you that the BCITO programme provided:',
      options: [
        {
          id: 1,
          value: 'Very poor',
        },
        {
          id: 2,
          value: 'Poor',
        },
        {
          id: 3,
          value: 'Neutral',
        },
        { id: 4, value: 'Good' },
        { id: 5, value: 'Excellent' },
      ],
      choices: [
        {
          id: 1,
          text: 'The advice and direction from your TA',
          value: 'The advice and direction from your TA',
        },
        {
          id: 2,
          text: 'Access to your TA',
          value: 'Access to your TA',
        },
        {
          id: 3,
          text: "Advance notice of TA's visit",
          value: "Advance notice of TA's visit",
        },
        {
          id: 4,
          text: 'The understanding your TA had of your training needs',
          value: 'The understanding your TA had of your training needs',
        },
        {
          id: 5,
          text: 'Advice from TA with bookwork or practical work',
          value: 'Advice from TA with bookwork or practical work',
        },
        {
          id: 6,
          text: 'Consistency of advice and service amongst TAs',
          value: 'Consistency of advice and service amongst TAs',
        },
        {
          id: 7,
          text: 'Coaching / mentoring support from your TA',
          value: 'Coaching / mentoring support from your TA',
        },
        {
          id: 8,
          text: 'Their professional conduct and courtesy to both you and your employer',
          value: 'Their professional conduct and courtesy to both you and your employer',
        },
      ],
    },
    {
      id: 'ta-visits',
      type: 'multiRadioList',
      submit: true,
      text: 'How many times has a TA visited you in the last 12 months you were in training?',
      choices: [
        {
          id: 1,
          answerChoices: [
            { id: 0, value: 'TA has not visited in last 12 months ' },
            { id: 1, value: '1 visits' },
            { id: 2, value: '2 visits' },
            { id: 3, value: '3 visits' },
            { id: 4, value: '4 visits' },
            { id: 5, value: '5 visits' },
            { id: 6, value: 'More than 5 visits' },
          ],
        },
      ],
    },
    {
      id: 'assessed-skills',
      type: multiChoice,
      text: 'Which of the following best describes when your skills were assessed?',
      choices: [
        {
          id: 1,
          value: 'Assessed too soon after learning the skills',
          text: 'Assessed too soon after learning the skills',
        },
        {
          id: 2,
          value: 'Assessment timing was about right',
          text: 'Assessment timing was about right',
        },
        {
          id: 3,
          value: 'Assessed too late after learning the skills',
          text: 'Assessed too late after learning the skills',
        },
        {
          id: 4,
          value: 'I was never assessed',
          text: 'I was never assessed',
        },
      ],
    },
    {
      id: 'training-cost',
      type: multiChoice,
      text: 'Did you pay all, some, or none of your BCITO training costs?',
      choices: [
        {
          id: 1,
          value: 'I paid for all of it',
          text: 'I paid for all of it',
        },
        {
          id: 2,
          value: 'I paid for some of it',
          text: 'I paid for some of it',
        },
        {
          id: 3,
          value: 'I paid for none of it',
          text: 'I paid for none of it',
        },
      ],
    },
    {
      id: 'which-devices-online',
      text: 'What types of devices do you use to go online?',
      type: multiSelect,
      disclaimer: 'Select all that apply',
      choices: [
        {
          id: 1,
          value: 'Smart phone',
          text: 'Smart phone',
        },
        {
          id: 2,
          value: 'Tablet',
          text: 'Tablet',
        },
        {
          id: 3,
          value: 'Laptop or desktop PC',
          text: 'Laptop or desktop PC',
        },
        {
          id: 4,
          value: "I don't go online",
          text: "I don't go online",
        },
      ],
    },
    {
      id: 'how-to-contact',
      text: 'What were you most preferred ways of communicating with BCITO?',
      type: multiSelect,
      disclaimer: 'Please select 3 ',
      limit: 3,
      choices: [
        {
          id: 1,
          value: 'Email',
          text: 'Email',
        },
        {
          id: 2,
          value: 'Traditional post',
          text: 'Traditional post',
        },
        {
          id: 3,
          value: 'Text message',
          text: 'Text message',
        },
        {
          id: 4,
          value: 'Phone',
          text: 'Phone',
        },
        {
          id: 5,
          value: 'Social media',
          text: 'Social media',
        },
        {
          id: 6,
          value: 'Face to face',
          text: 'Face to face',
        },
        { id: 7, text: 'Other, please specify', value: 'other', type: input },
      ],
    },
    {
      id: 'social-media-use',
      text: 'Please select which of the following social media websites you use',
      type: multiSelect,
      disclaimer: 'Select all that apply',
      choices: [
        {
          id: 1,
          value: 'Facebook',
          text: 'Facebook',
        },
        {
          id: 2,
          value: 'LinkedIn',
          text: 'LinkedIn',
        },
        {
          id: 3,
          value: 'Pinterest',
          text: 'Pinterest',
        },
        {
          id: 4,
          value: 'Instagram',
          text: 'Instagram',
        },
        {
          id: 5,
          value: 'Twitter',
          text: 'Twitter',
        },
        {
          id: 6,
          value: 'WhatsApp',
          text: 'WhatsApp',
        },
        {
          id: 7,
          value: 'Snapchat',
          text: 'Snapchat',
        },
        {
          id: 8,
          value: 'None of the above',
          text: 'None of the above',
        },
        { id: 9, text: 'Other, please specify', value: 'other', type: input },
      ],
    },
    {
      id: 'training-concerns',
      type: multiChoice,
      text:
        'Thinking about your training with BCITO, were there any specific issues or concerns that needed to be addressed?',
      next: ({ answers }) => {
        if (answers['training-concerns'].id === 1) {
          return 'reasons-for-concerns'
        }
        return 'bcito-national-representative'
      },
      choices: [
        {
          id: 1,
          text: 'Yes',
          value: 'Yes',
          fill: '#50d250',
          icon: <IconCheck fill="currentColor" />,
        },

        {
          id: 2,
          text: 'No',
          value: 'No',
          fill: '#1890ff',
          icon: <IconCross fill="currentColor" />,
        },
      ],
    },
    {
      id: 'reasons-for-concerns',
      type: singleTextbox,
      text: 'What were the specific issues or concerns that need to be addressed?',
      label: 'The main issues were...',
    },
    {
      id: 'bcito-national-representative',
      type: multiChoice,
      text:
        'Would you like BCITO to put in touch with employers so that you might finish our training?',
      choices: [
        {
          id: 1,
          value: 'Yes',
          text: 'Yes',
          image: 'Bcito/check.svg',
          fill: '#50d250',
          icon: <IconCheck fill="currentColor" />,
        },
        {
          id: 2,
          text: 'No',
          value: 'No',
          image: 'Bcito/cross.svg',
          fill: '#1890ff',
          icon: <IconCross fill="currentColor" />,
        },
      ],
    },
    {
      id: 'bcito-consent',
      type: multiChoice,
      text:
        'Would you like BCITO to put in touch with employers so that you might finish our training?',
      choices: [
        {
          id: 1,
          value: 'Yes',
          text: 'Yes - you can identify me',
          image: 'Bcito/check.svg',
          icon: <IconCheck fill="currentColor" />,
          fill: '#50d250',
        },
        {
          id: 2,
          value: 'No',
          text: 'No - I prefer to remain anonymous',
          fill: '#1890ff',
          icon: <IconCross fill="currentColor" />,

          image: 'Bcito/cross.svg',
        },
      ],
    },
    {
      id: 'prize-draw',
      type: multiChoice,
      text: (
        <Textbox>
          <h3 style={{ color: 'rgba(0, 0, 0, 0.65) ', fontWeight: 600 }}>
            Would you like to be included in the quarterly prize draw for a $500 Prezzie Card?
          </h3>
          <SmallText>
            Please note the winner&apos;s contact details need to be passed onto BCITO for delivery
            of the prize. These details will not be linked to any survey answers.
          </SmallText>
        </Textbox>
      ),
      choices: [
        {
          id: 1,
          value: 'Yes',
          text: 'Yes - please enter me in the prize draw!',
          image: 'Bcito/check.svg',
          icon: <IconCheck fill="currentColor" />,
          fill: '#50d250',
        },
        {
          id: 2,
          value: 'No',
          text: 'No thanks',
          fill: '#1890ff',
          icon: <IconCross fill="currentColor" />,
          image: 'Bcito/cross.svg',
        },
      ],
    },
    {
      id: 'end-page',
      type: welcomePage,
      text: (
        <Textbox>
          <h1 style={{ color: 'rgba(0, 0, 0, 0.65) ', fontWeight: 600 }}>
            You have reached the end of the survey
          </h1>
          <div>
            This survey has been brought to you by <img src={conferLogo} alt="confer-logo.jpg" />
          </div>
        </Textbox>
      ),
    },
  ],
  settings: {
    surveyName: 'bcito',
    writeToFirestore: false,
    // showQid: true,
    primaryColour: '#009ec9',
    secondaryColour: '#f38231',
    contrastColour: '#fff',
    hideSubmit: true,
    companyLogo: {
      images: [{ image: bcito }, { image: scarlatti }],
    },
    logoLocation: 'space-between',
    leftBackgroundImage,
    backgroundImage: leftBackgroundImage,
  },
}

export default BCITO
