import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import Survey from './surveys/SurveyArray'
import OutdatedBrowser from './ui/OutdatedBrowser'

// Dynamic Imports
const Home = lazy(() => import('./website/index'))
const AdminRouter = lazy(() => import('./dashboard/AdminRouter'))
const SurveyWrapper = lazy(() => import('./reducers/SurveyWrapper'))
const NotFound = lazy(() => import('./question-types/NotFound'))
const TestMain = lazy(() => import('./test/Main'))

const Router = () => {
  return (
    /* fallback prop accepts any react element while waiting for component to load */
    <Suspense fallback={<p>Loading...</p>}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/survey/:surveyName"
            render={props => {
              const lowerName = props.match.params.surveyName.toLowerCase()
              if (lowerName in Survey) {
                const { questions, settings } = Survey[lowerName]
                if (window.MSCompatibleInfo != null) {
                  console.log('internet explorer')
                  return <OutdatedBrowser />
                }
                return <SurveyWrapper questions={questions} settings={settings} />
              }
              return <NotFound />
            }}
          />
          <Route path="/test" render={() => <TestMain />} />
          <Route path="/:adminRoutes" render={() => <AdminRouter />} />
          <Route exact path="/" render={() => {
            window.location.href= "https://v2.confer.online"
            return null
          }} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  )
}

Router.propTypes = {
  match: PropTypes.instanceOf(Object),
}
export default Router
