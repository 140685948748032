/* eslint-disable no-unused-vars */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'
import React from 'react'
import styled from 'styled-components/macro'
import 'typeface-open-sans'
import companyLogo from '../../assets/images/StepChange/DairyNZ.jpg'
import backgroundVideo from '../../assets/images/StepChange/video.mp4'
import { xlButton } from '../ui/Layout/ButtonContainer'

import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import {
  endPage,
  imageRadioList,
  input,
  multiChoice,
  multiSelect,
  welcomePage,
} from '../question-types/QuestionTypes'

import { Textbox } from '../../assets/styles/global'

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  p {
    margin-bottom: 0;
  }
  align-self: baseline;
  /* align-items: center; */
`
const GreenText = styled.p`
  font-size: 1rem;
  color: #6fb139;
  font-family: 'Open Sans', sans-serif;
`

const StyledCheckOutlined = styled(CheckOutlined)`
  color: #46e4b5;
`

const StyledCloseOutlined = styled(CloseOutlined)`
  color: #5b3bdf;
`

const Q = styled.span`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`
const SectionInfo = styled.p`
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #9b9b9b;
`

const YellowText = styled.p`
  font-size: 1rem;
  color: #eaae19;
`

const DarkBlueText = styled.p`
  font-size: 1rem;
  color: #474f40;
  letter-spacing: 0.1em;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  width: 90vw;
`

const RedText = styled.p`
  font-size: 1rem;
  color: #84367a;
`

const BlueText = styled.p`
  font-size: 1rem;
  color: #00a7b8;
  font-family: 'Open Sans', sans-serif;
`

const DisclaimerText = styled.p`
  font-size: 1rem;
  width: 90vw;
  margin-bottom: 1rem;
  /* font-style: italic; */
`

const DarkGreenText = styled.p`
  font-size: 1rem;
  color: #4a6f2f;
  font-family: 'Open Sans', sans-serif;
`

const Grey = styled.h1`
  color: #535d52;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.1em;
`

const StyledP = styled.p`
  font-size: 20px;
`

const StandardText = styled.p`
  margin-bottom: 0.1rem;
  margin-top: 0;
`

const BoldText = styled.p`
  font-weight: 700;
  margin-top: 1rem;
`

const Container = styled.div`
  background-color: red;
  height: 100vh;
  width: 100vw;
`
const StepChangeCo_update = {
  questions: [
    // Intro-page
    {
      id: 'intro-page',
      type: welcomePage,
      text: (
        <Textbox>
          <p>
            DairyNZ are keen to understand what actions you are taking towards environmental
            sustainability.
          </p>
          <p>The survey will take 5 – 10 mins to complete.</p>
          <p>All answers you provide are anonymous.</p>
        </Textbox>
      ),
    },
    // Question 1 - region
    {
      id: 'region',
      required: true,
      type: multiChoice,
      verticalGrid: true,
      section: (
        <Wrapper>
          <BlueText>1. Which region are you in?</BlueText>
        </Wrapper>
      ),
      disclaimer: 'Please circle only one answer.',
      choices: [
        {
          id: 1,
          value: 'Northland',
          text: 'Northland',
        },
        {
          id: 2,
          value: 'Waikato',
          text: 'Waikato',
        },
        {
          id: 3,
          value: 'Bay of Plenty',
          text: 'Bay of Plenty',
        },
        {
          id: 4,
          value: 'Taranaki',
          text: 'Taranaki',
        },
        {
          id: 5,
          value: 'Lower North Island',
          text: 'Lower North Island',
        },
        {
          id: 6,
          value: 'Top of South Island',
          text: 'Top of South Island',
        },
        {
          id: 7,
          value: 'West Coast',
          text: 'West Coast',
        },
        {
          id: 8,
          value: 'Canterbury / North Otago',
          text: 'Canterbury / North Otago',
        },
        {
          id: 9,
          value: 'Southland / South Otago',
          text: 'Southland / South Otago',
        },
      ],
    },
    // Question 2 - action towards environmental sustainability
    {
      id: 'actions',
      required: true,
      buttonSize: xlButton.width,
      type: multiChoice,
      verticalGrid: true,
      mobileHeight: '7rem',
      height: '4rem',
      section: (
        <Wrapper>
          <BlueText>
            2. Which best describes you in relation to taking action towards environmental
            sustainability?
          </BlueText>
        </Wrapper>
      ),
      disclaimer: 'Please tick only one answer.',
      choices: [
        {
          id: 1,
          text: "It's not on my radar",
          value: "It's not on my radar",
        },
        {
          id: 2,
          text:
            "I don't think I should have to, or don't want to, take action towards environmental sustainability",
          value:
            "I don't think I should have to, or don't want to, take action towards environmental sustainability",
        },
        {
          id: 3,
          text:
            "I'm willing to take action towards environmental sustainability, but I don't know where to start",
          value:
            "I'm willing to take action towards environmental sustainability, but I don't know where to start",
        },
        {
          id: 4,
          text:
            "I'm willing to take action towards environmental sustainability, and I know where to start, but it's not a priority so I haven't done anything yet",
          value:
            "I'm willing to take action towards environmental sustainability, and I know where to start, but it's not a priority so I haven't done anything yet",
        },
        {
          id: 5,
          text:
            "I've taken some action towards environmental sustainability but I'm not confident it's enough to meet long term obligations and whether I can be profitable as well",
          value:
            "I've taken some action towards environmental sustainability but I'm not confident it's enough to meet long term obligations and whether I can be profitable as well",
        },
        {
          id: 6,
          text:
            "I've adapted my farm system to be both environmentally and financially sustainable and I feel confident about the future",
          value:
            "I've adapted my farm system to be both environmentally and financially sustainable and I feel confident about the future",
        },
      ],
    },
    // Question 3 - True or False - FEP
    {
      id: 'TrueFalse_FEP',
      required: true,
      buttonSize: xlButton.width,
      type: imageRadioList,
      section: (
        <Wrapper>
          <BlueText>3. Are each of the following are true for you?</BlueText>
        </Wrapper>
      ),
      disclaimer: 'Please tick only one answer for each row.',
      text: 'Freshwater farm plan / Farm Environment Plan (FEP)',
      choices: [
        {
          id: 1,
          text:
            'I have a Freshwater Farm Plan that was developed with input from a qualified advisor',
          value:
            'I have a Freshwater Farm Plan that was developed with input from a qualified advisor',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              icon: <IconCheck fill="currentColor" />,
              fill: '#50d250',
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              icon: <IconCross fill="currentColor" />,
              fill: '#1890ff',
            },
          ],
        },
        {
          id: 2,
          text:
            'We are recording when we complete the actions recommended in the Freshwater Farm Plan.',
          value:
            'We are recording when we complete the actions recommended in the Freshwater Farm Plan.',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              icon: <IconCheck fill="currentColor" />,
              fill: '#50d250',
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              icon: <IconCross fill="currentColor" />,
              fill: '#1890ff',
            },
          ],
        },
      ],
    },
    // Question 3 - True or False - Nutrient management
    {
      id: 'TrueFalse_Nutrient',
      required: true,
      type: imageRadioList,
      section: (
        <Wrapper>
          <BlueText>3. Are each of the following are true for you?</BlueText>
        </Wrapper>
      ),
      disclaimer: 'Please tick only one answer for each row.',
      text: 'Nutrient management',
      choices: [
        {
          id: 3,
          text: 'A nutrient budget is completed every year and reviewed with a qualified advisor.',
          value: 'A nutrient budget is completed every year and reviewed with a qualified advisor.',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              icon: <IconCheck fill="currentColor" />,
              fill: '#50d250',
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              icon: <IconCross fill="currentColor" />,
              fill: '#1890ff',
            },
          ],
        },
        {
          id: 4,
          text:
            'Soil phosphorous levels are monitored through regular soil testing and maintained at or below agronomic optimum levels.',
          value:
            'Soil phosphorous levels are monitored through regular soil testing and maintained at or below agronomic optimum levels.',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              icon: <IconCheck fill="currentColor" />,
              fill: '#50d250',
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              icon: <IconCross fill="currentColor" />,
              fill: '#1890ff',
            },
          ],
        },
      ],
    },
    // Question 3 - True or False - Water quality
    {
      id: 'TrueFalse_Water_Quality',
      required: true,
      type: imageRadioList,
      section: (
        <Wrapper>
          <BlueText>3. Are each of the following are true for you?</BlueText>
        </Wrapper>
      ),
      disclaimer: 'Please tick only one answer for each row.',
      text: 'Water quality risk assessment',
      choices: [
        {
          id: 5,
          text:
            'I have assessed the risk from all our farm practices to water quality (e.g. from farm dairy effluent, management of critical source areas – where water accumulates during wet periods; water use and irrigation; crop and pasture grazing principles).',
          value:
            'I have assessed the risk from all our farm practices to water quality (e.g. from farm dairy effluent, management of critical source areas – where water accumulates during wet periods; water use and irrigation; crop and pasture grazing principles).',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              icon: <IconCheck fill="currentColor" />,
              fill: '#50d250',
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              icon: <IconCross fill="currentColor" />,
              fill: '#1890ff',
            },
          ],
        },
        {
          id: 6,
          text:
            'For all high and medium risk areas identified we have developed time-bound actions to mitigate impacts.',
          value:
            'For all high and medium risk areas identified we have developed time-bound actions to mitigate impacts.',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              icon: <IconCheck fill="currentColor" />,
              fill: '#50d250',
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              icon: <IconCross fill="currentColor" />,
              fill: '#1890ff',
            },
          ],
        },
      ],
    },
    // Question 3 - True or False - Greenhouse gases
    {
      id: 'TrueFalse_Greenhouse_Gases',
      required: true,
      type: imageRadioList,
      section: (
        <Wrapper>
          <BlueText>3. Are each of the following are true for you?</BlueText>
        </Wrapper>
      ),
      disclaimer: 'Please tick only one answer for each row.',
      text: 'Greenhouse gases',
      choices: [
        {
          id: 7,
          text: 'I understand my greenhouse gas numbers and how they compare.',
          value: 'I understand my greenhouse gas numbers and how they compare.',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              icon: <IconCheck fill="currentColor" />,
              fill: '#50d250',
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              icon: <IconCross fill="currentColor" />,
              fill: '#1890ff',
            },
          ],
        },
        {
          id: 8,
          text: 'I know what to do to reduce my greenhouse gas emissions.',
          value: 'I know what to do to reduce my greenhouse gas emissions.',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              icon: <IconCheck fill="currentColor" />,
              fill: '#50d250',
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              icon: <IconCross fill="currentColor" />,
              fill: '#1890ff',
            },
          ],
        },
      ],
    },
    // Question 3 - True or False - Profitability
    {
      id: 'TrueFalse_Profitability',
      required: true,
      type: imageRadioList,
      section: (
        <Wrapper>
          <BlueText>3. Are each of the following are true for you?</BlueText>
        </Wrapper>
      ),
      disclaimer: 'Please tick only one answer for each row.',
      text: 'Profitability',
      choices: [
        {
          id: 9,
          text:
            'I can see that it is possible for my farm to meet current regulations and remain profitable.',
          value:
            'I can see that it is possible for my farm to meet current regulations and remain profitable.',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              icon: <IconCheck fill="currentColor" />,
              fill: '#50d250',
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              icon: <IconCross fill="currentColor" />,
              fill: '#1890ff',
            },
          ],
        },
      ],
    },
    // Question 4 - management practices that apply
    {
      id: 'practices',
      required: true,
      groupedChoices: true,
      type: multiSelect,
      limit: 4,
      section: (
        <Wrapper>
          <BlueText>
            4. What farm management practices are you applying or have you applied for environmental
            protection in the last two years?
          </BlueText>
        </Wrapper>
      ),
      disclaimer: 'Please tick all that apply.',
      choices: [
        {
          id: 1,
          text: 'Used coated urea products',
          value: 'Used coated urea products',
        },
        {
          id: 2,
          text: 'Fenced off waterways',
          value: 'Fenced off waterways',
        },
        {
          id: 3,
          text: 'Upgraded the effluent system',
          value: 'Upgraded the effluent system',
        },
        {
          id: 4,
          text: 'Retired land',
          value: 'Retired land',
        },
        {
          id: 5,
          text: 'Put in soil moisture meters',
          value: 'Put in soil moisture meters',
        },
        {
          id: 6,
          text: 'Established or preserved wetlands',
          value: 'Established or preserved wetlands',
        },
        {
          id: 7,
          text: 'Put in water meters',
          value: 'Put in water meters',
        },
        {
          id: 8,
          text: 'Provided proof of placement mapping for fertiliser',
          value: 'Provided proof of placement mapping for fertiliser',
        },
        {
          id: 9,
          text: 'Did riparian planting',
          value: 'Did riparian planting',
        },
        {
          id: 10,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
        {
          id: 11,
          text: 'Other action:',
          value: 'other',
          type: input,
        },
      ],
    },
    // End survey
    {
      id: 'end-survey',
      text: 'Thank you for your time.',
      type: endPage,
    },
  ],
  settings: {
    writeToFirestore: true,
    surveyName: 'farmer-action-co-update',
    showQid: true,
    logoFirstPageOnly: true,
    // logoLocation: 'space-between',
    // logoStyle: {
    //   marginTop: '1rem'
    // },
    // companyLogo: {
    //   images: [{ image: logo }]
    // },
    companyLogo,
    objectPosition: 'right',
    primaryColour: '#7ac140',
    useVideo: true,
    backgroundVideo,
    hideSubmit: true,
  },
}

export default StepChangeCo_update
