import React, { Component } from 'react'
import {
  commentBox,
  endPage,
  multiChoice,
  multiSelect,
  multiTextBox,
  ranking,
  responsiveMatrix,
  responsiveMatrixTable,
  responsiveSlider,
  welcomePage,
} from '../question-types/QuestionTypes'
import { Textbox } from '../../assets/styles/global'
import styled from 'styled-components/macro'
import leftBackgroundImage from '../../assets/images/Bragato/bragatoBg.jpg'
import bragatoLogo from '../../assets/images/Bragato/BRILogo.png'
import scarlattiLogo from '../../assets/images/Scarlatti/scarlattiLogo.svg'
import _ from 'lodash'
const Text = styled.p`
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
  margin-bottom: 0rem;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  font-size: 1rem;
  h1 {
    color: #da915a;
    font-size: 1.5rem;
    font-weight: bold;
  }
  ul {
    margin-left: 30px;
    margin: 0 0 0 4rem;
    li {
      color: #da915a;
      span {
        color: black;
      }
    }
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .ant-divider-horizontal {
    margin: 0px 0;
    border-top: 1px solid #c3c3c3;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    .ant-divider-horizontal {
      width: 90vw;
    }
  }
`
const TextSign = styled.div`
  margin: 8rem 3.5rem 0.5rem auto;
  font-style: italic;
  font-size: 0.8rem;
  color: #E4813F;
`
const practices_3_3 = [
  { id: 1, text: 'Cover crop', value: 'Cover crop' },
  { id: 2, text: 'Compost', value: 'Compost' },
  { id: 3, text: 'Side throwing clippings', value: 'Side throwing clippings' },
  { id: 4, text: 'Plants growing in the undervine area', value: 'Plants growing in the undervine area' },
  { id: 5, text: 'Mulch (shredded or not)', value: 'Mulch (shredded or not)' },
  { id: 6, text: 'Sheep integration in the vineyard', value: 'Sheep integration in the vineyard' },
  { id: 7, text: 'Planting or keeping native plants', value: 'Planting or keeping native plants' },
  { id: 8, text: 'Nutrition', value: 'Nutrition' },
  { id: 9, text: 'Irrigation management', value: 'Irrigation management' },
  { id: 10, text: 'Reduce tractor passes', value: 'Reduce tractor passes' },
  { id: 11, text: 'Avoid driving on wet ground', value: 'Avoid driving on wet ground' },
  { id: 12, text: "No-till", value: "No-till" },
  { id: 13, text: 'Management tools to assess soil health', value: 'Management tools to assess soil health' },
].sort(function shuffleArray(a, b) {
  return 0.5 - Math.random()
})
let stopped = []
const updateStopped = ans => {
  stopped.length = 0
  ans.forEach(element => stopped.push(element.text))
}
let factors = []
const updateFactors = specify => {
  factors.length = 0
  specify.forEach(item => factors.push(item.text))
}
const Bragato = {
  questions: [
    //Ending survey page 
    {
      id: 'Survey-Closed',
      type: welcomePage,
      section: (
        <Wrapper>
          <a href="https://bri.co.nz/" target="_blank" rel="noopener noreferrer">
            <img src={bragatoLogo} alt="Bragato logo" width="300" height="60" />
          </a>
          <Textbox style={{ marginTop: '6rem' }}>
            <Text>Thank you for your interest.</Text>
            <p>This survey is now closed and no data will be collected after the 30th of November 2022.</p>
          </Textbox>
        </Wrapper>
      ),
    },
    // Intro Page
    {
      id: 'intro-page',
      type: welcomePage,
      section: (
        <Wrapper>
          <h1>Soil health building practices in vineyards</h1>
          <p>
            This survey is aimed at understanding soil health management practices in New Zealand vineyards.
          </p>
          <p>
            You can find further details about this research project and how your anonymity will be protected in our{' '}
            <u>
              <a
                href="https://www.dropbox.com/s/y9au4ublsonj5xs/Vineyards%20Soil%20Health%20Project-Information%20sheet.pdf?dl=0"
                target="_blank"
                rel="noopener noreferrer"
              >
                information sheet
              </a>
            </u>
            .
          </p>
          <p>By selecting to start the survey, you indicate that you consent to participate in this study and that you understand your answers are being collected for the purpose of this research.</p>
        </Wrapper>
      ),
      nextBtnText: "Start now"
    },
    // // Consent
    // {
    //   id: 'consent',
    //   type: multiChoice,
    //   section: (
    //     <Wrapper style={{ marginBottom: '1rem' }}>
    //       <h1>Electronic consent</h1>
    //       <em>Please select your choice below.</em>
    //       <p style={{ margin: '1rem auto 0 0' }}>By selecting “Agree” I am indicating that:</p>
    //       <ul>
    //         <li>
    //           <span>
    //             I have read the information sheet concerning this project and understand what it is
    //             about.
    //           </span>
    //         </li>
    //         <li>
    //           <span>I understand that I am free to request further information at any stage.</span>
    //         </li>
    //         <li>
    //           <span>All my questions have been answered to my satisfaction. </span>
    //         </li>
    //         <li>
    //           <span>I agree to participate in this study.</span>
    //         </li>
    //       </ul>
    //       <p style={{ margin: '1rem auto 0 0' }}>I know that:</p>
    //       <ul>
    //         <li>
    //           <span>My participation in the survey is voluntary (my choice).</span>
    //         </li>
    //         <li>
    //           <span>I am free to withdraw from the survey at any point.</span>
    //         </li>
    //       </ul>
    //     </Wrapper>
    //   ),
    //   choices: [
    //     {
    //       id: 1,
    //       text: 'Agree',
    //       value: 'Agree',
    //     },
    //   ],
    // },
    // Vineyard Region
    //1.1
    {
      id: '1_1_region',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Vineyard region</h1>
          <p>Which region do you currently work in most of the time for winegrowing?</p>
          <em style={{ margin: '0 0 0.5rem 0' }}>If you work in more than one vineyard, please answer this and the following questions for <b>one vineyard</b> only (of your choice). </em>
          <em style={{ margin: '0 0 0.5rem 0' }}>If you wish to answer these questions for other vineyards you work in, you will have the opportunity to complete this questionnaire again at the end of this survey.</em>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Northland', value: 'Northland' },
        { id: 2, text: 'Auckland', value: 'Auckland' },
        { id: 3, text: 'Waikato / Bay of plenty', value: 'Waikato / Bay of plenty' },
        { id: 4, text: 'Gisborne', value: 'Gisborne' },
        { id: 5, text: "Hawke's Bay", value: "Hawke's Bay" },
        { id: 6, text: 'Wairarapa', value: 'Wairarapa' },
        { id: 7, text: 'Nelson', value: 'Nelson' },
        { id: 8, text: 'Marlborough', value: 'Marlborough' },
        { id: 9, text: 'North Canterbury', value: 'North Canterbury' },
        { id: 10, text: 'Waitaki Valley', value: 'Waitaki Valley' },
        { id: 11, text: 'Central Otago', value: 'Central Otago' },
        { id: 12, text: 'Otago', value: 'Otago' },
        { id: 14, type: 'input', text: 'Other, please specify', value: '' },
        { id: 13, text: 'Prefer not to answer', value: 'Prefer not to answer', submit: true },
      ],
    },
    // Vineyard Soil
    //2.1
    {
      id: '2_1_soil_type',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Vineyard soil</h1>
          <p>What is the main soil texture of the vineyard?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Loam',
          value: 'Loam'
        },
        {
          id: 2,
          text: 'Clay loam',
          value: 'Clay loam'
        },
        {
          id: 3,
          text: 'Silt loam',
          value: 'Silt loam'
        },
        {
          id: 4,
          text: 'Sandy loam',
          value: 'Sandy loam'
        },
        {
          id: 5,
          text: 'Clay',
          value: 'Clay'
        },
        {
          id: 6,
          text: 'Sand',
          value: 'Sand'
        },
        {
          id: 7,
          text: 'Silt',
          value: 'Silt'
        },
        {
          id: 8,
          text: "I don't know",
          value: "I don't know"
        },
      ],
    },
    //2.2
    {
      id: '2_2_soil_type',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Vineyard soil</h1>
          <p>What is the main soil stoniness of the vineyard?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Gravelly',
          value: 'Gravelly'
        },
        {
          id: 2,
          text: 'No stones',
          value: 'No stones'
        },
        {
          id: 3,
          text: 'Slightly stony',
          value: 'Slightly stony'
        },
        {
          id: 4,
          text: 'Moderately stony',
          value: 'Moderately stony'
        },
        {
          id: 5,
          text: 'Very stony',
          value: 'Vert stony'
        },
        {
          id: 6,
          text: "I don't know",
          value: "I don't know"
        },
      ],
    },
    // Soil health practices
    //3.1
    {
      id: '3_1_soil_importance',
      section: (
        <Wrapper>
          <h1>Soil health practices</h1>
          <p>How important is the soil health of the vineyard to you at this time?</p>
        </Wrapper>
      ),
      type: responsiveMatrix,
      required: true,
      useValues: true,
      choices: [{ id: 1, text: '', value: '' },],
      options: [
        { id: 1, text: 'Not important', value: 'Not important', submit: true },
        { id: 2, text: 'Not a priority at the moment', value: 'Not a priority at the moment', submit: true },
        { id: 3, text: 'Important', value: 'Important', submit: true },
        { id: 4, text: 'Crucial', value: 'Crucial', submit: true },
      ],
    },
    //3.2
    {
      id: '3_2_soil_quality',
      section: (
        <Wrapper>
          <h1>Soil health practices</h1>
          <p>How would you rate soil health on the vineyard?</p>
          <em>
            Choose between 1 and 6, 1 meaning poor soil health and 6 meaning an ideal soil for wine{' '}
          </em>
        </Wrapper>
      ),
      type: responsiveSlider,
      default: 1,
      max: 7,
      required: true,
      choices: [
        { id: 1, text: '1<br />Poor', value: '1 Poor' },
        { id: 2, text: '2', value: '2' },
        { id: 3, text: '3', value: '3' },
        { id: 4, text: '4', value: '4' },
        { id: 5, text: '5', value: '5' },
        { id: 6, text: '6<br />Ideal', value: '6 Ideal' },
        { id: 7, text: "I don't know", value: "I don't know" },
      ],
    },
    //3.3
    {
      id: '3_3_current_practices',
      section: (
        <Wrapper>
          <h1>Soil health practices</h1>
          <p>
            Which practices are <b>currently</b> applied in the vineyard to improve or maintain soil health?
          </p>
          <em>Tick all that apply</em>
        </Wrapper>
      ),
      type: multiSelect,
      required: true,
      choices: practices_3_3
        .sort(function shuffleArray(a, b) {
          return 0.5 - Math.random()
        })
        .concat([
          { id: 17, type: 'input', text: 'Other, please specify', value: '' },
          { id: 18, type: 'input', text: 'Other, please specify', value: '' },
          { id: 19, text: 'None', value: 'None', submit: true },
        ]),
      next: ({ answers }) => {
        if (answers['3_3_current_practices'].value.length === 1) {
          if (answers['3_3_current_practices'].value[0].value === 'None') {
            return '3_7_stopped_practices'
          }
          return '3_5_successful'
        }
        return '3_4_prioritise'
      },
    },
    //3.4
    {
      id: '3_4_prioritise',
      required: true,
      section: (
        <Wrapper>
          <h1>Soil health practices</h1>
          <p>Which of these practices are prioritised in the vineyard?</p>
        </Wrapper>
      ),
      type: ranking,
      verticalGrid: true,
      choices: ({ answers }) => {
        if (!answers) return []
        let result = answers['3_3_current_practices'].value
        result.forEach((item) => {
          if (item.value.includes('Other, please specify')) {
            item.value = item.rawValue
            item.text = item.rawValue
          }
        })
        return result
      },
    },
    //3.5
    {
      id: '3_5_successful',
      section: (
        <Wrapper>
          <h1>Soil health practices</h1>
          <p>Do you think this / these practice(s) are successful?</p>
        </Wrapper>
      ),
      type: responsiveMatrixTable,
      required: true,
      choices: ({ answers }) => {
        if (!answers) return []
        let result = answers['3_3_current_practices'].value
        result.forEach((item) => {
          if (item.value.includes('Other, please specify')) {
            item.value = item.rawValue
            item.text = item.rawValue
          }
        })
        return result
      },
      options: [
        {
          id: 1,
          text: 'Not successful at all',
          value: 'Not successful at all',
        },
        {
          id: 2,
          text: 'Not very successful',
          value: 'Not very successful',
        },
        {
          id: 3,
          text: 'A little successful',
          value: 'A little successful',
        },
        {
          id: 4,
          text: 'Very successful',
          value: 'Very successful',
        },
        {
          id: 5,
          text: "I don't know",
          value: "I don't know",
        },
      ],
    },
    //3.6
    {
      id: '3_6_successful_measure',
      section: (
        <Wrapper>
          <h1>Soil health practices</h1>
          <p>How do you evaluate if this / these practice(s) are successful?</p>
          <em>Tick all that apply</em>
        </Wrapper>
      ),
      type: multiSelect,
      required: true,
      choices: [
        {
          id: 1,
          text: 'Physical indicators (water capacity, aggregate stability…)',
          value: 'Physical indicators (water capacity, aggregate stability…)',
        },
        {
          id: 2,
          text: 'Biological indicators (organic matter, soil protein, worms…)',
          value: 'Biological indicators (organic matter, soil protein, worms…)',
        },
        {
          id: 3,
          text: 'Chemical indicators (soil chemical composition, salinity…)',
          value: 'Chemical indicators (soil chemical composition, salinity…)',
        },
        {
          id: 4,
          text: 'Instinct, observation',
          value: 'Instinct, observation',
        },
        {
          id: 5,
          text: 'Knowledge and experience',
          value: 'Knowledge and experience',
        },
        {
          id: 6,
          text: 'Wine quality monitoring',
          value: 'Wine quality monitoring',
        },
        {
          id: 7,
          text: 'Profitability monitoring',
          value: 'Profitability monitoring',
        },
        {
          id: 8,
          text: 'Workload for the team',
          value: 'Workload for the team',
        },
        {
          id: 9,
          text: 'Wellbeing of the team and myself',
          value: 'Wellbeing of the team and myself',
        },
        {
          id: 10,
          text: "I don't know",
          value: "I don't know",
          submit: true,
        },
        {
          id: 12,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
      ],
    },
    //3.7
    {
      id: '3_7_stopped_practices',
      section: (
        <Wrapper>
          <h1>Soil health practices</h1>
          <p>Which of these practices were <b>previously</b> applied in the vineyard with the intention of improving soil health but are no longer used?</p>
          <em>Tick all that apply.</em>
        </Wrapper>
      ),
      type: multiSelect,
      required: true,
      choices: ({ answers }) => {
        if (!answers) return []
        const selected = answers['3_3_current_practices'].value
        const nonSelected = practices_3_3
          .sort(function shuffleArray(a, b) {
            return 0.5 - Math.random()
          })
          .filter(practice => selected.every(select => select.id != practice.id))
          .concat([{ id: 19, text: 'None', value: 'None', submit: true }])
        return nonSelected
      },
      next: ({ answers }) => {
        updateStopped(answers['3_7_stopped_practices'].value)
        if (answers['3_7_stopped_practices'].value[0].text === 'None') {
          return '3_9_general_barriers'
        }
        return '3_8_stop_reason'
      }
    },
    //3.8
    {
      id: '3_8_stop_reason',
      startLoop: stopped,
      endLoop: true,
      required: true,
      loopText: ({ answers, loopIndex }) => {
        return answers['3_7_stopped_practices'].value.map(item => item.text)[loopIndex]
      },
      type: multiSelect,
      section: ({ answers, loopIndex }) => {
        const oneChoice = answers['3_7_stopped_practices'].value.map(item => item.text)[loopIndex]
        return (
          <Wrapper>
            <h1>Soil health practices</h1>
            <p>
              What is the main reason <b style={{ textTransform: 'lowercase' }}>{oneChoice}</b> is no
              longer applied in the vineyard?
            </p>
            <em>Tick all that apply</em>
          </Wrapper>
        )
      },
      choices: ({ answers, loopIndex }) => {
        return [
          {
            id: 1,
            text: 'Vineyard looked messy',
            value: 'Vineyard looked messy',
          },
          {
            id: 2,
            text: 'Too much work',
            value: 'Too much work',
          },
          {
            id: 3,
            text: 'Expensive / financial risk',
            value: 'Expensive / financial risk',
          },
          {
            id: 4,
            text: 'Dangerous for the safety / health of the team and / or myself',
            value: 'Dangerous for the safety / health of the team and / or myself',
          },
          {
            id: 5,
            text: 'Complains from the neighbours (odour, noise, etc.)',
            value: 'Complains from the neighbours (odour, noise, etc.)',
          },
          { id: 6, value: 'Lack of tools', text: 'Lack of tools' },
          { id: 7, value: 'Lack of time', text: 'Lack of time' },
          { id: 8, value: 'Lack of capability / skills', text: 'Lack of capability / skills' },
          {
            id: 9,
            value: 'Lack of capacity (staff shortage)',
            text: 'Lack of capacity (staff shortage)',
          },
          { id: 10, value: 'Lack of knowledge', text: 'Lack of knowledge' },
          { id: 11, value: 'Lack of support', text: 'Lack of support' },
          { id: 12, value: 'Harms soil', text: 'Harms soil' },
          {
            id: 13,
            value: "Doesn't align with my values and / or the team's values",
            text: "Doesn't align with my values and / or the team's values",
          },
          {
            id: 14,
            type: 'input',
            text: 'Other, please specify',
            value: '',
          },
          { id: 15, value: "I am not sure", text: 'I am not sure', submit: true },
          {
            id: 16,
            value: "I didn't participate in the decision",
            text: "I didn't participate in the decision",
            submit: true,
          },
        ]
      },
    },
    //3.9
    {
      id: '3_9_general_barriers',
      type: multiSelect,
      required: true,
      section: (
        <Wrapper>
          <h1>Soil health practices</h1>
          <p>In general, what are the barriers to applying soil health building practices in the vineyard?</p>
          <em>Tick up to three</em>
        </Wrapper>
      ),
      max: 3,
      choices: [
        {
          id: 1,
          text: 'Vineyard looks messy',
          value: 'Vineyard looks messy',
        },
        {
          id: 2,
          text: 'Too much work',
          value: 'Too much work',
        },
        {
          id: 3,
          text: 'Expensive / financial risk',
          value: 'Expensive / financial risk',
        },
        {
          id: 4,
          text: 'Dangerous for the safety / health of the team and / or myself',
          value: 'Dangerous for the safety / health of the team and / or myself',
        },
        {
          id: 5,
          text: 'Complains from the neighbours (odour, noise, etc.)',
          value: 'Complains from the neighbours (odour, noise, etc.)',
        },
        { id: 6, value: 'Lack of tools', text: 'Lack of tools' },
        { id: 7, value: 'Lack of time', text: 'Lack of time' },
        { id: 8, value: 'Lack of capability / skills', text: 'Lack of capability / skills' },
        {
          id: 9,
          value: 'Lack of capacity (staff shortage)',
          text: 'Lack of capacity (staff shortage)',
        },
        { id: 10, value: 'Lack of knowledge', text: 'Lack of knowledge' },
        { id: 11, value: 'Lack of support', text: 'Lack of support' },
        {
          id: 12,
          value: "Doesn't align with my values and / or the team's values",
          text: "Doesn't align with my values and / or the team's values",
        },
        {
          id: 13,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
        { id: 14, value: "I am not sure", text: 'I am not sure', submit: true },
      ],
    },
    //3.10
    {
      id: '3_z10_future_interest',
      required: true,
      section: (
        <Wrapper>
          <h1>Soil health practices</h1>
          <p>Which practices would you be interested in trying in the future?</p>
          <em>Tick up to three</em>
        </Wrapper>
      ),
      type: multiSelect,
      max: 3,
      choices: ({ answers }) => {
        if (!answers) return []
        const selected = answers['3_3_current_practices'].value
        const nonSelected = practices_3_3
          .sort(function shuffleArray(a, b) {
            return 0.5 - Math.random()
          })
          .filter(practice => selected.every(select => select.id != practice.id))
          .concat([
            { id: 17, type: 'input', text: 'Other, please specify', value: '' },
            { id: 18, text: 'None', value: 'None', submit: true },
          ])
        return nonSelected
      },
    },
    //3.11
    {
      id: '3_z11_future_test',
      section: (
        <Wrapper>
          <h1>Soil health practices</h1>
          <p>Which practices would you like to see tested in research trials in New Zealand?</p>
          <em>Tick up to three</em>
        </Wrapper>
      ),
      type: multiSelect,
      max: 3,
      required: true,
      choices: practices_3_3
        .sort(function shuffleArray(a, b) {
          return 0.5 - Math.random()
        })
        .concat([
          { id: 17, type: 'input', text: 'Other, please specify', value: '' },
          { id: 18, text: "None", value: "None", submit: true },
        ]),
    },
    //3.12
    {
      id: '3_z12_research_assess',
      section: (
        <Wrapper>
          <h1>Soil health practices</h1>
          <p>What would you like future research on these practices to assess?</p>
          <em>Tick up to three</em>
        </Wrapper>
      ),
      type: multiSelect,
      max: 3,
      required: true,
      choices: [
        { id: 1, text: 'Profitability', value: 'Profitability' },
        {
          id: 2,
          text: 'Consumer perceptions of quality',
          value: 'Consumer perceptions of quality',
        },
        { id: 3, text: 'Labour quantity', value: 'Labour quantity' },
        { id: 4, text: 'Cost of implementation', value: 'Cost of implementation' },
        { id: 5, text: 'Wine quality', value: 'Wine quality' },
        { id: 6, text: 'Grape quality', value: 'Grape quality' },
        { id: 7, text: 'Pest and disease', value: 'Pest and disease' },
        { id: 8, text: 'Vine yield', value: 'Vine yield' },
        { id: 9, text: 'Soil compaction', value: 'Soil compaction' },
        { id: 10, text: 'Soil carbon levels', value: 'Soil carbon levels' },
        { id: 11, text: 'Soil water holding capacity', value: 'Soil water holding capacity' },
        { id: 12, text: 'Soil pH', value: 'Soil pH' },
        { id: 13, text: 'Nutrition', value: 'Nutrition' },
        { id: 14, type: 'input', text: 'Other, please specify', value: '' },
        { id: 15, text: 'None', value: 'None', submit: true },
      ],
    },
    // Vineyard management
    //4.1
    {
      id: '4_1_agriculture_approach',
      required: true,
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Vineyard management</h1>
          <p>What agricultural approach best describes the practices applied in the vineyard?</p>
        </Wrapper>
      ),
      choices: [
        { id: 1, text: 'Conventional', value: 'Conventional' },
        { id: 2, text: 'Biodynamic', value: 'Biodynamic' },
        { id: 3, text: 'Organic', value: 'Organic' },
        { id: 4, text: 'Integrated', value: 'Integrated' },
        { id: 5, text: 'Sustainable', value: 'Sustainable' },
        { id: 6, text: 'Regenerative', value: 'Regenerative' },
        { id: 7, text: 'Agroecology', value: 'Agroecology' },
        { id: 8, type: 'input', text: 'Other, please specify', value: '' },
        { id: 9, text: "I don't know", value: "I don't know" },
      ],
    },
    //4.2
    {
      id: '4_2_certification',
      required: true,
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Vineyard management</h1>
          <p>Is the vineyard recognised by a certification programme?</p>
          <p>(For example, Organic agriculture, Sustainable Winegrowing NZ™, etc.)</p>
        </Wrapper>
      ),
      choices: [
        { id: 1, text: 'Yes', value: 'Yes' },
        { id: 2, text: 'No', value: 'No' },
        { id: 3, text: "I don't know", value: "I don't know" },
      ],
      next: ({ answers }) => {
        if (answers['4_2_certification'].value === 'Yes') {
          return '4_3_certification_details'
        } else {
          return '5_1_start_time'
        }
      },
    },
    //4.3
    {
      id: '4_3_certification_details',
      required: true,
      type: multiSelect,
      section: (
        <Wrapper>
          <h1>Vineyard management</h1>
          <p>What is the vineyard certification(s)?</p>
          <em>Tick all that apply</em>
        </Wrapper>
      ),
      choices: [
        { id: 1, text: 'Organic agriculture', value: 'Organic agriculture' },
        { id: 2, text: 'Sustainable Winegrowing NZ™', value: 'Sustainable Winegrowing NZ™' },
        { id: 3, text: 'AsureQuality', value: 'AsureQuality' },
        { id: 4, text: 'BioGro-NZ', value: 'BioGro-NZ' },
        { id: 5, text: 'Demeter', value: 'Demeter' },
        { id: 6, text: 'ISO 140001', value: 'ISO 140001' },
        { id: 7, type: 'input', text: 'Other, please specify', value: '' },
      ],
      next: ({ answers }) => {
        if (answers['3_3_current_practices'].value[0].value != 'None') {
          return '5_1_start_time'
        }
        return '5_2_adopt_reason'
      },
    },
    // Motivation and support
    //5.1
    {
      id: '5_1_start_time',
      required: true,
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>Motivation and support</h1>
          <p>When did soil health building practices start to be implemented in the vineyard?</p>
        </Wrapper>
      ),
      choices: [
        { id: 1, text: 'Not currently implemented', value: 'Not currently implemented' },
        { id: 2, text: "Haven't started yet, but planning to", value: "Haven't started yet, but planning to" },
        { id: 3, text: 'Less than 1 year ago', value: 'Less than 1 year ago' },
        { id: 4, text: 'Between 1 and 2 years ago', value: 'Between 1 and 2 years ago' },
        { id: 5, text: 'Between 3 and 5 years ago', value: 'Between 3 and 5 years ago' },
        { id: 6, text: 'Between 6 and 10 years ago', value: 'Between 6 and 10 years ago' },
        { id: 7, text: 'Between 11 and 20 years ago', value: 'Between 11 and 20 years ago' },
        { id: 8, text: 'More than 20 years ago', value: 'More than 20 years ago' },
        { id: 9, text: "I don't remember", value: "I don't remember" },
      ],
      next: ({ answers }) => {
        let ansID = answers['5_1_start_time'].id
        if (ansID === 1 || ansID === 2) {
          return '5_3_info_support'
        }
        return '5_2_adopt_reason'
      },
    },
    //5.2
    {
      id: '5_2_adopt_reason',
      required: true,
      type: multiSelect,
      section: (
        <Wrapper>
          <h1>Motivation and support</h1>
          <p>Why have soil health building practice been implemented in the vineyard?</p>
          <em>Tick all that apply</em>
        </Wrapper>
      ),
      choices: [
        {
          id: 1,
          text: 'To be certified by a sustainability standard',
          value: 'To be certified by a sustainability standard',
        },
        { id: 2, text: 'To sell more wine', value: 'To sell more wine' },
        { id: 3, text: 'To produce a high-quality wine', value: 'To produce a high-quality wine' },
        { id: 4, text: "To please clients' demands", value: "To please clients' demands" },
        {
          id: 5,
          text: "To align my production with my values/my team's values",
          value: "To align my production with my values/my team's values",
        },
        { id: 6, text: 'To reduce the production cost', value: 'To reduce the production cost' },
        { id: 7, text: 'To increase retail price', value: 'To increase retail price' },
        { id: 8, text: 'To take care of the land', value: 'To take care of the land' },
        {
          id: 9,
          text: 'To reduce our environmental impact',
          value: 'To reduce our environmental impact',
        },
        {
          id: 10,
          text: 'To improve my wellbeing / the wellbeing of my team',
          value: 'To improve my wellbeing / the wellbeing of my team',
        },
        { id: 11, type: 'input', text: 'Other, please specify', value: '' },
        { id: 12, text: "I don't know", value: "I don't know", submit: true },
      ],
    },
    //5.3
    {
      id: '5_3_info_support',
      required: true,
      type: multiSelect,
      section: (
        <Wrapper>
          <h1>Motivation and support</h1>
          <p>Where do you get information and / or support about soil health?</p>
          <em>Tick all that apply</em>
        </Wrapper>
      ),
      choices: [
        { id: 1, text: 'MPI', value: 'MPI' },
        { id: 2, text: 'MfE', value: 'MfE' },
        {
          id: 3,
          text: 'Technical representatives (Farmlands, PGG, etc.)',
          value: 'Technical representatives (Farmlands, PGG, etc.)',
        },
        { id: 4, text: 'New Zealand Winegrowers', value: 'New Zealand Winegrowers' },
        {
          id: 5,
          text: 'Another industry organisation',
          value: 'Another industry organisation',
        },
        {
          id: 6,
          text: 'A winegrower association',
          value: 'A winegrower association',
        },
        { id: 7, text: 'Team members', value: 'Team members' },
        { id: 8, text: 'Other winegrower', value: 'Other winegrower' },
        {
          id: 9,
          text: 'An interest-based community (e.g., winegrower group, catchment group, etc.)',
          value: 'An interest-based community (e.g., winegrower group, catchment group, etc.)',
        },
        {
          id: 10,
          text: 'Website(s)',
          value: 'Website(s)',
        },
        {
          id: 11,
          text: 'Magazine(s), newsletter(s), journal(s), podcast(s)',
          value: 'Magazine(s), newsletter(s), journal(s), podcast(s)',
        },
        { id: 12, text: 'Friends and family', value: 'Friends and family' },
        { id: 13, text: 'Growers Liaison', value: 'Growers Liaison' },
        {
          id: 14,
          text: 'I have never received support / information',
          value: 'I have never received support / information',
          submit: true,
        },

        { id: 15, type: 'input', text: 'Other, please specify', value: '' },
        { id: 16, text: "I don't remember", value: "I don't remember", submit: true },
      ],
      next: ({ answers }) => {
        const chosedFactors = answers['5_3_info_support'].value
        const specify = chosedFactors.filter(item => [3, 5, 6, 9, 10, 11].includes(item.id))
        updateFactors(specify)
        if (specify.length > 0) {
          return '5_3_1_support_details'
        }
        return '6_1_size'
      },
    },
    //5.3.1
    {
      id: '5_3_1_support_details',
      startLoop: factors,
      endLoop: true,
      type: commentBox,
      placeholder: 'Please specify',
      section: ({ answers, loopIndex }) => {
        const oneChoice = factors[loopIndex]
        return (
          <Wrapper>
            <h1>Motivation and support</h1>
            <p>
              Can you name <b style={{ textTransform: 'lowercase' }}>{oneChoice}</b> where you get
              information and/or support about soil health?
            </p>
          </Wrapper>
        )
      },
      choices: [{ id: 0, type: 'input', text: 'Please specify', value: 'defualt-None' }],
    },
    // About the vineyard
    //6.1
    {
      id: '6_1_size',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>About the vineyard</h1>
          <p>What is the size of the vineyard that you work at?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: '3 hectares or less', value: '3 hectares or less' },
        { id: 2, text: '4 to 10 hectares', value: '4 to 10 hectares' },
        { id: 3, text: '11 to 20 hectares', value: '11 to 20 hectares' },
        { id: 4, text: '21 to 50 hectares', value: '21 to 50 hectares' },
        { id: 5, text: 'More than 50 hectares', value: 'More than 50 hectares' },
        { id: 6, text: "I don't know", value: "I don't know" },
      ],
    },
    //6.2
    {
      id: '6_2_num_people',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>About the vineyard</h1>
          <p>How many people (including you) permanently work on the vineyard (full-time)?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Less than 1 full time equivalent', value: 'Less than 1 full time equivalent' },
        { id: 2, text: '1 - 2', value: '1 to 2' },
        { id: 3, text: '3 - 4', value: '3 to 4' },
        { id: 4, text: '5 - 6', value: '5 to 6' },
        { id: 5, text: '7 - 10', value: '7 to 10' },
        { id: 6, text: '11+', value: '11+' },
        { id: 7, text: "I don't know", value: "I don't know" },
      ],
    },
    //6.3
    {
      id: '6_3_contracts_usage',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>About the vineyard</h1>
          <p>Do you use contractors on the vineyard?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Yes',
          value: 'Yes',
        },
        {
          id: 2,
          text: 'No',
          value: 'No',
        },
        { id: 3, text: "I don't know", value: "I don't know" },
      ],
      next: ({ answers }) => {
        if (answers['6_3_contracts_usage'].id === 1) {
          return '6_4_contracted_tasks'
        }
        return '6_5_business'
      }
    },
    //6.4
    {
      id: '6_4_contracted_tasks',
      type: multiSelect,
      section: (
        <Wrapper>
          <h1>About the vineyard</h1>
          <p>What tasks are contracted on the vineyard?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Spraying', value: 'Spraying' },
        { id: 2, text: 'Mowing', value: 'Mowing' },
        { id: 3, text: 'Sowing cover crops', value: 'Sowing cover crops' },
        { id: 4, text: 'Trimming', value: 'Trimming' },
        { id: 5, text: 'Plucking', value: 'Plucking' },
        { id: 6, text: 'Weeding', value: 'Weeding' },
        { id: 7, text: 'Vine work (e.g. wire listing, shoot thinning)', value: 'Vine work (e.g. wire listing, shoot thinning)' },
        { id: 8, type: 'input', text: 'Other, please specify', value: '' },
      ],
    },
    //6.5
    {
      id: '6_5_business',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>About the vineyard</h1>
          <p>Which of the following situations best describes the vineyard business?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'We only grow grapes',
          value: 'We only grow grapes',
        },
        {
          id: 2,
          text: 'We grow grapes, and we make wine to sell as bulk',
          value: 'We grow grapes, and we make wine to sell as bulk',
        },
        {
          id: 3,
          text: 'We grow grapes, and we make wine to sell under our own label',
          value: 'We grow grapes, and we make wine to sell under our own label',
        },
      ],
    },
    // About you
    //7.1
    {
      id: '7_1_role',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>About you</h1>
          <p>What is your current role in the vineyard?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Vineyard assistant', value: 'Vineyard assistant' },
        { id: 2, text: 'Assistant manager', value: 'Assistant manager' },
        { id: 3, text: 'Vineyard manager', value: 'Vineyard manager' },
        { id: 4, text: 'Technician Viticulturalist', value: 'Technician Viticulturalist' },
        { id: 5, text: 'Owner operator', value: 'Owner operator' },
        { id: 6, text: 'Machinery operator', value: 'Machinery operator' },
        { id: 7, type: 'input', text: 'Other, please specify', value: '' },
        { id: 8, text: 'Prefer not to answer', value: 'Prefer not to answer' },
      ],
    },
    //7.2
    {
      id: '7_2_grow_years',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>About you</h1>
          <p>How long you have been involved in wine growing?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Less than 2 years', value: 'Less than 2 years' },
        { id: 2, text: '2 to 4 years', value: '2 to 4 years' },
        { id: 3, text: '5 to 9 years', value: '5 to 9 years' },
        { id: 4, text: '10 to 19 years', value: '10 to 19 years' },
        { id: 5, text: 'More than 20 years', value: 'More than 20 years' },
        { id: 6, text: 'Prefer not to answer', value: 'Prefer not to answer' },
      ],
    },
    //7.3
    {
      id: '7_3_age',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>About you</h1>
          <p>What is your age?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Under 18', value: 'Under 18' },
        { id: 2, text: '18 - 29 years', value: '18 to 29 years' },
        { id: 3, text: '30 - 39 years', value: '30 to 39 years' },
        { id: 4, text: '40 - 49 years', value: '40 to 49 years' },
        { id: 5, text: '50 - 59 years', value: '50 to 59 years' },
        { id: 6, text: '60+ years', value: '60+ years' },
        { id: 7, text: 'Prefer not to answer', value: 'Prefer not to answer' },
      ],
    },
    //7.4
    {
      id: '7_4_gender',
      type: multiChoice,
      section: (
        <Wrapper>
          <h1>About you</h1>
          <p>What gender do you identify with?</p>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'Male', value: 'Male' },
        { id: 2, text: 'Female', value: 'Female' },
        {
          id: 3,
          text: 'Gender variant / Non-conforming',
          value: 'Gender variant / Non-conforming',
        },
        { id: 4, text: 'Other', value: 'Other' },
        { id: 5, text: 'Prefer not to answer', value: 'Prefer not to answer' },
      ],
    },
    //7.5
    {
      id: '7_5_ethnicity',
      type: multiSelect,
      section: (
        <Wrapper>
          <h1>About you</h1>
          <p>What ethnic group(s) do you identify with?</p>
          <em>Tick all that apply.</em>
        </Wrapper>
      ),
      required: true,
      choices: [
        { id: 1, text: 'European / Pākehā', value: 'European / Pākehā' },
        { id: 2, text: 'Māori', value: 'Māori' },
        { id: 3, text: 'Pacific People', value: 'Pacific People' },
        { id: 4, text: 'Asian', value: 'Asian' },
        { id: 5, text: 'Other European', value: 'Other European' },
        {
          id: 6,
          text: 'Middle Eastern / Latin American / African',
          value: 'Middle Eastern / Latin American / African',
        },
        { id: 7, type: 'input', text: 'Other, please specify', value: '' },
        { id: 8, text: 'Prefer not to answer', value: 'Prefer not to answer', submit: true },
      ],
    },
    // Optional contact
    // 8.1
    {
      id: '8_1_contact_email',
      type: multiTextBox,
      section: (
        <Wrapper>
          <h1>Optional contact</h1>
          <p>
            If you are interested in receiving summary of the findings from the research once it is
            completed (early 2023), please enter your email address.
          </p>
        </Wrapper>
      ),
      choices: [{ id: 1, text: '', value: '' }],
      textBelow: (
        <Wrapper>
          <p>It will be kept separately from your responses to ensure your anonymity.</p>
          <em>
            This is entirely optional. Your email address will solely be used for sending a summary of the findings and will not be added to any database.
          </em>
        </Wrapper>
      ),
      next: () => {
        return 'end-page'
      },
    },
    // End-page
    {
      id: 'end-page',
      type: endPage,
      section: (
        <Wrapper>
          <a href="https://bri.co.nz/" target="_blank" rel="noopener noreferrer">
            <img src={bragatoLogo} alt="Bragato logo" width="300" height="60" />
          </a>
          <Textbox style={{ marginTop: '6rem' }}>
            <Text>Thank you for your time</Text>
            <p style={{ marginTop: '1rem' }}>Your responses will inform future research.</p>

            <p>
              If you have additional feedback, please contact{' '}
              <a href="mailto: michelle.barry@bri.co.nz">michelle.barry@bri.co.nz</a>
            </p>

            <p>
              If you wish to complete the survey again for another vineyard, please click on this{' '}
              <a href="https://confer.online/survey/bragato?id=another_vineyard">link</a>.
            </p>
          </Textbox>
          <TextSign>
            <p
              align="right"
            >
              This survey was powered by
            </p>
            <span>
              <a href="https://www.scarlatti.co.nz/" target="_blank" rel="noopener noreferrer">
                <img src={scarlattiLogo} alt="Scarlatti logo" height="40" />
              </a>
            </span>
          </TextSign>
        </Wrapper>
      ),
    },
  ],

  settings: {
    writeToFirestore: true,
    surveyName: 'bragato',
    progressBar: true,
    primaryColour: '#DA915A',
    hideLogo: true,
    leftBackgroundImage,
    backgroudImage: leftBackgroundImage,
  },
}

export default Bragato
