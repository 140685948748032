import styled from 'styled-components/macro'
import React from 'react'

export const smButton = {
  width: '15rem',
}

export const regButton = {
  width: '24.5rem',
}

export const lgButton = {
  width: '30rem',
}

export const xlButton = {
  width: '49rem',
}

const ButtonContainer = styled.div`
  display: flex;
  /* width: 800px; */
  /* margin-top: 1rem; */
  /* align-content: baseline; */
  align-content: ${props => (props.settings.fullscreen ? 'center' : 'baseline')};
  flex-direction: column;
  flex-wrap: wrap;
  height: ${props =>
    props.dynamicChoices.length >= 10 && props.size === regButton.width ? (Math.ceil(props.dynamicChoices.length / 2) * 3.625) + 'rem' : 'auto'};
  width: ${props => (props.dynamicChoices.length >= 10 ? '50.5rem' : 'inherit')};
  @media (min-width: 0px) and (max-width: 479px) {
    width: 90vw;
    flex-wrap: nowrap;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    width: 90vw;
    flex-wrap: nowrap;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
  // Fred comment out the following part to solve overlapping issue in FH survey
  /* @media (min-width: 769px) and (max-width: 1279px) {
    //width: 90vw;
    flex-wrap: nowrap;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */
`

const ButtonLayout = props => {
  const { dynamicChoices, gridDisplay, children, size, settings } = props
  return (
    <ButtonContainer
      dynamicChoices={dynamicChoices}
      gridDisplay={gridDisplay}
      settings={settings}
      size={size ? xlButton.width : regButton.width}
    >
      {children}
    </ButtonContainer>
  )
}

export default ButtonLayout
