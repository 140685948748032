import React from 'react'
import styled from 'styled-components'
import {
  commentBox,
  multiChoice,
  multiSelect,
  responsiveMatrix,
  welcomePage,
  endPage,
  multiDropdown,
  responsiveMatrixTable,
  responsiveSlider,
  multiTextBox,
} from '../question-types/QuestionTypes'
import { Divider } from 'antd'
import goodyarnLogo from '../../assets/images/GoodYarn/goodyarnlogo.png'
import leftBackgroundImage from '../../assets/images/GoodYarn/bg1.jpg'
import _ from 'lodash'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  p {
    margin-bottom: 0rem;
  }
  .ant-divider-horizontal {
    margin: 0px 0;
    border-top: 1px solid #c3c3c3;
    width: 49rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 80vw;
    .ant-divider-horizontal {
      width: 90vw;
    }
  }
`
const Text = styled.p`
  font-size: 1rem;
  color: ${props => props.color};
  font-weight: bold;
`
const Q = styled.p`
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: #757575;
  margin-bottom: 0.5rem;
`
const T = styled.p`
  font-size: 1rem;
  margin-top: 0.5rem;
  text-indent: ${props => props.indent};
`
const I = styled.p`
  font-size: 1rem;
  margin-top: 0.5rem;
  font-style: italic;
`
const G = styled.span`
  font-variation-settings: 'wght' 900, 'GRAD' 80;
  font-size: 1rem;
  color: #36a7ea;
`
const groupSources = [
  {
    id: 1,
    text: 'Positive',
    value: 'Positive',
  },
  {
    id: 2,
    text: 'Negative',
    value: 'Negative',
  },
  {
    id: 3,
    text: 'Surprising',
    value: 'Surprising',
  },
  {
    id: 4,
    text: 'Profound',
    value: 'Profound',
  },
  {
    id: 5,
    text: 'Serious',
    value: 'Serious',
  },
  {
    id: 6,
    text: 'Fun',
    value: 'Fun',
  },
  {
    id: 7,
    text: 'Honest',
    value: 'Honest',
  },
  {
    id: 8,
    text: 'Beneficial',
    value: 'Beneficial',
  },
  {
    id: 9,
    text: 'Far-reaching',
    value: 'Far-reaching',
  },
  {
    id: 10,
    text: 'Minimal',
    value: 'Minimal',
  },
  {
    id: 11,
    text: 'Thought-provoking',
    value: 'Thought-provoking',
  },
  {
    id: 12,
    text: 'Dull',
    value: 'Dull',
  },
  {
    id: 13,
    text: 'Superficial',
    value: 'Superficial',
  },
].sort(function shuffleArray(a, b) {
  return 0.5 - Math.random()
})

const wellbeing_programs = [
  {
    id: 1,
    text: 'Employee Assistance Programme (EAP)',
    value: 'Employee Assistance Programme (EAP)',
  },
  {
    id: 2,
    text: 'Mental health workshops and training',
    value: 'Mental health workshops and training',
  },
  {
    id: 3,
    text: 'Social activities and events',
    value: 'Social activities and events',
  },
  {
    id: 4,
    text: 'Mental health awareness campaigns',
    value: 'Mental health awareness campaigns',
  },
  {
    id: 5,
    text: 'Health benefits (e.g., mental health days)',
    value: 'Health benefits (e.g., mental health days)',
  },
  {
    id: 6,
    text: 'Wellness benefits (e.g., financed access to gym or wellness app)',
    value: 'Wellness benefits (e.g., financed access to gym or wellness app)',
  },
  {
    id: 7,
    text: 'Policies (e.g., wellbeing policy)',
    value: 'Policies (e.g., wellbeing policy)',
  },
].sort(function shuffleArray(a, b) {
  return 0.5 - Math.random()
})

const GoodYarn = {
  questions: [
    // Close
    {
      id: 'close',
      type: welcomePage,
      section: (
        <Wrapper>
          <T>Thank you for your interest.</T>
          <T>
            The <G>GoodYarn</G> survey is now closed.
          </T>
          <T>
            If you have any queries relating to this survey, please contact{' '}
            <a href="mailto: michelle@goodyarn.org">michelle@goodyarn.org</a>
          </T>
        </Wrapper>
      ),
			hideUi: true,
    },
    /*
    // Introduction
    {
      id: 'intro-page',
      type: welcomePage,
      section: (
        <Wrapper>
          <T>
            <G>GoodYarn</G> is a mental health literacy programme for workplaces and communities.
          </T>
          <T>
            We would like to know if <G>GoodYarn</G> is making a difference so we can continue to
            improve and evolve our workshops and services.
          </T>
          <T>
            - This survey will take about <b>5 - 10 minutes</b>
          </T>
          <T>
            - We value your privacy and your responses will be <b>anonymous</b>
          </T>
          <T>
            - You can enter the <b>draw to win $1000*</b> at the end of the survey
          </T>
          <br />
          <T>
            <em>*One winner among eligible respondents will be drawn randomly on <b>10 October 2023</b>.
              The winner will be contacted via the email address and phone number provided.</em>
          </T>
          <T>
            <em>Individuals who are the main contact for GoodYarn, a GoodYarn facilitator or who have attended a GoodYarn workshop are eligible.</em>
          </T>
        </Wrapper>
      ),
    },
    // 1. Your Role
    {
      id: '1_your_role',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 1</Q>
          <Text color={'#36a7ea'}>Your Role</Text>
          <Divider />
          <T>Please tell us: </T>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'I am the main contact for the GoodYarn programme in my workplace',
          value: 'I am the main contact for the GoodYarn programme in my workplace',
        },
        {
          id: 2,
          text: 'I am a GoodYarn Facilitator for my workplace',
          value: 'I am a GoodYarn Facilitator for my workplace',
        },
        {
          id: 3,
          text: 'I have attended a GoodYarn workshop (I am not a Facilitator)',
          value: 'I have attended a GoodYarn workshop (I am not a Facilitator)',
        },
        {
          id: 4,
          text: 'I have never attended a GoodYarn workshop',
          value: 'I have never attended a GoodYarn workshop',
        },
      ],
      next: ({ answers }) => {
        if (answers['1_your_role'].id === 2 || answers['1_your_role'].id === 3) {
          return 'intro-page-section-4'
        }
        if (answers['1_your_role'].id === 1) {
          return 'intro-page-section-2'
        }
        return 'thank_you_1'
      },
    },
    // 2. Working with GoodYarn
    {
      id: 'intro-page-section-2',
      type: welcomePage,
      section: (
        <Wrapper>
          <Q>Section 2</Q>
          <Text color={'#36a7ea'}>Your Organisation</Text>
          <T>
            This section is about your experience working with <G>GoodYarn</G>.
          </T>
          <Divider />
        </Wrapper>
      ),
    },
    {
      id: '2_1_annual_eval',
      type: responsiveMatrixTable,
      section: (
        <Wrapper>
          <Q>Section 2 Page 1</Q>
          <Text color={'#36a7ea'}>Working With GoodYarn</Text>
          <T>
            These statements relate to the <b>annual evaluation report</b> sent by <G>GoodYarn</G>.
          </T>
          <T>Please indicate to what extent you agree or disagree.</T>
          <Divider />
        </Wrapper>
      ),
      required: true,
      useValues: true,
      useColumns: true,
      width: '12rem',
      height: '6rem',
      choices: [
        {
          id: 1,
          text: 'I read the evaluation report when I receive it',
          value: 'I read the evaluation report when I receive it',
        },
        {
          id: 2,
          text: 'The information is easy to understand',
          value: 'The information is easy to understand',
        },
        {
          id: 3,
          text: 'Reading the reports is worth my time',
          value: 'Reading the reports is worth my time',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
        },
        {
          id: 3,
          text: 'Agree',
          value: 'Agree',
        },
        {
          id: 4,
          text: 'Strongly agree',
          value: 'Strongly agree',
        },
        {
          id: 5,
          text: 'N/A',
          value: 'N/A',
        },
      ],
    },
    {
      id: '2_2_shared',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 2 Page 2</Q>
          <Text color={'#36a7ea'}>Working With GoodYarn</Text>
          <Divider />
          <T>
            Have you previously shared the <b>annual evaluation report</b> with anyone within your
            workplace?
          </T>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Yes',
          value: 'Yes',
        },
        {
          id: 2,
          text: 'No',
          value: 'No',
        },
      ],
      next: ({ answers }) => {
        if (answers['2_2_shared'].id === 1) {
          return '2_3_shared_with'
        }
        return '2_4_added_wellbeing'
      },
    },
    {
      id: '2_3_shared_with',
      type: multiSelect,
      section: (
        <Wrapper>
          <Q>Section 2 Page 3</Q>
          <Text color={'#36a7ea'}>Working With GoodYarn</Text>
          <Divider />
          <T>Who have you shared it with? </T>
          <I>Select all that apply</I>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Leadership team',
          value: 'Leadership team',
        },
        {
          id: 2,
          text: 'GoodYarn Facilitators',
          value: 'GoodYarn Facilitators',
        },
        {
          id: 3,
          text: 'Managers',
          value: 'Managers'
        },
        {
          id: 4,
          text: 'GoodYarn workshop participants',
          value: 'GoodYarn workshop participants',
        },
        {
          id: 5,
          text: 'All staff',
          value: 'All staff',
        },
        {
          id: 6,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
      ],
    },
    {
      id: '2_4_added_wellbeing',
      type: multiSelect,
      section: (
        <Wrapper>
          <Q>Section 2 Page 4</Q>
          <Text color={'#36a7ea'}>Working With GoodYarn</Text>
          <Divider />
          <T>
            Since initiating <G>GoodYarn</G>, which of the following have you added to your
            wellbeing programme?
          </T>
          <I>Select all that apply</I>
        </Wrapper>
      ),
      required: true,
      choices: wellbeing_programs.concat([
        {
          id: 17,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
        {
          id: 18,
          text: "I don't know",
          value: "I don't know",
          submit: true,
        },
        {
          id: 19,
          text: 'None',
          value: 'None',
          submit: true,
        },
      ]),
    },
    {
      id: '2_5_implement',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 2 Page 5</Q>
          <Text color={'#36a7ea'}>Working With GoodYarn</Text>
          <Divider />
          <T>
            Overall, how difficult or easy was <G>GoodYarn</G> to implement in your workplace?
          </T>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Very difficult',
          value: 'Very difficult',
        },
        {
          id: 2,
          text: 'Difficult',
          value: 'Difficult',
        },
        {
          id: 3,
          text: 'Easy',
          value: 'Easy',
        },
        {
          id: 4,
          text: 'Very easy',
          value: 'very easy',
        },
      ],
    },
    {
      id: '2_6_value',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 2 Page 6</Q>
          <Text color={'#36a7ea'}>Working With GoodYarn</Text>
          <Divider />
          <T>
            How would you rate the value for money of the <G>GoodYarn</G> programme for increasing
            mental health literacy in the workplace?
          </T>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Very low value for money',
          value: 'Very low value for money',
        },
        {
          id: 2,
          text: 'Low value for money',
          value: 'Low value for money',
        },
        {
          id: 3,
          text: 'Good value for money',
          value: 'Good value for money',
        },
        {
          id: 4,
          text: 'Very good value for money',
          value: 'Very good value for money',
        },
      ],
    },
    {
      id: '2_7_satisfaction',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 2 Page 7</Q>
          <Text color={'#36a7ea'}>Working With GoodYarn</Text>
          <Divider />
          <T>
            How would you rate your overall satisfaction with the <G>GoodYarn</G> programme?
          </T>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Very unsatisfied',
          value: 'Very unsatisfied',
        },
        {
          id: 2,
          text: 'Unsatisfied',
          value: 'Unsatisfied',
        },
        {
          id: 3,
          text: 'Satisfied',
          value: 'Satisfied',
        },
        {
          id: 4,
          text: 'Very satisfied',
          value: 'Very satisfied',
        },
      ],
    },
    // 3. Your organisation
    {
      id: 'intro-page-section-3',
      type: welcomePage,
      section: (
        <Wrapper>
          <Q>Section 3</Q>
          <Text color={'#36a7ea'}>Your Organisation</Text>
          <T>In this section, we ask a few questions about your workplace.</T>
          <Divider />
        </Wrapper>
      ),
    },
    {
      id: '3_1_primary_industry',
      type: multiDropdown,
      section: (
        <Wrapper>
          <Q>Section 3 Page 1</Q>
          <Text color={'#36a7ea'}>Your Organisation</Text>
          <Divider />
          <T>What is the primary industry or line of business in your workplace?</T>
        </Wrapper>
      ),
      choices: [
        {
          id: 1,
          text: '',
        },
      ],
      required: true,
      options: [
        {
          id: 1,
          text: 'Accommodation & Food',
          value: 'Accommodation & Food',
        },
        {
          id: 2,
          text: 'Administrative & Support',
          value: 'Administrative & Support',
        },
        {
          id: 3,
          text: 'Agriculture',
          value: 'Agriculture',
        },
        {
          id: 4,
          text: 'Arts & Recreation',
          value: 'Arts & Recreation',
        },
        {
          id: 5,
          text: 'Construction',
          value: 'Construction',
        },
        {
          id: 6,
          text: 'Education & Training',
          value: 'Education & Training',
        },
        {
          id: 7,
          text: 'Electricity, Gas, Water & Waste',
          value: 'Electricity, Gas, Water & Waste',
        },
        {
          id: 8,
          text: 'Financial & Insurance',
          value: 'Financial & Insurance',
        },
        {
          id: 9,
          text: 'Health Care & Social Assistance',
          value: 'Health Care & Social Assistance',
        },
        {
          id: 10,
          text: 'Information Media & Telecommunications',
          value: 'Information Media & Telecommunications',
        },
        {
          id: 11,
          text: 'Manufacturing',
          value: 'Manufacturing',
        },
        {
          id: 12,
          text: 'Mining',
          value: 'Mining',
        },
        {
          id: 13,
          text: 'Personal Services',
          value: 'Personal Services',
        },
        {
          id: 14,
          text: 'Professional, Scientific & Technical',
          value: 'Professional, Scientific & Technical',
        },
        {
          id: 15,
          text: 'Public Administration & Safety',
          value: 'Public Administration & Safety',
        },
        {
          id: 16,
          text: 'Rental, Hiring & Real Estate',
          value: 'Rental, Hiring & Real Estate',
        },
        {
          id: 17,
          text: 'Retail Trade',
          value: 'Retail Trade',
        },
        {
          id: 18,
          text: 'Transport, Postal & Warehousing',
          value: 'Transport, Postal & Warehousing',
        },
        {
          id: 19,
          text: 'Wholesale Trade',
          value: 'Wholesale Trade',
        },
        {
          id: 20,
          text: "I don't know",
          value: "I don't know",
        },
      ],
    },
    {
      id: '3_2_num_employee',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 3 Page 2</Q>
          <Text color={'#36a7ea'}>Your Organisation</Text>
          <Divider />
          <T>
            What is the approximate total number of employees (part-time and full-time) in your
            workplace?
          </T>
          <I>If you have several locations, please indicate the total for all locations.</I>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: '1 - 49',
          value: '1 - 49',
        },
        {
          id: 2,
          text: '50 - 99',
          value: '50 - 99',
        },
        {
          id: 3,
          text: '100 - 199',
          value: '100 - 199',
        },
        {
          id: 4,
          text: '200 - 499',
          value: '200 - 499',
        },
        {
          id: 5,
          text: '500+',
          value: '500+',
        },
      ],
    },
    // 4. Your thoughts on mental health and wellbeing
    {
      id: 'intro-page-section-4',
      type: welcomePage,
      section: (
        <Wrapper>
          <Q>Section 4</Q>
          <Text color={'#36a7ea'}>Your Thoughts on Mental Health and Wellbeing</Text>
          <T>This section is about your personal opinions on mental health and wellbeing.</T>
          <Divider />
        </Wrapper>
      ),
    },
    {
      id: '4_1_wellbeing',
      type: responsiveMatrixTable,
      section: (
        <Wrapper>
          <Q>Section 4 Page 1</Q>
          <Text color={'#36a7ea'}>Your Thoughts on Mental Health and Wellbeing</Text>
          <T>To what extent do you agree or disagree with the following:</T>
          <Divider />
        </Wrapper>
      ),
      required: true,
      useValues: true,
      useColumns: true,
      width: '12rem',
      height: '6rem',
      choices: [
        {
          id: 1,
          text: 'Having a mental health problem is nothing to be ashamed of',
          value: 'Having a mental health problem is nothing to be ashamed of',
        },
        {
          id: 2,
          text: 'I would ask friends and family for support if I had a mental health problem',
          value: 'I would ask friends and family for support if I had a mental health problem',
        },
        {
          id: 3,
          text: 'I would view someone differently if I knew they had a mental health problem',
          value: 'I would view someone differently if I knew they had a mental health problem',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
        },
        {
          id: 3,
          text: 'Agree',
          value: 'Agree',
        },
        {
          id: 4,
          text: 'Strongly agree',
          value: 'Strongly agree',
        },
      ],
    },
    {
      id: '4_2_wellbeing',
      type: responsiveMatrixTable,
      section: (
        <Wrapper>
          <Q>Section 4 Page 2</Q>
          <Text color={'#36a7ea'}>Your Thoughts on Mental Health and Wellbeing</Text>
          <T>To what extent do you agree or disagree with the following:</T>
          <Divider />
        </Wrapper>
      ),
      required: true,
      useValues: true,
      useColumns: true,
      width: '12rem',
      height: '6rem',
      choices: [
        {
          id: 4,
          text: 'If I had a mental health problem, I would be comfortable talking about it',
          value: 'If I had a mental health problem, I would be comfortable talking about it',
        },
        {
          id: 5,
          text:
            'If a friend / colleague of mine developed a mental health problem, I would listen to them without judging or criticising',
          value:
            'If a friend / colleague of mine developed a mental health problem, I would listen to them without judging or criticising',
        },
        {
          id: 6,
          text: 'I know where to find helpful resources on mental health and wellbeing',
          value: 'I know where to find helpful resources on mental health and wellbeing',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
        },
        {
          id: 3,
          text: 'Agree',
          value: 'Agree',
        },
        {
          id: 4,
          text: 'Strongly agree',
          value: 'Strongly agree',
        },
      ],
    },
    // 5. Mental health and wellbeing at work
    {
      id: 'intro-page-section-5',
      type: welcomePage,
      section: (
        <Wrapper>
          <Q>Section 5</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing at Work</Text>
          <T>
            This section is about your thoughts on how mental health and wellbeing is approached in
            your workplace.
          </T>
          <Divider />
        </Wrapper>
      ),
    },
    {
      id: '5_1_work_wellbeing',
      type: responsiveMatrixTable,
      section: (
        <Wrapper>
          <Q>Section 5 Page 1</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing at Work</Text>
          <T>To what extent do you agree or disagree with the following:</T>
          <Divider />
        </Wrapper>
      ),
      required: true,
      useValues: true,
      useColumns: true,
      width: '12rem',
      height: '6rem',
      choices: [
        {
          id: 1,
          text:
            'I feel comfortable talking about my mental health and wellbeing with others in my workplace',
          value:
            'I feel comfortable talking about my mental health and wellbeing with others in my workplace',
        },
        {
          id: 2,
          text:
            "There is someone at work I can talk to about a colleague's mental health problems if I am concerned",
          value:
            "There is someone at work I can talk to about a colleague's mental health problems if I am concerned",
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
        },
        {
          id: 3,
          text: 'Agree',
          value: 'Agree',
        },
        {
          id: 4,
          text: 'Strongly agree',
          value: 'Strongly agree',
        },
      ],
    },
    {
      id: '5_2_work_wellbeing',
      type: responsiveMatrixTable,
      section: (
        <Wrapper>
          <Q>Section 5 Page 2</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing at Work</Text>
          <T>To what extent do you agree or disagree with the following:</T>
          <Divider />
        </Wrapper>
      ),
      required: true,
      useValues: true,
      useColumns: true,
      width: '12rem',
      height: '6rem',
      choices: [
        {
          id: 1,
          text: 'My workplace prioritises the mental wellbeing of our team',
          value: 'My workplace prioritises the mental wellbeing of our team',
        },
        {
          id: 2,
          text:
            'My workplace would make changes to my workload or work environment to improve my mental health',
          value:
            'My workplace would make changes to my workload or work environment to improve my mental health',
        },
        {
          id: 3,
          text: 'I would be okay asking for time off work due to a mental health problem',
          value: 'I would be okay asking for time off work due to a mental health problem',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
        },
        {
          id: 3,
          text: 'Agree',
          value: 'Agree',
        },
        {
          id: 4,
          text: 'Strongly agree',
          value: 'Strongly agree',
        },
      ],
    },
    {
      id: '5_3_work_with',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 5 Page 3</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing at Work</Text>
          <Divider />
          <T>
            How willing would you be to have someone with a mental health problem start working
            closely with you on a job?
          </T>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: "I definitely wouldn't want to work with them",
          value: "I definitely wouldn't want to work with them",
        },
        {
          id: 2,
          text: 'I would probably be uncomfortable working with them',
          value: 'I would probably be uncomfortable working with them',
        },
        {
          id: 3,
          text: "I don't think I would mind working with them",
          value: "I don't think I would mind working with them",
        },
        {
          id: 4,
          text: 'I would have no problem working with them',
          value: 'I would have no problem working with them',
        },
        {
          id: 5,
          text: 'I am unsure',
          value: 'I am unsure',
        },
      ],
      next: ({ answers }) => {
        const ansID = answers['5_3_work_with'].id
        if (ansID === 1) {
          return '5_4_1_why_not'
        }
        if (ansID === 2) {
          return '5_4_2_why_uncomfortable'
        }
        if (ansID === 3) {
          return '5_4_3_why_might_not'
        }
        return '5_5_employ'
      },
    },
    {
      id: '5_4_1_why_not',
      type: commentBox,
      placeholder: "Optional",
      section: (
        <Wrapper>
          <Q>Section 5 Page 3</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing at Work</Text>
          <Divider />
          <T>Could you please explain in a few words why you would not want to work with them?</T>
        </Wrapper>
      ),
      next: () => {
        return '5_5_employ'
      },
    },
    {
      id: '5_4_2_why_uncomfortable',
      type: commentBox,
      placeholder: "Optional",
      section: (
        <Wrapper>
          <Q>Section 5 Page 3</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing at Work</Text>
          <Divider />
          <T>
            Could you please explain in a few words why you would be uncomfortable working with
            them?
          </T>
        </Wrapper>
      ),
      next: () => {
        return '5_5_employ'
      },
    },
    {
      id: '5_4_3_why_might_not',
      type: commentBox,
      placeholder: "Optional",
      section: (
        <Wrapper>
          <Q>Section 5 Page 3</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing at Work</Text>
          <Divider />
          <T>Could you please explain in a few words why you might not want to work with them?</T>
        </Wrapper>
      ),
      next: () => {
        return '5_5_employ'
      },
    },
    {
      id: '5_5_employ',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 5 Page 4</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing at Work</Text>
          <Divider />
          <T>
            How willing would you be to employ someone if you knew they had a mental health problem?
          </T>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: "I definitely wouldn't hire them",
          value: "I definitely wouldn't hire them",
        },
        {
          id: 2,
          text: 'I would probably be hesitant to hire them',
          value: 'I would probably be hesitant to hire them',
        },
        {
          id: 3,
          text: "I don't think this information would affect my decision to hire them",
          value: "I don't think this information would affect my decision to hire them",
        },
        {
          id: 4,
          text: 'This information would not affect my decision to hire them',
          value: 'This information would not affect my decision to hire them',
        },
        {
          id: 5,
          text: 'I am unsure',
          value: 'I am unsure',
        },
        {
          id: 6,
          text: 'Not applicable (I do not make hiring decisions)',
          value: 'Not applicable (I do not make hiring decisions)',
        },
      ],
      next: ({ answers }) => {
        const ansID = answers['5_5_employ'].id
        if (ansID === 1) {
          return '5_6_1_why_not'
        }
        if (ansID === 2) {
          return '5_6_2_why_hesitant'
        }
        if (ansID === 3) {
          return '5_6_3_why_affect'
        }
        return 'intro-page-section-6'
      },
    },
    {
      id: '5_6_1_why_not',
      type: commentBox,
      placeholder: "Optional",
      section: (
        <Wrapper>
          <Q>Section 5 Page 4</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing at Work</Text>
          <Divider />
          <T>Could you please explain in a few words why you would not hire them?</T>
        </Wrapper>
      ),
      next: () => {
        return 'intro-page-section-6'
      },
    },
    {
      id: '5_6_2_why_hesitant',
      type: commentBox,
      placeholder: "Optional",
      section: (
        <Wrapper>
          <Q>Section 5 Page 4</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing at Work</Text>
          <Divider />
          <T>Could you please explain in a few words why you would be hesitant to hire them?</T>
        </Wrapper>
      ),
      next: () => {
        return 'intro-page-section-6'
      },
    },
    {
      id: '5_6_3_why_affect',
      type: commentBox,
      placeholder: "Optional",
      section: (
        <Wrapper>
          <Q>Section 5 Page 4</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing at Work</Text>
          <Divider />
          <T>
            Could you please explain in a few words why it might affect your decision to hire them?
          </T>
        </Wrapper>
      ),
      next: () => {
        return 'intro-page-section-6'
      },
    },
    // 6. Mental health and wellbeing culture
    {
      id: 'intro-page-section-6',
      type: welcomePage,
      section: (
        <Wrapper>
          <Q>Section 6</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing Culture</Text>
          <T>
            This section is about your thoughts on the mental health and wellbeing culture within
            your workplace.
          </T>
          <Divider />
        </Wrapper>
      ),
    },
    {
      id: '6_1_wellbeing_culture',
      type: responsiveMatrixTable,
      section: (
        <Wrapper>
          <Q>Section 6 Page 1</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing Culture</Text>
          <T>To what extent do you agree or disagree with the following:</T>
          <Divider />
        </Wrapper>
      ),
      required: true,
      useValues: true,
      choices: [
        {
          id: 1,
          text: 'My workplace makes mental health and wellbeing a priority',
          value: 'My workplace makes mental health and wellbeing a priority ',
        },
        {
          id: 2,
          text:
            'I have a clear understanding of the mental health resources and services available to me at work',
          value:
            'I have a clear understanding of the mental health resources and services available to me at work',
        },
        {
          id: 3,
          text:
            "I know where to go within my organisation for help if I am worried about myself or someone else's mental health",
          value:
            "I know where to go within my organisation for help if I am worried about myself or someone else's mental health",
        },
        {
          id: 4,
          text: 'There is enough support available at work for people with mental health problems',
          value: 'There is enough support available at work for people with mental health problems',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
        },
        {
          id: 3,
          text: 'Agree',
          value: 'Agree',
        },
        {
          id: 4,
          text: 'Strongly agree',
          value: 'Strongly agree',
        },
      ],
    },
    {
      id: '6_2_conversations',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 6 Page 2</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing Culture</Text>
          <Divider />
          <T>
            How often are you observing conversations, in person or on social media, about mental
            health within your workplace?
          </T>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Never',
          value: 'Never',
        },
        {
          id: 2,
          text: 'Rarely',
          value: 'Rarely',
        },
        {
          id: 3,
          text: 'Sometimes',
          value: 'Sometimes',
        },
        {
          id: 4,
          text: 'Often',
          value: 'Often',
        },
        {
          id: 5,
          text: "I don't know",
          value: "I don't know",
        },
        {
          id: 6,
          text: 'I am not sure what conversations about mental health might look / sound like',
          value: 'I am not sure what conversations about mental health might look / sound like',
        },
      ],
    },
    {
      id: '6_3_communicate',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 6 Page 3</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing Culture</Text>
          <Divider />
          <T>
            How often does your workplace communicate with employees about mental health awareness
            or wellbeing support?
          </T>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Never',
          value: 'Never',
        },
        {
          id: 2,
          text: 'Rarely (less than once a year)',
          value: 'Rarely (less than once a year)',
        },
        {
          id: 3,
          text: 'Occasionally (up to 3 times per year)',
          value: 'Occasionally (up to 3 times per year)',
        },
        {
          id: 4,
          text: 'Often (up to 6 times per year)',
          value: 'Often (up to 6 times per year)',
        },
        {
          id: 5,
          text: 'Frequently (more than 6 times per year)',
          value: 'Frequently (more than 6 times per year)',
        },
        {
          id: 6,
          text: "I don't know",
          value: "I don't know",
        },
      ],
    },
    {
      id: '6_4_rate_support',
      type: responsiveMatrix,
      section: (
        <Wrapper>
          <Q>Section 6 Page 4</Q>
          <Text color={'#36a7ea'}>Mental Health and Wellbeing Culture</Text>
          <Divider />
          <T>
            Overall, how would you rate the mental health and wellbeing support provided by your
            workplace?
          </T>
        </Wrapper>
      ),
      text: '',
      required: true,
      useValues: true,
      choices: [
        {
          id: 1,
          text: '',
          value: '',
        },
      ],
      options: [
        { id: 1, text: 'Very poor', value: 'Very poor', submit: true },
        { id: 2, text: 'Poor', value: 'Poor', submit: true },
        { id: 3, text: 'Acceptable', value: 'Acceptable', submit: true },
        { id: 4, text: 'Good', value: 'Good', submit: true },
        { id: 5, text: 'Very good', value: 'Very good', submit: true },
      ],
    },
    // 7. GoodYarn programme
    {
      id: 'intro-page-section-7',
      type: welcomePage,
      section: (
        <Wrapper>
          <Q>Section 7</Q>
          <Text color={'#36a7ea'}>GoodYarn Programme</Text>
          <T>
            This section is about your thoughts on the effectiveness of the <G>GoodYarn</G>{' '}
            programme.
          </T>
          <Divider />
        </Wrapper>
      ),
    },
    {
      id: '7_1_peer_to_peer',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 7 Page 1</Q>
          <Text color={'#36a7ea'}>GoodYarn Programme</Text>
          <T>
            <G>GoodYarn</G> uses a <b>peer-to-peer delivery model</b> (individuals in the workplace
            are trained to deliver workshops to their peers instead of outsourcing to
            professionals).
          </T>
          <Divider />
          <T>
            How effective do you think this model is in delivering mental health literacy in the
            workplace?
          </T>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Not successful at all',
          value: 'Not successful at all',
        },
        {
          id: 2,
          text: 'Not successful',
          value: 'Not successful',
        },
        {
          id: 3,
          text: 'Successful',
          value: 'Successful',
        },
        {
          id: 4,
          text: 'Very successful',
          value: 'Very successful',
        },
        {
          id: 5,
          text: "I don't know",
          value: "I don't know",
        },
      ],
    },
    {
      id: '7_2_rate_impact',
      type: responsiveSlider,
      section: (
        <Wrapper>
          <Q>Section 7 Page 2</Q>
          <Text color={'#36a7ea'}>GoodYarn Programme</Text>
          <Divider />
          <T>
            On a scale of 1 - 10, how would you rate <G>GoodYarn</G>’s impact on your workplace?
          </T>
          <I>1 = negative impact</I>
          <I>10 = positive impact</I>
        </Wrapper>
      ),
      default: 1,
      max: 10,
      required: true,
      choices: [
        { id: 1, value: '1 Negative impact', text: '1<br />Negative impact' },
        { id: 2, value: '2', text: '2' },
        { id: 3, value: '3', text: '3' },
        { id: 4, value: '4', text: '4' },
        { id: 5, value: '5', text: '5' },
        { id: 6, value: '6', text: '6' },
        { id: 7, value: '7', text: '7' },
        { id: 8, value: '8', text: '8' },
        { id: 9, value: '9', text: '9' },
        { id: 10, value: '10 Positive impact', text: '10<br />Positive impact' },
      ],
    },
    {
      id: '7_3_describe',
      type: multiSelect,
      section: (
        <Wrapper>
          <Q>Section 7 Page 3</Q>
          <Text color={'#36a7ea'}>GoodYarn Programme</Text>
          <Divider />
          <T>
            Please pick <b>up to 3 words</b> that you would use to describe the impact of{' '}
            <G>GoodYarn</G> in the workplace.
          </T>
          <I>Use the “other” option if you do not find the right words in the list provided</I>
        </Wrapper>
      ),
      required: true,
      max: 3,
      choices: ({ answers }) => {
        const randomArray = [groupSources]
        const other = { id: 7, type: 'input', text: 'Other, please specify', value: 'other' }
        randomArray.push(other)
        return _.flatten(randomArray)
      },
    },
    {
      id: '7_4_how_improve',
      type: commentBox,
      placeholder: "Optional",
      section: (
        <Wrapper>
          <Q>Section 7 Page 4</Q>
          <Text color={'#36a7ea'}>GoodYarn Programme</Text>
          <Divider />
          <T>
            In your opinion, how could the <G>GoodYarn</G> programme and workshops be improved?
          </T>
        </Wrapper>
      ),
    },
    // 8. About you
    {
      id: 'intro-page-section-8',
      type: welcomePage,
      section: (
        <Wrapper>
          <Q>Section 8</Q>
          <Text color={'#36a7ea'}>About You</Text>
          <T>This section is to capture some simple demographic information such as your age. </T>
          <Divider />
        </Wrapper>
      ),
    },
    {
      id: '8_1_age',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 8 Page 1</Q>
          <Text color={'#36a7ea'}>About You</Text>
          <Divider />
          <T>What is your age?</T>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Under 18',
          value: 'Under 18',
        },
        {
          id: 2,
          text: '18 - 29',
          value: '18 - 29',
        },
        {
          id: 3,
          text: '30 - 39',
          value: '30 - 39',
        },
        {
          id: 4,
          text: '40 - 49',
          value: '40 - 49',
        },
        {
          id: 5,
          text: '50 - 59',
          value: '50 - 59',
        },
        {
          id: 6,
          text: '60+',
          value: '60+',
        },
        {
          id: 7,
          text: 'Prefer not to answer',
          value: 'Prefer not to answer',
        },
      ],
    },
    {
      id: '8_2_ethnic',
      type: multiSelect,
      section: (
        <Wrapper>
          <Q>Section 8 Page 2</Q>
          <Text color={'#36a7ea'}>About You</Text>
          <Divider />
          <T>What ethnic group(s) do you identify with?</T>
          <I>Select all that apply</I>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'NZ European (Pākehā)',
          value: 'NZ European (Pākehā)',
        },
        {
          id: 2,
          text: 'Māori',
          value: 'Māori',
        },
        {
          id: 3,
          text: 'Pacific People',
          value: 'Pacific People',
        },
        {
          id: 4,
          text: 'Asian',
          value: 'Asian',
        },
        {
          id: 5,
          text: 'Other European',
          value: 'Other European',
        },
        {
          id: 6,
          text: 'Middle Eastern / Latin American / African',
          value: 'Middle Eastern / Latin American / African',
        },
        {
          id: 7,
          type: 'input',
          text: 'Other ethnicity, please specify',
          value: '',
        },
        {
          id: 8,
          text: 'Prefer not to answer',
          value: 'Prefer not to answer',
          submit: true,
        },
      ],
    },
    {
      id: '8_3_gender',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 8 Page 3</Q>
          <Text color={'#36a7ea'}>About You</Text>
          <Divider />
          <T>What gender do you identify with?</T>
        </Wrapper>
      ),
      required: true,
      choices: [
        {
          id: 1,
          text: 'Female',
          value: 'Female',
        },
        {
          id: 2,
          text: 'Male',
          value: 'Male',
        },
        {
          id: 3,
          text: 'Gender variant / Non-conforming',
          value: 'Gender variant / Non-conforming',
        },
        {
          id: 4,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
        {
          id: 5,
          text: 'Prefer not to answer',
          value: 'Prefer not to answer',
        },
      ],
    },
    {
      id: '8_4_occupation',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Section 8 Page 4</Q>
          <Text color={'#36a7ea'}>About You</Text>
          <Divider />
          <T>
            What is your current occupation in this workplace?
          </T>
        </Wrapper>
      ),
      choices: [
        { id: 1, value: 'Manager', text: 'Manager' },
        { id: 2, value: 'Professional', text: 'Professional' },
        { id: 3, value: 'Technician or trade worker', text: 'Technician or trade worker' },
        { id: 4, value: 'Community and / or personal service worker', text: 'Community and / or personal service worker' },
        { id: 5, value: 'Clerical and administrative worker', text: 'Clerical and administrative worker' },
        { id: 6, value: 'Sales worker', text: 'Sales worker' },
        { id: 7, value: 'Machinery operator and / or driver', text: 'Machinery operator and / or driver' },
        { id: 8, value: 'Labourer', text: 'Labourer' },
        { id: 9, value: '', text: 'Other, please specify', type: 'input' },
        { id: 10, value: 'Prefer not to answer', text: 'Prefer not to answer' },
      ],
    },
    {
      id: '8_5_comment',
      type: commentBox,
      placeholder: "Optional",
      section: (
        <Wrapper>
          <Q>Section 8 Page 5</Q>
          <Text color={'#36a7ea'}>About You</Text>
          <Divider />
          <T>
            Do you have any other comment for us about <G>GoodYarn</G> or mental health literacy
            programmes in the workplace?
          </T>
        </Wrapper>
      ),
    },
    // 9. Draw
    {
      id: '9_Draw',
      type: multiTextBox,
      placeholder: "Optional",
      section: (
        <Wrapper>
          <Q>Section 9</Q>
          <Text color={'#36a7ea'}>Draw</Text>
          <Divider />
          <T>
            If you would like to be entered in the draw for a chance to win $1000*, please indicate your full name, email address and phone number below.
          </T>
          <T>
            Your name and contact details will solely be used for contacting the winner of the draw and will not be associated to your responses to this survey or added to any database.
          </T>
        </Wrapper>
      ),
      choices: [
        { id: 1, text: 'Full name:     ', value: '' },
        { id: 2, text: 'Email address: ', value: '' },
        { id: 3, text: 'Phone number:  ', value: '' },
      ],
      textBelow: (
        <Wrapper>
          <T>
            <em>*One winner among eligible respondents</em>
          </T>
        </Wrapper>
      ),
    },
    // Thank you
    {
      id: 'thank_you',
      type: endPage,
      section: (
        <Wrapper>
          <T>Thank you for taking the time to fill out our survey.</T>
          <T>The results will help us to get more people talking about mental health.</T>
          <br></br>
          <Text color={'#36a7ea'}>Oranga Kōrero.</Text>
        </Wrapper>
      ),
    },
    {
      id: 'thank_you_1',
      type: endPage,
      section: (
        <Wrapper>
          <T>
            Thank you for your time, however this survey is only for <G>GoodYarn</G> Facilitators
            and those who have attended a <G>GoodYarn</G> workshop.
          </T>
          <T>
            If you are interested in learning more about <G>GoodYarn</G>, please contact your
            manager.
          </T>
          <br></br>
          <Text color={'#36a7ea'}>Ngā mihi.</Text>
        </Wrapper>
      ),
    },
		*/
  ],

  settings: {
    writeToFirestore: true,
    surveyName: 'goodyarn',
    // progressBar: true,
    primaryColour: '#36a7ea',
    leftBackgroundImage,
    backgroudImage: leftBackgroundImage,
    companyLogo: {
      images: [{ image: goodyarnLogo, height: '100px' }],
    },
    logoFirstPageOnly: false,
    logoLocation: 'end',
    logoStyle: { marginTop: '1rem' },
  },
}

export default GoodYarn
