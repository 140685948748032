import _ from 'lodash'
import testSurvey from './refactor-test'
import GoodYarn from './GoodYarn'
import GreenBayHigh from './GreenBayHigh'
import GreenBayHighCommunity from './GreenBayHigh-com'
// import Pias from './PIAS'
import GreenbayHighStaff from './GreenBayHigh-staff'
import Stepchange from './StepChange'
import StepchangeCo from './StepChangeCo'
import StepChangeV2 from './StepChangev2'
import StepChangeV3 from './StepChangev3'
import StepChangeDel from './Stepchange-DEL'
import CNA from './CNA'
import DragDropDemo from './dragdropdemo'
import SurveyDemo from './surveydemo'
import BCITO from './BCITO'
import AggTest from './AggTest'
// import Scratch from './Scratch'
// import OldPias from './OldPias'
import LandingPage from './LandingPage'
import DemoSurvey from './DemoSurvey'
import HillCountryFuture from './HillCountryFutures'
import E350Preworkshop from './PreE350'
import E350Workshop from './E350Workshop'
import E350Graphs from './E350Graphs'
import Scarlatti from './InternalSurvey-Scarlatti'
import NavigationTool from './navigation-tool'
import ESInternal from './es-internal'
import INZBIndustry from './INZB-longitudinal'
import ConjointTest from './ConjointTest'
import HillCountryFuturesConjoint from './HCF-conjoint'
import ScarlattiNov from './QuarterlyScarlatti'
import StepChangeCoUpdate from './StepChangeCo-update'
import FutureHurunuiConjoint from './FH-conjoint'
import RuralCoWellbeing from './RuralCo-wellbeing'
// import HillCountryFuturesTest from './HillCountryFuturesTest'
import MillsAlbert from './MillsAlbert'
// import TestSurvey22 from './TestSurvey22'
import ScarlattiWorkload from './ScarlattiWorkload'
import DairyNZWellbeing from './DairyNZWellbeing'
import HillCountryFuturesConjointV2 from './HCF-conjointv2'
import Hotfixes from './Bugfixing'
import GridSkillsTLM from './GridSkillsTLM'
import GridSkillsPT from './GridSkillsPT'
import ScarlattiSnapshot from './ScarlattiSnapshot'
import ConCOVE from './ConCOVE'
import HillCountryFuturesWorkshop from './HCF-BLNZ-workshop'
import Bragato from './Bragato'
import GFiD from './GFiD'
import GFiDa from './GFiD-a'
import RedirectTest from './RedirectTest'
import GFiDDynata from './GFiD-Dynata'

export const SurveysObj = {
  GFiDDynata,
  RedirectTest,
  GFiD,
  GridSkillsPT,
  GridSkillsTLM,
  Bragato,
  ConCOVE,
  GoodYarn,
  ScarlattiSnapshot,
  Hotfixes,
  HillCountryFuturesConjointV2,
  DairyNZWellbeing,
  ScarlattiWorkload,
  // TestSurvey22,
  RuralCoWellbeing,
  // HillCountryFuturesTest,
  FutureHurunuiConjoint,
  HillCountryFuturesConjoint,
  ConjointTest,
  ScarlattiNov,
  INZBIndustry,
  ESInternal,
  Scarlatti,
  testSurvey,
  GreenBayHigh,
  GreenBayHighCommunity,
  // OldPias,
  HillCountryFuture,
  // Pias,
  E350Preworkshop,
  E350Workshop,
  E350Graphs,
  DemoSurvey,
  GreenbayHighStaff,
  Stepchange,
  StepChangeDel,
  StepchangeCo,
  StepChangeV2,
  StepChangeV3,
  CNA,
  DragDropDemo,
  SurveyDemo,
  BCITO,
  AggTest,
  // Scratch,
  LandingPage,
  NavigationTool,
  StepChangeCoUpdate,
  RuralCoWellbeing,
  MillsAlbert,
  HillCountryFuturesWorkshop,
  GFiDa,
}

export const Surveys = _.mapKeys(SurveysObj, val => val.settings.surveyName.toLowerCase())

// const Surveys = {}
export default Surveys
