import {
  multiSelect,
  welcomePage,
  singleTextbox,
  responsiveMatrix,
} from '../question-types/QuestionTypes'

const DemoSurvey = {
  questions: [
    {
      id: 'favorite-colour',
      text: 'Choose your favorite color',
      type: multiSelect,
      choices: [
        { id: 'red', text: 'red', value: 'red' },
        { id: 'blue', text: 'blue', value: 'blue' },
        { id: 'green', text: 'green', value: 'green' },
      ],
    },
    {
      id: 'color-results',
      type: welcomePage,
      text: 'Aggregate results',
      output: {
        type: 'doughnut-chart',
        xLabel: 'Option',
        yLabel: 'Count',
        targetQuestionId: 'favorite-colour',
        surveyName: 'demo-survey-test',
      },
    },
    {
      id: 'car-ownership',
      type: responsiveMatrix,
      text: 'Overall..',
      options: [
        { id: 1, text: 'Yes', value: 'Yes' },
        { id: 2, text: 'No', value: 'No' },
      ],
      choices: [
        {
          id: 1,
          text: 'Do you drive an EV?',
          value: 'Do you drive an EV?',
        },
        {
          id: 2,
          text: 'Do you frequently drive over the speed limit?',
          value: 'Do you frequently drive over the speed limit?',
        },
      ],
      columns: [
        {
          id: 'Yes',
          value: 'Yes',
        },
        {
          id: 'No',
          value: 'No',
        },
      ],
      rows: [
        {
          id: 1,
          text: 'Do you drive an EV?',
          value: 'Do you drive an EV?',
        },
        {
          id: 2,
          text: 'Do you frequently drive over the speed limit?',
          value: 'Do you frequently drive over the speed limit?',
        },
      ],
    },
    {
      id: 'ownership-results',
      type: welcomePage,
      text: 'Aggregate results',
      output: {
        type: 'bar-chart',
        xLabel: 'Option',
        smart: true,
        yLabel: 'Count',
        targetQuestionId: 'car-ownership',
        nestedQuestionId: 'Do you drive an EV?',
        surveyName: 'demo-survey-test',
      },
    },
    {
      id: 'compare-results',
      type: welcomePage,
      text: 'Aggregate results',
      output: {
        type: 'bar-chart',
        xLabel: 'Option',
        smart: true,
        compare: true,
        yLabel: 'Count',
        targetQuestionId: 'car-ownership',
        nestedQuestionId: 'Do you drive an EV?',
        compareQuestionId: 'Do you frequently drive over the speed limit?',
        surveyName: 'demo-survey-test',
      },
    },
    // {
    //   id: 'pref-car',
    //   type: imageChoice,
    //   text:
    //     'Would you like BCITO to put in touch with employers so that you might finish our training?',
    //   choices: [
    //     {
    //       id: 1,
    //       value: 'Yes',
    //       image: 'Bcito/check.svg',
    //     },
    //     {
    //       id: 2,
    //       value: 'No',
    //       image: 'Bcito/cross.svg',
    //     },
    //   ],
    // },
    {
      id: 'dream-car',
      type: singleTextbox,
      inputType: 'text',
      placeholder: '',
      text: 'What is your dream car?',
    },
    {
      id: 'dreamcar-results',
      type: welcomePage,
      text: 'Aggregate results',
      output: {
        type: 'word-cloud',
        xLabel: 'Option',
        yLabel: 'Count',
        targetQuestionId: 'dream-car',
        surveyName: 'demo-survey-test',
      },
    },
  ],
  settings: {
    surveyName: 'demo-survey-test',
    primaryColour: '#48a54c',
    otherColour: '#72a174',
    hideSubmit: true,
  },
}

export default DemoSurvey
