import * as firebase from 'firebase/app'
import { multiChoice, welcomePage } from '../question-types/QuestionTypes'

const aggResults = firebase.functions().httpsCallable('aggResults')

const AggTest = {
  questions: [
    {
      id: 0,
      type: multiChoice,
      text: 'Choose one of the following options:',
      choices: [
        { id: 1, text: 'Option A', value: 'A' },
        { id: 2, text: 'Option B', value: 'B' },
        { id: 3, text: 'Option C', value: 'C' },
        { id: 4, text: 'Option D', value: 'D' },
      ],
    },
    {
      id: 1,
      type: welcomePage,
      text: 'Aggregate results',
      output: {
        type: 'bar-chart',
        xLabel: 'Option',
        yLabel: 'Count',
        qid: 0,
        function: () => aggResults({ surveyName: 'AggTest', qid: 0 }),
      },
    },
  ],
  settings: {
    writeToFirestore: true,
    surveyName: 'AggTest',
    progressBar: false,
  },
}

export default AggTest
