import React from 'react'
import styled from 'styled-components/macro'
import { conjointAnalysis } from '../question-types/QuestionTypes'
import { v4 as uuidv4 } from 'uuid'
// import backgroundImage from '../../assets/images/Scarlatti/Background_1.svg'
import backgroundImage from '../../assets/images/Scarlatti/christmas_background.svg'

import { ReactComponent as Banner } from '../../assets/images/Scarlatti/banner.svg'
import { ReactComponent as a2 } from '../../assets/images/Scarlatti/a2.svg'
import { ReactComponent as a3 } from '../../assets/images/Scarlatti/a3.svg'
import { ReactComponent as g1 } from '../../assets/images/Scarlatti/g1.svg'
import { ReactComponent as g2 } from '../../assets/images/Scarlatti/g2.svg'
import { ReactComponent as g3 } from '../../assets/images/Scarlatti/g3.svg'
import { ReactComponent as s1 } from '../../assets/images/Scarlatti/s1.svg'
import { ReactComponent as s2 } from '../../assets/images/Scarlatti/s2.svg'
import { ReactComponent as s3 } from '../../assets/images/Scarlatti/s3.svg'
import _ from 'lodash'

const tempUserId = uuidv4()

// const Santa = styled(head)``

const A2 = styled(a2)`
  height: 4rem;
  width: 4rem;
`
const A3 = styled(a3)`
  height: 4rem;
  width: 4rem;
`
const G1 = styled(g1)`
  height: 4rem;
  width: 4rem;
`
const G2 = styled(g2)`
  height: 4rem;
  width: 4rem;
`
const G3 = styled(g3)`
  height: 4rem;
  width: 4rem;
`
const S1 = styled(s1)`
  height: 4rem;
  width: 4rem;
`
const S2 = styled(s2)`
  height: 4rem;
  width: 4rem;
`

const S3 = styled(s3)`
  height: 4rem;
  width: 4rem;
`

const IconBanner = styled(Banner)`
  margin-bottom: 1.5rem;
`

const Container = styled.div`
  padding-top: 15vh;
`

const Text = styled.p`
  font-weight: 600;
  font-size: 1.035rem;
  margin-bottom: 0rem;
`

const StyledA = styled.a`
  text-decoration: underline;
  color: #096dd9;
`

// const Spacer = styled.div`
//   height:
// `

const ConjointTest = {
  questions: [
    {
      id: 'intro-page',
      type: 'welcomePage',
      text: (
        <Container>
          {/* <Spacer/> */}
          <IconBanner />
          <p>
            Santa is looking at how he can improve the wellbeing of his elves, while reducing his
            environmental footprint. But, of Claus, Santa can’t afford to do it all.
          </p>
          <p>
            This survey will clarify which of the changes will deliver the highest value. Santa will
            then have the final sleigh.{' '}
          </p>
          {/* <Santa /> */}
        </Container>
      ),
    },
    {
      id: 'intro-page-2',
      type: 'welcomePage',
      text: (
        <Container>
          <p>
            As an Elf, you will be asked what changes would give you the most value. These changes
            could include:
          </p>
          <p>
            <div>
              <li>Santa upgrades his sleigh from coal to either petrol or electric</li>
              <li>You get a 10, 20 or 30 gold coin pay increase from 100 gold coins a month</li>
              <li>You get an increase in annual leave from 4 to 8 weeks</li>
            </div>
          </p>
        </Container>
      ),
    },
    {
      id: 'test',
      type: conjointAnalysis,
      hideUi: true,
      choices: 2,
      maxChoices: 6,
      attributes: [
        {
          id: 1,
          value: 'Sleigh type',
          levels: [
            { id: 1, value: 'Coal', text: 'Keep coal sleigh', icon: <S1 /> },
            { id: 2, value: 'Petrol', text: 'Upgrade to petrol sleigh', icon: <S2 /> },
            { id: 3, value: 'Electric', text: 'Upgrade to electric sleigh', icon: <S3 /> },
          ],
        },
        {
          id: 2,
          value: 'Annual leave',
          levels: [
            { id: 1, value: '4 weeks', text: '4 weeks annual leave ', icon: <A2 /> },
            { id: 2, value: '8 weeks', text: '8 weeks annual leave ', icon: <A3 /> },
          ],
        },
        {
          id: 3,
          value: 'Elf pay',
          levels: [
            { id: 1, value: '$10', text: '10 gold coin increase', icon: <G1 /> },
            { id: 2, value: '$20', text: '20 gold coin increase', icon: <G2 /> },
            { id: 3, value: '$30', text: '30 gold coin increase', icon: <G3 /> },
          ],
        },
      ],
      levels: [],
      text: (
        <Container>
          <Text>Which of these 2 do you prefer?</Text>
        </Container>
      ),
    },
    {
      id: 'the-end',
      type: 'welcomePage',
      hidePreviousButton: true,
      // hideUi: true,
      text: (
        <Container>
          <Text>
            How much of your 100 gold coins a month would you and the other elves be willing to give
            up for:
          </Text>
        </Container>
      ),
      output: {
        type: 'conjoint',
        chartType: 'horizontal',
        xLabel: 'Gold coins',
        userColour: '#07A94B',
        othersColour: '#EE2B39',
        xStepSize: 5,
        smart: false,
        usePercentage: false,
        displayLegend: true,
        targetQuestionType: conjointAnalysis,
        targetQuestionId: 'test',
        surveyName: 'conjoint-test',
        height: '50vh',
        transformData: data => {
          const raw = valueModel(data)
          const output = {
            '4->8 weeks leave': raw['Annual leave - 8 weeks'] - raw['Annual leave - 4 weeks'],
            'Coal -> petrol sleigh': raw['Sleigh type - Petrol'] - raw['Sleigh type - Coal'],
            'Coal -> electric sleigh': raw['Sleigh type - Electric'] - raw['Sleigh type - Coal'],
          }
          return output
        },
        // transformData: data => {
        //   const valScale = (20 - 10) / (data['Elf pay - $20'].value - data['Elf pay - $10'].value)

        //   return _.mapValues(data, datapoint => {
        //     return Math.round((datapoint.value - data['Elf pay - $10'].value) * valScale + 10)
        //   })
        // },
      },
    },
    {
      id: 'call-to-action',
      type: 'endPage',
      text: (
        <Container>
          <p>
            Thanks for sleighing along with our little quiz. Santa is now much better informed about
            the elves' views.
          </p>
          <p>
            You have just completed a conjoint analysis survey which is a clever way to determine
            people’s trade-offs and how they make complex choices.{' '}
          </p>
          <p>
            Want to run a survey like this? The{' '}
            <StyledA target="_blank" rel="noreferrer" href="https://scarlatti.co.nz/contact/">
              Scarlatti team
            </StyledA>{' '}
            are ready to chat with you.
          </p>
        </Container>
      ),
    },
  ],
  settings: {
    surveyName: 'conjoint-test',
    primaryColour: '#48a54c',
    uuid: tempUserId,
    progressBar: false,
    hideSubmit: true,
    fullscreen: true,
    mobileBackground: true,
    mobileImage: backgroundImage,
    backgroundImage: backgroundImage,
    logoLocation: 'space-between',
    // writeToFirestore: true,
  },
}

function valueModel(data) {
  // const partworthToValue = partworth => {
  //   let point1
  //   let point2
  //   if (partworth <= data['Elf pay - $20'].value) {
  //     point1 = [10, data['Elf pay - $10'].value]
  //     point2 = [20, data['Elf pay - $20'].value]
  //   } else {
  //     point1 = [20, data['Elf pay - $20'].value]
  //     point2 = [30, data['Elf pay - $30'].value]
  //   }
  //   return (partworth - getIntercept(point1, point2)) / getSlope(point1, point2)
  // }

  const partworthToValue = partworth => {
    return (
      (partworth - data['Elf pay - $10'].value) *
        getSlope([10, data['Elf pay - $10'].value], [30, data['Elf pay - $30'].value]) +
      10
    )
  }

  return _.omitBy(
    _.mapValues(data, datapoint => {
      return Math.round(partworthToValue(datapoint.value))
    }),
    (val, key) => {
      return key.startsWith('Elf pay')
    },
  )
}

function getSlope(point1, point2) {
  return (point2[1] - point1[1]) / (point2[0] - point1[0])
}

function getIntercept(point1, point2) {
  return point1[1] - getSlope(point1, point2) * point1[0]
}

export default ConjointTest
