/* eslint-disable no-unused-vars */
import { Divider } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'

import {
  commentBox,
  multiChoice,
  responsiveMatrix,
  responsiveSlider,
  welcomePage,
} from '../question-types/QuestionTypes'

import { ReactComponent as Cancel } from '../../assets/images/ExtensionServices-internal/cancel.svg'
import backgroundVideo from '../../assets/images/ExtensionServices-internal/es-internal.mp4'
import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import { ReactComponent as Happy } from '../../assets/images/Scarlatti/Happy Face.svg'
import { ReactComponent as Ok } from '../../assets/images/Scarlatti/Ok face.svg'
import { ReactComponent as Sad } from '../../assets/images/Scarlatti/Sad Face.svg'
import { ReactComponent as VeryHappy } from '../../assets/images/Scarlatti/Very happy Face.svg'
import { ReactComponent as VerySad } from '../../assets/images/Scarlatti/Very sadFace 1.svg'
import { Textbox } from '../../assets/styles/global'
import { xlButton } from '../ui/Layout/ButtonContainer'

const IconHappy = styled(Happy)`
  width: 3rem;
  height: 3rem;
`
const IconOk = styled(Ok)`
  width: 3rem;
  height: 3rem;
`
const IconSad = styled(Sad)`
  width: 3rem;
  height: 3rem;
`
const IconVeryHappy = styled(VeryHappy)`
  width: 3rem;
  height: 3rem;
`
const IconVerySad = styled(VerySad)`
  width: 3rem;
  height: 3rem;
`

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 800px;
  li {
    font-size: 1rem;
    font-weight: 400;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 100%;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    width: 100%;
  }
`

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`

const IconCancel = styled(Cancel)`
  width: 45px;
  height: 80px;
`

const Q = styled.p`
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: #757575;
  margin-bottom: 0.5rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  p {
    margin-bottom: 0rem;
  }
  .ant-divider-horizontal {
    margin: 0px 0;
    border-top: 1px solid #c3c3c3;
    width: 49rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 80vw;
    .ant-divider-horizontal {
      width: 90vw;
    }
  }
  /* align-items: center; */
`
const Text = styled.p`
  font-size: 1rem;
  color: ${props => props.color};
`

// console.log('companies', distinctCompanies)

const ES = {
  questions: [
    {
      id: 'intro-page',
      type: welcomePage,
      buttonSize: xlButton.width,
      text: (
        <StyledTextbox>
          <h4>Extension Services project team monthly survey </h4>
          <p>The purpose of this survey is to:</p>
          <ul style={{ marginLeft: '1rem', lineHeight: '2' }}>
            <li>Gauge how the Scarlatti team members are feeling towards Extension Services</li>
            <li>Understand what is working well with the project management process </li>
            <li>
              Develop a picture of what challenges the team members are facing within the project
            </li>
            <li>Provide a space for team members to provide ideas for improvement</li>
          </ul>
          <p>
            The survey is anonymous unless you provide your own name. The survey should take
            approximately 5 minutes to complete. We’ll look to complete the survey regularly over
            the course of the project and report back to the team on the results of the survey at
            team meetings.
          </p>
          <p>
            This survey is being completed through Confer – Scarlatti’s very own survey tool! Team
            Confer would love to get your feedback on the user experience of Confer. We will provide
            a space for you to do this at the end of the survey.{' '}
          </p>
          <p style={{ marginBottom: '1rem' }}>
            Please click <i>NEXT</i> to begin
          </p>
        </StyledTextbox>
      ),
    },
    // 1.1
    {
      id: 'scale-questions',
      type: responsiveMatrix,
      section: (
        <Wrapper>
          <Q>Questions 1 of 3</Q>
          <Text color={'#48a54c'}>Operations</Text>
          <p style={{ marginTop: '0.5rem' }}>
            Please indicate your agreement with the following statements:
          </p>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: '',
      choices: [
        {
          id: 1,
          text: '1) I understand my role in this project:',
          value: 'I understand my role in this project',
        },
        {
          id: 2,
          text:
            '2) I feel that my contribution (knowledge and ideas) to the conversation is valued:',
          value: 'I feel that my contribution (knowledge and ideas) to the conversation is valued',
        },
        {
          id: 3,
          text: '3) I receive the support and time I need from others within the project:',
          value: 'I receive the support and time I need from others within the project',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    {
      id: 'scale-questions-2',
      type: responsiveMatrix,
      section: (
        <Wrapper>
          <Q>Questions 2 of 3</Q>
          <Text color={'#48a54c'}>Operations</Text>
          <p style={{ marginTop: '0.5rem' }}>
            Please indicate your agreement with the following statements:
          </p>
          <Divider />
        </Wrapper>
      ),
      // useValues: true,
      useIcons: true,
      text: '',
      choices: [
        {
          id: 1,
          text:
            '4) I can ask questions and get the answers I need to complete the activities I need to:',
          value:
            'I can ask questions and get the answers I need to complete the activities I need to',
        },
        {
          id: 2,
          text:
            '5) I have the right amount of time allocated to complete the activities I need to:',
          value: 'I have the right amount of time allocated to complete the activities I need to',
        },
        {
          id: 3,
          text: '6) I understand the direction of the project:',
          value: 'I understand the direction of the project',
        },
        {
          id: 4,
          text: '7) I feel engaged and motivated with the work I’m doing in this project:',
          value: 'I feel engaged and motivated with the work I’m doing in this project',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    {
      id: 'scale-questions-3',
      type: responsiveMatrix,
      section: (
        <Wrapper>
          <Q>Questions 3 of 3</Q>
          <Text color={'#48a54c'}>Operations</Text>
          <p style={{ marginTop: '0.5rem' }}>
            Please indicate your agreement with the following statements:
          </p>
          <Divider />
        </Wrapper>
      ),
      commentBox: true,
      placeholder: 'Please specify',
      // useValues: true,
      useIcons: true,
      text: '',
      choices: [
        {
          id: 1,
          text: '8) I am managing the work on this project well alongside my other work:',
          value: 'I am managing the work on this project well alongside my other work',
        },
        {
          id: 2,
          text: '9) I think the team are working well together on this project:',
          value: 'I think the team are working well together on this project',
        },
        {
          id: 3,
          text: '10) I feel like our processes are positively evolving with our own learnings:',
          value: 'I feel like our processes are positively evolving with our own learnings',
        },
        {
          id: 4,
          text: '11) I am happy that the project is on track at this stage of the work:',
          value: 'I am happy that the project is on track at this stage of the work',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    // 1.2
    {
      id: 'monthly-success',
      type: commentBox,
      text: 'What has been the biggest success(es) in the past month? ',
      placeholder: 'The biggest success was...',
    },
    // 1.3
    {
      id: 'monthly-challenge',
      type: commentBox,
      text: 'What has been the biggest challenge(s) in the past month?',
      placeholder: 'The biggest challenge was...',
    },
    // {
    //   id: 'improvements-comments',
    //   type: commentBox,
    //   text: 'What changes, adaptations or improvements have been made in the ES project to date? ',
    //   placeholder: 'Please specify',
    // },
    {
      id: 'communications',
      type: multiChoice,
      nestedInput: { id: 3, text: 'Please specify', value: '', trigger: 'Yes' },
      emailInput: true,
      noSkip: true,
      inputText: 'Please specify?',
      width: '489px',
      text: (
        <Textbox style={{ padding: 0 }}>
          <p style={{ marginBottom: '1rem' }}>
            Are there any changes, adaptations, or improvements you would like to make in the
            future?
          </p>
        </Textbox>
      ),
      choices: [
        {
          id: 1,
          text: 'Yes',
          value: 'Yes',
          icon: <Check fill="currentColor" />,
          fill: '#50d250',
        },
        {
          id: 2,
          text: 'No',
          value: 'No',
          icon: <Cross fill="currentColor" />,
          fill: '#1890ff',
        },
      ],
    },
    // 2.1

    // 3.2
    {
      id: 'meetings-slider',
      section: (
        <Wrapper>
          {/* <Text color={'#F9744C'}>Wellbeing</Text> */}
          <p style={{ marginTop: '0.5rem' }}>
            Please indicate your agreement with the following statements:
          </p>
          <Divider />
        </Wrapper>
      ),
      text: 'Do you think the frequency of internal meetings is about right?',
      type: responsiveSlider,
      default: 2,
      // showValue: true,
      marks: [
        { id: 1, text: 'No, we have too many meetings' },
        { id: 2, text: 'Yes, about right' },
        { id: 2, text: 'No, we need to meet more often' },
      ],
      choices: [1, 2, 3],
    },
    {
      id: 'regional-project-team',
      text: 'Are you part of the regional project team?',
      type: multiChoice,
      choices: [
        {
          id: 1,
          text: 'No',
          value: 'No',
          fill: '#1890ff',
          icon: <IconCross fill="currentColor" />,
        },
        {
          id: 2,
          text: 'Yes',
          value: 'Yes',
          fill: '#50d250',
          icon: <IconCheck fill="currentColor" />,
        },
        {
          id: 3,
          text: 'Prefer not to say',
          value: 'Prefer not to say',
          icon: <IconCancel fill="currentColor" />,
        },
      ],
    },
    {
      id: 'further-comments',
      type: commentBox,
      text: 'Are there any other comments you would like to make?',
      placeholder: 'Please specify',
    },
    // {
    //   id: 'confer-intro',
    //   type: welcomePage,
    //   section: (
    //     <Wrapper>
    //       <Text color={'#48a54c'}>Confer</Text>
    //       <Divider />
    //     </Wrapper>
    //   ),
    //   text: (
    //     <StyledTextbox>
    //       <p>Team Confer would love to get your feedback on the user experience of Confer </p>
    //     </StyledTextbox>
    //   ),
    // },
    // {
    //   id: 'confer-scale',
    //   type: responsiveMatrix,
    //   section: (
    //     <Wrapper>
    //       <Q>Questions 1 of 2</Q>
    //       <Text color={'#48a54c'}>Confer</Text>
    //       <p style={{ marginTop: '0.5rem' }}>
    //         Please indicate your agreement with the following statements:
    //       </p>
    //       <Divider />
    //     </Wrapper>
    //   ),
    //   // useValues: true,
    //   useIcons: true,
    //   text: '',
    //   choices: [
    //     {
    //       id: 1,
    //       text: '1) This survey was easy to understand and follow:',
    //       value: 'This survey was easy to understand and follow',
    //     },
    //     {
    //       id: 2,
    //       text: '2) I liked the overall look and feel:',
    //       value: 'I liked the overall look and feel',
    //     },
    //     {
    //       id: 2,
    //       text: '3) The survey worked well on my device / browser:',
    //       value: 'The survey worked well on my device / browser',
    //     },
    //   ],
    //   options: [
    //     {
    //       id: 1,
    //       text: 'Strongly disagree',
    //       value: 'Strongly disagree',
    //       icon: <IconVerySad />,
    //     },
    //     {
    //       id: 2,
    //       text: 'Disagree',
    //       value: 'Disagree',
    //       icon: <IconSad />,
    //     },
    //     {
    //       id: 3,
    //       text: 'Neutral',
    //       value: 'Neutral',
    //       icon: <IconOk />,
    //     },
    //     {
    //       id: 4,
    //       text: 'Agree',
    //       value: 'Agree',
    //       icon: <IconHappy />,
    //     },
    //     {
    //       id: 5,
    //       text: 'Strongly agree',
    //       value: 'Strongly agree',
    //       icon: <IconVeryHappy />,
    //     },
    //   ],
    // },
    // // 1.2
    // {
    //   id: 'confer-feedback',
    //   type: commentBox,
    //   text: 'Do you have any suggestions on how we could make this survey experience better?',
    //   placeholder: 'Please specify',
    // },
    {
      id: 'end-page',
      type: 'endPage',
      text: ({ answers }) => {
        return (
          <div>
            <p style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              Thank you
              <IconVeryHappy style={{ marginLeft: '1rem' }} />
            </p>
          </div>
        )
      },
    },
  ],
  settings: {
    writeToFirestore: true,
    surveyName: 'internal-es',
    hideButton: true,
    // showQid: true,
    useSections: false,
    hideSubmit: true,
    logoLocation: 'space-between',
    progressBar: true,
    objectPosition: 'center',
    useVideo: true,
    backgroundVideo,
  },
}

export default ES
