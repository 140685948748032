import styled from 'styled-components/macro'

export const SmallText = styled.p`
  font-size: 0.8rem;
  font-family: 'Inter', sans-serif;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-wrap: break-word;
  word-break: normal;
  /* margin-bottom: 1rem; */
  .ant-divider-horizontal {
    margin: 15px 0;
    border-top: 1px solid #48a54c;
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  p {
    overflow-wrap: break-word;
    word-break: normal;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 100%;

    .ant-divider-horizontal {
      width: 100% !important;
    }
  }
`

export const ColumnContainer = styled.div`
  display: flex;
  width: inherit;
  word-wrap: break-word;
  flex-direction: column;
`

export const QuestionHeader = styled.h6`
  font-size: 1.3rem;
  margin-bottom: 0;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
`

export const SectionHeader = styled.p`
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
`

export const Textbox = styled.div`
  word-wrap: break-word;
  .ant-divider-horizontal {
    margin: 15px 0;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    /* width: 100vw; */

    align-items: center;
    word-wrap: break-word;
    div {
      /* overflow: auto; */
    }
  }
  @media (min-width: 480px) and (max-width: 768px) {
    /* padding: 22px; */
    padding: 22px;
    padding-left: 0px;
    padding-bottom: 0px;
    align-items: center;
    word-wrap: break-word;
    /* height: 600px; */
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    padding: 22px;
    padding-bottom: 0px;
    padding-left: 0px;
    align-items: center;
    word-wrap: break-word;
  }
`

// export const Global = createGlobalStyle`

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }
// :root {
//   font-size: ${px2vw(24)};
//   @media (min-width: 768px) {
//     font-size: ${px2vw(18)}
//   }

//   @media (min-width: 1024px) {
//     font-size: ${px2vw(16)}
//   }

// }
// `

// const App = () => {
//   ;<>
//     <Global />
//   </>
// }

// export default Global
