import * as firebase from 'firebase/app'
import _, { sum } from 'lodash'
import React from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { ReactComponent as revenue } from '../../assets/images/HCF/revenue.svg'
import leftBackgroundImage from '../../assets/images/Scarlatti/retreat.JPG' // Todo compress image
import { conjointAnalysis, endPage, welcomePage } from '../question-types/QuestionTypes'
import { xlButton } from '../ui/Layout/ButtonContainer'
// import backgroudImage from '../../assets/images/HCF/min-background-1.png'
import scarlatti from '../../assets/images/PIAS/scarlatti.png'

import { v4 as uuidv4 } from 'uuid'
import conferLogo from '../../assets/images/Confer-logo.png'
import { Textbox } from '../../assets/styles/global'

import { ReactComponent as star } from '../../assets/images/HCF/star.svg'

const db = firebase.firestore()

const aColours = {
  work: '#4a4a93',
  support: '#4384cd',
  social: '#003d5b',
  pay: '#086a46',
}

const StarIcon = styled(star)`
  display: flex;
  height: 1.5rem;
  fill: ${props => props.aColor};
`

const StarContainer = styled.div`
  display: flex;
`

const ConjointTitle = styled.p`
  font-weight: 600;
  text-align: left;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  align-items: baseline;
  padding: 10px;
  @media (min-width: 0px) and (max-width: 479px) {
    width: 100%;
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ImgContainer = styled.div`
  margin: 0;
  // svg behaves weirdly with width defined
  /* width: 100%; */
  /* margin-right: 0.5rem; */
  height: 100%;
  // used for debug
  /* background-color: red; */
  img {
    padding: 20px;
    height: 12rem;
    // svg behaves weirdly with width defined

    /* width: 100%; */
    svg {
      display: block;
    }
  }
  @media (min-width: 0px) and (max-width: 900px) {
    img {
      /* border-bottom-left-radius: 10px;
      border-top-left-radius: 10px; */
      height: 7.5rem;
      width: 8rem;
      object-fit: cover;
    }
  }
`
const DBold = styled.span`
  font-weight: 600;
`

const Title = styled.p`
  margin-bottom: 0.5rem;
  text-align: left;
  font-weight: 600;
  color: ${props => props.aColor};
`

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin-top: 8rem; */
  ul {
    list-style: none;
    list-style-position: inside;
    margin-left: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    margin-top: 0rem;
  }
  /* width: 800px; */
`

const IconRevenue = styled(revenue)`
  height: 3rem;
`

const HText = styled.p`
  /* margin:  */
  text-align: left;
  margin-bottom: 0rem;
`
const Italic = styled.p`
  /* font-style: italic; */
  margin-bottom: 0rem;
`
const Text = styled.p`
  font-weight: 600;
  margin-bottom: 0rem;
  /* font-size: 1.035rem; */
`

const StyledP = styled.p`
  line-height: 1.7;
  /* font-size: 1rem;
  color: rgba(0, 0, 0, 0.65); */
  margin-bottom: 1rem;
  margin-top: 0rem;
  font-weight: 400;
`

const animateText = keyframes`
0% {
  background-size: 0% 100%;
}
100% {
  background-size: 100% 100%
}
`

const Headline = styled.p`
  font-size: 1rem;
  background: linear-gradient(180deg, transparent 85%, #05b153 0);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  margin-bottom: 0rem;
  animation: 2s ${animateText} ease-in-out;
`

let sortedAnswers

const tempUserId = uuidv4()

const ScarlattiSnapshot = {
  questions: [
    {
      id: 'intro-page',
      type: welcomePage,
      buttonSize: xlButton.width,
      text: (
        <StyledTextbox style={{ paddingTop: '20px', alignItems: 'baseline' }}>
          <h3 style={{ fontWeight: '600' }}>Valuing wellbeing initiatives for Scarlatti</h3>
          <StyledP>
            We wanted to include an interactive conjoint analysis example in the Scarlatti snapshot
            presentation. We looked to create a survey that would collect some insights from the
            team, and potentially provide us with a tool we could use in future wellbeing programme
            design projects. So we created this!
          </StyledP>
          <StyledP>
            This survey aims to gauge what combinations of wellbeing initiatives employees at
            Scarlatti would most value. <b>We are testing this conjoint with Scarlatti</b>, where
            the results from this survey will be used to{' '}
            <b>inform a wellbeing programme for Scarlatti</b>. As Dana said, “we are not Sweden” so
            no promises will be made on what we can deliver and when.
          </StyledP>
          <StyledP>
            In this survey you will be presented with a series of eight choices, each containing a
            trade off between two wellbeing initiatives. Once you have, to the best of your ability,
            selected your preferences, you will be presented with the analysis of your answers
            compared to the answers of others.{' '}
            <b>
              Please do not close this screen as the results will update as others complete this
              survey live and we will discuss these results together.
            </b>
          </StyledP>
        </StyledTextbox>
      ),
    },

    {
      id: 'valuing-outcomes',
      type: conjointAnalysis,
      hideUi: true,
      useIcon: false,
      showProgress: true,
      useStar: true,
      label: false,
      levelsTextAlign: 'left',
      removeInfo: true, // removes text on right
      height: '50%',
      maxChoices: 8,
      buttonSize: xlButton.width,
      justify: 'space-between',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              value: 'Productivity',
              levels: [
                { id: 1, value: 'level 4', text: 'Productivity level 4' },
                { id: 2, value: 'level 3', text: 'Productivity level 3' },
                { id: 3, value: 'level 2', text: 'Productivity level 2' },
                { id: 4, value: 'level 1', text: 'Productivity level 1' },
              ],
            },
            {
              id: 2,
              value: 'Environmental',
              levels: [
                { id: 1, value: 'level 4', text: 'Environmental level 4' },
                { id: 2, value: 'level 3', text: 'Environmental level 3' },
                { id: 3, value: 'level 2', text: 'Environmental level 2' },
                { id: 4, value: 'level 1', text: 'Environmental level 1' },
              ],
            },
            {
              id: 3,
              value: 'Wellbeing',
              levels: [
                { id: 1, value: 'level 4', text: 'Wellbeing level 4' },
                { id: 2, value: 'level 3', text: 'Wellbeing level 3' },
                { id: 3, value: 'level 2', text: 'Wellbeing level 2' },
                { id: 4, value: 'level 1', text: 'Wellbeing level 1' },
              ],
            },
          ]
          return defaultAnswers
        }

        let pipedAnswersA = [
          {
            id: 1,
            value: 'Flexible work',
            text: 'A 40-hour work week with same pay',
            levels: [
              {
                id: 1,
                icon: (
                  <Column>
                    <Title aColor={aColours['work']}>Flexible work</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['work']} />
                    </StarContainer>
                  </Column>
                ),
                value: 'A 40-hour work week with same pay',
                text: (
                  <div>
                    <p style={{ marginBottom: 0 }}>
                      A <b>40-hour work week</b> with the same pay
                    </p>
                  </div>
                ),
              },
              {
                id: 2,
                icon: (
                  <Column>
                    <Title aColor={aColours['work']}>Flexible work</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['work']} />
                      <StarIcon aColor={aColours['work']} />
                    </StarContainer>
                  </Column>
                ),
                value: 'A 36-hour work week with the same pay',
                text: (
                  <div>
                    <p style={{ marginBottom: 0 }}>
                      A <b>36-hour work week</b> with the same pay
                    </p>
                  </div>
                ),
              },
              {
                id: 3,
                icon: (
                  <Column>
                    <Title aColor={aColours['work']}>Flexible work</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['work']} />
                      <StarIcon aColor={aColours['work']} />
                      <StarIcon aColor={aColours['work']} />
                    </StarContainer>
                  </Column>
                ),
                value: 'A 32-hour work week with the same pay',
                text: (
                  <div>
                    <p style={{ marginBottom: 0 }}>
                      A <b>32-hour work week</b> with the same pay
                    </p>
                  </div>
                ),
              },
            ],
          },
          {
            id: 2,
            value: 'Support management',
            text: 'No access to a support manager',
            levels: [
              {
                id: 1,
                value: 'No access to a support manager',
                icon: (
                  <Column>
                    {/* <D1></D1> */}
                    <Title aColor={aColours['support']}>Support management</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['support']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <ConjointTitle>Support management</ConjointTitle> */}
                    <p style={{ marginBottom: 0 }}>
                      <b>No access</b> to a capable and compassionate support manager
                    </p>
                  </div>
                ),
              },
              {
                id: 2,
                value: 'Weekly access to an untrained support manager',
                icon: (
                  <Column>
                    {/* <D1></D1> */}
                    <Title aColor={aColours['support']}>Support management</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['support']} />
                      <StarIcon aColor={aColours['support']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <ConjointTitle>Support management</ConjointTitle> */}
                    <p style={{ marginBottom: 0 }}>
                      <b>Limited access</b> to a capable and compassionate support manager
                    </p>
                  </div>
                ),
              },
              {
                id: 3,
                value: 'Weekly access to a well-trained support manager',
                icon: (
                  <Column>
                    {/* <D1></D1> */}
                    <Title aColor={aColours['support']}>Support management</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['support']} />
                      <StarIcon aColor={aColours['support']} />
                      <StarIcon aColor={aColours['support']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <ConjointTitle>Support management</ConjointTitle> */}
                    <p style={{ marginBottom: 0 }}>
                      <b>Access whenever you need</b> to a capable and compassionate support manager
                    </p>
                  </div>
                ),
              },
            ],
          },
          {
            id: 3,
            value: 'Social activities',
            text: 'No planned or paid for social activities',
            levels: [
              {
                id: 1,
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                value: 'No planned or paid for social activities',
                icon: (
                  <Column>
                    {/* <D1></D1> */}
                    <Title aColor={aColours['social']}>Social activities</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['social']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <ConjointTitle>Social activities</ConjointTitle> */}
                    <p style={{ marginBottom: 0 }}>No planned or paid for social activities</p>
                  </div>
                ),
              },
              {
                id: 2,
                icon: (
                  <Column>
                    {/* <D1></D1> */}
                    <Title aColor={aColours['social']}>Social activities</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['social']} />
                      <StarIcon aColor={aColours['social']} />
                    </StarContainer>
                  </Column>
                ),
                value: 'Planned and paid for social activities twice a year',
                text: (
                  <div>
                    {/* <ConjointTitle>Social activities</ConjointTitle> */}
                    <p style={{ marginBottom: 0 }}>
                      Planned and paid for social activities <b>twice a year</b>
                    </p>
                  </div>
                ),
              },
              {
                id: 3,
                icon: (
                  <Column>
                    {/* <D1></D1> */}
                    <Title aColor={aColours['social']}>Social activities</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['social']} />
                      <StarIcon aColor={aColours['social']} />
                      <StarIcon aColor={aColours['social']} />
                    </StarContainer>
                  </Column>
                ),
                value: 'Planned and paid for social activities once a month',
                text: (
                  <div>
                    {/* <ConjointTitle>Social activities</ConjointTitle> */}
                    <p style={{ marginBottom: 0 }}>
                      Planned and paid for social activities <b>once a month</b>
                    </p>
                  </div>
                ),
              },
            ],
          },

          {
            id: 4,
            value: 'Pay',
            text: 'Pay placeholder',
            levels: [
              {
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                id: 1,
                icon: (
                  <Column>
                    {/* <D1></D1> */}
                    <Title aColor={aColours['pay']}>Pay</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['pay']} />
                    </StarContainer>
                  </Column>
                ),
                value: 'Pay',
                text: (
                  <div>
                    {/* <ConjointTitle>Pay</ConjointTitle> */}
                    <p style={{ marginBottom: 0 }}>
                      <b>No increase</b> in salary
                    </p>
                  </div>
                ),
              },
              {
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                id: 2,
                icon: (
                  <Column>
                    {/* <D1></D1> */}
                    <Title aColor={aColours['pay']}>Pay</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['pay']} />
                      <StarIcon aColor={aColours['pay']} />
                    </StarContainer>
                  </Column>
                ),
                value: 'Pay',
                text: (
                  <div>
                    {/* <ConjointTitle>Pay</ConjointTitle> */}
                    <p style={{ marginBottom: 0 }}>
                      <b>$3,000 increase</b> in salary a year
                    </p>
                  </div>
                ),
              },
              {
                icon: (
                  <Column>
                    {/* <D1></D1> */}
                    <Title aColor={aColours['pay']}>Pay</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['pay']} />
                      <StarIcon aColor={aColours['pay']} />
                      <StarIcon aColor={aColours['pay']} />
                    </StarContainer>
                  </Column>
                ),
                id: 3,
                value: 'Pay',
                text: (
                  <div>
                    {/* <ConjointTitle>Pay</ConjointTitle> */}
                    <p style={{ marginBottom: 0 }}>
                      <b>$10,000 increase</b> in salary a year
                    </p>
                  </div>
                ),
              },
            ],
          },
        ]

        return pipedAnswersA
      },

      text: (
        <Textbox>
          <Text>
            If you could choose which of the following options would you{' '}
            <span style={{ color: '#F9744C' }}>prefer</span> implemented at Scarlatti?
          </Text>
        </Textbox>
      ),
    },
    {
      id: 'results',
      type: 'welcomePage',
      buttonSize: xlButton.width,

      // hideUi: true,
      // text: 'placeholder text',
      text: () => {
        return (
          <StyledTextbox style={{ alignItems: 'baseline' }}>
            <StyledP style={{ fontWeight: '600' }}>Thank you for completing the survey.</StyledP>
            <StyledP>
              Based on your responses, the chart below illustrates the relative value you (compared
              to others) place on the receiving the best level for each wellbeing initiative.
            </StyledP>
            <StyledP style={{ fontStyle: 'italic' }}>
              For more information about what this is, click{' '}
              <a
                href="https://conjointly.com/guides/how-to-interpret-partworth-utilities/"
                rel="noreferrer"
                target="_blank"
                style={{ color: '#F9744C', textDecoration: 'underline' }}
              >
                here
              </a>
            </StyledP>
          </StyledTextbox>
        )
      },
      output: {
        type: 'conjoint',
        chartType: 'horizontal',
        xLabel: 'Relative value',
        userColour: '#F9744C',
        // othersColour: '#EE2B39',
        xStepSize: 5,
        smart: false,
        userAnswers: false,
        usePercentage: true,
        displayLegend: true,
        targetQuestionType: conjointAnalysis,
        targetQuestionId: 'valuing-outcomes',
        surveyName: 'scarlatti-snapshot',
        transformData: data => {
          let mydata = data['valuing-outcomes']['value'].partworths

          let attributes = data['valuing-outcomes']['value'].attributes.map(item => ({
            label: item.value,
            attribute_id: item.id,
            partworth: mydata
              .find(attrId => item.id === attrId.attribute_id)
              ['partworth_value'].find(level => level['level_id'] === 3).partworth,
          }))

          let partworthsArray = mydata.map(
            arr => arr['partworth_value'].find(level => level['level_id'] === 3).partworth,
          )

          let output = {}
          attributes.forEach((item, index) => {
            output[item.label] = Math.round((item['partworth'] / sum(partworthsArray)) * 100)
          })
          return output
          // object == keys from attributes
        },
        transformAggData: (dbData, userData) => {
          const transformUserdata = udata => {
            let mydata = udata['valuing-outcomes']['value'].partworths
            console.log('rawdata (per user)', mydata)

            let updatedLabels = {
              Weather: 'Resilience to adverse weather',
              Land: 'Health of land',
              Waterways: 'Health of waterways',
              Community: 'Strength of community',
              Health: 'Physical and mental health',
              Profitability: 'Profitability',
            }

            let attributes = udata['valuing-outcomes']['value'].attributes.map(item => ({
              label: item.value,
              attribute_id: item.id,
              partworth: mydata
                .find(attrId => item.id === attrId.attribute_id)
                ['partworth_value'].find(level => level['level_id'] === 3).partworth,
            }))

            let partworthsArray = mydata.map(
              arr => arr['partworth_value'].find(level => level['level_id'] === 3).partworth,
            )
            console.log('partworths', partworthsArray)

            let output = {}
            attributes.forEach((item, index) => {
              output[item.label] = Math.round((item['partworth'] / sum(partworthsArray)) * 100)
            })
            return output
          }

          const transformDbdata = (ddata, udata) => {
            let updatedDBLabels = {
              'attr_id 1': 'Flexible work',
              'attr_id 2': 'Support management',
              'attr_id 3': 'Social activities',
              'attr_id 4': 'Pay',
            }
            let newArray = Object.entries(ddata).map(item => ({
              label: updatedDBLabels[item[0]],
              attribute_id: item[0],
              partworth: item[1]['level_id 3'].PW,
            }))

            let attr_user = udata['valuing-outcomes']['value'].attributes.map(item => item.id)

            let filteredArray = []
            for (let i = 0; i < newArray.length; i++) {
              const t = parseInt(newArray[i].attribute_id.split(' ').pop())
              if (attr_user.includes(t)) {
                filteredArray.push(newArray[i])
              }
            }

            const pwArray = filteredArray.map(a => a.partworth)
            let dboutput = {}
            filteredArray.forEach((item, index) => {
              dboutput[item.label] = Math.round((item.partworth / sum(pwArray)) * 100)
            })
            return dboutput
          }
          const output1 = transformUserdata(userData)
          const output2 = transformDbdata(dbData, userData)

          return [output1, output2]
        },
      },
    },

    {
      id: 'survey-end',
      type: endPage,
      text: () => {
        return (
          <Textbox style={{ padding: '0px' }}>
            <h1 style={{ color: 'rgba(0, 0, 0, 0.65) ', fontSize: '18px', fontWeight: 600 }}>
              Thank you for completing the survey!
              <br />
              Your response will help inform efforts to protect and enhance our hill country
              environment.
            </h1>

            {/* <p>Like this survey and want to create your own? Let&apos;s Confer!</p> */}
            <a
              href="https://confer.online"
              rel="noreferrer"
              target="_blank"
              style={{ color: '#E82D7C', textDecoration: 'underline' }}
            >
              <p>Like this survey and want to create your own? Let&apos;s Confer!</p>
            </a>
            <div style={{ fontSize: '18px' }}>
              <img width="255px" src={conferLogo} alt="confer-logo.jpg" />
            </div>
          </Textbox>
        )
      },
    },
  ],
  settings: {
    surveyName: 'scarlatti-snapshot',
    // primaryColour: '#afd135',
    primaryColour: '#F9744C',
    uuid: tempUserId,

    // hideButton: true,
    otherColour: '#72a174',
    hideSubmit: true,
    showQid: false,
    specialEffects: true,
    logoFirstPageOnly: true,
    logoStyle: {
      marginTop: '1rem',
    },
    companyLogo: {
      images: [{ image: scarlatti }],
    },
    logoStyle: {
      marginTop: '1rem',
    },
    logoLocation: 'space-between',
    // fullscreen: true,
    // leftBackgroundImage,
    // backgroundImage: backgroudImage,
    leftBackgroundImage,
    backgroundImage: leftBackgroundImage,
    progressBar: true,
  },
}

export default ScarlattiSnapshot
