/* eslint-disable no-unused-vars */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'
import React from 'react'
import styled from 'styled-components/macro'
import 'typeface-open-sans'
import leftBackgroundImage from '../../assets/images/StepChange/cow-left.jpg'
import companyLogo from '../../assets/images/StepChange/DairyNZ.png'

import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import { endPage, multiChoice, welcomePage } from '../question-types/QuestionTypes'

import { Textbox } from '../../assets/styles/global'
import { xlButton } from '../ui/Layout/ButtonContainer'

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  letter-spacing: 0.1em;
  p {
    margin-bottom: 0;
  }
  /* align-items: center; */
`
const GreenText = styled.p`
  font-size: 1rem;
  color: #6fb139;
  font-family: 'Open Sans', sans-serif;
`

const StyledCheckOutlined = styled(CheckOutlined)`
  color: #46e4b5;
`

const StyledCloseOutlined = styled(CloseOutlined)`
  color: #5b3bdf;
`

const SectionInfo = styled.p`
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #9b9b9b;
`

const YellowText = styled.p`
  font-size: 1rem;
  color: #eaae19;
`

const DarkBlueText = styled.p`
  font-size: 1rem;
  color: #474f40;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  width: 90vw;
`

const RedText = styled.p`
  font-size: 1rem;
  color: #84367a;
`

const BlueText = styled.p`
  font-size: 1rem;
  color: #00a7b8;
  font-family: 'Open Sans', sans-serif;
`

const DisclaimerText = styled.p`
  font-size: 1rem;
  width: 90vw;
  margin-bottom: 1rem;
  /* font-style: italic; */
`

const DarkGreenText = styled.p`
  font-size: 1rem;
  color: #4a6f2f;
  font-family: 'Open Sans', sans-serif;
`

const Grey = styled.h1`
  color: #535d52;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.1em;
`

const StyledP = styled.p`
  font-size: 20px;
`

const BoldText = styled.p`
  font-weight: 700;
  margin-top: 1rem;
`

const Container = styled.div`
  background-color: red;
  height: 100vh;
  width: 100vw;
`

const StepChangeFinal = {
  questions: [
    {
      id: 0,
      type: welcomePage,
      text: (
        <Textbox>
          <Grey> DairyNZ farmer action survey</Grey>
          <p>
            DairyNZ are keen to understand your thoughts and what actions you are taking to meet
            your emissions and water quality obligations.
          </p>
          <p>
            The survey will take 5 – 10 mins to complete. Please be assured that all answers you
            provide are anonymous.
          </p>
        </Textbox>
      ),
    },
    {
      id: 1,
      required: true,
      section: (
        <Wrapper>
          <BlueText>Overall</BlueText>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'Which region are you in?',
      choices: [
        {
          id: 1,
          value: 'Northland',
          text: 'Northland',
        },
        {
          id: 2,
          value: 'North Waikato',
          text: 'North Waikato',
        },
        {
          id: 3,
          value: 'South Waikato',
          text: 'South Waikato',
        },
        {
          id: 4,
          value: 'Bay of Plenty',
          text: 'Bay of Plenty',
        },
        {
          id: 5,
          value: 'Taranaki',
          text: 'Taranaki',
        },
        {
          id: 6,
          value: 'Lower North Island',
          text: 'Lower North Island',
        },
        {
          id: 7,
          value: 'Top of South Island / West Coast',
          text: 'Top of South Island / West Coast',
        },
        {
          id: 8,
          value: 'Canterbury / North Otago',
          text: 'Canterbury / North Otago',
        },
        {
          id: 9,
          value: 'Southland / South Otago',
          text: 'Southland / South Otago',
        },
      ],
    },
    {
      id: 2,
      required: true,
      buttonSize: xlButton.width,
      section: (
        <Wrapper>
          <BlueText>Overall</BlueText>
        </Wrapper>
      ),
      type: multiChoice,
      text:
        'Which best describes you in relation to taking action towards environmental sustainability?',
      choices: [
        {
          id: 1,
          text: "It's not on my radar",
          value: "It's not on my radar",
        },
        {
          id: 2,
          text:
            "I don't think I should have to, or don't want to, take action towards environmental sustainability",
          value:
            "I don't think I should have to, or don't want to, take action towards environmental sustainability",
        },
        {
          id: 3,
          text:
            "I'm willing to take action towards environmental sustainability, but I don't know where to start",
          value:
            "I'm willing to take action towards environmental sustainability, but I don't know where to start",
        },
        {
          id: 4,
          text:
            "I'm willing to take action towards environmental sustainability, and I know where to start, but its not a priority so I haven't done anything yet",
          value:
            "I'm willing to take action towards environmental sustainability, and I know where to start, but its not a priority so I haven't done anything yet",
        },
        {
          id: 5,
          text:
            "I've taken some action towards environmental sustainability but I'm not confident its enough to meet long term obligations and whether I can be profitable as well",
          value:
            "I've taken some action towards environmental sustainability but I'm not confident its enough to meet long term obligations and whether I can be profitable as well",
        },
        {
          id: 6,
          text:
            "I've adapted my farm system to be both environmentally and financially sustainable and I feel confident about the future",
          value:
            "I've adapted my farm system to be both environmentally and financially sustainable and I feel confident about the future",
        },
      ],
    },
    {
      id: 3,
      required: true,
      section: (
        <Wrapper>
          <GreenText>Financial management</GreenText>
        </Wrapper>
      ),
      type: 'imageRadioList',
      text: 'Which of the following is true for you?',
      choices: [
        {
          id: 1,
          text: 'I have a written business plan and am implementing it',
          value: 'I have a written business plan and am implementing it',
          answerChoices: [
            {
              id: 1,
              text: 'Yes',
              value: 'Yes',
              icon: <StyledCheckOutlined />,
            },
            {
              id: 2,
              text: 'No',
              value: 'No',
              icon: <StyledCloseOutlined />,
            },
            // { id: 1, value: 'Yes' },
            // { id: 2, value: 'No' },
          ],
        },
        {
          id: 2,
          text:
            'I use a budgeting and cashflow tool (template, resource, software etc.) to manage my finances',
          value:
            'I use a budgeting and cashflow tool (template, resource, software etc.) to manage my finances',
          answerChoices: [
            { id: 3, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 4, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 3,
          text:
            'I use financial benchmarking and key performance indicators (e.g. operating profit/ha, farm working expenses, return on assets etc.) to help me achieve my finance goals',
          value:
            'I use financial benchmarking and key performance indicators (e.g. operating profit/ha, farm working expenses, return on assets etc.) to help me achieve my finance goals',
          answerChoices: [
            { id: 5, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 6, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 4,
          text:
            'We have a clear plan to manage our debt over time (pay off plan, maintaining a debt to asset ratio)',
          value:
            'We have a clear plan to manage our debt over time (pay off plan, maintaining a debt to asset ratio)',
          answerChoices: [
            { id: 7, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 8, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
      ],
    },
    {
      id: 4,
      required: true,
      buttonSize: xlButton.width,
      section: (
        <Wrapper>
          <DarkBlueText>Freshwater Farm Plan / Farm Environment Plans</DarkBlueText>
          <DisclaimerText>
            Note: currently many Freshwater Farm Plans are known as Farm Environment Plans.
          </DisclaimerText>
        </Wrapper>
      ),
      disclaimer: 'Note: currently many Freshwater Farm Plans are known as Farm Environment Plans.',

      type: multiChoice,
      text: 'Which of the following best describes you with regards to a plan for your farm?',
      choices: [
        {
          id: 1,
          text:
            'Our farm has a Freshwater Farm Plan that was developed with input from a qualified advisor',
          value:
            'Our farm has a Freshwater Farm Plan that was developed with input from a qualified advisor',
        },
        {
          id: 2,
          text:
            'Our farm has a Freshwater Farm Plan but I am not confident that it is up to date and fit-for-purpose',
          value:
            'Our farm has a Freshwater Farm Plan but I am not confident that it is up to date and fit-for-purpose',
        },
        {
          id: 3,
          text: 'I have a plan in place to get a Freshwater Farm Plan',
          value: 'I have a plan in place to get a Freshwater Farm Plan',
        },
        {
          id: 4,
          text: 'I don’t plan to get a Freshwater Farm Plan',
          value: 'I don’t plan to get a Freshwater Farm Plan',
        },
      ],
    },
    {
      id: 5,
      required: true,
      section: (
        <Wrapper>
          <DarkBlueText>Freshwater Farm Plan / Farm Environment Plans</DarkBlueText>
          <DisclaimerText>
            Note: currently many Freshwater Farm Plans are known as Farm Environment Plans.
          </DisclaimerText>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'Is the following true for you?',
      // text:
      //   'We are recording when we complete the actions recommended in the Freshwater Farm Plan',
      statement: () => {
        return (
          <div>
            <BoldText>
              We are recording when we complete the actions recommended in the Freshwater Farm Plan.
            </BoldText>
          </div>
        )
      },
      choices: [
        {
          id: 1,
          text: 'Yes',
          value: 'Yes',
          icon: <IconCheck fill="currentColor" />,
        },
        {
          id: 2,
          text: 'No',
          value: 'No',
          icon: <IconCross fill="currentColor" />,
        },
      ],
    },
    {
      id: 6,
      required: true,
      section: (
        <Wrapper>
          <DarkBlueText>Nutrient management</DarkBlueText>
        </Wrapper>
      ),
      text: 'Which of the following is true for you?',
      type: 'imageRadioList',
      options: [
        { id: 1, value: 'Yes' },
        { id: 2, value: 'No' },
        { id: 3, value: 'Yes' },
        { id: 4, value: 'No' },
        { id: 5, value: 'Yes' },
        { id: 6, value: 'No' },
      ],
      headers: [
        'Which of the following is true for you?',
        'We understand Good Management Practices that apply to nutrient management and fertiliser application and follow these ',
      ],
      choices: [
        {
          id: 1,
          text: 'A nutrient budget is completed every year and reviewed with a qualified advisor',
          value: 'A nutrient budget is completed every year and reviewed with a qualified advisor',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 2, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 2,
          text:
            'Soil phosphorous levels are monitored through regular soil testing and maintained at or below agronomic optimum levels',
          value:
            'Soil phosphorous levels are monitored through regular soil testing and maintained at or below agronomic optimum levels',
          answerChoices: [
            { id: 3, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 4, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
      ],
      otherChoices: [
        {
          id: 3,
          text:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          value:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          answerChoices: [
            { id: 5, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 6, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 4,
          text:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          value:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          answerChoices: [
            { id: 7, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 8, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 5,
          text: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          value: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          answerChoices: [
            { id: 9, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 10, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 6,
          text:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          value:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          answerChoices: [
            { id: 11, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 12, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
      ],
    },
    {
      id: 7,
      required: true,
      section: (
        <Wrapper>
          <DarkBlueText>Nutrient management</DarkBlueText>
        </Wrapper>
      ),
      text: 'Which of the following is true for you?',
      type: 'imageRadioList',
      choices: [
        {
          id: 1,
          text:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          value:
            'Manage the amount and timing of fertiliser inputs, taking into account all sources of nutrients, to match plant requirements and minimise risk of losses.',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 2, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 2,
          text:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          value:
            'Store and load fertiliser to minimise risk of spillage, leaching and loss into water bodies.',
          answerChoices: [
            { id: 3, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 4, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 3,
          text: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          value: 'Ensure equipment for spreading fertiliser is well maintained and calibrated.',
          answerChoices: [
            { id: 5, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 6, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 4,
          text:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          value:
            'Store, transport and distribute feed to minimise wastage, leachate and soil damage.',
          answerChoices: [
            { id: 7, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 8, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
      ],
    },
    {
      id: 8,
      required: true,
      section: (
        <Wrapper>
          <BlueText>Water quality risk assesment</BlueText>
        </Wrapper>
      ),
      type: 'imageRadioList',
      text: 'Which of the following is true for you?',
      options: [
        { id: 1, value: 'Yes', icon: <StyledCheckOutlined /> },
        { id: 2, value: 'No', icon: <StyledCloseOutlined /> },
      ],
      choices: [
        {
          id: 1,
          text:
            'I have assessed the risk from all our farm practices to water quality (e.g. from farm dairy effluent, management of critical source areas – where water accumulates during wet periods; water use and irrigation; crop and pasture grazing principles.)',
          value:
            'I have assessed the risk from all our farm practices to water quality (e.g. from farm dairy effluent, management of critical source areas – where water accumulates during wet periods; water use and irrigation; crop and pasture grazing principles.)',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 2, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
        {
          id: 2,
          text:
            'For all high and medium risk areas identified we have developed time-bound actions to mitigate impacts.',
          value:
            'For all high and medium risk areas identified we have developed time-bound actions to mitigate impacts.',
          answerChoices: [
            { id: 3, value: 'Yes', icon: <StyledCheckOutlined /> },
            { id: 4, value: 'No', icon: <StyledCloseOutlined /> },
          ],
        },
      ],
    },
    {
      id: 9,
      required: true,
      buttonSize: xlButton.width,
      section: (
        <Wrapper>
          <GreenText>Greenhouse gases</GreenText>
        </Wrapper>
      ),
      type: multiChoice,
      text: 'Which of the following best describes you with regards to emissions on your farm? ',
      choices: [
        {
          id: 1,
          text:
            'I have not estimated greenhouse gas emissions or set targets / actions for my farm',
          value:
            'I have not estimated greenhouse gas emissions or set targets / actions for my farm',
        },
        {
          id: 2,
          text:
            'I have estimated how much greenhouse gases are emitted from my farm but have not set targets / actions',
          value:
            'I have estimated how much greenhouse gases are emitted from my farm but have not set targets / actions',
        },
        {
          id: 3,
          text: 'I have set targets and / or identified actions but have not taken action yet',
          value: 'I have set targets and / or identified actions but have not taken action yet',
        },
        {
          id: 4,
          text:
            'I have taken specific actions to change the amount of greenhouse gases produced by my farm',
          value:
            'I have taken specific actions to change the amount of greenhouse gases produced by my farm',
        },
      ],
    },
    {
      id: 10,
      text: 'Your answers have been submitted. Thank you for your time.',
      type: endPage,
    },
  ],
  settings: {
    writeToFirestore: false,
    surveyName: 'farmer-action-final',
    companyLogo,
    leftBackgroundImage,
    backgroundImage: leftBackgroundImage,
  },
}

export default StepChangeFinal
