import React from 'react'
import {
  commentBox,
  endPage,
  multiChoice,
  multiSelect,
  multiTextBox,
  welcomePage,
} from '../question-types/QuestionTypes'
import { Textbox } from '../../assets/styles/global'
import styled from 'styled-components/macro'
import ConCOVELogo from '../../assets/images/ConCOVE/ConCOVElogo.png'
import { xlButton } from '../ui/Layout/ButtonContainer'
import leftBackgroundImage from '../../assets/images/ConCOVE/ConCOVEbg.png'
import scarlattiLogo from '../../assets/images/ConCOVE/scarlattiLogo.svg'
import { Divider } from 'antd'
import _ from 'lodash'

const Text = styled.p`
  font-size: 1.2rem;
  color: ${props => props.color};
  font-weight: bold;
  margin-bottom: 0rem;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  font-size: 1rem;
  l {
    text-transform: lowercase;
    color: #ff6600;
  }
  ul {
    margin-left: 30px;
    margin-bottom: 0rem;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .ant-divider-horizontal {
    margin: 0px 0;
    border-top: 1px solid #c3c3c3;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    .ant-divider-horizontal {
      width: 90vw;
    }
  }
`
const activities = [
  {
    id: 1,
    text: 'Career framework',
    value: 'career framework',
  },
  {
    id: 2,
    text: 'Disruption',
    value: 'disruption',
  },
  {
    id: 3,
    text: 'Entry',
    value: 'entry',
  },
  {
    id: 4,
    text: 'Career progression',
    value: 'career progression',
  },
  {
    id: 5,
    text: 'Diversity',
    value: 'diversity',
  },
  {
    id: 6,
    text: 'Sustainability',
    value: 'sustainability',
  },
  {
    id: 7,
    text: 'I am not aware of any of these activities',
    value: 'I am not aware of any of these activities',
    submit: true,
  },
]
const ans_10 = [
  {
    id: 1,
    text: 'Training to progress employees',
    value: 'Training to progress employees',
  },
  {
    id: 2,
    text: 'Sustainability',
    value: 'Sustainability',
  },
  {
    id: 3,
    text: 'Funding for programmes (who and how)',
    value: 'Funding for programmes (who and how)',
  },
  {
    id: 4,
    text: 'How we treat each other',
    value: 'How we treat each other',
  },
  {
    id: 5,
    text: 'What we learn and how we learn',
    value: 'What we learn and how we learn',
  },
  {
    id: 6,
    text: 'Productivity of the sector',
    value: 'Productivity of the sector',
  },
  {
    id: 7,
    text: 'Better diversity, equity and inclusion in the workforce',
    value: 'Better diversity, equity and inclusion in the workforce',
  },
  {
    id: 8,
    text: 'Approaches to employment (workforce flexibility)',
    value: 'Approaches to employment (workforce flexibility)',
  },

  {
    id: 9,
    text: 'More on careers advising (clear pathways / doors to sector entry)',
    value: 'More on careers advising (clear pathways / doors to sector entry)',
  },

  {
    id: 10,
    text: 'Future proof the industry (disruptive technology upskilling / innovation)',
    value: 'Future proof the industry (disruptive technology upskilling / innovation)',
  },

  {
    id: 11,
    type: 'input',
    text: 'Any other suggestions (no matter how big or small)',
    value: '',
  },
]
let pathMap = []
const goNext = () => {
  const nextInd = pathMap.findIndex(item => !item.visited)
  if (nextInd === -1) {
    return '3_Knowledge_of_ConCOVE'
  }
  pathMap[nextInd].visited = true
  return pathMap[nextInd].mapTo
}

const ConCOVE = {
  questions: [
    // Intro Page
    {
      id: 'intro-page',
      type: welcomePage,
      section: (
        <Wrapper>
          <h1 style={{ color: '#33062E', fontWeight: 'bold' }}>
            Your thoughts matter to the ConCOVE
          </h1>
          The Construction and Infrastructure Centre of Vocational Excellence’s (ConCOVE's) mission
          is to connect and align industry, learners and vocational education to reimagine clear,
          equitable and supported career pathways within the construction and infrastructure sector.
          <p style={{ fontWeight: 'bold', marginTop: '1rem', marginBottom: '0rem' }}>
            Why take this survey?
          </p>
          You can help us to understand whether the work of the ConCOVE is supporting meaningful
          change in the vocational education system. Your contribution to this survey matters, no
          matter what level of involvement you have with the ConCOVE (even if you have not heard of
          it).
          <p style={{ marginTop: '1rem' }}>
            For every completed survey, $5 will be donated to the charity{' '}
            <a href="https://mates.net.nz/" target="_blank" rel="noopener noreferrer">
              Mates in Construction
            </a>
            .
          </p>
          <p style={{ fontWeight: 'bold', marginBottom: '0rem' }}>How long will it take?</p>
          Just 5 to 10 minutes.
          <p style={{ fontWeight: 'bold', marginTop: '1rem', marginBottom: '0rem' }}>
            What about my privacy?
          </p>
          <p>
            <a href="https://www.scarlatti.co.nz/" target="_blank" rel="noopener noreferrer">
              Scarlatti
            </a>{' '}
            is undertaking this survey on behalf of the ConCOVE and will analyse the data set on
            their behalf. If individual survey responses are shared with the ConCOVE, names and
            other identifying details, will be removed.
          </p>
          <p>Thank you, we value your response.</p>
        </Wrapper>
      ),
      text: '',
    },
    // 1. Your Role in the Sector Q1-2
    // 1
    {
      id: '1_your_role_in_the_sector',
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>Your role in the sector</Text>
          <Divider />
          These questions will help us to understand your role in the sector and connection to the
          ConCOVE.
          <p></p>
          <b>What type of organisation do you work at?</b>
          <em>Select all that apply</em>
        </Wrapper>
      ),
      type: multiSelect,
      required: true,
      text: '',
      choices: [
        {
          id: 1,
          text: 'A construction business',
          value: 'A construction business',
        },
        {
          id: 2,
          text: 'An infrastructure provider',
          value: 'An infrastructure provider',
        },
        {
          id: 3,
          text: 'Industry body',
          value: 'Industry body',
        },
        {
          id: 4,
          text: 'Tertiary organisation',
          value: 'Tertiary organisation',
        },
        {
          id: 5,
          text: 'Government agency',
          value: 'Government agency',
        },
        {
          id: 6,
          text: 'A business that supplies construction businesses',
          value: 'A business that supplies construction businesses',
        },
        {
          id: 7,
          text: 'ConCOVE',
          value: 'ConCOVE',
        },
        {
          id: 8,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
      ],
      next: ({ answers }) => {
        let tempVal = answers['1_your_role_in_the_sector'].value
        const formMapping = ID => {
          if (ID === 1) {
            // Construction | Infrastructure
            return '2a_your_role_in_the_sector'
          } else if (ID === 2) {
            //Infrastructure
            return '2b_your_role_in_the_sector'
          } else if (ID === 3) {
            // Industry
            return '2c_your_role_in_the_sector'
          } else if (ID === 4) {
            // Tertiary
            return '2d_your_role_in_the_sector'
          } else if (ID === 5) {
            // Govt
            return '2e_your_role_in_the_sector'
          } else if (ID === 6) {
            // Business supply construction
            return '2f_your_role_in_the_sector'
          }
          return '3_Knowledge_of_ConCOVE' // ConCOVE | Other 8, 9
        }
        let roles = answers['1_your_role_in_the_sector'].value.sort((a, b) => a.id - b.id)
        if (roles.filter)
          pathMap = roles.map(aRole => ({
            text: aRole.text ? aRole.text : aRole.rawValue,
            visited: false,
            mapTo: formMapping(aRole.id),
          }))
        pathMap[0].visited = true
        return pathMap[0].mapTo
      },
    },
    //Q2 a b c d e
    // 2a construction business
    {
      id: '2a_your_role_in_the_sector',
      type: multiSelect,
      max: 1,
      section: () => (
        <Wrapper>
          <Text color={'#FF6600'}>Your role in the sector</Text>
          <Divider />
          <b style={{ marginTop: '0rem', marginBottom: '0rem' }}>
            Which of the following best describes your <l>construction business</l> role?
          </b>
        </Wrapper>
      ),
      required: true,
      text: '',
      choices: [
        {
          id: 1,
          text: 'I am an employer / self-employed',
          value: 'I am an employer / self-employed',
        },
        {
          id: 2,
          text: 'I am an employee',
          value: 'I am an employee',
        },
        {
          id: 3,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
      ],
      next: () => {
        const indend = pathMap.findIndex(item => item.mapTo === '3_Knowledge_of_ConCOVE')
        if (indend === -1) {
          pathMap = pathMap.concat([
            {
              text: '',
              visited: false,
              mapTo: '2g_your_role_in_the_sector',
            },
          ])
        } else {
          pathMap[indend].mapTo = '2g_your_role_in_the_sector'
        }
        return goNext()
      },
    },
    // 2b infrastructure business
    {
      id: '2b_your_role_in_the_sector',
      type: multiSelect,
      max: 1,
      section: () => (
        <Wrapper>
          <Text color={'#FF6600'}>Your role in the sector</Text>
          <Divider />
          <b style={{ marginTop: '0rem', marginBottom: '0rem' }}>
            Which of the following best describes your <l>infrastructure business</l> role?
          </b>
        </Wrapper>
      ),
      required: true,
      text: '',
      choices: [
        {
          id: 1,
          text: 'I am an employer / self-employed',
          value: 'I am an employer / self-employed',
        },
        {
          id: 2,
          text: 'I am an employee',
          value: 'I am an employee',
        },
        {
          id: 3,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
      ],
      next: () => {
        const indend = pathMap.findIndex(item => item.mapTo === '3_Knowledge_of_ConCOVE')
        if (indend === -1) {
          pathMap = pathMap.concat([
            {
              text: '',
              visited: false,
              mapTo: '2g_your_role_in_the_sector',
            },
          ])
        } else {
          pathMap[indend].mapTo = '2g_your_role_in_the_sector'
        }
        return goNext()
      },
    },
    // 2c industry body
    {
      id: '2c_your_role_in_the_sector',
      type: multiSelect,
      section: () => (
        <Wrapper>
          <Text color={'#FF6600'}>Your role in the sector</Text>
          <Divider />
          <b style={{ marginTop: '0rem', marginBottom: '0rem' }}>
            Which of the following best describes your <l>industry body</l> role?
          </b>
        </Wrapper>
      ),
      max: 1,
      required: true,
      text: '',
      choices: [
        {
          id: 1,
          text: 'I manage or design strategy, policy, and / or investment',
          value: 'I manage or design strategy, policy, and / or investment',
        },
        {
          id: 2,
          text: 'I manage or design training or other skill-building activities',
          value: 'I manage or design training or other skill-building activities',
        },
        {
          id: 3,
          text: 'I deliver training or other skill-building activities',
          value: 'I deliver training or other skill-building activities',
        },
        {
          id: 4,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
      ],
      next: () => {
        return goNext()
      },
    },
    // 2d tertiary organisation
    {
      id: '2d_your_role_in_the_sector',
      type: multiSelect,
      section: () => (
        <Wrapper>
          <Text color={'#FF6600'}>Your role in the sector</Text>
          <Divider />
          <b style={{ marginTop: '0rem', marginBottom: '0rem' }}>
            Which of the following best describes your <l>tertiary organisation</l> role?
          </b>
        </Wrapper>
      ),
      max: 1,
      required: true,
      text: '',
      choices: [
        {
          id: 1,
          text: 'I manage or design training or other skill-building activities',
          value: 'I manage or design training or other skill-building activities',
        },
        {
          id: 2,
          text: 'I deliver training or other skill-building activities',
          value: 'I deliver training or other skill-building activities',
        },
        {
          id: 3,
          text: 'I work in a management or administrative role',
          value: 'I work in a management or administrative role',
        },
        {
          id: 4,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
      ],
      next: () => {
        return goNext()
      },
    },
    // 2e government agency
    {
      id: '2e_your_role_in_the_sector',
      type: multiSelect,
      section: () => (
        <Wrapper>
          <Text color={'#FF6600'}>Your role in the sector</Text>
          <Divider />
          <b style={{ marginTop: '0rem', marginBottom: '0rem' }}>
            Which of the following best describes your <l>government agency</l> role?
          </b>
        </Wrapper>
      ),
      max: 1,
      required: true,
      text: '',
      choices: [
        {
          id: 1,
          text: 'I manage or design strategy, policy, and / or investment',
          value: 'I manage or design strategy, policy, and / or investment',
        },
        {
          id: 2,
          text: 'I manage or design training activies',
          value: 'I manage or design training activies',
        },
        {
          id: 3,
          text: 'I undertake economic or data analysis to inform policy or strategy',
          value: 'I undertake economic or data analysis to inform policy or strategy',
        },
        {
          id: 4,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
      ],
      next: () => {
        return goNext()
      },
    },
    // 2f business that supplies construction businesses
    {
      id: '2f_your_role_in_the_sector',
      type: multiSelect,
      max: 1,
      section: () => (
        <Wrapper>
          <Text color={'#FF6600'}>Your role in the sector</Text>
          <Divider />
          <b style={{ marginTop: '0rem', marginBottom: '0rem' }}>
            Which of the following best describes your{' '}
            <l>business that supplies construction businesses</l> role?
          </b>
        </Wrapper>
      ),
      required: true,
      text: '',
      choices: [
        {
          id: 1,
          text: 'I am an employer / self-employed',
          value: 'I am an employer / self-employed',
        },
        {
          id: 2,
          text: 'I am an employee',
          value: 'I am an employee',
        },
        {
          id: 3,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
      ],
      next: () => {
        return '2g_your_role_in_the_sector'
      },
    },
    // 2g Responsibilities (2a , 2b --> +2g ; 2f --> 2g)
    {
      id: '2g_your_role_in_the_sector',
      type: multiSelect,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>Your role in the sector</Text>
          <Divider />
          <b>In your role/s, do you have any of the following responsibilities?</b>
          <em>Select as many as apply</em>
        </Wrapper>
      ),
      required: true,
      text: '',
      choices: [
        {
          id: 1,
          text: 'Hiring staff',
          value: 'Hiring staff',
        },
        {
          id: 2,
          text: 'Delivering training',
          value: 'Delivering training',
        },
        {
          id: 3,
          text: 'Organising training',
          value: 'Organising training',
        },
        {
          id: 4,
          text: 'Managing staff',
          value: 'Managing staff',
        },
        {
          id: 5,
          text: 'None of the above',
          value: 'None of the above',
          submit: true,
        },
      ],
    },
    // 2. Knowledge of ConCOVE Q3-5
    // 3
    {
      id: '3_Knowledge_of_ConCOVE',
      type: multiChoice,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>Knowledge of the ConCOVE</Text>
          <Divider />
          <b>
            Which of the following best describes your knowledge of the ConCOVE's objectives and
            activities?
          </b>
        </Wrapper>
      ),
      required: true,
      text: '',
      choices: [
        {
          id: 1,
          text: 'I am involved in the decision-making of the ConCOVE',
          value: 'I am involved in the decision-making of the ConCOVE',
        },
        {
          id: 2,
          text: "I'm not part of the decision-making team but involved with the ConCOVE",
          value: "I'm not part of the decision-making team but involved with the ConCOVE",
        },
        {
          id: 3,
          text: 'I am not directly involved, but know what the ConCOVE is working to do',
          value: 'I am not directly involved, but know what the ConCOVE is working to do',
        },
        {
          id: 4,
          text: 'I have limited knowledge of the ConCOVE',
          value: 'I have limited knowledge of the ConCOVE',
        },
        {
          id: 5,
          text: "I don't know anything about the ConCOVE / I have never heard of it",
          value: "I don't know anything about the ConCOVE / I have never heard of it",
        },
      ],
      next: ({ answers }) => {
        let ID = answers['3_Knowledge_of_ConCOVE'].id
        if (ID === 5) {
          return '10_Opportunities'
        }
        return '4_Knowledge_of_ConCOVE'
      },
    },
    // 4
    {
      id: '4_Knowledge_of_ConCOVE',
      type: multiChoice,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>Knowledge of the ConCOVE</Text>
          <Divider />
          <p>
            The ConCOVE's role is to connect and align industry, learners and vocational education
            to re-imagine clear, equitable and supported career pathways within the construction and
            infrastructure sector.
          </p>
          <p style={{ marginBottom: '0rem' }}>The ConCOVE work includes:</p>
          <ul>
            <li>Connecting and aligning industry, learners and vocational education</li>
            <li>Re-imagining clear, equitable and supported career pathways</li>
            <li>Creating an inclusive, sustainable and productive sector</li>
            <li>Establishing a career framework to map career-long transitions through industry</li>
            <li>Measuring transitions in and out of jobs and levels by priority groups</li>
            <li>Honouring Te Tiriti O Waitangi.</li>
          </ul>
          <b style={{ marginTop: '1rem' }}>Are you aware of any of this work by the ConCOVE?</b>
        </Wrapper>
      ),
      required: true,
      text: '',
      buttonSize: xlButton.width,
      choices: [
        {
          id: 1,
          text: 'I have not seen or heard anything the ConCOVE has done from the above examples',
          value: 'I have not seen or heard anything the ConCOVE has done from the above examples',
        },
        {
          id: 2,
          text:
            'I have heard about the ConCOVE doing some of the above, but have not seen anything',
          value:
            'I have heard about the ConCOVE doing some of the above, but have not seen anything',
        },
        {
          id: 3,
          text: 'I have seen some evidence of the ConCOVE doing the above',
          value: 'I have seen some evidence of the ConCOVE doing the above',
        },
        {
          id: 4,
          text: 'I am well aware of the work the ConCOVE is doing',
          value: 'I am well aware of the work the ConCOVE is doing',
        },
      ],
      next: ({ answers }) => {
        let ID = answers['4_Knowledge_of_ConCOVE'].id
        if (ID === 1 || ID === 2) {
          return '6_ConCOVE_activities'
        }
        return '5_Knowledge_of_ConCOVE'
      },
    },
    // 5
    {
      id: '5_Knowledge_of_ConCOVE',
      type: multiSelect,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>Knowledge of the ConCOVE</Text>
          <Divider />
          <b>How did you find out about work completed by the ConCOVE?</b>
          <em>Select all that apply</em>
        </Wrapper>
      ),
      required: true,
      buttonSize: xlButton.width,
      text: '',
      choices: [
        {
          id: 1,
          text: 'Word of mouth',
          value: 'Word of mouth',
        },
        {
          id: 2,
          text: 'At an event I attended',
          value: 'At an event I attended',
        },
        {
          id: 3,
          text: 'On the ConCOVE website',
          value: 'On the ConCOVE website',
        },
        {
          id: 4,
          text: 'In the media or social media ',
          value: 'In the media or social media ',
        },
        {
          id: 5,
          text: 'On training provider websites or material ',
          value: 'On training provider websites or material ',
        },
        {
          id: 6,
          text: 'On government websites ',
          value: 'On government websites ',
        },
        {
          id: 7,
          text: 'At webinars or other presentations ',
          value: 'At webinars or other presentations ',
        },
        {
          id: 8,
          text: 'Newsletter / ConCOVE communications',
          value: 'Newsletter / ConCOVE communications',
        },
        {
          id: 9,
          text: 'I cannot recall any specific examples, I’m just aware the ConCOVE is doing this',
          value: 'I cannot recall any specific examples, I’m just aware the ConCOVE is doing this',
          submit: true,
        },
        {
          id: 10,
          type: 'input',
          text: 'Other, please specify',
          value: '',
        },
      ],
    },
    // 3. ConCOVE activities Q6-9
    // 6
    {
      id: '6_ConCOVE_activities',
      type: multiSelect,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>ConCOVE activities</Text>
          <Divider />
          Below is a list of activities the ConCOVE have commenced work on.
          <ul>
            <li>
              <b>Career framework</b> - Co-designing, with industry, a comprehensive framework with
              the express aim of identifying and enabling career-long transitions.
            </li>
            <li>
              <b>Disruption</b> - Identifying the timing, scale and scope of future disruptions to
              the workforce, and developing appropriate responses.
            </li>
            <li>
              <b>Entry</b> - Designing a new learning model that increases participation and
              outcomes for learners, employers, and the industry.
            </li>
            <li>
              <b>Career progression</b> - Improving productivity through retention, upskilling and
              reskilling.
            </li>
            <li>
              <b>Diversity</b> - Growing and strengthening the workforce through diversity.
            </li>
            <li>
              <b>Sustainability</b> - Improving the industry's attention to environmental
              sustainability through establishing how relevant sustainability principles can be
              incorporated into appropriate training products.
            </li>
          </ul>
          <p></p>
          <b>Which of these ConCOVE activities, if any, are you aware of?</b>
          <em>Select all that apply</em>
        </Wrapper>
      ),
      required: true,
      hide: true,
      text: '',
      choices: activities,
      next: ({ answers }) => {
        let numSelect = answers['6_ConCOVE_activities'].value.length
        let ID = answers['6_ConCOVE_activities'].value[0].id
        if (ID === 7) {
          return '10_Opportunities'
        } else if (numSelect === 1) {
          return '8_ConCOVE_activities'
        }
        return '7_ConCOVE_activities'
      },
    },
    // 7
    {
      id: '7_ConCOVE_activities',
      type: multiChoice,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>ConCOVE activities</Text>
          <Divider />
          <b>Which activity do you have the strongest connection with?</b>
        </Wrapper>
      ),
      required: true,
      text: '',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          return activities
        }
        let result = activities.filter(choice => {
          return answers['6_ConCOVE_activities'].value.some(chosen => {
            return chosen.id === choice.id
          })
        })
        return result
      },
    },
    // 8
    {
      id: '8_ConCOVE_activities',
      type: multiChoice,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>ConCOVE activities</Text>
        </Wrapper>
      ),
      required: true,
      buttonSize: xlButton.width,
      text: ({ answers }) => {
        let prevText
        let ID = answers['6_ConCOVE_activities'].value[0].id
        if (answers['7_ConCOVE_activities']) {
          prevText = answers['7_ConCOVE_activities'].value
        } else {
          prevText = activities[ID - 1].value
        }
        return (
          <Wrapper>
            <Divider />
            <span>
              <b>How much impact has the {prevText} project had on you / your organisation?</b>
            </span>
          </Wrapper>
        )
      },
      choices: [
        {
          id: 1,
          text: "I am aware of the activity, but I don't know much about it",
          value: "I am aware of the activity, but I don't know much about it",
        },
        {
          id: 2,
          text: 'I know about the activity, but it has no relevance to me or my organisation',
          value: 'I know about the activity, but it has no relevance to me or my organisation',
        },
        {
          id: 3,
          text:
            'I know about the activity, and I have begun to think about how it relates to me or my organisation',
          value:
            'I know about the activity, and I have begun to think about how it relates to me or my organisation',
        },
        {
          id: 4,
          text:
            'The activity has prompted discussions within our organisation or with other people',
          value:
            'The activity has prompted discussions within our organisation or with other people',
        },
        {
          id: 5,
          text:
            'The activity has led our organisation to do things differently or implement some changes',
          value:
            'The activity has led our organisation to do things differently or implement some changes',
        },
      ],
    },
    // 9
    {
      id: '9_ConCOVE_activities',
      type: commentBox,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>ConCOVE activities</Text>
          <Divider />
          <b>
            Do you have any additional comments or feedback about your experience with the activity?
          </b>
        </Wrapper>
      ),
      text: '',
      value: '',
    },
    // 4. Opportunities and examples of excellence and innovation Q10-12
    //10
    {
      id: '10_Opportunities',
      type: multiSelect,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>Opportunities and examples of excellence and innovation</Text>
          <Divider />
          <p>
            We would love your help to understand which areas of the construction and infrastructure
            VET system should be priorities for the ConCOVE. We also want to hear examples of
            excellence and innovation so we can celebrate and share successes.
          </p>
          <b>
            What are some specific ways in which you think the construction and infrastructure
            system should be strengthened that the ConCOVE may have influence over?
          </b>
          <em>Select all that apply</em>
        </Wrapper>
      ),
      required: true,
      buttonSize: xlButton.width,
      text: '',
      choices: [
        {
          id: 1,
          text: 'Training to progress employees',
          value: 'Training to progress employees',
        },
        {
          id: 2,
          text: 'Sustainability',
          value: 'Sustainability',
        },
        {
          id: 3,
          text: 'Funding for programmes (who and how)',
          value: 'Funding for programmes (who and how)',
        },
        {
          id: 4,
          text: 'How we treat each other',
          value: 'How we treat each other',
        },
        {
          id: 5,
          text: 'What we learn and how we learn',
          value: 'What we learn and how we learn',
        },
        {
          id: 6,
          text: 'Productivity of the sector',
          value: 'Productivity of the sector',
        },
        {
          id: 7,
          text: 'Clearer doors to sector entry',
          value: 'Clearer doors to sector entry',
        },
        {
          id: 8,
          text: 'Better diversity, equity and inclusion in the workforce',
          value: 'Better diversity, equity and inclusion in the workforce',
        },
        {
          id: 9,
          text: 'Approaches to employment (workforce flexibility)',
          value: 'Approaches to employment (workforce flexibility)',
        },
        {
          id: 10,
          text: 'Future proof the industry (disruptive technology upskilling / innovation)',
          value: 'Future proof the industry (disruptive technology upskilling / innovation)',
        },
        {
          id: 11,
          type: 'input',
          text: 'Any other suggestions (no matter how big or small)',
          value: '',
        },
      ],
      next: ({ answers }) => {
        if (answers['10_Opportunities'].value.length === 1) {
          return '12_Opportunities'
        }
        return '11_Opportunities'
      },
    },
    // 11
    {
      id: '11_Opportunities',
      type: multiChoice,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>Opportunities and examples of excellence and innovation</Text>
          <Divider />
          <b>
            Which of these areas you selected do you think should be the highest priority for the
            ConCOVE?
          </b>
        </Wrapper>
      ),
      required: true,
      buttonSize: xlButton.width,
      text: '',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          return ans_10
        }
        let result = answers['10_Opportunities'].value
        const indOther = result.findIndex(object => {
          return object.id === 11
        })
        if (indOther !== -1) {
          result[indOther].text = result[indOther].rawValue
        }
        return result
      },
    },
    // 12
    {
      id: '12_Opportunities',
      type: commentBox,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>Opportunities and examples of excellence and innovation</Text>
          <Divider />

          <b>
            Can you think of any examples of excellence, or innovation in the construction and
            infrastructure VET system that the ConCOVE could showcase and learn from?
          </b>
          <em>
            For each example, provide one sentence which includes a name or description, and details
            of where to go or to contact to learn more.
          </em>
        </Wrapper>
      ),
      text: '',
    },
    // 5. Information about you
    // 13
    {
      id: '13_Information',
      type: multiChoice,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>Information about you</Text>
          <Divider />
          <b>Please select your region</b>
        </Wrapper>
      ),
      choices: [
        { id: 1, text: 'Northland / Te Tai Tokerau', value: 'Northland / Te Tai Tokerau' },
        { id: 2, text: 'Auckland / Tāmaki Makaurau', value: 'Auckland / Tāmaki Makaurau' },
        { id: 3, text: 'Waikato', value: 'Waikato' },
        { id: 4, text: 'Bay of Plenty / Te Moana-a-Toi', value: 'Bay of Plenty / Te Moana-a-Toi' },
        { id: 5, text: 'Gisbone / Te Tai Rāwhiti', value: 'Gisbone / Te Tai Rāwhiti' },
        { id: 6, text: "Hawke's Bay / Te Matau-a-Māui", value: "Hawke's Bay / Te Matau-a-Māui" },
        { id: 7, text: 'Taranaki', value: 'Taranaki' },
        { id: 8, text: 'Whanganui - Manawatu', value: 'Whanganui - Manawatu' },
        {
          id: 9,
          text: 'Wellington / Te Whanganui-a-Tara',
          value: 'Wellington / Te Whanganui-a-Tara',
        },
        {
          id: 10,
          text: 'Marlborough / Te Tauihu-o-te-waka',
          value: 'Marlborough / Te Tauihu-o-te-waka',
        },
        { id: 11, text: 'Nelson - Tasman / Whakatū', value: 'Nelson - Tasman / Whakatū' },
        { id: 12, text: 'West Coast / Te Tai Poutini', value: 'West Coast / Te Tai Poutini' },
        { id: 13, text: 'Canterbury / Waitaha', value: 'Canterbury / Waitaha' },
        { id: 14, text: 'Otago / Ōtākou', value: 'Otago / Ōtākou' },
        { id: 15, text: 'Southland / Murihiku', value: 'Southland / Murihiku' },
      ],
    },
    //14
    {
      id: '14_Information',
      type: multiChoice,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>Information about you</Text>
          <Divider />
          <b>What is your gender?</b>
        </Wrapper>
      ),
      choices: [
        { id: 1, text: 'Male', value: 'Male' },
        { id: 2, text: 'Female', value: 'Female' },
        { id: 3, text: 'Another gender', value: 'Another gender' },
        { id: 4, text: 'Prefer not to say', value: 'Prefer not to say' },
      ],
    },
    //15
    {
      id: '15_Information',
      type: multiSelect,
      required: true,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>Information about you</Text>
          <Divider />
          <b>What is your ethnicity?</b>
        </Wrapper>
      ),
      choices: [
        { id: 1, text: 'Māori', value: 'Māori' },
        { id: 2, text: 'New Zealand European / Pākehā', value: 'New Zealand European / Pākehā' },
        {
          id: 3,
          text: 'Middle Eastern / Latin American / African',
          value: 'Middle Eastern / Latin American / African',
        },
        { id: 4, text: 'Pacific People', value: 'Pacific People' },
        { id: 5, text: 'Asian', value: 'Asian' },
        {
          id: 6,
          type: 'input',
          text: 'Other ethnicity, please specify',
          value: '',
        },
        { id: 7, text: 'Prefer not to say', value: 'Prefer not to say', submit: true },
      ],
    },
    // 6. Final feedback
    // 16
    {
      id: '16_Final_feedback',
      type: commentBox,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>Any final feedback</Text>
          <Divider />
          <b>Do you have any final comments or feedback?</b>
        </Wrapper>
      ),
      text: '',
    },
    // 7. We will contact you again in a year Q13
    {
      id: '17_contact',
      type: multiTextBox,
      section: (
        <Wrapper>
          <Text color={'#FF6600'}>We will contact you again in a year</Text>
          <Divider />
          <p>
            The questions we have asked so far are part of an annual engagement survey to monitor
            the long-term effects that the ConCOVE and its work are having on the construction and
            infrastructure sector.
          </p>
          <p>We would love to follow up with you in a year's time. </p>
          <p>
            <b>Please enter your name and email if you wish to participate in the future.</b>
          </p>
        </Wrapper>
      ),
      text: '',
      choices: [
        { id: 1, text: 'Name:', value: '' },
        { id: 2, text: 'Email:', value: '' },
      ],
    },
    // 8. Thank you
    // Testing:
    // {
    //   id: '15_test',
    //   type: commentBox,
    //   section: (
    //     <Wrapper>
    //       <Text color={'#FF6600'}>Survey feedback</Text>
    //       <Divider />
    //       <p>Thank you for being one of the first to complete this survey.</p>
    //       <p>
    //         The following questions will help us to refine the survey before it's sent out to a
    //         wider group.
    //       </p>
    //       <b>What did you think of this survey?</b>
    //     </Wrapper>
    //   ),
    //   text: '',
    //   value: '',
    // },
    // {
    //   id: '16_test',
    //   type: multiChoice,
    //   section: (
    //     <Wrapper>
    //       <Text color={'#FF6600'}>Survey feedback</Text>
    //       <Divider />
    //       <b>Was the survey?</b>
    //     </Wrapper>
    //   ),
    //   text: '',
    //   required: true,
    //   choices: [
    //     { id: 1, text: 'Too long', value: 'Too long' },
    //     { id: 2, text: 'About right', value: 'About right' },
    //     { id: 3, text: 'Too short', value: 'Too short' },
    //   ],
    // },
    // {
    //   id: '17_test',
    //   type: commentBox,
    //   section: (
    //     <Wrapper>
    //       <Text color={'#FF6600'}>Survey feedback</Text>
    //       <Divider />
    //       <b>Do you have any suggestions for improvements to this survey?</b>
    //     </Wrapper>
    //   ),
    //   text: '',
    //   value: '',
    // },
    // Thank you
    {
      id: 'end-page',
      type: endPage,
      section: (
        <Wrapper>
          <Textbox>
            <Text color={'#FF6600'}>Thank you for completing the ConCOVE survey</Text>
            <p style={{ marginTop: '1rem' }}>
              Your feedback will help us to understand what the ConCOVE can do better.
            </p>
            <p>$5 will be donated to Mates in Construction.</p>
            <p>
              If you have additional feedback, please contact{' '}
              <a href="mailto: rachel.knopp@scarlatti.co.nz">rachel.knopp@scarlatti.co.nz</a>
            </p>
          </Textbox>
          <p
            align="center"
            style={{
              marginTop: '8rem',
              marginBottom: '0.5rem',
              fontStyle: 'italic',
              fontSize: '0.9rem',
              color: '#E4813F',
            }}
          >
            This survey was powered by
          </p>
          <span>
            <a href="https://www.scarlatti.co.nz/" target="_blank" rel="noopener noreferrer">
              <img src={scarlattiLogo} alt="Scarlatti logo" width="50" height="50" class="center" />
            </a>
          </span>
        </Wrapper>
      ),
      text: '',
    },
  ],

  settings: {
    writeToFirestore: true,
    surveyName: 'concove',
    progressBar: true,
    primaryColour: '#33062E',
    leftBackgroundImage,
    backgroudImage: leftBackgroundImage,
    companyLogo: {
      images: [{ image: ConCOVELogo, link: 'https://concove.ac.nz/', height: '150px' }],
    },
    logoFirstPageOnly: false,
    logoLocation: 'end',
    logoStyle: { marginTop: '3rem' },
  },
}

export default ConCOVE
