import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import leftBackgroundImage from '../../assets/images/HCF/backgroundimage.jpg' // Todo compress image
import { ReactComponent as revenue } from '../../assets/images/HCF/revenue.svg'
import {
  conjointAnalysis,
  endPage,
  inputFieldNumber,
  multiChoice,
  welcomePage,
} from '../question-types/QuestionTypes'
import { lgButton, xlButton } from '../ui/Layout/ButtonContainer'
// import backgroudImage from '../../assets/images/HCF/min-background-1.png'
import { v4 as uuidv4 } from 'uuid'
import conferLogo from '../../assets/images/Confer-logo.png'
import { ReactComponent as d1 } from '../../assets/images/conjoint_images/d_1.svg'
import { ReactComponent as d2 } from '../../assets/images/conjoint_images/d_2.svg'
import { ReactComponent as d3 } from '../../assets/images/conjoint_images/d_3.svg'
import { ReactComponent as e1 } from '../../assets/images/conjoint_images/e_1.svg'
import { ReactComponent as e2 } from '../../assets/images/conjoint_images/e_2.svg'
import { ReactComponent as e3 } from '../../assets/images/conjoint_images/e_3.svg'
import { ReactComponent as wq1 } from '../../assets/images/conjoint_images/wq_1.svg'
import { ReactComponent as wq2 } from '../../assets/images/conjoint_images/wq_2.svg'
import { ReactComponent as wq3 } from '../../assets/images/conjoint_images/wq_3.svg'
import { ReactComponent as w1 } from '../../assets/images/conjoint_images/w_1.svg'
import { ReactComponent as w2 } from '../../assets/images/conjoint_images/w_2.svg'
import { ReactComponent as w3 } from '../../assets/images/conjoint_images/w_3.svg'
import { ReactComponent as star } from '../../assets/images/HCF/star.svg'
import HCFLogo from '../../assets/images/HCF/test.svg'
import { ReactComponent as a2 } from '../../assets/images/Scarlatti/a2.svg'
import { ReactComponent as a3 } from '../../assets/images/Scarlatti/a3.svg'
import { ReactComponent as g1 } from '../../assets/images/Scarlatti/g1.svg'
import { ReactComponent as g2 } from '../../assets/images/Scarlatti/g2.svg'
import { ReactComponent as g3 } from '../../assets/images/Scarlatti/g3.svg'
import { ReactComponent as s1 } from '../../assets/images/Scarlatti/s1.svg'
import { ReactComponent as s2 } from '../../assets/images/Scarlatti/s2.svg'
import { ReactComponent as s3 } from '../../assets/images/Scarlatti/s3.svg'
import { Textbox } from '../../assets/styles/global'

const aColours = {
  income: '#086a46',
  soil: '#4a4a93',
  water: '#4384cd',
  weather: '#003d5b',
  profit: '#adc773',
}

const D1 = styled(d1)`
  height: 100%;
`
const D2 = styled(d2)`
  height: 100%;
`
const D3 = styled(d3)`
  height: 100%;
`

const E1 = styled(e1)`
  height: 100%;
`
const E2 = styled(e2)`
  height: 100%;
`
const E3 = styled(e3)`
  height: 100%;
`

const W1 = styled(w1)`
  height: 100%;
`
const W2 = styled(w2)`
  height: 100%;
`
const W3 = styled(w3)`
  height: 100%;
`

const WQ1 = styled(wq1)`
  height: 100%;
`
const WQ2 = styled(wq2)`
  height: 100%;
`
const WQ3 = styled(wq3)`
  height: 100%;
`

const StarIcon = styled(star)`
  display: flex;
  height: 1.5rem;
  fill: ${props => props.aColor};
`

const ConjointTitle = styled.p`
  font-weight: 500;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  margin: 0.5rem;
`
const DBold = styled.span`
  font-weight: 600;
`

const Title = styled.p`
  margin-bottom: 0.5rem;
  text-align: left;
  font-weight: 600;
  color: ${props => props.aColor};
`

const StarContainer = styled.div`
  display: flex;
`

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ul {
    margin-left: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0rem;
  }
  /* width: 800px; */
`

const IconRevenue = styled(revenue)`
  height: 3rem;
`

const HText = styled.p`
  margin-bottom: 0rem;
`
const Italic = styled.p`
  /* font-style: italic; */
  margin-bottom: 0rem;
`
const Text = styled.p`
  font-weight: 600;
  /* font-size: 1.035rem; */
`

const StyledP = styled.p`
  line-height: 1.7;
  /* font-size: 1rem;
  color: rgba(0, 0, 0, 0.65); */
  margin-bottom: 0rem;
  margin-top: 1rem;
`

const A2 = styled(a2)`
  height: 4rem;
  width: 4rem;
`
const A3 = styled(a3)`
  height: 4rem;
  width: 4rem;
`
const G1 = styled(g1)`
  height: 4rem;
  width: 4rem;
`
const G2 = styled(g2)`
  height: 4rem;
  width: 4rem;
`
const G3 = styled(g3)`
  height: 4rem;
  width: 4rem;
`
const S1 = styled(s1)`
  height: 4rem;
  width: 4rem;
`
const S2 = styled(s2)`
  height: 4rem;
  width: 4rem;
`

const S3 = styled(s3)`
  height: 4rem;
  width: 4rem;
`

const tempUserId = uuidv4()

const HillCountryFuturesConjoint = {
  questions: [
    {
      id: 'intro-page',
      type: welcomePage,
      buttonSize: xlButton.width,
      text: (
        <StyledTextbox style={{ alignItems: 'baseline' }}>
          <StyledP>
            Scarlatti is conducting an exercise to value on-farm outcomes on behalf of Beef + Lamb
            New Zealand (B+LNZ). We will use this to advise on how to maximise the value that{' '}
            <b>you</b> receive from programmes. This will focus on five on-farm outcomes:
          </StyledP>
          <ul>
            <li>1. Income diversification</li>
            <li>2. Resilience to adverse weather events</li>
            <li>3. Soil erosion</li>
            <li>4. Water quality</li>
            <li>5. Profitability</li>
          </ul>
          <StyledP>
            We will do this in two parts – both relating to these five outcomes. In the first we
            will ask you a series of questions to gauge the value you place on each outcome. In the
            second we will ask you to estimate where you currently sit.
          </StyledP>
          <StyledP>The results from this survey will be completely anonymous.</StyledP>
          <StyledP> If you have any questions regarding this survey, please contact</StyledP>{' '}
          <a
            href="mailto:penelope.ainsworth@scarlatti.co.nz"
            rel="noreferrer"
            target="_blank"
            style={{ color: '#E82D7C', textDecoration: 'underline' }}
          >
            penelope.ainsworth@scarlatti.co.nz
          </a>
        </StyledTextbox>
      ),
    },
    {
      id: 'farm-land-size',
      type: inputFieldNumber,
      symbol: '',
      inputLabel: 'ha',
      required: true,
      label: 'Please estimate farm size in hectares',
      text: 'How large is the farm you work on?',
    },
    {
      id: 'farm-type',
      type: multiChoice,
      verticalGrid: false,
      text: 'Which of the below best describes your farm type?',
      required: true,
      choices: [
        {
          id: 1,
          text: 'High country',
          value: 'High country',
        },
        {
          id: 2,
          text: 'Hill country',
          value: 'Hill country',
        },
        {
          id: 3,
          text: 'Hard hill country',
          value: 'Hard hill country',
        },
        {
          id: 4,
          text: 'Intensive finishing',
          value: 'Intensive finishing',
        },
        {
          id: 5,
          text: 'Finishing breeding',
          value: 'Finishing breeding',
        },
        {
          id: 6,
          text: 'Mixed finishing',
          value: 'Mixed finishing',
        },
        // {
        //   id: 7,
        //   text: 'Other',
        //   type: input,
        //   width: '551px',
        //   placeholder: 'Please specify',
        //   value: '',
        //   mobileProps: { top: null },
        // },
      ],
    },
    {
      id: 'defining-levels-1a',
      type: multiChoice,
      buttonSize: lgButton.width,
      text:
        'Roughly, what percent of your farm income comes from sources other than sheep and beef?',
      choices: [
        {
          id: 1,
          text: 'More than 50%',
          value: 'More than 50%',
          html: (
            <HText>
              More than 50% <DBold>(Well diversified)</DBold>
            </HText>
          ),
        },
        {
          id: 2,
          text: 'Between 10 and 50%',
          value: 'Between 10 and 50%',
          html: (
            <HText>
              Between 10 and 50% <DBold>(Reasonably diversified)</DBold>
            </HText>
          ),
        },
        {
          id: 3,
          text: 'Less than 10%',
          value: 'Less than 10%',
          html: (
            <HText>
              Less than 10% <DBold>(Not diversified)</DBold>
            </HText>
          ),
        },
      ],
    },
    {
      id: 'defining-levels-1b',
      type: multiChoice,
      buttonSize: lgButton.width,
      text: (
        <StyledTextbox style={{ alignItems: 'baseline' }}>
          <StyledP>
            Consider a one in ten-year weather event, what would be your estimated <b>decline</b> in
            revenue that year?
          </StyledP>
        </StyledTextbox>
      ),
      choices: [
        {
          id: 1,
          text: 'Less than 10%',
          value: 'less than 10%',
          html: (
            <HText>
              Less than 10% <DBold>(Very resilient)</DBold>
            </HText>
          ),
        },
        {
          id: 2,
          text: 'Between 10 and 30%',
          value: 'between 10 and 30%',
          html: (
            <HText>
              Between 10 and 30% <DBold>(Somewhat resilient)</DBold>
            </HText>
          ),
        },
        {
          id: 3,
          text: 'More than 30%',
          value: 'more than 30%',
          html: (
            <HText>
              More than 30% <DBold>(Not resilient)</DBold>
            </HText>
          ),
        },
      ],
    },
    {
      id: 'defining-levels-1c',
      type: multiChoice,
      buttonSize: lgButton.width,
      text: 'Approximately, how much of your farm area is affected by soil erosion?',
      choices: [
        {
          id: 1,
          text: 'Less than 10%',
          value: 'less than 10%',
          html: (
            <HText>
              Less than 10% <DBold>(Low soil erosion)</DBold>
            </HText>
          ),

          altText: 'My production declines by less than 5%',
        },
        {
          id: 2,
          text: 'Between 10 and 30%',
          value: 'between 10 and 30%',
          html: (
            <HText>
              Between 10 and 30% <DBold>(Medium soil erosion)</DBold>
            </HText>
          ),

          altText: 'My production declines between 5 and 15%',
        },
        {
          id: 3,
          text: 'More than 30%',
          value: 'more than 30%',
          html: (
            <HText>
              More than 30% <DBold>(High soil erosion)</DBold>
            </HText>
          ),

          altText: 'My production declines by more than 15%',
        },
      ],
    },
    {
      id: 'defining-levels-1d',
      type: multiChoice,
      buttonSize: lgButton.width,
      mobileHeight: '5rem',
      text: 'Considering the impact my farm has on waterways, I consider myself to be a(n):',
      // text:
      //   'What is your confidence level in meeting future regulatory requirements regarding healthy waterways?',
      choices: [
        {
          id: 1,
          text: 'Industry leader (i.e. my farm is among the best 20%)',
          value: 'Industry leader (i.e. my farm is among the best 20%)',
          html: (
            <HText>
              <DBold>Industry leader</DBold> (i.e. my farm is among the best 20%)
            </HText>
          ),
        },
        {
          id: 2,
          text: 'Contributor (i.e. my farm is among the best 50%)',
          value: 'Contributor (i.e. my farm is among the best 50%)',
          html: (
            <HText>
              <DBold>Contributor</DBold> (i.e. my farm is among the best 50%)
            </HText>
          ),
        },
        {
          id: 3,
          text: 'Follower (i.e. my farm is among the bottom 50%)',
          value: 'Follower (i.e. my farm is among the bottom 50%)',
          html: (
            <HText>
              <DBold>Follower</DBold> (i.e. my farm is among the bottom 50%)
            </HText>
          ),
        },
      ],
    },
    // {
    //   id: 'test-og',
    //   type: conjointAnalysis,
    //   hideUi: true,
    //   choices: 2,
    //   maxChoices: 6,
    //   attributes: [
    //     {
    //       id: 1,
    //       value: 'Productivity',
    //       levels: [
    //         { id: 1, value: 'level 4', text: 'Productivity level 4' },
    //         { id: 2, value: 'level 3', text: 'Productivity level 3' },
    //         { id: 3, value: 'level 2', text: 'Productivity level 2' },
    //         { id: 4, value: 'level 1', text: 'Productivity level 1' },
    //       ],
    //     },
    //     {
    //       id: 2,
    //       value: 'Environmental',
    //       levels: [
    //         { id: 1, value: 'level 4', text: 'Environmental level 4' },
    //         { id: 2, value: 'level 3', text: 'Environmental level 3' },
    //         { id: 3, value: 'level 2', text: 'Environmental level 2' },
    //         { id: 4, value: 'level 1', text: 'Environmental level 1' },
    //       ],
    //     },
    //     {
    //       id: 3,
    //       value: 'Wellbeing',
    //       levels: [
    //         { id: 1, value: 'level 4', text: 'Wellbeing level 4' },
    //         { id: 2, value: 'level 3', text: 'Wellbeing level 3' },
    //         { id: 3, value: 'level 2', text: 'Wellbeing level 2' },
    //         { id: 4, value: 'level 1', text: 'Wellbeing level 1' },
    //       ],
    //     },
    //   ],
    //   levels: [],
    //   text: (
    //     <Textbox>
    //       <Text>Which of these 2 do you prefer?</Text>
    //     </Textbox>
    //   ),
    // },
    {
      id: 'valuing-outcomes',
      type: conjointAnalysis,
      hideUi: true,
      useIcon: false,
      showProgress: true,
      useStar: true,
      label: false,
      levelsTextAlign: 'left',
      // neutralText: 'No support and no cost', // check with Sam
      removeInfo: true, // removes text on right
      // choices: 2,
      height: '70%',
      maxChoices: 10,
      buttonSize: xlButton.width,
      justify: 'space-between',
      choices: ({ answers }) => {
        if (_.isUndefined(answers)) {
          let defaultAnswers = [
            {
              id: 1,
              value: 'Productivity',
              levels: [
                { id: 1, value: 'level 4', text: 'Productivity level 4' },
                { id: 2, value: 'level 3', text: 'Productivity level 3' },
                { id: 3, value: 'level 2', text: 'Productivity level 2' },
                { id: 4, value: 'level 1', text: 'Productivity level 1' },
              ],
            },
            {
              id: 2,
              value: 'Environmental',
              levels: [
                { id: 1, value: 'level 4', text: 'Environmental level 4' },
                { id: 2, value: 'level 3', text: 'Environmental level 3' },
                { id: 3, value: 'level 2', text: 'Environmental level 2' },
                { id: 4, value: 'level 1', text: 'Environmental level 1' },
              ],
            },
            {
              id: 3,
              value: 'Wellbeing',
              levels: [
                { id: 1, value: 'level 4', text: 'Wellbeing level 4' },
                { id: 2, value: 'level 3', text: 'Wellbeing level 3' },
                { id: 3, value: 'level 2', text: 'Wellbeing level 2' },
                { id: 4, value: 'level 1', text: 'Wellbeing level 1' },
              ],
            },
          ]
          return defaultAnswers
        }

        const calculateLevelMeasure = targetQuestionId => {
          let choices = targetQuestionId.choices
          let userAnswer = targetQuestionId.value
          let indexOfUserAnswer = choices.findIndex(choice => choice.value === userAnswer)
          let next = (indexOfUserAnswer - 1) % choices.length

          if (indexOfUserAnswer === 0) {
            next = indexOfUserAnswer
          }

          let text = choices[next].value
          // console.log('altText', choices[next])
          return text
        }

        const calculateNextBestLevel = targetQuestionId => {
          let choices = targetQuestionId.choices
          let userAnswer = targetQuestionId.value
          let indexOfUserAnswer = choices.findIndex(choice => choice.value === userAnswer)
          console.log('targetQuestionId B', targetQuestionId)
          let next = indexOfUserAnswer

          if (indexOfUserAnswer === 0) {
            next = (indexOfUserAnswer + 1) % choices.length
          }

          let text = choices[next].value
          // console.log('altText', choices[next])
          return text
        }

        let northIsland = [
          'Northland',
          'Mid Northern North Island',
          'Eastern North Island',
          'Western North Island',
        ]

        // let checkIfNorthIsland = northIsland.filter(region =>
        //   answers['map'].value.map(dbValue => dbValue.value).includes(region),
        // )

        let standardDeviation = {
          'High country': 40,
          'Hill country': 115,
          'Hard hill country': 121,
          'Intensive finishing': 256,
          'Finishing breeding': 241,
          'Mixed finishing': 365,
        }

        // if (checkIfNorthIsland.length > 1) {
        //   standardDeviation = {
        //     'High country': 40,
        //     'Hill country': 196,
        //     'Hard hill country': 121,
        //     'Intensive finishing': 254,
        //     'Finishing breeding': 241,
        //     'Mixed finishing': 365,
        //   }
        // }

        let farmType = answers['farm-type'].value
        let farmSize = answers['farm-land-size'].value

        let pipedAnswersA = [
          {
            id: 1,
            value: 'Income diversification',
            text: 'Percent of farm income that comes from sources other than sheep and beef',
            levels: [
              {
                id: 1,
                value: 'Help to achieve a small increase in your income diversification',
                icon: (
                  <Column>
                    {/* <D1></D1> */}
                    <Title aColor={aColours['income']}>Income diversification</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['income']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <p>
                      Help to achieve a small increase in your <b>income diversification</b>
                    </p> */}
                    <ConjointTitle>Not diversified</ConjointTitle>
                    <p>Less than 10% of my income comes from sources other than sheep and beef</p>
                    {/* <Italic>
                      A 5% increase (e.g. [midpoint in current level]% to [midpoint + 5%]%)
                    </Italic> */}
                  </div>
                ),
                // value: `${calculateLevelMeasure(answers['defining-levels-1a'])}`,
                // text: `${calculateLevelMeasure(answers['defining-levels-1a'])}`,
                // icon: <IconRevenue />,
              },
              {
                id: 2,
                value: 'Help to achieve a large increase in your income diversification',
                icon: (
                  <Column>
                    {/* <D2></D2> */}
                    <Title aColor={aColours['income']}>Income diversification</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['income']} />
                      <StarIcon aColor={aColours['income']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <p>
                      Help to achieve a large increase in your <b>income diversification</b>
                    </p> */}
                    <ConjointTitle>Reasonably diversified</ConjointTitle>
                    <p>
                      Between 10 and 50% of my income comes from sources other than sheep and beef
                    </p>
                    {/* <Italic>
                      A 20% increase (e.g. [midpoint in current level]% to [midpoint + 20%]%)
                    </Italic> */}
                  </div>
                ),
              },
              {
                id: 3,
                value: 'Help to achieve a large increase in your income diversification',
                icon: (
                  <Column>
                    {/* <D3 /> */}
                    <Title aColor={aColours['income']}>Income diversification</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['income']} />
                      <StarIcon aColor={aColours['income']} />
                      <StarIcon aColor={aColours['income']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <p>
                      Help to achieve a large increase in your <b>income diversification</b>
                    </p> */}
                    <ConjointTitle>Well diversified</ConjointTitle>
                    <p>More than 50% of my income comes from sources other than sheep and beef</p>
                    {/* <Italic>
                      A 20% increase (e.g. [midpoint in current level]% to [midpoint + 20%]%)
                    </Italic> */}
                  </div>
                ),
              },
            ],
          },
          {
            id: 2,
            value: 'Resilience to adverse weather events',
            text: 'Percentage revenue drop due to a one in ten-year weather event',
            levels: [
              {
                id: 1,
                icon: (
                  <Column>
                    {/* <W1 /> */}
                    <Title aColor={aColours['weather']}>Resilience to adverse weather</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['weather']} />
                    </StarContainer>
                  </Column>
                ),
                value: 'Help to achieve a small increase in your resilience to adverse weather',
                text: (
                  <div>
                    {/* <p>
                      Help to achieve a small increase in your <b>resilience to adverse weather</b>
                    </p> */}
                    <ConjointTitle>Not resilient</ConjointTitle>
                    <p>More than 30% of my revenue is lost in a one in ten-year weather event</p>
                    {/* <Italic>
                      A 5% decline (e.g. [midpoint in current level]% to [midpoint - 5%]%)
                    </Italic> */}
                  </div>
                ),
                // value: `My revenue would drop ${calculateLevelMeasure(
                //   answers['defining-levels-1b'],
                // )}`,
                // text: `My revenue would drop ${calculateLevelMeasure(
                //   answers['defining-levels-1b'],
                // )}`,
              },
              {
                id: 2,
                value: 'Help to achieve a large increase in your resilience to adverse weather',
                icon: (
                  <Column>
                    {/* <W2 /> */}
                    <Title aColor={aColours['weather']}>Resilience to adverse weather</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['weather']} />
                      <StarIcon aColor={aColours['weather']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <p>
                      Help to achieve a large increase in your <b>resilience to adverse weather</b>
                    </p> */}
                    <ConjointTitle>Somewhat resilient</ConjointTitle>
                    <p>
                      Between 10 and 30% of my revenue is lost in a one in ten-year weather event
                    </p>
                    {/* <Italic>
                      A 20% decline (e.g. [midpoint in current level]% to [midpoint - 20%]%)
                    </Italic> */}
                  </div>
                ),
                // value: `My revenue would drop ${calculateNextBestLevel(
                //   answers['defining-levels-1b'],
                // )}`,
                // text: `My revenue would drop ${calculateNextBestLevel(
                //   answers['defining-levels-1b'],
                // )}`,
              },
              {
                id: 3,
                value: 'Help to achieve a large increase in your resilience to adverse weather',
                icon: (
                  <Column>
                    {/* <W3 /> */}
                    <Title aColor={aColours['weather']}>Resilience to adverse weather</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['weather']} />
                      <StarIcon aColor={aColours['weather']} />
                      <StarIcon aColor={aColours['weather']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <p>
                      Help to achieve a large increase in your <b>resilience to adverse weather</b>
                    </p> */}
                    <ConjointTitle>Very resilient</ConjointTitle>
                    <p>Less than 10% of my revenue is lost in a one in ten-year weather event</p>
                    {/* <Italic>
                      A 20% decline (e.g. [midpoint in current level]% to [midpoint - 20%]%)
                    </Italic> */}
                  </div>
                ),
                // value: `My revenue would drop ${calculateNextBestLevel(
                //   answers['defining-levels-1b'],
                // )}`,
                // text: `My revenue would drop ${calculateNextBestLevel(
                //   answers['defining-levels-1b'],
                // )}`,
              },
            ],
          },
          {
            id: 3,
            value: 'Minimal production loss due to erosion',
            text: 'Percentage production decline due to erosion',
            levels: [
              {
                id: 1,
                value: 'Help to achieve a small reduction in soil erosion',
                icon: (
                  <Column>
                    {/* <E1 /> */}
                    <Title aColor={aColours['soil']}>Soil erosion</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['soil']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <p>
                      Help to achieve a small reduction in <b>soil erosion</b>
                    </p> */}
                    <ConjointTitle>High soil erosion</ConjointTitle>
                    <p>More than 30% of my farm area is affected by soil erosion</p>
                    {/* <Italic>
                      A 5% decline (e.g. [midpoint in current level]% to [midpoint - 5%]%)
                    </Italic> */}
                  </div>
                ),
                // value: `My production declines ${calculateLevelMeasure(
                //   answers['defining-levels-1c'],
                // )}`,
                // text: `My production declines ${calculateLevelMeasure(
                //   answers['defining-levels-1c'],
                // )}`,
              },
              {
                id: 2,
                value: 'Help to achieve a large reduction in soil erosion',
                icon: (
                  <Column>
                    {/* <E2 /> */}

                    <Title aColor={aColours['soil']}>Soil erosion</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['soil']} />
                      <StarIcon aColor={aColours['soil']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <p>
                      Help to achieve a large reduction in <b>soil erosion</b>
                    </p> */}
                    <ConjointTitle>Medium soil erosion</ConjointTitle>
                    <p>Between 10 and 30% of my farm area is affected by soil erosion</p>
                    {/* <Italic>
                      A 20% decline (e.g. [midpoint in current level]% to [midpoint - 20%]%)
                    </Italic> */}
                  </div>
                ),
                // value: `My production declines ${calculateNextBestLevel(
                //   answers['defining-levels-1c'],
                // )}`,
                // text: `My production declines ${calculateNextBestLevel(
                //   answers['defining-levels-1c'],
                // )}`,
              },
              {
                id: 3,
                value: 'Help to achieve a large reduction in soil erosion',
                icon: (
                  <Column>
                    {/* <E3 /> */}
                    <Title aColor={aColours['soil']}>Soil erosion</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['soil']} />
                      <StarIcon aColor={aColours['soil']} />
                      <StarIcon aColor={aColours['soil']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <p>
                      Help to achieve a large reduction in <b>soil erosion</b>
                    </p> */}
                    <ConjointTitle>Low soil erosion</ConjointTitle>
                    <p>Less than 10% of my farm area is affected by soil erosion</p>
                    {/* <Italic>
                      A 20% decline (e.g. [midpoint in current level]% to [midpoint - 20%]%)
                    </Italic> */}
                  </div>
                ),
                // value: `My production declines ${calculateNextBestLevel(
                //   answers['defining-levels-1c'],
                // )}`,
                // text: `My production declines ${calculateNextBestLevel(
                //   answers['defining-levels-1c'],
                // )}`,
              },
            ],
          },
          {
            id: 4,
            value: 'Healthy waterways',
            text: 'Confidence in meeting regulatory requirements relating to fresh water',
            levels: [
              {
                id: 1,
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                value: 'Help to achieve a small increase in the health of your waterways',
                icon: (
                  <Column>
                    {/* <WQ1 /> */}
                    <Title aColor={aColours['water']}>Waterways health</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['water']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    {/* <p>
                      Help to achieve a small increase in the <b>health of your waterways</b>
                    </p> */}
                    <ConjointTitle>Follower</ConjointTitle>
                    <p>
                      My farm is among the bottom 50% of farms contributing to healthy waterways
                    </p>
                    {/* <Italic>
                      A 5% increase (e.g. [midpoint in current level]% to [midpoint + 5%]%)
                    </Italic> */}
                  </div>
                ),
                // value: calculateNextBestLevel(answers['defining-levels-1d']),
                // text: calculateNextBestLevel(answers['defining-levels-1d']),
              },
              {
                id: 2,
                icon: (
                  <Column>
                    {/* <WQ2 /> */}

                    <Title aColor={aColours['water']}>Waterways health</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['water']} />
                      <StarIcon aColor={aColours['water']} />
                    </StarContainer>
                  </Column>
                ),
                value: 'Help to achieve a large increase in the health of your waterways',
                text: (
                  <div>
                    {/* <p>
                      Help to achieve a large increase in the <b>health of your waterways</b>
                    </p> */}
                    <ConjointTitle>Contributor</ConjointTitle>
                    <p>My farm is among the top 50% of farms contributing to healthy waterways</p>
                    {/* <Italic>
                      A 20% increase (e.g. [midpoint in current level]% to [midpoint + 20%]%)
                    </Italic> */}
                  </div>
                ),
                // value: calculateNextBestLevel(answers['defining-levels-1d']),
                // text: calculateNextBestLevel(answers['defining-levels-1d']),
              },
              {
                id: 3,
                icon: (
                  <Column>
                    {/* <WQ3 /> */}

                    <Title aColor={aColours['water']}>Waterways health</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['water']} />
                      <StarIcon aColor={aColours['water']} />
                      <StarIcon aColor={aColours['water']} />
                    </StarContainer>
                  </Column>
                ),
                value: 'Help to achieve a large increase in the health of your waterways',
                text: (
                  <div>
                    {/* <p>
                      Help to achieve a large increase in the <b>health of your waterways</b>
                    </p> */}
                    <ConjointTitle>Industry leader</ConjointTitle>
                    <p>My farm is among the top 20% of farms contributing to healthy waterways</p>
                    {/* <Italic>
                      A 20% increase (e.g. [midpoint in current level]% to [midpoint + 20%]%)
                    </Italic> */}
                  </div>
                ),
                // value: calculateNextBestLevel(answers['defining-levels-1d']),
                // text: calculateNextBestLevel(answers['defining-levels-1d']),
              },
            ],
          },
          {
            id: 5,
            value: 'Profitability',
            text: 'Additional income earned on farm per year',
            levels: [
              {
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                id: 3,
                icon: (
                  <Column>
                    <Title aColor={aColours['profit']}>Profitability</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['profit']} />
                      <StarIcon aColor={aColours['profit']} />
                      <StarIcon aColor={aColours['profit']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    <ConjointTitle>Considerably more profitable</ConjointTitle>
                    <p>A 20% increase in my profitability</p>
                  </div>
                ),
                // value: 'Help to achieve a small increase in your profitability',
                // text: (
                //   <div>
                //     <p>Help to achieve a small increase in your profitability</p>
                //     <p>
                //       A 5% increase (e.g. an extra $[ [5% of mean profitability]) in your
                //       profitability
                //     </p>
                //   </div>
                // ),

                value: `$${Math.round(1 * standardDeviation[farmType] * farmSize)}`,
                // text: `$${Math.round(1 * standardDeviation[farmType] * farmSize)}`,
              },
              {
                //NOTE: HIGHER ID NUM = HIGHER LEVEL
                id: 2,
                icon: (
                  <Column>
                    <Title aColor={aColours['profit']}>Profitability</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['profit']} />
                      <StarIcon aColor={aColours['profit']} />
                    </StarContainer>
                  </Column>
                ),
                text: (
                  <div>
                    <ConjointTitle>Slightly more profitable</ConjointTitle>
                    <p>A 5% increase in my profitability</p>
                  </div>
                ),
                // value: 'Help to achieve a large increase in your profitability',
                // text: (
                //   <div>
                //     <p>Help to achieve a large increase in your profitability</p>
                //     <p>
                //       A 20% increase (e.g. an extra $[5% of mean profitability]) in your
                //       profitability
                //     </p>
                //   </div>
                // ),
                value: `$${Math.round(0.1 * standardDeviation[farmType] * farmSize)}`,
                // text: `$${Math.round(0.1 * standardDeviation[farmType] * farmSize)}`,
              },
              {
                icon: (
                  <Column>
                    <Title aColor={aColours['profit']}>Profitability</Title>
                    <StarContainer>
                      <StarIcon aColor={aColours['profit']} />
                    </StarContainer>
                  </Column>
                ),
                id: 1,
                text: (
                  <div>
                    <ConjointTitle>No improvement</ConjointTitle>
                    <p>No change in my current profitability levels</p>
                  </div>
                ),
                value: `Remain at current income levels`,
                // text: `Remain at current income levels`,
              },
            ],
          },
        ]

        return pipedAnswersA
      },
      icons: [
        { id: 1, 1: <IconRevenue />, 2: 'id 1 - icon-2' },
        { id: 2, 1: <IconRevenue />, 2: 'id 2 - icon-2' },
        { id: 3, 1: <IconRevenue />, 2: 'id 3 icon-2' },
        { id: 4, 1: <IconRevenue />, 2: 'id 4 icon-2' },
      ],
      levels: [],
      text: (
        <Textbox>
          <Text>Which of the combined outcomes would you prefer on your farm?</Text>
        </Textbox>
      ),
    },
    {
      id: 'survey-end',
      type: endPage,
      text: () => {
        return (
          <Textbox style={{ padding: '0px' }}>
            <h1 style={{ color: 'rgba(0, 0, 0, 0.65) ', fontSize: '18px', fontWeight: 600 }}>
              Thank you for completing the survey! <br />
              <br />
              Your response will help inform efforts to protect and enhance our hill country
              environment.
            </h1>

            {/* <p>Like this survey and want to create your own? Let&apos;s Confer!</p> */}
            <a
              href="https://confer.online"
              rel="noreferrer"
              target="_blank"
              style={{ color: '#E82D7C', textDecoration: 'underline' }}
            >
              <p>Like this survey and want to create your own? Let&apos;s Confer!</p>
            </a>
            <div style={{ fontSize: '18px' }}>
              <img width="255px" src={conferLogo} alt="confer-logo.jpg" />
            </div>
          </Textbox>
        )
      },
    },
  ],
  settings: {
    surveyName: 'hcf-conjoint',
    // primaryColour: '#afd135',
    primaryColour: '#05B153',
    uuid: tempUserId,

    // hideButton: true,
    otherColour: '#72a174',
    hideSubmit: true,
    showQid: false,
    specialEffects: true,
    logoFirstPageOnly: true,
    logoStyle: {
      marginTop: '1rem',
    },
    companyLogo: {
      images: [{ image: HCFLogo }],
    },
    logoStyle: {
      marginTop: '1rem',
    },
    logoLocation: 'space-between',
    // fullscreen: true,
    // leftBackgroundImage,
    // backgroundImage: backgroudImage,
    leftBackgroundImage,
    backgroundImage: leftBackgroundImage,
    progressBar: true,
  },
}

export default HillCountryFuturesConjoint
