/* eslint-disable no-unused-vars */
import { Divider } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'
import scarlatti from '../../assets/images/PIAS/scarlatti.png'

import {
  multiChoice,
  responsiveMatrix,
  responsiveSlider,
  welcomePage,
} from '../question-types/QuestionTypes'

import { ReactComponent as Check } from '../../assets/images/HCF/check-icon.svg'
import { ReactComponent as Cross } from '../../assets/images/HCF/cross-icon.svg'
import { ReactComponent as Happy } from '../../assets/images/Scarlatti/Happy Face.svg'
import { ReactComponent as Ok } from '../../assets/images/Scarlatti/Ok face.svg'
import { ReactComponent as Sad } from '../../assets/images/Scarlatti/Sad Face.svg'
import { ReactComponent as VeryHappy } from '../../assets/images/Scarlatti/Very happy Face.svg'
import { ReactComponent as VerySad } from '../../assets/images/Scarlatti/Very sadFace 1.svg'

import backgroundVideo from '../../assets/images/Scarlatti/confer-scarlatti.mp4'
import { Textbox } from '../../assets/styles/global'
import { xlButton } from '../ui/Layout/ButtonContainer'

const IconHappy = styled(Happy)`
  width: 3rem;
  height: 3rem;
`
const IconOk = styled(Ok)`
  width: 3rem;
  height: 3rem;
`
const IconSad = styled(Sad)`
  width: 3rem;
  height: 3rem;
`
const IconVeryHappy = styled(VeryHappy)`
  width: 3rem;
  height: 3rem;
`
const IconVerySad = styled(VerySad)`
  width: 3rem;
  height: 3rem;
`

const StyledTextbox = styled(Textbox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 800px;
  li {
    font-size: 1rem;
    font-weight: 400;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 100%;
  }

  @media (min-width: 480px) and (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1025px) {
    width: 100%;
  }
`

const IconCheck = styled(Check)`
  width: 45px;
  height: 80px;
`

const IconCross = styled(Cross)`
  width: 45px;
  height: 80px;
`

const Q = styled.p`
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: #757575;
  margin-bottom: 0.5rem;
`
const T = styled.p`
  font-size: 1rem;
  margin-top: 0.5rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  p {
    margin-bottom: 0rem;
  }
  .ant-divider-horizontal {
    margin: 0px 0;
    border-top: 1px solid #c3c3c3;
    width: 49rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  @media (min-width: 0px) and (max-width: 479px) {
    width: 80vw;
    .ant-divider-horizontal {
      width: 90vw;
    }
  }
  /* align-items: center; */
`
const Text = styled.p`
  font-size: 1rem;
  color: ${props => props.color};
`

// console.log('companies', distinctCompanies)

const Pias = {
  questions: [
    // {
    //   id: 'intro-page',
    //   type: welcomePage,
    //   choices: [],
    //   text: (
    //     <StyledTextbox>
    //       <h4>The purpose of this survey is to:</h4>
    //       <ul style={{ marginLeft: '1rem', lineHeight: '2' }}>
    //         <li>Assess our operations</li>
    //         <li>Get a baseline for engagement which we can compare to other organisations </li>
    //         <li>
    //           Do a wellbeing scan (check workload, work stress levels and identify our
    //           organisational risks){' '}
    //         </li>
    //       </ul>
    //       <p style={{ marginBottom: '1rem' }}>
    //         Please click <i>NEXT</i> to begin
    //       </p>
    //     </StyledTextbox>
    //   ),
    //   next: () => 'engagement',
    // },
    // // 1.1
    // {
    //   id: 'retreat-goals',
    //   type: responsiveMatrix,
    //   section: (
    //     <Wrapper>
    //       <Q>Questions 1 of 3</Q>
    //       <Text color={'#F9744C'}>Operations</Text>
    //       <Divider />
    //     </Wrapper>
    //   ),
    //   // useValues: true,
    //   useIcons: true,
    //   text: 'Please indicate how you feel about business identify and operations',
    //   choices: [
    //     {
    //       id: 1,
    //       text: '1) I am clear about what Scarlatti does (services, approach):',
    //       value: 'I am clear about what Scarlatti does ',
    //     },
    //     {
    //       id: 2,
    //       text: '2) I am clear on who Scarlatti is as a company:',
    //       value: 'I am clear on who Scarlatti is as a company',
    //     },
    //     {
    //       id: 3,
    //       text: '3) I know who to go to for what:',
    //       value: 'I know who to go to for what',
    //     },
    //   ],
    //   options: [
    //     {
    //       id: 1,
    //       text: 'Completely confused',
    //       value: 'Completely confused',
    //       icon: <IconVerySad />,
    //     },
    //     {
    //       id: 2,
    //       text: 'Have a vague understanding',
    //       value: 'Have a vague understanding',
    //       icon: <IconSad />,
    //     },
    //     {
    //       id: 3,
    //       text: 'Clear enough for me',
    //       value: 'Clear enough for me',
    //       icon: <IconHappy />,
    //     },
    //     {
    //       id: 4,
    //       text: 'Crystal clear',
    //       value: 'Crystal clear',
    //       icon: <IconVeryHappy />,
    //     },
    //     // {
    //     //   id: 5,
    //     //   text: 'Goal is complete',
    //     //   value: 'Goal is complete',
    //     //   icon: <IconVeryHappy />,
    //     // },
    //   ],
    // },
    // {
    //   id: 'retreat-goals-2',
    //   type: responsiveMatrix,
    //   section: (
    //     <Wrapper>
    //       <Q>Questions 2 of 3</Q>
    //       <Text color={'#F9744C'}>Operations</Text>
    //       <p style={{ marginTop: '0.5rem' }}>
    //         Please indicate your agreement with the following statements:
    //       </p>
    //       <Divider />
    //     </Wrapper>
    //   ),
    //   // useValues: true,
    //   useIcons: true,
    //   text: '',
    //   choices: [
    //     {
    //       id: 1,
    //       text: '4) I feel well informed on things that are relevant to me:',
    //       value: 'I feel well informed on things that are relevant to me',
    //     },
    //     {
    //       id: 2,
    //       text: '5) I am satisfied with the training and development I am receiving:',
    //       value: 'I am satisfied with the training and development I am receiving',
    //     },
    //     {
    //       id: 3,
    //       text: '6) As an organisation we are efficient with our time:',
    //       value: 'As an organisation we are efficient with our time',
    //     },
    //     // {
    //     //   id: 4,
    //     //   text: 'Improved work efficiency:',
    //     //   value: 'Improved work efficiency',
    //     // },
    //     // {
    //     //   id: 5,
    //     //   text: 'Improved accuracy in quoting:',
    //     //   value: 'Improved accuracy in quoting',
    //     // },
    //   ],
    //   options: [
    //     {
    //       id: 1,
    //       text: 'Strongly disagree',
    //       value: 'Strongly disagree',
    //       icon: <IconVerySad />,
    //     },
    //     {
    //       id: 2,
    //       text: 'Disagree',
    //       value: 'Disagree',
    //       icon: <IconSad />,
    //     },
    //     {
    //       id: 3,
    //       text: 'Agree',
    //       value: 'Agree',
    //       icon: <IconHappy />,
    //     },
    //     {
    //       id: 4,
    //       text: 'Strongly agree',
    //       value: 'Strongly agree',
    //       icon: <IconVeryHappy />,
    //     },
    //     // {
    //     //   id: 5,
    //     //   text: 'Goal is complete',
    //     //   value: 'Goal is complete',
    //     //   icon: <IconVeryHappy />,
    //     // },
    //   ],
    // },
    // {
    //   id: 'retreat-goals-3',
    //   type: responsiveMatrix,
    //   section: (
    //     <Wrapper>
    //       <Q>Questions 3 of 3</Q>
    //       <Text color={'#F9744C'}>Operations</Text>
    //       <p style={{ marginTop: '0.5rem' }}>
    //         Please indicate your agreement with the following statements:
    //       </p>
    //       <Divider />
    //     </Wrapper>
    //   ),
    //   // useValues: true,
    //   useIcons: true,
    //   text: '',
    //   choices: [
    //     {
    //       id: 1,
    //       text: '7) As an organisation we are accurate with our quoting:',
    //       value: 'As an organisation we are accurate with our quoting',
    //     },
    //     {
    //       id: 2,
    //       text: '8) As an organisation we are effective with our time tracking and planning:',
    //       value: 'As an organisation we are effective with our time tracking and planning',
    //     },
    //   ],
    //   options: [
    //     {
    //       id: 1,
    //       text: 'Strongly disagree',
    //       value: 'Strongly disagree',
    //       icon: <IconVerySad />,
    //     },
    //     {
    //       id: 2,
    //       text: 'Disagree',
    //       value: 'Disagree',
    //       icon: <IconSad />,
    //     },
    //     {
    //       id: 3,
    //       text: 'Agree',
    //       value: 'Agree',
    //       icon: <IconHappy />,
    //     },
    //     {
    //       id: 4,
    //       text: 'Strongly agree',
    //       value: 'Strongly agree',
    //       icon: <IconVeryHappy />,
    //     },
    //   ],
    // },
    // // 1.2
    // {
    //   id: 'other-comments',
    //   type: commentBox,
    //   text:
    //     'Do you have any comments you’d like to make regarding our business identity, structure or operations?',
    //   placeholder: 'I think we should..',
    // },
    // 2.1
    {
      id: 'engagement',
      type: responsiveMatrix,
      section: (
        <Wrapper>
          <Q>Page 1 of 4</Q>
          <Text color={'#F9744C'}>Engagement</Text>
          <T>
            The below questions are called the “Gallup Q12”. It is the widest used organisational
            engagement survey in the world. The questions (some are slightly bizarre) are based on
            research around what creates engagement. By answering them, we will be able to compare
            ourselves to other organisations, and national averages.
          </T>
          <Divider />
        </Wrapper>
      ),
      useIcons: true,
      choices: [
        {
          id: 1,
          text: '1) I know what is expected of me at work:',
          value: 'I know what is expected of me at work',
        },
        {
          id: 2,
          text: '2) I have the materials and equipment I need to do my work right:',
          value: 'I have the materials and equipment I need to do my work right',
        },
        {
          id: 3,
          text: '3) At work, I have the opportunity to do what I do best every day:',
          value: 'At work, I have the opportunity to do what I do best every day',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    {
      id: 'engagement-2',
      type: responsiveMatrix,
      useIcons: true,
      section: (
        <Wrapper>
          <Q>Page 2 of 4</Q>
          <Text color={'#F9744C'}>Engagement</Text>
          <T>Please indicate your agreement with the following statements:</T>
          <Divider />
        </Wrapper>
      ),
      text: '',
      choices: [
        {
          id: 4,
          text:
            '4) In the last seven days, I have received recognition or praise for doing good work:',
          value:
            'In the last seven days, I have received recognition or praise for doing good work',
        },
        {
          id: 5,
          text: '5) My supervisor, or someone at work, seems to care about me as a person:',
          value: 'My supervisor, or someone at work, seems to care about me as a person',
        },
        {
          id: 6,
          text: '6) There is someone at work who encourages my development:',
          value: 'There is someone at work who encourages my development',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    {
      id: 'engagement-3',
      type: responsiveMatrix,
      section: (
        <Wrapper>
          <Q>Page 3 of 4</Q>
          <Text color={'#F9744C'}>Engagement</Text>
          <T>Please indicate your agreement with the following statements:</T>
          <Divider />
        </Wrapper>
      ),
      useIcons: true,
      text: '',
      choices: [
        {
          id: 1,
          text: '7) At work, my opinions seem to count:',
          value: 'At work, my opinions seem to count',
        },
        {
          id: 2,
          text: '8) The mission or purpose of my company makes me feel my job is important:',
          value: 'The mission or purpose of my company makes me feel my job is important',
        },
        {
          id: 3,
          text: '9) My fellow employees are committed to doing quality work:',
          value: 'My fellow employees are committed to doing quality work',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    {
      id: 'engagement-4',
      type: responsiveMatrix,
      useIcons: true,
      section: (
        <Wrapper>
          <Q>Page 4 of 4</Q>
          <Text color={'#F9744C'}>Engagement</Text>
          <T>Please indicate your agreement with the following statements:</T>
          <Divider />
        </Wrapper>
      ),
      text: '',
      choices: [
        {
          id: 10,
          text: '10) I have a best friend at work:',
          value: 'I have a best friend at work',
        },
        {
          id: 11,
          text: '11) In the last six months, someone at work has talked to me about my progress:',
          value: 'In the last six months, someone at work has talked to me about my progress',
        },
        {
          id: 12,
          text: '12) This last year, I have had opportunities to learn and grow:',
          value: 'This last year, I have had opportunities to learn and grow',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
      next: () => 'contribution-slider',
    },

    // 3
    {
      id: 'wellbeing-intro',
      type: welcomePage,
      buttonSize: xlButton.width,
      section: (
        <Wrapper>
          <Text color={'#F9744C'}>Wellbeing</Text>
          <Divider />
        </Wrapper>
      ),
      text: (
        <StyledTextbox>
          <T>
            All work is stressful, but not all stress is bad. This section is about getting an
            understanding of our organisational wellbeing risks. While it is unlikely that any
            organisation can remove stressors, it is possible to mitigate them.
          </T>
          {/* <p>
            When answering these questions, it can be difficult to separate work and personal life.
            Think carefully on each answer, reflecting on the nature of the job separate to your
            personal circumstances or behaviours. For example, things like having overly high
            standards, a tendency to procrastination or the fact you live far away from your place
            of work are not caused by the job. It can be tricky to separate these things. The
            helpful thing to do can be to think if you were in another job – would the situation be
            the less or more or the same?
          </p> */}
        </StyledTextbox>
      ),
    },
    // 3.1
    {
      id: 'workload-slider',
      section: (
        <Wrapper>
          <Q>Questions 1 of 6</Q>
          <Text color={'#F9744C'}>Wellbeing</Text>
          <T>Please indicate your agreement with the following statements:</T>
          <Divider />
        </Wrapper>
      ),
      text: (
        <Wrapper>
          {/* <p>Please indicate your agreement with the following statements:</p> */}
          <T>How would you rate your workload for the past quarter (December - February)?</T>
        </Wrapper>
      ),
      // text:
      //   'If you had to rate your workload for the past quarter (May-July) on a scale of  1- 10, 1 being bored and not knowing what to do, 5 being healthy, rewarding challenges that you can attack within working hours, and 10 being burnout and no longer enjoying your work, where would you put yourself?',
      type: responsiveSlider,
      // showValue: true,
      default: 2,
      max: 10,
      // marks: [
      //   { id: 1, text: 'Being bored and not knowing what to do' },
      //   { id: 2, text: 'Healthy, rewarding challenges' },
      //   { id: 3, text: 'Burnt out' },
      // ],
      choices: [
        { id: 1, value: 1, text: 'Being bored and not knowing what to do' },
        { id: 2, value: 2, text: '' },
        { id: 3, value: 3, text: '' },
        { id: 4, value: 4, text: '' },
        { id: 5, value: 5, text: 'Healthy, rewarding challenges' },
        { id: 6, value: 6, text: '' },
        { id: 7, value: 7, text: '' },
        { id: 8, value: 8, text: '' },
        { id: 9, value: 9, text: '' },
        { id: 10, value: 10, text: 'Burnt out' },
      ],
    },
    // 3.2
    {
      id: 'contribution-slider',
      type: responsiveSlider,
      section: (
        <Wrapper>
          <Q>Page 1 of 1</Q>
          <Text color={'#F9744C'}>Remuneration</Text>
          <p style={{ marginTop: '0.5rem' }}>
            Please indicate your agreement with the following statements:
          </p>
          <Divider />
        </Wrapper>
      ),
      text: 'I feel remunerated fairly for the effort I put in and the contribution I make',
      default: 2,
      max: 10,

      // showValue: true,
      // marks: [
      //   { id: 1, text: 'Poor' },
      //   { id: 2, text: 'Neutral' },
      //   { id: 2, text: 'Excellent' },
      // ],
      // choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      choices: [
        { id: 1, value: 1, text: 'Poor' },
        { id: 2, value: 2, text: '' },
        { id: 3, value: 3, text: '' },
        { id: 4, value: 4, text: '' },
        { id: 5, value: 5, text: 'Neutral' },
        { id: 6, value: 6, text: '' },
        { id: 7, value: 7, text: '' },
        { id: 8, value: 8, text: '' },
        { id: 9, value: 9, text: '' },
        { id: 10, value: 10, text: 'Excellent' },
      ],
    },
    // 3.3
    {
      id: 'worklife-balance',
      useIcons: true,
      type: responsiveMatrix,
      section: (
        <Wrapper>
          <Q>Page 1 of 4</Q>
          <Text color={'#F9744C'}>Wellbeing</Text>
          {/* <T>
            When answering these questions, it can be difficult to separate work and personal life.
            Think carefully on each answer, reflecting on the nature of the job separate to your
            personal circumstances or behaviours. For example, things like having overly high
            standards, a tendency to procrastination or the fact you live far away from your place
            of work are not caused by the job. It can be tricky to separate these things. The
            helpful thing to do can be to think if you were in another job – would the situation be
            the less or more or the same?
          </T> */}
          <T>Please indicate your agreement with the following statements:</T>
          <Divider />
        </Wrapper>
      ),
      choices: [
        {
          id: 1,
          text:
            'My job does NOT hinder me doing the things I love and seeing the people that are important to me',
          value:
            'My job does NOT hinder me doing the things I love and seeing the people that are important to me',
        },
        {
          id: 2,
          text: 'My job does NOT negatively affect the quality of sleep I get',
          value: 'My job does NOT negatively affect the quality of sleep I get',
        },
        {
          id: 3,
          text: 'My job does NOT hinder me from getting the exercise that is right for me',
          value: 'My job does NOT hinder me from getting the exercise that is right for me',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    {
      id: 'worklife-balance-2',
      useIcons: true,
      type: responsiveMatrix,
      section: (
        <Wrapper>
          <Q>Page 2 of 4</Q>
          <Text color={'#F9744C'}>Wellbeing</Text>
          <T>Please indicate your agreement with the following statements:</T>
          <Divider />
        </Wrapper>
      ),
      text: '',
      choices: [
        {
          id: 1,
          text: 'My job does NOT hinder me from being able to make healthy food choices',
          value: 'My job does NOT hinder me from being able to make healthy food choices',
        },
        {
          id: 2,
          text: 'My job does NOT make me feel isolated due to my ethnicity or gender identity ',
          value: 'My job does NOT make me feel isolated due to my ethnicity or gender identity ',
        },
      ],
      options: [
        {
          id: 1,
          text: 'Strongly disagree',
          value: 'Strongly disagree',
          icon: <IconVerySad />,
        },
        {
          id: 2,
          text: 'Disagree',
          value: 'Disagree',
          icon: <IconSad />,
        },
        {
          id: 3,
          text: 'Neutral',
          value: 'Neutral',
          icon: <IconOk />,
        },
        {
          id: 4,
          text: 'Agree',
          value: 'Agree',
          icon: <IconHappy />,
        },
        {
          id: 5,
          text: 'Strongly agree',
          value: 'Strongly agree',
          icon: <IconVeryHappy />,
        },
      ],
    },
    // 3.2
    {
      id: 'job-distress',
      type: multiChoice,
      section: (
        <Wrapper>
          <Q>Page 3 of 4</Q>
          <Text color={'#F9744C'}>Wellbeing</Text>
          <Divider />
        </Wrapper>
      ),
      text: 'My job creates distress',
      choices: [
        {
          id: 1,
          text: 'Yes',
          value: 'Yes',
          icon: <Check fill="currentColor" />,
          fill: '#50d250',
        },
        {
          id: 2,
          text: 'No',
          value: 'No',
          icon: <Cross fill="currentColor" />,
          fill: '#1890ff',
        },
      ],
      next: ({ answers }) => {
        if (answers['job-distress'].value === 'Yes') {
          return 'job-distress-p2'
        }
        return 'role-details'
      },
    },
    // 3.3
    {
      id: 'job-distress-p2',
      type: 'imageRadioList',
      section: (
        <Wrapper>
          <Q>Page 4 of 4</Q>
          <Text color={'#F9744C'}>Wellbeing</Text>
          <Divider />
        </Wrapper>
      ),
      text: 'Which of the following are contributing to your work-caused distress?',
      placeholder: 'Other, please specify..',
      choices: [
        {
          id: 1,
          text: 'Lack of resources (human, equipment or budget)',
          value: 'Lack of resources (human, equipment or budget)',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 2, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 2,
          text: 'Lack of support',
          value: 'Lack of support',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 2, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 3,
          text: 'High pressure, frequent urgent deadlines',
          value: 'High pressure, frequent urgent deadlines',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 2, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 4,
          text: 'Excessive cognitive switching ',
          value: 'Excessive cognitive switching ',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 2, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 5,
          text: 'Unrealistic responsibilities',
          value: 'Unrealistic responsibilities',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 2, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 6,
          text: 'Human conflict / bullying / injustice ',
          value: 'Human conflict / bullying / injustice ',
          answerChoices: [
            { id: 1, value: 'Yes', icon: <IconCheck fill="currentColor" />, fill: '#50d250' },
            { id: 2, value: 'No', icon: <IconCross fill="currentColor" />, fill: '#1890ff' },
          ],
        },
        {
          id: 7,
          text: 'Other - Please specify',
          type: 'commentBox',
        },
      ],
    },
    // 4
    {
      id: 'role-details',
      type: multiChoice,
      section: (
        <div>
          <p style={{ fontSize: '1rem' }}>
            It would be great to know what your role is so we can look for trends within roles.
            However, if you prefer not to indicate, that is okay.
          </p>
        </div>
      ),
      text: 'Which of the following describes your role:',
      choices: [
        { id: 1, text: 'Associate', value: 'Associate' },
        { id: 2, text: 'Research manager', value: 'Research manager' },
        { id: 3, text: 'Senior team', value: 'Senior team' },
        { id: 4, text: 'Prefer not to indicate', value: 'Prefer not to indicate' },
      ],
    },
    {
      id: 'role-details2',
      type: multiChoice,
      text: 'Which of the following fits your tenure?',
      choices: [
        {
          id: 1,
          text: 'Been with Scarlatti for 3 years or more',
          value: 'Been with Scarlatti for 3 years or more',
        },
        {
          id: 2,
          text: 'Been with Scarlatti 1 to 3 years',
          value: 'Been with Scarlatti 1 to 3 years',
        },
        {
          id: 3,
          text: 'Been with Scarlatti less than a year',
          value: 'Been with Scarlatti less than a year',
        },
        { id: 4, text: 'Prefer not to indicate', value: 'Prefer not to indicate' },
      ],
    },
    {
      id: 'end-page',
      type: 'endPage',
      text: ({ answers }) => {
        return (
          <div>
            <p style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              Thank you, we look forward to sharing the results with you{' '}
              <IconVeryHappy style={{ marginLeft: '1rem' }} />
            </p>
          </div>
        )
      },
    },
  ],
  settings: {
    writeToFirestore: true,
    surveyName: 'internal-scarlatti',
    hideButton: true,
    // showQid: true,
    useSections: false,
    // secondaryColour: '#f38231',
    // contrastColour: '#fff',
    hideSubmit: true,
    hideLogo: false,
    logoFirstPageOnly: true,
    companyLogo: scarlatti,
    logoLocation: 'space-between',
    progressBar: true,
    objectPosition: 'center',
    // primaryColour: '#05B153',
    primaryColour: '#F9744C',
    useVideo: true,
    backgroundVideo,
  },
}

export default Pias
